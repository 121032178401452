import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { Field } from 'formik';

import { Tooltip } from '..';

import './sass/field.scss';

const FormField = ({ name, label, className, labelStyle, type, value, errors, touched, labelVisible, options, children, tooltip, ...props }) => {
  const containerClasses = classNames(className || 'form-group', {
    'is-invalid': errors && touched,
    'is-label-visible': value || labelVisible,
  });

  const fieldClass = classNames('form-control', {
    'is-invalid': errors && touched,
  });

  if (options) {
    return (
      <div className={containerClasses}>
        {label && (
          <label htmlFor={name} className="mb-0 mt-1" style={labelStyle}>
            {label}
            {tooltip && <Tooltip>{tooltip}</Tooltip>}
          </label>
        )}
        <Field name={name} type={type} as={type} className={`${fieldClass}`} {...props}>
          {_.map(options, ({ name, value }, key) => (
            <option key={key} value={value}>
              {name}
            </option>
          ))}
        </Field>
      </div>
    );
  }

  return (
    <div className={containerClasses}>
      {label && (
        <label htmlFor={name} className="mb-0 mt-1" style={labelStyle}>
          {label}
          {tooltip && <Tooltip>{tooltip}</Tooltip>}
        </label>
      )}
      <Field name={name} type={type} className={`${fieldClass}`} {...props} />
      {children}
      {typeof errors === 'string' && (
        <div className="invalid-feedback" style={{ textAlign: 'left' }}>
          {errors}
        </div>
      )}
    </div>
  );
};

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.string,
  touched: PropTypes.bool,
  labelVisible: PropTypes.bool,
  options: PropTypes.array,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  className: PropTypes.string,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
};

export default FormField;
