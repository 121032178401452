const prefix = '@drivex/translations';

const GET_LANGUAGES_SUCCESS = `${prefix}/GET_LANGUAGES_SUCCESS`;

const GET_SOURCE_TRANSLATIONS_SUCCESS = `${prefix}/GET_SOURCE_TRANSLATIONS_SUCCESS`;
const GET_TARGETED_TRANSLATIONS_SUCCESS = `${prefix}/GET_TARGETED_TRANSLATIONS_SUCCESS`;

const GET_SOURCE_TERMS_AND_CONDITIONS_SUCCESS = `${prefix}/GET_SOURCE_TERMS_AND_CONDITIONS_SUCCESS`;
const GET_TARGETED_TERMS_AND_CONDITIONS_SUCCESS = `${prefix}/GET_TARGETED_TERMS_AND_CONDITIONS_SUCCESS`;

const GET_SOURCE_GDPR_PRIVACY_POLICY = `${prefix}/GET_SOURCE_GDPR_PRIVACY_POLICY`;
const GET_TARGETED_GDPR_PRIVACY_POLICY = `${prefix}/GET_TARGETED_GDPR_PRIVACY_POLICY`;

export default {
  GET_LANGUAGES_SUCCESS,
  GET_SOURCE_TRANSLATIONS_SUCCESS,
  GET_TARGETED_TRANSLATIONS_SUCCESS,
  GET_SOURCE_TERMS_AND_CONDITIONS_SUCCESS,
  GET_TARGETED_TERMS_AND_CONDITIONS_SUCCESS,
  GET_SOURCE_GDPR_PRIVACY_POLICY,
  GET_TARGETED_GDPR_PRIVACY_POLICY
};
