import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { checkIsDateTodayOrLessThanToday, getInputDateFormat, createISODate, dateFormatter, getPreviousDate } from '../../../../../../utils/date';
import { PhotoSeriesShareModal } from '../../../../../../components';
import { makePost, makePatch } from '../../../../../../api/ajax';
import { trackEvent, EVENT_TYPES } from '../../../../../../api/analytics';
import { actions as photoSeriesActions } from '../../../../../../redux/photoSeries';
import { selectors as customerSelectors } from '../../../../../../redux/customers';
import { constants as commentsConstants } from '../../../../../../redux/comments';

const getExpirationDate = (expiringDate, toggleOn, timeZoneOffset) => {
  if (toggleOn) {
    if (expiringDate < new Date().toDateInputValue()) {
      return null;
    }
    // NOTE: We convert local expiresAt date into customer specific TZ
    return createISODate(expiringDate, timeZoneOffset);
  }
  const twoDaysAgoDate = dateFormatter(getPreviousDate(2)).inputDateFormat();
  return createISODate(twoDaysAgoDate, timeZoneOffset);
};

const PhotoSeriesShareModalSection = ({ show, onHide, photoSeries, customersTags }) => {
  const dispatch = useDispatch();
  const expiresAt = photoSeries?.accessUrl?.expiresAt;
  const id = photoSeries?.accessUrl?.id;
  const url = photoSeries?.accessUrl?.url;
  const [ajaxError, setAjaxError] = useState('');
  const [accessUrlToBeCopied, setAccessUrlToBeCopied] = useState(url);
  const [isCopied, setIsCopied] = useState(false);
  const [isLoadingSharePhotoSeries, setIsLoadingSharePhotoSeries] = useState(false);
  const [shownCopiedSuccessMessage, setShownCopiedSuccessMessage] = useState(!!url);
  const [expiringDate, setExpiringDate] = useState((!!expiresAt && getInputDateFormat(expiresAt)) || '');
  const [toggleOn, setToggleOn] = useState(photoSeries?.accessUrl === null ? false : !checkIsDateTodayOrLessThanToday(expiresAt));
  const [sharedComments, setSharedComments] = useState([commentsConstants.COMMENT_ORIGIN.PUBLIC]);
  const [publishToCompanyToggleOn, setPublishToCompanyToggleOn] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [sharedWithSavedMessage, setSharedWithSavedMessage] = useState(null);
  const currentCustomer = useSelector(customerSelectors.selectCurrentCustomer);

  const companiesSelection = customersTags != null
    ? customersTags
      .filter((tag) => tag.customerId !== currentCustomer.id)
      .map((tag) => ({ id: tag.customerId, name: tag.value }))
    : [];

  const initialCompaniesSelection = photoSeries.sharedWithCustomerIds && customersTags
    ? photoSeries.sharedWithCustomerIds.map((customerId) => ({
      id: customerId,
      name: customersTags.find((tag) => tag.customerId === customerId)?.value
    }))
    : [];

  const handleSaveSharedWith = () => {
    saveSharedWith(selectedCompanies.map((company) => company.id));
  };

  const saveSharedWith = useCallback((customerIds) => {
    setSharedWithSavedMessage(null);
    const data = {
      sharedWithCustomerIds: customerIds
    };
    makePatch(`photoSeries/${photoSeries.id}`, data).subscribe(
      ({ response }) => {
        dispatch(photoSeriesActions.loadPhotoSeriesSuccess(photoSeries.id, response));
        setSharedWithSavedMessage('Shared with has been updated!');
      }
    );
  }, [photoSeries.id, dispatch]);

  useEffect(() => {
    if (!publishToCompanyToggleOn && selectedCompanies.length > 0) {
      saveSharedWith([]);
    }
  }, [publishToCompanyToggleOn, selectedCompanies, saveSharedWith]);

  useEffect(() => {
    if (!show) {
      setSharedWithSavedMessage(null);
      setPublishToCompanyToggleOn(photoSeries.sharedWithCustomerIds !== null && photoSeries.sharedWithCustomerIds.length > 0);
    }
  }, [show, photoSeries.sharedWithCustomerIds]);
  useEffect(() => {
    if (photoSeries?.accessUrl?.sharedComments) {
      setSharedComments(photoSeries.accessUrl.sharedComments);
    }
  }, [photoSeries, setSharedComments]);

  // updates the toggleOn, accessUrlToBeCopied, and shownCopiedSuccessMessage state as photoSeries accessUrl data persists
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setToggleOn((toggleOn) => {
        const currentToggleStatus = photoSeries?.accessUrl === null
          ? false : !checkIsDateTodayOrLessThanToday(expiresAt);
        if (toggleOn !== currentToggleStatus) {
          return currentToggleStatus;
        }
        return toggleOn;
      });
      setAccessUrlToBeCopied((accessUrlToBeCopied) => {
        if (url !== accessUrlToBeCopied) {
          setShownCopiedSuccessMessage(true);
          return url;
        }
        return accessUrlToBeCopied;
      });
      setExpiringDate(() => (!!expiresAt && getInputDateFormat(expiresAt)) || '');
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [photoSeries?.accessUrl]);

  const sharePhotoSeriesHandler = (expiringDate, toggleOn, sharedComments) => {
    setIsLoadingSharePhotoSeries(true);
    setIsCopied(false);
    if (id) {
      const expiresAt = getExpirationDate(expiringDate, toggleOn, currentCustomer.timeZoneOffset);
      makePatch(`accessUrls/${id}`, {
        id,
        photoSeriesId: photoSeries.id,
        expiresAt,
        sharedComments
      }).subscribe(
        ({ response }) => {
          dispatch(photoSeriesActions.loadPhotoSeriesSuccess(response.photoSeriesId, { ...photoSeries, accessUrl: response }));
          // Track event in Analytics
          trackEvent(EVENT_TYPES.PUBLIC_SHARING_CHANGED, { isPublic: checkIsDateTodayOrLessThanToday(expiresAt) });
          setIsLoadingSharePhotoSeries(false);
          setAccessUrlToBeCopied(response.url);
          setShownCopiedSuccessMessage(true);
        },
        ({ response }) => {
          setIsLoadingSharePhotoSeries(false);
          setShownCopiedSuccessMessage(false);
          setAjaxError(response.error);
        },
      );
    } else {
      makePost('accessUrls', {
        photoSeriesId: photoSeries.id,
        sharedComments
      }).subscribe(
        ({ response }) => {
          dispatch(photoSeriesActions.loadPhotoSeriesSuccess(response.photoSeriesId, { ...photoSeries, accessUrl: response }));
          // Track event in Analytics
          trackEvent(EVENT_TYPES.PUBLIC_SHARING_CHANGED, { isPublic: true });
          setIsLoadingSharePhotoSeries(false);
          setAccessUrlToBeCopied(response.url);
          setShownCopiedSuccessMessage(true);
        },
        ({ response }) => {
          setIsLoadingSharePhotoSeries(false);
          setShownCopiedSuccessMessage(false);
          setAjaxError(response.error);
        },
      );
    }
  };

  const photoSeriesShareModalProps = {
    show,
    onHide,
    isLoadingSharePhotoSeries,
    isCopied,
    toggleOn,
    shownCopiedSuccessMessage,
    accessUrlToBeCopied,
    ajaxError,
    expiringDate,
    sharedComments,
    setSharedComments,
    setIsCopied,
    setAjaxError,
    sharePhotoSeriesHandler,
    setToggleOn,
    setExpiringDate,
    publishToCompanyToggleOn,
    setPublishToCompanyToggleOn,
    companiesSelection,
    initialCompaniesSelection,
    setSelectedCompanies,
    saveSharedWith: handleSaveSharedWith,
    sharedWithSavedMessage
  };

  return <PhotoSeriesShareModal {...photoSeriesShareModalProps} />;
};

PhotoSeriesShareModalSection.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  photoSeries: PropTypes.object,
  customersTags: PropTypes.array
};

export default PhotoSeriesShareModalSection;
