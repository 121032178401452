import { createSelector } from 'reselect';

import constants from './constants';

const selectCustomerConfigurationsState = (state) => state[constants.NAME];
const selectCustomerConfiguration = createSelector(
  [selectCustomerConfigurationsState],
  (customerConfigurations) => customerConfigurations.customerConfiguration
);
const selectLocalLogoUrl = createSelector(
  [selectCustomerConfigurationsState],
  (customerConfigurations) => customerConfigurations.localLogoUrl
);

export default {
  selectCustomerConfiguration,
  selectLocalLogoUrl,
};
