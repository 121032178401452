import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationService } from '../../services';

import { makeGet } from '../../api/ajax';
import { routes } from '../../routes/Routes';
import { MenuPage } from '../../layouts';
import { PageTitle, SettingsSummary } from '../../components';
import { actions as customerConfigurationsActions, selectors as customerConfigurationsSelectors } from '../../redux/customerConfigurations';
import { actions as customerActions, selectors as customerSelectors } from '../../redux/customers';
import { constants as sessionConstants } from '../../redux/session';
import { actions as translationsActions, selectors as translationsSelectors } from '../../redux/translations';
import { actions as tagsActions, selectors as tagsSelectors } from '../../redux/tags';

import DefaultLanguageModal from './containers/DefaulLanguageModal/DefaultLanguageModal';
import CustomerWatermarkModal from './containers/CustomerWatermarkModal/CustomerWatermarkModal';

import UsersTable from './containers/UsersTable/UsersTable';
import ReportModal from './containers/ReportModal/ReportModal';
import CustomerModal from './containers/CustomerModal/CustomerModal';
import SubscriptionsSection from './containers/SubscriptionsSection/SubscriptionsSection';
import WebhookModal from './containers/WebhookModal/WebhookModal';
import SubscriptionWarningModal from './containers/SubscriptionWarningModal/SubscriptionWarningModal';
import InactivityTimeoutModal from './containers/InactivityTimeoutModal/InactivityTimeoutModal';
import ScriveSettingsModal from './containers/ScriveSettingsModal/ScriveSettingsModal';
import ReportLanguageModal from './containers/ReportLanguageModal/ReportLanguageModal';
import TagModal from './containers/TagModal/TagModal';
import InslyTenantIdModal from './containers/InslyTenantIdModal/InslyTenantIdModal';
import EnabledLanguagesModal from './containers/EnabledLanguagesModal/EnabledLanguagesModal';

const loadLanguages = (dispatch) => {
  makeGet('languages').subscribe(({ response }) => {
    dispatch(translationsActions.getLanguagesSuccess(response));
  });
};

const Account = () => {
  const [showTableModal, setShowTableModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showWebhookModal, setShowWebhookModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [showInactivityTimeoutModal, setShowInactivityTimeoutModal] = useState(false);
  const [showCustomerWatermarkEnabledModal, setShowCustomerWatermarkEnabledModal] = useState(false);
  const [showScriveSettingsModal, setShowScriveSettingsModal] = useState(false);
  const [showReportLanguageModal, setShowReportLanguageModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [showInslyTenantIdModal, setShowInslyTenatIdModal] = useState(false);
  const [showEnabledLanguagesModal, setShowEnabledLanguagesModal] = useState(false);

  const [showSubscriptionWarningModal, setShowSubscriptionWarningModal] = useState(false);
  const [isLoadingCustomersDetails, setIsLoadingCustomersDetails] = useState(false);

  const dispatch = useDispatch();
  const customerDetails = useSelector(customerSelectors.selectCustomerDetails);
  const customerConfiguration = useSelector(customerConfigurationsSelectors.selectCustomerConfiguration);
  const languages = useSelector(translationsSelectors.selectLanguages);
  const customerEnabledLanguages = customerConfiguration
    ? languages.filter((lang) => customerConfiguration.enabledLanguages.includes(lang.id))
    : [];
  const customerTag = useSelector(tagsSelectors.selectCustomerTag);

  useEffect(() => {
    if (customerTag == null && customerDetails) {
      makeGet(`tags?customerId=${customerDetails.id}`).subscribe(({ response }) => {
        const tag = response && response.length !== 0
          ? response[0].value
          : '';
        dispatch(tagsActions.loadCustomerTagSuccess(tag));
      });
    }
  }, [customerTag, customerDetails, dispatch]);

  useEffect(() => {
    if (customerDetails) return;

    setIsLoadingCustomersDetails(true);

    makeGet('customers/details').subscribe(({ response }) => {
      setIsLoadingCustomersDetails(false);
      dispatch(customerActions.loadCustomerDetailsSuccess(response));
    }, () => {
      setIsLoadingCustomersDetails(false);
    });
  }, [customerDetails, dispatch]);

  useEffect(() => {
    let isMounted = true;

    if (!customerConfiguration && isMounted) {
      makeGet('customerConfigurations/current').subscribe(({ response }) => {
        dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(response));
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (languages?.length > 0) return;

    loadLanguages(dispatch);
  }, [languages, dispatch]);

  if (languages?.length < 1) return null;

  const defaultLanguage = languages.find((l) => l.code === customerDetails?.defaultLanguage)?.name;
  const reportLanguage = languages.find((l) => l.code === customerConfiguration?.reportLanguage)?.name;
  const canCreateCustomers = authenticationService.currentUserValue.permissions.indexOf(sessionConstants.PERMISSION_TYPES.CREATE_CUSTOMERS) !== -1;

  return (
    <MenuPage currentRoute={routes.account}>
      <PageTitle>
        <PageTitle.Left headingText="Account" isLoading={isLoadingCustomersDetails} />

        <PageTitle.Right>
          {canCreateCustomers && (
            <button className="btn btn-primary" type="button" onClick={() => setShowCustomerModal(true)}>
              Add new customer
            </button>
          )}
        </PageTitle.Right>
      </PageTitle>

      {customerDetails && customerConfiguration && languages && (
        <div className="row">
          <div className="col-sm-6">
            <SettingsSummary
              title="Access management"
              testId="accessManagementEditBtn"
              content={`${customerDetails.userCount} users`}
              onEditClick={() => setShowTableModal(true)}
            />
            <SettingsSummary
              title="Webhook endpoint URL"
              testId="webhookEditBtn"
              content={customerConfiguration.webhookUrl}
              onEditClick={() => setShowWebhookModal(true)}
            />
            <SettingsSummary
              title="Warning subscription usage"
              testId="subEditBtn"
              content={
                !customerConfiguration.subscriptionUsageWarning
                  ? 'Off'
                  : `When ${customerConfiguration.subscriptionUsageWarning}% is used`
              }
              onEditClick={() => setShowSubscriptionWarningModal(true)}
            />
            <SettingsSummary
              title="Customer watermark"
              testId="customerWatermarkEditBtn"
              content={customerConfiguration.customerWatermarkEnabled ? 'On' : 'Off'}
              onEditClick={() => setShowCustomerWatermarkEnabledModal(true)}
            />
            <SettingsSummary
              title="Tag"
              testId="tagBtn"
              content={`${customerConfiguration.sharingToCompanyEnabled ? 'On' : 'Off'}${(customerTag ? (` / ${customerTag}`) : '')}`}
              onEditClick={() => setShowTagModal(true)}
            />
            <SettingsSummary
              title="Insly tenant id"
              testId="inslyTenantIdBtn"
              content={customerConfiguration.inslyTenantId}
              onEditClick={() => setShowInslyTenatIdModal(true)}
            />
          </div>
          <div className="col-sm-6">
            <SettingsSummary
              title="Report email"
              testId="reportEmailEditBtn"
              content={customerDetails.reportingEmail}
              onEditClick={() => setShowReportModal(true)}
            />
            <SettingsSummary
              title="Enabled languages"
              testId="enabledLanguagesBtn"
              content={`${customerConfiguration.enabledLanguages.length}`}
              onEditClick={() => setShowEnabledLanguagesModal(true)}
            />
            <SettingsSummary
              title="Report language"
              testId="reportLanguageBtn"
              content={reportLanguage}
              onEditClick={() => setShowReportLanguageModal(true)}
            />
            <SettingsSummary
              title="Default link generation language"
              testId="langEditBtn"
              content={defaultLanguage}
              onEditClick={() => setShowLanguageModal(true)}
            />
            <SettingsSummary
              title="Inactivity timeout"
              tooltipTitle="If X number of minutes passes between taking two external images, SmartScan will restart the users session"
              testId="inactivityTimeoutEditBtn"
              content={
                !customerConfiguration.inactivityTimeout
                  ? 'No timeout'
                  : `${customerConfiguration.inactivityTimeout} minutes`
              }
              onEditClick={() => setShowInactivityTimeoutModal(true)}
            />
            <SettingsSummary
              title="Scrive settings"
              testId="scriveSettingsBtn"
              content={customerConfiguration.scriveUserEmail}
              onEditClick={() => setShowScriveSettingsModal(true)}
            />
          </div>
        </div>
      )}

      {showTableModal && <UsersTable show={showTableModal} id={customerDetails.id} onHide={() => setShowTableModal(false)} />}
      <WebhookModal show={showWebhookModal} onModalClose={() => setShowWebhookModal(false)} />
      <InslyTenantIdModal show={showInslyTenantIdModal} onModalClose={() => setShowInslyTenatIdModal(false)} />
      <ReportModal show={showReportModal} onModalClose={() => setShowReportModal(false)} />
      <DefaultLanguageModal languages={customerEnabledLanguages} show={showLanguageModal} onModalClose={() => setShowLanguageModal(false)} />
      <CustomerWatermarkModal show={showCustomerWatermarkEnabledModal} onModalClose={() => setShowCustomerWatermarkEnabledModal(false)} />
      <ScriveSettingsModal show={showScriveSettingsModal} onModalClose={() => setShowScriveSettingsModal(false)} />
      <ReportLanguageModal
        show={showReportLanguageModal}
        customerConfiguration={customerConfiguration}
        onModalClose={() => setShowReportLanguageModal(false)}
        languages={customerEnabledLanguages}
      />
      {
        customerConfiguration && customerTag != null && (
          <TagModal
            show={showTagModal}
            customerConfiguration={customerConfiguration}
            customerTag={customerTag}
            onModalClose={() => setShowTagModal(false)}
          />
        )
      }

      {showCustomerModal && <CustomerModal show={showCustomerModal} onModalClose={() => setShowCustomerModal(false)} />}
      {showSubscriptionWarningModal && (
      <SubscriptionWarningModal
        shownModal={showSubscriptionWarningModal}
        customerConfiguration={customerConfiguration}
        onModalClose={() => setShowSubscriptionWarningModal(false)}
      />
      )}
      {showInactivityTimeoutModal && (
        <InactivityTimeoutModal
          customerConfiguration={customerConfiguration}
          show={showInactivityTimeoutModal}
          onModalClose={() => setShowInactivityTimeoutModal(false)}
        />
      )}
      {
        showEnabledLanguagesModal && (
          <EnabledLanguagesModal
            show={showEnabledLanguagesModal}
            customerConfiguration={customerConfiguration}
            onModalClose={() => setShowEnabledLanguagesModal(false)}
            languages={languages}
          />
        )
      }
      <SubscriptionsSection />
    </MenuPage>
  );
};

export default Account;
