import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CaretDown } from '../../assets/img/icn-caret-down.svg';

import './sass/text-expander.scss';

const TextExpander = ({ type, values }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      {values && values.length > 0 && (
        <div className={`text-expander-container border--${type}`}>
          <div
            className="btn-wrapper"
            role="button"
            tabIndex={0}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-controls="contents"
          >
            <span className="btn-wrapper__text">
              Show
              {isExpanded ? ' less' : ' more'}
            </span>

            <span className={`btn-wrapper__arrow--${isExpanded ? 'up' : 'down'}`}>
              <CaretDown className="svg-small" />
            </span>
          </div>

          {isExpanded && (
            <ul className={`content-wrapper content--type--${type}`} id="content">
              {values.map((value, i) => (
                <li key={i.toString()} className="list-value">
                  {value}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

TextExpander.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'warning']).isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
};

export default TextExpander;
