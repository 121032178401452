import types from './types';

const loadMonthlyConsumptionSuccess = (monthlyConsumption, month) => ({
  type: types.LOAD_MONTHLY_CONSUMPTION_SUCCESS,
  payload: {
    monthlyConsumption,
    month,
  },
});

export default {
  loadMonthlyConsumptionSuccess,
};
