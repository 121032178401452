import React from 'react';
import Routes from './routes/Routes';

import './@proptypes/proptypes';
import './polyfills/date-polyfills';
import './components/SkeletonUI/sass/skeleton-ui.scss';

const App = () => <Routes />;

export default App;
