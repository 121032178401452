import { createSelector } from 'reselect';

import constants from './constants';

const selectState = (state) => state[constants.NAME];
const selectExperimentalFeatures = createSelector(
  [selectState],
  (state) => state
);

export default {
  selectExperimentalFeatures
};
