import types from './types';

const loadBlacklistedUserAgents = (userAgents) => ({
  type: types.LOAD_USER_AGENT_BLACKLIST_SUCCESS,
  payload: {
    userAgents
  }
});

const deleteBlacklistedUserAgent = (id) => ({
  type: types.DELETE_BLACKLISTED_USER_AGENT,
  payload: {
    id
  }
});

const newBlacklistedUserAgent = (id, phrase, comment, operatingSystem) => ({
  type: types.NEW_BLACKLISTED_USER_AGENT,
  payload: {
    id, phrase, comment, operatingSystem
  }
});

export default {
  loadBlacklistedUserAgents,
  deleteBlacklistedUserAgent,
  newBlacklistedUserAgent
};
