import { useState, useEffect, useLayoutEffect } from 'react';

import { makeGet, makePost, makePut, makePatch, makeDelete, makeGetFile, makeFileUpload } from '../../api/ajax';

/**
 * @param {string} url a string property for ajax resource location
 * @param {null || instanceof HTMLElement} layoutDomNodeRef a DOMNode instance usually by a useRef push system
 * @param {Function} errorDispatch a callback function that takes an argument needed for redux dispatch
 * @param {Function} successDispatch a callback function that takes an argument needed for redux dispatch
 * @param {any [] } depsArr array of optional dependencies when isUseLayoutEffectGetRequest || isUseEffectGetRequest
 * @param {boolean } noInternalDataState an optional boolean that helps to avoid redundant internal-data-state
 * @param {boolean} effectType an optional string param that tells which effect should trigger a push-base get request.
 * It can also be used for conditionally triggering an effect e.g when id is available like this: {effectType: obj?.id ? EFFECT_TYPES.USE_EFFECT:""}
 */
export const EFFECT_TYPES = {
  USE_LAYOUT_EFFECT: 'useLayoutEffect',
  USE_EFFECT: 'useEffect',
};

export function useAjax(args) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const { url, depsArr, effectType, layoutDomNodeRef, successDispatch, errorDispatch, noInternalDataState = true } = args;

  const makeGetHandler = () => {
    makeGet(url).subscribe(
      ({ response }) => {
        setIsLoading(false);
        if (!noInternalDataState) setData(response);
        if (successDispatch) successDispatch(response);
      },
      (error) => {
        setIsLoading(false);
        setError(error);
        if (errorDispatch) errorDispatch(error);
      }
    );
  };

  useEffect(() => {
    let isDOMContentLoaded = true;

    if (isDOMContentLoaded && effectType === EFFECT_TYPES.USE_EFFECT) {
      setIsLoading(true);
      makeGetHandler();
    }
    return () => {
      isDOMContentLoaded = false;
    };
    // eslint-disable-next-line
  }, [url, depsArr]);

  useLayoutEffect(() => {
    let isDOMContentLoaded = true;

    if (isDOMContentLoaded && effectType === EFFECT_TYPES.USE_LAYOUT_EFFECT) {
      setIsLoading(true);

      makeGetHandler();
    }
    return () => {
      isDOMContentLoaded = false;
    };
    // eslint-disable-next-line
  }, [url, layoutDomNodeRef, depsArr]);

  const getRequest = () => {
    setIsLoading(true);
    makeGetHandler();
  };

  const getFileRequest = () => {
    setIsLoading(true);

    makeGetFile(url).subscribe(
      ({ response }) => {
        setIsLoading(false);
        if (!noInternalDataState) setData(response);
        if (successDispatch) successDispatch(response);
      },
      (error) => {
        setIsLoading(false);
        setError(error);
        if (errorDispatch) errorDispatch(error);
      }
    );
  };

  const postRequest = (data) => {
    setIsLoading(true);

    makePost(url, data).subscribe(
      ({ response }) => {
        setIsLoading(false);
        if (!noInternalDataState) setData(response);
        if (successDispatch) successDispatch(response);
      },
      (error) => {
        setIsLoading(false);
        setError(error);
        if (errorDispatch) errorDispatch(error);
      }
    );
  };

  const putRequest = (data) => {
    setIsLoading(true);

    makePut(url, data).subscribe(
      ({ response }) => {
        setIsLoading(false);
        if (!noInternalDataState) setData(response);
        if (successDispatch) successDispatch(response);
      },
      (error) => {
        setIsLoading(false);
        setError(error);
        if (errorDispatch) errorDispatch(error);
      }
    );
  };

  const patchRequest = (data) => {
    setIsLoading(true);

    makePatch(url, data).subscribe(
      ({ response }) => {
        setIsLoading(false);
        if (!noInternalDataState) setData(response);
        if (successDispatch) successDispatch(response);
      },
      (error) => {
        setIsLoading(false);
        setError(error);
        if (errorDispatch) errorDispatch(error);
      }
    );
  };

  const deleteRequest = () => {
    setIsLoading(true);

    makeDelete(url).subscribe(
      ({ response }) => {
        setIsLoading(false);
        if (!noInternalDataState) setData(response);
        if (successDispatch) successDispatch(response);
      },
      (error) => {
        setIsLoading(false);
        setError(error);
        if (errorDispatch) errorDispatch(error);
      }
    );
  };

  const postFileRequest = (dataUri, postData, fileKey, method) => {
    setIsLoading(true);

    makeFileUpload(url, dataUri, postData, fileKey, method).subscribe(
      ({ response }) => {
        setIsLoading(false);
        if (!noInternalDataState) setData(response);
        if (successDispatch) successDispatch(response);
      },
      (error) => {
        setIsLoading(false);
        setError(error);
        if (errorDispatch) errorDispatch(error);
      }
    );
  };

  return { isLoading, data, error, setError, getRequest, getFileRequest, postRequest, putRequest, patchRequest, deleteRequest, postFileRequest };
}
