import types from './types';

const loadCustomerDetailsSuccess = (customerDetails) => ({
  type: types.LOAD_CUSTOMER_DETAILS_SUCCESS,
  payload: {
    customerDetails,
  },
});

const loadCustomerSuccess = (customer) => ({
  type: types.LOAD_CUSTOMER_SUCCESS,
  payload: {
    customer,
  },
});

export default {
  loadCustomerDetailsSuccess,
  loadCustomerSuccess,
};
