import types from './types';

import { authenticationService } from '../../services';

const initialState = {
  customerCode: authenticationService.currentUserValue
    && authenticationService.currentUserValue.customerCode,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOG_IN: {
      const {
        customerCode,
      } = action.payload;

      return {
        ...state,
        customerCode,
      };
    }
    case types.LOG_OUT: {
      return {};
    }
    default:
      return state;
  }
};

export default sessionReducer;
