import { createSelector } from 'reselect';

import { NAME, STATE_KEYS } from './constants';

const selectTranslationsState = (state) => state[NAME];

const { SOURCE_TRANSLATIONS, TARGETED_TRANSLATIONS, LANGUAGES, SOURCE_TERMS_AND_CONDITIONS, TARGETED_TERMS_AND_CONDITIONS } = STATE_KEYS;

const selectLanguages = createSelector([selectTranslationsState], (translations) => translations[LANGUAGES]);
const selectSourceTranslations = createSelector([selectTranslationsState], (translations) => translations[SOURCE_TRANSLATIONS]);
const selectTargetedTranslations = createSelector([selectTranslationsState], (translations) => translations[TARGETED_TRANSLATIONS]);
const selectSourceTermsAndConditions = createSelector([selectTranslationsState], (translations) => translations[SOURCE_TERMS_AND_CONDITIONS]);
const selectTargetedTermsAndConditions = createSelector([selectTranslationsState], (translations) => translations[TARGETED_TERMS_AND_CONDITIONS]);

export default {
  selectLanguages,
  selectSourceTranslations,
  selectTargetedTranslations,
  selectSourceTermsAndConditions,
  selectTargetedTermsAndConditions
};
