import { useReducer } from 'react';

import { checkboxReducer } from '../store/checkbox.reducer';
import { checkYes, checkNo } from '../store/checkbox.action';

const initialState = {
  yes: null,
  no: null,
};

export const useCheckbox = (reducer = checkboxReducer) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchCheckYes = () => dispatch(checkYes());
  const dispatchCheckNo = () => dispatch(checkNo());

  return { state, dispatchCheckYes, dispatchCheckNo };
};
