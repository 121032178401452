import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { routes } from '../../routes/Routes';
import { Container, Logo } from '../index';

import './sass/menu.scss';

const getNavigationItemClassNames = (route, currentRoute) => {
  let classNames = 'nav-item';

  if (route && currentRoute === route) classNames += ' active';

  return classNames;
};

const MenuItem = ({ name, route, currentRoute, onClick }) => (
  <li className={getNavigationItemClassNames(route, currentRoute)}>
    {onClick ? (
      <button className="is-transparent nav-link" onClick={onClick} type="button">
        {name}
      </button>
    ) : (
      <Link className="nav-link" to={route}>
        {name}
      </Link>
    )}
  </li>
);

const Menu = ({ children }) => (
  <nav className="navbar navbar-expand-md navbar-dark nav-custom">
    <Container className="collapse navbar-collapse">
      <Link to={routes.home} className="navbar-brand__link">
        <Logo white />
      </Link>

      {children}
    </Container>
  </nav>
);

Menu.MenuItem = MenuItem;

Menu.propTypes = {
  children: PropTypes.node,
};

MenuItem.propTypes = {
  route: PropTypes.string.isRequired,
  currentRoute: PropTypes.string.isRequired,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default Menu;
