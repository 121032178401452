import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StackTrace from 'stacktrace-js';
import { useHistory, useLocation } from 'react-router-dom';
import { AjaxError } from 'rxjs/ajax';
import { EMPTY } from 'rxjs';

import { TextExpander, NotificationModal } from '../../components';
import { drivexStorage } from '../../hooks/useLocalStorage/useLocalStorage';
import { routes } from '../../routes/Routes';
import { makePost } from '../../api/ajax';
import { trackEvent, EVENT_TYPES } from '../../api/analytics';

import authenticationService from '../../services/authentication.service';

const defaultMessage = 'Oops! Something went wrong.';

const logType = {
  Information: 0,
  Warning: 1,
  Error: 2,
};

const initialErrorData = Object.freeze({
  isError: false,
  message: '',
  file: '',
  line: '',
  column: '',
  stack: {},
  details: [],
});

const reloadAppOnError = () => {
  if (process.env.REACT_RELOAD_APP_ON_ERROR) {
    window.location.reload();
  }
};

const GlobalErrorEngine = ({ children }) => {
  const localStorageError = drivexStorage.getItem('error');

  const location = useLocation();
  const history = useHistory();
  const [errorData, setErrorData] = useState(() => localStorageError || initialErrorData);

  const { isError, message, details } = errorData;

  /* //////////////////////////////////////////////////////////// */
  useEffect(() => {
    const getLocalStorageData = () => {
      const localStorageError = drivexStorage.getItem('error');

      if (localStorageError) {
        setErrorData(localStorageError);
      }
    };

    window.addEventListener('storage', getLocalStorageData);

    return () => {
      window.removeEventListener('storage', getLocalStorageData);
    };
  }, []);

  /* //////////////////////////////////////////////////////////// */
  const pageRedirections = {
    logout() {
      authenticationService.logout();

      // Track event in Analytics
      trackEvent(EVENT_TYPES.USER_LOGGED_OUT);

      reloadAppOnError();
    },

    goToHome() {
      history.replace(routes.home);
      reloadAppOnError();
    },

    goToFallbackPage() {
      history.replace({
        pathname: routes.fallback,
        search: location.search,
        state: { message: 'Oops! An unexpected error has occured.', fallbackPath: routes.home },
      });

      reloadAppOnError();
    },

    goToLogin() {
      authenticationService.logout();

      // Track event in Analytics
      trackEvent(EVENT_TYPES.USER_LOGGED_OUT);

      reloadAppOnError();
    }
  };

  /* //////////////////////////////////////////////////////////// */
  const isAjaxError = (errorObject) => errorObject instanceof AjaxError;

  /* //////////////////////////////////////////////////////////// */
  const getErrorStack = (errorObject) => {
    if (!errorObject || !errorObject.stack) return Promise.resolve(null);

    return StackTrace.fromError(errorObject).then((stackframes) => stackframes.map((sf) => sf.toString()).join('\n'));
  };

  /* //////////////////////////////////////////////////////////// */
  const onTechnicalErrorHandler = (errorMessage, errorObject) => {
    const message = `${errorMessage} \nUser Agent: ${navigator.userAgent}`;

    getErrorStack(errorObject).then((stacktrace) =>
      makePost('log', { app: 'DriveX Admin', message, stacktrace, logType: logType.Error }).subscribe(
        () => {
          reloadAppOnError();
          return EMPTY;
        },
        () => {
          reloadAppOnError();
          return EMPTY;
        },
      ));
  };

  /* //////////////////////////////////////////////////////////// */
  const onAjaxErrorHandler = ({ message, filename, lineno, colno, errorObject }) => {
    const { status, response, request } = errorObject;

    const getDetails = () => {
      const _details = [];

      if (status === 400) {
        _details.push(response.error || defaultMessage);
      } else if (status === 404) {
        _details.push('404 not found');

        _details.push(request.url);
      } else if (status === 500) {
        _details.push(defaultMessage);
      }
      return _details;
    };

    // store error to localStorage
    if (status !== 401 && status !== 403) {
      drivexStorage.setItem('error', {
        isError: true,
        message,
        fileName: filename,
        lineNo: lineno,
        colNo: colno,
        errorObject,
        details: getDetails(),
      });
    }

    switch (status) {
      case 400: {
        pageRedirections.goToFallbackPage();
        break;
      }

      case 401: {
        pageRedirections.logout();
        break;
      }

      case 403: {
        pageRedirections.logout();
        break;
      }

      case 404: {
        pageRedirections.goToHome();
        break;
      }

      case 500: {
        pageRedirections.goToFallbackPage();
        break;
      }

      default: {
        pageRedirections.goToLogin();
        break;
      }
    }
  };

  /* //////////////////////////////////////////////////////////// */
  const logError = ({ message, fileNo, lineNo, colNo, errorObject }) => {
    if (process.env.NODE_ENV === 'production') return;

    getErrorStack(errorObject).then((stack) => {
      console.log('\n\nOops, something went wrong.');
      console.log('\tMessage:');
      console.log(message);
      console.log('\tError object:');
      console.log(errorObject);
      console.log('\tFile:');
      console.log(fileNo);
      console.log('\tLine:');
      console.log(lineNo);
      console.log('\tColumn:');
      console.log(colNo);
      console.log('\tError stack:');
      console.log(stack);
    });
  };

  /* //////////////////////////////////////////////////////////// */
  const onGlobalErrorListener = ({ message, filename, lineno, colno, error }) => {
    logError({
      message,
      fileName: filename,
      lineNo: lineno,
      colNo: colno,
      errorObject: error,
    });

    // This exception was made due to third party component react-compare-slider throwing ResizeObserver error
    // which onTechnicalErrorHandler catches and reloads page. Error is harmless and does not break site.
    // This "fix" works for Edge and Chrome but does not work for Safari
    if (message === 'ResizeObserver loop limit exceeded' || message === 'ResizeObserver loop completed with undelivered notifications.') {
      return;
    }

    if (isAjaxError(error)) {
      onAjaxErrorHandler({
        message,
        filename,
        lineno,
        colno,
        errorObject: error,
      });
    } else {
      onTechnicalErrorHandler(message, error);
    }
  };

  /* //////////////////////////////////////////////////////////// */
  useEffect(() => {
    window.addEventListener('error', onGlobalErrorListener);

    return () => {
      window.removeEventListener('error', onGlobalErrorListener);
    };
  });

  return (
    <>
      {children}

      {isError && (
        <NotificationModal
          type="error"
          position="right"
          message={message || defaultMessage}
          showModal={isError}
          extraContent={<TextExpander values={details} type="error" />}
          onCancel={() => {
            setErrorData(initialErrorData);
            drivexStorage.removeItem('error');
          }}
        />
      )}
    </>
  );
};

GlobalErrorEngine.propTypes = {
  children: PropTypes.node,
};

export default GlobalErrorEngine;
