import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { routes } from '../../../routes/Routes';

import './sass/info-modal.scss';

const InfoModal = ({ type, message, shownModal, onCloseModal }) => (
  <>
    {shownModal && (
      <div className={`info-modal info-modal--${type}`}>
        <div className="info-modal__row1">
          <div className="info-modal__message">{message}</div>

          {onCloseModal && <div className="info-modal__close-btn" role="button" type="button" tabIndex={0} onClick={onCloseModal}>X</div>}
        </div>

        <div className="info-modal__row2">
          <div className="sub-link-info-wrapper">
            To continue using this feature, kindly upgrade your subscription&nbsp;
            <Link to={routes.account} className="sub-link-info-wrapper__here">here</Link>
          </div>

        </div>
      </div>
    )}
  </>
);

InfoModal.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'danger', 'info']).isRequired,
  message: PropTypes.string.isRequired,
  shownModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func
};

export default InfoModal;
