import types from './types';

const defaultState = {
  customers: null,
  customerTag: null
};

const tagsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_CUSTOMERS_TAGS_SUCCESS: {
      const { tags } = action.payload;
      return {
        ...state,
        customers: tags
      };
    }
    case types.LOAD_CUSTOMER_TAG_SUCCESS: {
      const { tag } = action.payload;
      return {
        ...state,
        customerTag: tag
      };
    }
    default:
      return state;
  }
};

export default tagsReducer;
