import _ from 'lodash';

import types from './types';

import sessionTypes from '../session/types';

const defaultState = {};

const imagesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.CLEAR_IMAGE: {
      const { imageId } = action.payload;

      const imageUrl = state.imageLocalUrls && state.imageLocalUrls[imageId];
      if (imageUrl) {
        window.URL.revokeObjectURL(imageUrl);
      }

      return {
        ...state,
        imageLocalUrls: {
          ...state.imageLocalUrls,
          [imageId]: undefined,
        }
      };
    }
    case types.LOAD_IMAGE_SUCCESS: {
      const { imageId, data } = action.payload;
      const localImageUrl = window.URL.createObjectURL(data);

      return {
        ...state,
        imageLocalUrls: {
          ...state.imageLocalUrls,
          [imageId]: localImageUrl,
        },
      };
    }
    case types.LOAD_IMAGES_SUCCESS: {
      const { photoSeriesId, images } = action.payload;

      return {
        ...state,
        images: {
          ...state.images,
          [photoSeriesId]: images.map((image) => ({
            ...image,
            damages: !image.damages ? [] : image.damages.map((damage) => ({
              ...damage,
              show: damage.flaggedAt === null
            }))
          })),
        },
      };
    }
    case sessionTypes.LOG_OUT: {
      const { imageLocalUrls } = state;

      if (imageLocalUrls) {
        _.forOwn(imageLocalUrls, (value) => {
          window.URL.revokeObjectURL(value);
        });
      }

      return defaultState;
    }
    default:
      return state;
  }
};

export default imagesReducer;
