import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeGet } from '../../../../api/ajax';

import { selectors as workflowsSelectors, actions as workflowsActions } from '../../../../redux/workflows';

import { Accordion, renderShowConfirmationModal, ActiveWorkflowsInfo } from '../../../../components';

import CustomImageTypePreview from '../CustomImageTypePreview/CustomImageTypePreview';

const CustomImageTypesAccordion = ({ customImageTypes }) => {
  const [activeKey, setActiveKey] = useState(customImageTypes[0].id);

  const [clickedCustomImageTypeId, setClickedCustomImageTypeId] = useState(activeKey);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [customImageTypeForms, setCustomImageTypeForms] = useState({});

  const dispatch = useDispatch();
  const workflows = useSelector(workflowsSelectors.selectWorkFlows);

  useEffect(() => {
    const newCustomImageTypeForms = {};
    customImageTypes.forEach((customImageType) => {
      newCustomImageTypeForms[customImageType.id] = { dirty: false };
    });
    setCustomImageTypeForms(newCustomImageTypeForms);
  }, [customImageTypes]);

  useEffect(() => {
    if (!workflows) {
      makeGet('workflows/currentCustomer/admin?showDeleted=true').subscribe(
        ({ response }) => {
          dispatch(workflowsActions.loadWorkflowsSuccess(response));
        }
      );
    }
  }, [workflows, dispatch]);

  const handleCustomImageTypePreviewFormChanged = (customImageTypeId, dirty) => {
    setCustomImageTypeForms({
      ...customImageTypeForms,
      [customImageTypeId]: { dirty }
    });
  };

  return (
    <>
      {
        renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, () => {
          setShowConfirmModal(false);
          handleCustomImageTypePreviewFormChanged(activeKey, false);
          setActiveKey(clickedCustomImageTypeId);
        })
      }
      <Accordion
        className="custom-image-types-accordion"
        defaultActiveKey={customImageTypes[0].id}
        activeKey={activeKey}
        onSelect={(eventKey) => {
          setActiveKey(eventKey);
        }}
      >
        {customImageTypes.map((customImageType) => (
          <span key={customImageType.id}>
            <Accordion.Header
              onClick={() => {
                const isDirty = Object.keys(customImageTypeForms).some((key) => customImageTypeForms[key].dirty);
                if (isDirty && activeKey !== customImageType.id) {
                  setShowConfirmModal(true);
                }
                setClickedCustomImageTypeId(customImageType.id);
              }}
              isDisabled={Object.keys(customImageTypeForms).some((key) => customImageTypeForms[key].dirty)}
              eventKey={customImageType.id}
              childrenSize={6}
              caretSize={6}
              noFocus
            >
              <span>
                <h5 className="mb-1">{customImageType.name}</h5>
                <ActiveWorkflowsInfo id={customImageType.id} workflows={workflows} workflowIds={customImageType.workflowIds} />
              </span>
            </Accordion.Header>

            <Accordion.Body eventKey={customImageType.id} borderType="none">
              <span>
                {/* Only render when opened */}
                {activeKey === customImageType.id && (
                  <CustomImageTypePreview
                    imageTypeId={customImageType.id}
                    onCustomImageTypePreviewFormChanged={handleCustomImageTypePreviewFormChanged}
                  />
                )}
              </span>
            </Accordion.Body>
          </span>
        ))}
      </Accordion>
    </>
  );
};

CustomImageTypesAccordion.propTypes = {
  customImageTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      workflowIds: PropTypes.array.isRequired,
    })
  )
};

export default CustomImageTypesAccordion;
