import { useEffect } from 'react';

/**
 *@useOutsideAlerter Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (ref, onOperation) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOperation(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onOperation]);
};
