export const THEMES_STATE_KEYS = Object.freeze({
  logoFile: 'logoFile',
  headingColor: 'headingColor',
  paragraphColor: 'paragraphColor',
  primaryButtonBackgroundColor: 'primaryButtonBackgroundColor',
  primaryButtonTextColor: 'primaryButtonTextColor',
  secondaryButtonBackgroundColor: 'secondaryButtonBackgroundColor',
  secondaryButtonTextColor: 'secondaryButtonTextColor',
  successColor: 'successColor',
  errorColor: 'errorColor',
  warningColor: 'warningColor',
  errorObj: 'error',
});

export const errorMessages = Object.freeze({
  headingColor: 'Heading color is required',
  paragraphColor: 'Paragraph color is required',
  primaryButtonBackgroundColor: 'Primary button background color is required',
  primaryButtonTextColor: 'Primary button text color is required',
  secondaryButtonBackgroundColor: 'Secondary button background color is required',
  secondaryButtonTextColor: 'Secondary button text color is required',
  successColor: 'Success is required',
  errorColor: 'Error color is required',
  warningColor: 'Warning color is required',
});
