import { createSelector } from 'reselect';
import _ from 'lodash';

import constants from './constants';

const selectWorkflowsState = (state) => state[constants.NAME];

const selectWorkFlows = createSelector([selectWorkflowsState], ({ workflows }) => workflows);
const selectSharedWorkflows = createSelector([selectWorkflowsState], ({ sharedWorkflows }) => sharedWorkflows);

const hasWorkflows = createSelector([selectWorkflowsState], ({ workflows }) => !_.isEmpty(workflows));

const getCaptureSettings = (state) => state[constants.NAME].captureSettings;

const getWorkflowIdParam = (_, workflowId) => workflowId;

const getImageTypeParam = (_, __, imageType) => imageType;

const getImageTypeCaptureSettings = createSelector(
  getCaptureSettings,
  getWorkflowIdParam,
  getImageTypeParam,
  (captureSettings, workflowId, imageType) => (captureSettings
    ? (captureSettings[`${workflowId}_${imageType}`])
    : null),
);

const getWorkflow = createSelector(
  selectWorkFlows,
  getWorkflowIdParam,
  (workflows, workflowId) => workflows[workflowId],
);

export default {
  selectWorkFlows,
  selectSharedWorkflows,
  getImageTypeCaptureSettings,
  hasWorkflows,
  getWorkflow,
};
