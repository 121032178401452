import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './sass/image-with-text.scss';

const ImageWithText = ({ primaryText, secondaryText, large, small, children }) => {
  const classes = classNames({
    'image-with-text': true,
    'is-large': large,
    'is-small': small,
  });

  return (
    <div className={classes}>
      <div className="image-container">
        {children}
      </div>

      {primaryText && (
        <div className="primary-text">
          {primaryText}
        </div>
      )}

      {secondaryText && (
        <div className="secondary-text">
          {secondaryText}
        </div>
      )}
    </div>
  );
};

ImageWithText.propTypes = {
  children: PropTypes.node.isRequired,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  large: PropTypes.bool,
  small: PropTypes.bool,

};

export default ImageWithText;
