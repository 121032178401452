import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { makeGet } from '../../../../api/ajax';
import { getUtcToday } from '../../../../utils/date';

import { MonthPager } from '../../../../components';

import {
  actions as customerMonthlyConsumptionsActions,
  selectors as customerMonthlyConsumptionsSelectors,
} from '../../../../redux/customerMonthlyConsumptions';

import Statistics from './containers/Statistics';
import { trackEvent, EVENT_TYPES } from '../../../../api/analytics';

const Dashboard = ({ setIsLoadingStatistics }) => {
  const [today, setToday] = useState(getUtcToday());

  const dispatch = useDispatch();
  const monthlyConsumption = useSelector(customerMonthlyConsumptionsSelectors.selectCustomerMonthlyConsumption);

  const todayString = today.toISOString();

  useEffect(() => {
    if (!monthlyConsumption || !monthlyConsumption[todayString]) {
      setIsLoadingStatistics(true);

      makeGet(`customerMonthlyConsumptions/${todayString}`).subscribe(({ response }) => {
        setIsLoadingStatistics(false);

        dispatch(customerMonthlyConsumptionsActions.loadMonthlyConsumptionSuccess(response || {}, todayString));
      }, () => {
        setIsLoadingStatistics(false);
      });
    }

    // eslint-disable-next-line
  }, [todayString, monthlyConsumption, dispatch]);

  const switchMonth = (diff) => {
    const newMonth = today.getMonth() + diff;
    const newDate = new Date(Date.UTC(today.getUTCFullYear(), newMonth, today.getUTCDate(), 0, 0, 0));
    setToday(newDate);

    // Track event in Analytics
    trackEvent(EVENT_TYPES.STATISTICS_MONTH_CHANGED, { selectedMonth: newDate });
  };

  if (!monthlyConsumption) return null;

  const currentMonthConsumption = monthlyConsumption[todayString];

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <MonthPager
            activeMonth={today}
            switchMonth={switchMonth}
            disableSwitching={_.isEmpty(currentMonthConsumption)}
          />
        </div>
      </div>
      <Statistics currentMonthConsumption={currentMonthConsumption} />
    </>
  );
};

Dashboard.propTypes = {
  setIsLoadingStatistics: PropTypes.func.isRequired
};
export default Dashboard;
