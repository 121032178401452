import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { makeGet } from '../../api/ajax';
import { actions as customerActions, selectors as customerSelectors } from '../../redux/customers';
import { Button, Modal, onHideHandler, renderShowConfirmationModal } from '../../components';

import PasswordForm from '../PasswordForm/PasswordForm';

const PasswordModal = ({ clearUsers, onModalClose, ...props }) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dispatch = useDispatch();
  const customer = useSelector(customerSelectors.selectCurrentCustomer);

  useEffect(() => {
    if (customer) return;

    makeGet('customers/current').subscribe(({ response }) => dispatch(customerActions.loadCustomerSuccess(response)));
  }, [customer, dispatch]);

  if (!customer) return null;

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, () => {
        onModalClose();
        setShowConfirmModal(false);
      })}

      <Modal
        headerText="Change password"
        size="sm"
        backdrop="static"
        onHide={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
        {...props}
      >
        <Modal.Body>
          <PasswordForm
            buttonPosition="text-right"
            children={(
              <Button
                className="btn btn-secondary mr-1"
                testId="passModalCancelBtn"
                onClick={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
              >
                Cancel
              </Button>
            )}
            onHide={onModalClose}
            hasUnsavedChanges={hasUnsavedChanges}
            setHasUnsavedChanges={(shouldHasUnsavedChanges) => setHasUnsavedChanges(shouldHasUnsavedChanges)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

PasswordModal.propTypes = {
  clearUsers: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
};

export default PasswordModal;
