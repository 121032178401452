import types from './types';

const loadExperimentalFeaturesSuccess = (experimentalFeatures) => ({
  type: types.LOAD_EXPERIMENTAL_FEATURES_SUCCESS,
  payload: experimentalFeatures,
});

export default {
  loadExperimentalFeaturesSuccess
};
