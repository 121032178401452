import React from 'react';
import PropTypes from 'prop-types';

import './sass/vehicle-damaged-indicator.scss';

const VehicleDamagedIndicator = ({ damages }) => {
  const hasDamages = damages && damages.length > 0;

  if (!hasDamages) return <span className="vehicle-not-damaged-indicator">No</span>;

  return <span className="vehicle-damaged-indicator">Yes</span>;
};

VehicleDamagedIndicator.propTypes = {
  damages: PropTypes.arrayOf(PropTypes.shape({
    bodyPart: PropTypes.number,
    damageType: PropTypes.number
  })).isRequired,
};

export default VehicleDamagedIndicator;
