const NAME = 'countries';

const DEFAULT_SORT = {
  page: 1,
  pageSize: 20,
};

export default {
  NAME,
  DEFAULT_SORT,
};
