import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useBeforeUnload } from '../../../../hooks';
import { makePatch } from '../../../../api/ajax';
import { Field } from '../../../../components/Form';
import { Modal, onHideHandler, renderShowConfirmationModal } from '../../../../components';
import { selectors, actions } from '../../../../redux/customerConfigurations';

const ScriveSettingsModal = ({ onModalClose, ...props }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useBeforeUnload(hasUnsavedChanges);

  const dispatch = useDispatch();
  const customerConfiguration = useSelector(selectors.selectCustomerConfiguration);

  const initialValues = {
    scriveUserEmail: customerConfiguration?.scriveUserEmail || '',
    scriveUserPassword: ''
  };

  const handleSubmit = (data, setStatus, setShowSuccessMessage, resetForm, setHasUnsavedChanges) => {
    makePatch('customerConfigurations/current', data).subscribe(
      ({ response }) => {
        dispatch(actions.loadCurrentCustomerConfigurationSuccess(response));
        setShowSuccessMessage('Scrive settings has been changed!');
        resetForm();

        setHasUnsavedChanges(false);
      },
      ({ response: { error } }) => {
        setStatus(error);

        setHasUnsavedChanges(false);
      }
    );
  };

  const hideDiscardConfirmation = () => {
    onModalClose();
    setShowConfirmModal(false);
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, hideDiscardConfirmation)}
      <Modal
        headerText="Change scrive settings"
        size="md"
        backdrop="static"
        onHide={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
        {...props}
      >
        <Modal.Body>
          <div className="row">
            <div className="col">
              {showSuccessMessage && (
                <Alert variant="success" dismissible onClick={() => setShowSuccessMessage(null)}>
                  {showSuccessMessage}
                </Alert>
              )}
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              scriveUserEmail: Yup.string().required('Email is required').email('Please enter a correct email'),
              scriveUserPassword: Yup.string().min(8, 'Must be at least 12 characters long'),
            })}
            onSubmit={({ scriveUserEmail, scriveUserPassword }, { setStatus, resetForm }) =>
              handleSubmit({ scriveUserEmail, scriveUserPassword }, setStatus, setShowSuccessMessage, resetForm, setHasUnsavedChanges)}
            validateOnChange={false}
          >
            {({ errors, touched }) => (
              <Form
                onChange={({ target }) => {
                  if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                    setHasUnsavedChanges(true);
                  }
                }}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-12">
                    <Field
                      label="Scrive user email"
                      type="email"
                      name="scriveUserEmail"
                      touched={touched.scriveUserEmail}
                      errors={errors.scriveUserEmail}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                    <Field
                      label="Scrive user password"
                      type="password"
                      name="scriveUserPassword"
                      touched={touched.scriveUserPassword}
                      errors={errors.scriveUserPassword}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <button
                      type="reset"
                      className="btn btn-secondary mr-1"
                      onClick={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

ScriveSettingsModal.propTypes = {
  onModalClose: PropTypes.func
};

export default ScriveSettingsModal;
