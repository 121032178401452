import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import './sass/bootstrap-modal.scss';

const CustomModal = ({
  size, children, headerText, ...props
}) => (
  <Modal {...props} centered size={size}>
    <Modal.Header closeButton>
      <h6>{headerText}</h6>
    </Modal.Header>
    {children}
  </Modal>
);

CustomModal.Body = Modal.Body;

CustomModal.propTypes = {
  headerText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
};

export default CustomModal;
