import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  constants as damagesConstants, actions as damagesActions, selectors as damagesSelectors
} from '../../../../../../../../redux/damages';
import { authenticationService } from '../../../../../../../../services';
import { constants as sessionConstants } from '../../../../../../../../redux/session';
import { ReactComponent as IconYellowWarning } from '../../../../../../../../assets/img/icn-warning-yellow.svg';
import { ReactComponent as IconFlagOutline } from '../../../../../../../../assets/img/icn-flag-outline.svg';
import { ReactComponent as IconFlag } from '../../../../../../../../assets/img/icn-flag.svg';
import { ReactComponent as IconAnnouncement } from '../../../../../../../../assets/img/icn-announcement-outline.svg';
import { ReactComponent as IconVisibility } from '../../../../../../../../assets/img/icn-visibility.svg';
import { ReactComponent as IconVisibilityOff } from '../../../../../../../../assets/img/icn-visibility-off.svg';
import { ReactComponent as IconPen } from '../../../../../../../../assets/img/icn-pen2.svg';
import { Sidebar, Loader, Button } from '../../../../../../../../components';
import { makePatch, makeGet } from '../../../../../../../../api/ajax';
import {
  selectors as photoSeriesSelectors,
  helpers as photoSeriesHelpers,
  actions as photoSeriesActions
} from '../../../../../../../../redux/photoSeries';
import { selectors as customerSelectors } from '../../../../../../../../redux/customers';

import './sass/damages.scss';

const DamagesSection = ({ photoSeriesId, imageId, damages }) => {
  const { hasPermission } = authenticationService;

  if (!hasPermission(sessionConstants.PERMISSION_TYPES.VIEW_DAMAGES)) return null;

  const DamagesVisibility = () => {
    if (!damages || damages.length === 0) return null;
    return (
      <div className="header-visibility">
        <Visibility damages={damages} photoSeriesId={photoSeriesId} imageId={imageId} />
      </div>
    );
  };

  return (
    <Sidebar.Section className="d-flex flex-column flex-grow-1 overflow-hidden damages">
      <Sidebar.Section.Header text="Damages" additional={<DamagesVisibility />}>
        <IconAnnouncement />
      </Sidebar.Section.Header>
      <Sidebar.Section.Body>
        <Damages photoSeriesId={photoSeriesId} imageId={imageId} damages={damages} />
      </Sidebar.Section.Body>
    </Sidebar.Section>
  );
};

const Damages = ({ photoSeriesId, imageId, damages }) => {
  const { hasPermission } = authenticationService;

  const photoSeries = useSelector(photoSeriesSelectors.selectPhotoSeries);
  const customer = useSelector(customerSelectors.selectCurrentCustomer);
  const customDamageTypes = useSelector(damagesSelectors.selectCustomDamageTypes);

  const currentPhotoSeries = photoSeries && photoSeries[photoSeriesId];
  const isPhotoSeriesShared = photoSeriesHelpers.isPhotoSeriesShared(currentPhotoSeries, customer);

  const hasEditDamagePermission = hasPermission(sessionConstants.PERMISSION_TYPES.EDIT_IMAGE_DAMAGE)
    && !isPhotoSeriesShared;

  if (!hasPermission(sessionConstants.PERMISSION_TYPES.VIEW_DAMAGES) || !damages) return null;
  if (damages.length === 0) return 'No damages found';

  return (
    <div className="container-fluid overflow-auto thin-scrollbar">
      <ul className="damages-list">
        { damages.map((damage) => (
          <li key={damage.damageId}>
            <DamageRow
              damage={damage}
              hasEditDamagePermission={hasEditDamagePermission}
              customDamageTypes={customDamageTypes}
              damages={damages}
              imageId={imageId}
              photoSeriesId={photoSeriesId}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const DamageRow = ({ damage, hasEditDamagePermission, customDamageTypes, damages, imageId, photoSeriesId }) => {
  const dispatch = useDispatch();

  const [isEditingDamage, setIsEditingDamage] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedDamageType, setSelectedDamageType] = useState(damage.customDamageType ? damage.customDamageType.id : damage.damageType);
  const { damageId, flaggedAt } = damage;
  const bodyPartTxt = damagesConstants.BODY_PART_NAMES[damage.bodyPart];
  const damageTypeTxt = damage.customDamageType
    ? damage.customDamageType.name
    : damagesConstants.DAMAGE_TYPE_NAME[damage.damageType];

  const damageTypes = [
    damagesConstants.DAMAGE_TYPE.CHIP,
    damagesConstants.DAMAGE_TYPE.DENT,
    damagesConstants.DAMAGE_TYPE.RUST,
    damagesConstants.DAMAGE_TYPE.CRACK,
    damagesConstants.DAMAGE_TYPE.SCRAPE,
    damagesConstants.DAMAGE_TYPE.SCRATCH,
    damagesConstants.DAMAGE_TYPE.MISALIGNED,
    damagesConstants.DAMAGE_TYPE.LAMP_BROKEN,
    damagesConstants.DAMAGE_TYPE.MISSING_PART,
    damagesConstants.DAMAGE_TYPE.OTHER_DAMAGE,
    damagesConstants.DAMAGE_TYPE.PAINT_DAMAGE,
    damagesConstants.DAMAGE_TYPE.FALSE_POSITIVE
  ];

  useEffect(() => {
    if (!customDamageTypes && hasEditDamagePermission) {
      makeGet('customDamageTypes?pageSize=150').subscribe(({ response }) => {
        dispatch(damagesActions.loadCustomDamageTypesSuccess(response.results));
      });
    }
  }, [customDamageTypes, hasEditDamagePermission, dispatch]);

  const handleSave = () => {
    setIsSaving(true);

    const customDamageTypeIds = customDamageTypes.map((type) => type.id);
    const isCustomDamageType = customDamageTypeIds.includes(selectedDamageType);
    const data = {
      damageId,
      damageType: isCustomDamageType ? null : parseInt(selectedDamageType, 10),
      customDamageTypeId: isCustomDamageType ? selectedDamageType : null
    };

    makePatch(`images/${imageId}/damages`, data).subscribe(() => {
      // Re load damages
      makeGet(`images?photoSeriesId=${photoSeriesId}&pageSize=50&sortColumn=CreatedAt&sortDirection=asc`).subscribe(
        ({ response }) => {
          dispatch(damagesActions.loadImageDamagesSuccess(photoSeriesId, response.results));

          // Re load photo series damage summary & estimations
          makeGet(`photoSeries/${photoSeriesId}`).subscribe(({ response }) => {
            dispatch(photoSeriesActions.loadPhotoSeriesRepairEstimationsSuccess(photoSeriesId, response.repairEstimations));
            dispatch(photoSeriesActions.loadPhotoSeriesDamageSummarySuccess(photoSeriesId, response.damages));
            dispatch(photoSeriesActions.loadPhotoSeriesReportStatusSuccess(photoSeriesId, response.reportStatus));
            dispatch(damagesActions.loadPhotoSeriesDamagesSuccess(photoSeriesId, response.damages));

            setIsSaving(false);
            setIsEditingDamage(false);
          });
        },
        () => {
          setIsSaving(false);
          setIsEditingDamage(false);
        }
      );
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm pl-0">
          <IconYellowWarning className="mr-2" />
          <span>{bodyPartTxt} </span>
          <span>(</span>
          <span className="damage-type">{damageTypeTxt}</span>
          <span>)</span>
        </div>
        <div className="col-sm-auto pr-0 d-flex justify-content-end">
          {
            hasEditDamagePermission && (
              <>
                <div role="presentation" className="edit mr-2">
                  <IconPen onClick={() => setIsEditingDamage(!isEditingDamage)} />
                </div>
                <Flag photoSeriesId={photoSeriesId} imageId={imageId} damageId={damageId} flagged={flaggedAt !== null} />
              </>
            )
          }
          <Visibility photoSeriesId={photoSeriesId} imageId={imageId} damageId={damageId} damages={damages} />
        </div>
      </div>
      {
        isEditingDamage && (
          <div className="row pt-2 pb-2">
            <div className="col-sm-8 d-flex justify-content-end pl-0">
              <select
                className="form-control"
                value={selectedDamageType}
                onChange={({ target: { value } }) => setSelectedDamageType(value)}
                name="damageType"
              >
                {
                  damageTypes.map((type) => (
                    <option key={type} value={type}>
                      {damagesConstants.DAMAGE_TYPE_NAME[type]}
                    </option>
                  ))
                }
                {
                  customDamageTypes.filter((type) => !type.isDeleted).map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))
                }
              </select>
            </div>
            <div className="col-sm-4 pl-0 pr-0 d-flex justify-content-end">
              <Button
                className="btn btn-primary"
                testId="saveDamageType"
                onClick={handleSave}
              >
                {!isSaving && (<span>Save</span>)}
                {isSaving && (<Loader size="tiny" isRelative noDelay white />)}
              </Button>
            </div>
          </div>
        )
      }
    </>
  );
};

DamageRow.propTypes = {
  damage: PropTypes.object,
  photoSeriesId: PropTypes.string.isRequired,
  imageId: PropTypes.string.isRequired,
  damages: PropTypes.arrayOf(PropTypes.shape({
    damageId: PropTypes.string.isRequired,
    bodyPart: PropTypes.number,
    damageType: PropTypes.number.isRequired,
    flaggedAt: PropTypes.string,
    customDamageTypeId: PropTypes.string
  })).isRequired,
  hasEditDamagePermission: PropTypes.bool.isRequired,
  customDamageTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isDeleted: PropTypes.bool.isRequired
  }))
};

const Flag = ({ photoSeriesId, imageId, damageId, flagged }) => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const apiFlagDamage = () => {
    setIsUpdating(true);
    makePatch(`images/${imageId}/damages`, { flagged: !flagged, damageId }).subscribe(() => {
      // Re load damages
      makeGet(`images?photoSeriesId=${photoSeriesId}&pageSize=50&sortColumn=CreatedAt&sortDirection=asc`).subscribe(
        ({ response }) => {
          dispatch(damagesActions.loadImageDamagesSuccess(photoSeriesId, response.results));

          // Re load photo series damage summary & estimations
          makeGet(`photoSeries/${photoSeriesId}`).subscribe(({ response }) => {
            dispatch(photoSeriesActions.loadPhotoSeriesRepairEstimationsSuccess(photoSeriesId, response.repairEstimations));
            dispatch(photoSeriesActions.loadPhotoSeriesDamageSummarySuccess(photoSeriesId, response.damages));
            dispatch(photoSeriesActions.loadPhotoSeriesReportStatusSuccess(photoSeriesId, response.reportStatus));
            dispatch(damagesActions.loadPhotoSeriesDamagesSuccess(photoSeriesId, response.damages));

            setIsUpdating(false);
          });
        },
        () => setIsUpdating(false)
      );
    });
  };

  if (isUpdating) {
    return (
      <Loader size="tiny" isRelative noDelay />
    );
  }

  return (
    <div onClick={apiFlagDamage} role="presentation" className="flag mr-2">
      { !flagged ? <IconFlag /> : <IconFlagOutline /> }
    </div>
  );
};

const Visibility = ({ photoSeriesId, imageId, damages, damageId }) => {
  const dispatch = useDispatch();

  const show = useCallback(() => {
    if (!damages) return false;
    if (!damageId) {
      return damages.some((damage) => damage.show);
    }

    const damage = damages.find((damage) => damage.damageId === damageId);
    return damage.show;
  }, [damages, damageId]);

  const handleShowDamage = () => {
    dispatch(damagesActions.showImageDamageBoundingBox(photoSeriesId, imageId, damageId, !show()));
  };

  return (
    <div onClick={handleShowDamage} role="presentation" className="visibility">
      { !show() ? <IconVisibilityOff /> : <IconVisibility /> }
    </div>
  );
};

Flag.propTypes = {
  photoSeriesId: PropTypes.string.isRequired,
  imageId: PropTypes.string.isRequired,
  damageId: PropTypes.string.isRequired,
  flagged: PropTypes.bool.isRequired
};

Visibility.propTypes = {
  photoSeriesId: PropTypes.string.isRequired,
  imageId: PropTypes.string.isRequired,
  damageId: PropTypes.string,
  damages: PropTypes.arrayOf(PropTypes.shape({
    damageId: PropTypes.string,
    bodyPart: PropTypes.number,
    damageType: PropTypes.number,
    flaggedAt: PropTypes.string
  })).isRequired
};

Damages.propTypes = {
  photoSeriesId: PropTypes.string.isRequired,
  imageId: PropTypes.string.isRequired,
  damages: PropTypes.arrayOf(PropTypes.shape({
    damageId: PropTypes.string.isRequired,
    bodyPart: PropTypes.number,
    damageType: PropTypes.number.isRequired,
    flaggedAt: PropTypes.string
  })).isRequired
};

DamagesSection.propTypes = {
  photoSeriesId: PropTypes.string.isRequired,
  imageId: PropTypes.string.isRequired,
  damages: PropTypes.arrayOf(PropTypes.shape({
    damageId: PropTypes.string.isRequired,
    bodyPart: PropTypes.number,
    damageType: PropTypes.number.isRequired,
    flaggedAt: PropTypes.string
  })).isRequired
};

export default DamagesSection;
