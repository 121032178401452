import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useBeforeUnload } from '../../../../hooks';
import { makePatch } from '../../../../api/ajax';
import { Field } from '../../../../components/Form';
import { Modal, onHideHandler, renderShowConfirmationModal } from '../../../../components';
import { selectors, actions } from '../../../../redux/customerConfigurations';

const Webhook = ({ onModalClose, ...props }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useBeforeUnload(hasUnsavedChanges);

  const dispatch = useDispatch();
  const customerConfiguration = useSelector(selectors.selectCustomerConfiguration);

  const initialValues = {
    webhookUrl: customerConfiguration?.webhookUrl || '',
  };

  const handleSubmit = (data, setStatus, setShowSuccessMessage, resetForm, setHasUnsavedChanges) => {
    makePatch('customerConfigurations/current', data).subscribe(
      ({ response }) => {
        dispatch(actions.loadCurrentCustomerConfigurationSuccess(response));
        setShowSuccessMessage('Webhook url has been changed!');
        resetForm();

        setHasUnsavedChanges(false);
      },
      ({ response: { error } }) => {
        setStatus(error);

        setHasUnsavedChanges(false);
      }
    );
  };

  const hideDiscardConfirmation = () => {
    onModalClose();
    setShowConfirmModal(false);
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, hideDiscardConfirmation)}
      <Modal
        headerText="Change webhook URL"
        size="md"
        backdrop="static"
        onHide={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
        {...props}
      >
        <Modal.Body>
          <div className="row">
            <div className="col">
              {showSuccessMessage && (
                <Alert variant="success" dismissible onClick={() => setShowSuccessMessage(null)}>
                  {showSuccessMessage}
                </Alert>
              )}
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              webhookUrl: Yup.string(),
            })}
            onSubmit={({ webhookUrl }, { setStatus, resetForm }) =>
              handleSubmit({ webhookUrl }, setStatus, setShowSuccessMessage, resetForm, setHasUnsavedChanges)}
            validateOnChange={false}
          >
            {({ errors, status, touched }) => (
              <Form
                onChange={({ target }) => {
                  if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                    setHasUnsavedChanges(true);
                  }
                }}
              >
                <div className="row">
                  <div className="col-10 col-md-7">
                    <Field
                      type="text"
                      name="webhookUrl"
                      touched={touched.webhookUrl}
                      errors={errors.webhookUrl || status}
                    />
                  </div>
                  <div className="col-4 col-md-5 text-right">
                    <button
                      type="reset"
                      className="btn btn-secondary mr-1"
                      onClick={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

Webhook.propTypes = {
  onModalClose: PropTypes.func
};

export default Webhook;
