/* eslint no-unused-vars: "off" */
import _ from 'lodash';

import { constants as vehiclesConstants } from '.';
import { parseTypes } from '../../utils/constants';

const filtersAreEqual = (filterFromContext, filterFromRedux) => {
  if (_.isEmpty(filterFromContext) || _.isEmpty(filterFromRedux)) return false;

  const contextFilterKeys = Object.keys(filterFromContext);
  const reduxFilterKeys = Object.keys(filterFromRedux);

  if (contextFilterKeys.length !== reduxFilterKeys.length) return false;

  for (const contextFilterKey of contextFilterKeys) {
    const filterConstant = vehiclesConstants.FILTER[contextFilterKey];

    if (reduxFilterKeys.indexOf(filterConstant.name) < 0) return false;

    switch (filterConstant.parseType) {
      case parseTypes.number:
      case parseTypes.string:
      case parseTypes.date:
      case parseTypes.boolean:
        if (filterFromContext[filterConstant.name] !== filterFromRedux[filterConstant.name]) return false;

        break;
      case parseTypes.stringArray:
      case parseTypes.numberArray:
        const contextArray = filterFromContext[filterConstant.name] || [];
        const reduxArray = filterFromRedux[filterConstant.name] || [];

        if (contextArray.length !== reduxArray.length) return false;

        for (const contextValue of contextArray) {
          if (reduxArray.indexOf(contextValue) < 0) return false;
        }

        break;
      default:
        break;
    }
  }

  return true;
};

export default {
  filtersAreEqual,
};
