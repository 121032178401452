import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { Field } from '../../../../../../components/Form';
import LanguageDropDown from './containers/LanguageDropDown/LanguageDropDown';

import { selectors as customerTranslationsSelectors } from '../../../../../../redux/customerTranslations';

import { composeFormKey, CAPTURE_MODE_KEY, INSTRUCTIONS_TEXT_KEY } from '../../../../../../utils/customImages';

import './sass/smart-scan-ui-texts.scss';

const SmartScanUITexts = ({
  onInputFocus, disableTranslationsInput, imageTypeId, languages,
  sourceLanguage
}) => {
  const [selectedLang, setSelectedLang] = useState(languages[0]);

  const translationCategory = useSelector(
    (state) => customerTranslationsSelectors.selectImageTypeTranslationCategory(state, selectedLang.id, imageTypeId)
  );

  const sourceTranslationCategoryId = useSelector(
    (state) => customerTranslationsSelectors.selectImageTypeTranslationCategory(state, sourceLanguage?.id, imageTypeId)
  )?.id;

  const { errors, touched, values } = useFormikContext();

  if (!languages || !translationCategory || !selectedLang) return null;

  const getBackgroundClass = (key, value) => {
    const classes = ['input-group', 'mt-2'];
    if (!sourceTranslationCategoryId) return classes.join('');

    if (!value.trim()) {
      classes.push('field--empty');
    }

    const sourceKey = composeFormKey(sourceTranslationCategoryId, key);
    if (values[sourceKey] === value) {
      classes.push('field--same-as-source');
    }

    return classes.join(' ');
  };

  const translationsMarkup = (
    <div className="smart-scan-ui-texts">
      <div className="row">
        <div className="col-sm-12">
          <LanguageDropDown
            selectLanguage={setSelectedLang}
            selectedLanguage={selectedLang}
            languages={languages}
          />
        </div>
      </div>
      <fieldset>
        {translationCategory.translationValues.map(({ key }) => {
          const formKey = composeFormKey(translationCategory.id, key);

          const formKeyValue = values[formKey] || '';
          const capturingHelperStyle = formKey.includes(CAPTURE_MODE_KEY) ? { textTransform: 'uppercase' } : {};
          const props = {
            rows: 1,
            type: 'text'
          };

          if (key === INSTRUCTIONS_TEXT_KEY) {
            props.rows = 6;
            props.as = 'textarea';
          }

          return (
            <Fragment key={formKey}>
              <label htmlFor={formKey}>{key}</label>
              <Field
                {...props}
                id={formKey}
                name={formKey}
                className={getBackgroundClass(key, formKeyValue)}
                placeholder={`Type your '${key}'`}
                wrap="hard"
                onFocus={onInputFocus}
                disabled={disableTranslationsInput}
                errors={errors[formKey]}
                touched={touched[formKey]}
                style={capturingHelperStyle}
              />
            </Fragment>
          );
        })}
      </fieldset>
    </div>
  );

  return (
    <div className="row">
      <div className="col-sm-12">
        {translationsMarkup}
      </div>
    </div>
  );
};

SmartScanUITexts.propTypes = {
  languages: PropTypes.array.isRequired,
  onInputFocus: PropTypes.func.isRequired,
  disableTranslationsInput: PropTypes.bool.isRequired,
  imageTypeId: PropTypes.string.isRequired,
  sourceLanguage: PropTypes.object
};

export default SmartScanUITexts;
