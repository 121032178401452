import React from 'react';
import { PropTypes } from 'prop-types';

import { ReactComponent as IcnChecked } from '../../assets/img/icn-checked.svg';
import { ReactComponent as IcnUnChecked } from '../../assets/img/icn-unchecked.svg';

import { Tooltip } from '../Form';

import './sass/checkbox.scss';

/** ********************************************************************************************************************
 * @Checkbox this checkbox can play the role of a checkboxes(where more than one value can be selected) and radio
 * button(where one value is expected as used in Vehicles filters), all you need is to dispatch the appropriate actions
 ********************************************************************************************************************** */

const Checkbox = ({ id, label, name, checked, onClickHandler, tooltip, disabled, className }) => (
  <div
    id={id}
    className={`checbox-container ${className || ''}`}
    role="checkbox"
    aria-checked={checked}
    tabIndex={0}
    onClick={() => {
      if (!disabled && onClickHandler) {
        onClickHandler({ name, value: checked });
      }
    }}
  >
    <label htmlFor={id} className="checbox-container__label">
      {
        checked
          ? <IcnChecked className={disabled ? 'check-icn-disabled' : 'check-icn'} />
          : <IcnUnChecked className={disabled ? 'check-icn-disabled' : 'check-icn'} />
      }
      { label && <span className="label-txt">{label}</span> }
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </label>
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClickHandler: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Checkbox;
