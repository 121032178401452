import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ReactComponent as IconImageCompare } from '../../../../../../../../assets/img/icn-image-compare.svg';
import { makeGet, makeGetFile } from '../../../../../../../../api/ajax';
import { selectors as photoSeriesSelectors } from '../../../../../../../../redux/photoSeries';
import { getTimeFrame } from '../../../../../../../../utils/date';
import { Button, SkeletonUI } from '../../../../../../../../components';
import { constants as imagesConstants } from '../../../../../../../../redux/images';

import './sass/image-compare-search.scss';

const ImageCompareSearch = ({ image, setCompareTargetSrc }) => {
  const listInnerRef = useRef();
  const [compareModeOn, setCompareModeOn] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [pageCount, setPageCount] = useState(null);
  const [page, setPage] = useState(1);
  const [loadingPhotoSeries, setLoadingPhotoSeries] = useState(true);

  const [dropdownPhotoSeries, setDropdownPhotoSeries] = useState([]);
  const photoSeries = useSelector(photoSeriesSelectors.selectPhotoSeries)[image.photoSeriesId];

  const loadPhotoSeries = (page) => {
    setLoadingPhotoSeries(true);

    const baseQuery = `vehicles/${photoSeries.vehicle.id}/photoSeries`;
    const queryParams = [
      'pageSize=10',
      `page=${page}`
    ];

    if (image.imageSubType) queryParams.push(`imageSubType=${image.imageSubType}`);
    queryParams.push(`imageType=${image.imageType}`);

    makeGet(`${baseQuery}?${queryParams.reverse().join('&')}`)
      .subscribe(({ response }) => {
        const photoSeriesFromResponse = response.results.filter((p) => p.id !== image.photoSeriesId).map((result) => ({
          id: result.id,
          timeframe: getTimeFrame(result.createdAt, result.completedAt),
          workflow: result.workflow.name,
          imageId: result.images[0].id
        }));
        if (page === 1) setDropdownPhotoSeries(photoSeriesFromResponse);
        else setDropdownPhotoSeries([...dropdownPhotoSeries, ...photoSeriesFromResponse]);
        setPageCount(response.pageCount);
        setLoadingPhotoSeries(false);
      }, () => {
      });
  };

  useEffect(() => {
    if (photoSeries && image.imageType && image.imageType !== imagesConstants.IMAGE_TYPES.ADDITIONAL) {
      setCompareModeOn(false);
      setPage(1);
      setShowDropdown(false);
      setCompareTargetSrc(null);
      loadPhotoSeries(1);
    }
    // eslint-disable-next-line
  }, [image, photoSeries]);

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && page <= pageCount) {
        setPage(page + 1);
        loadPhotoSeries(page + 1);
      }
    }
  };

  const handleSelect = (imageId) => {
    makeGetFile(`images/${imageId}/download`).subscribe(({ response }) => {
      setCompareTargetSrc(window.URL.createObjectURL(response));
    });

    setShowDropdown(false);
    setCompareModeOn(true);
  };

  const handleCancelCompare = () => {
    setCompareTargetSrc(null);
    setCompareModeOn(false);
  };

  if (loadingPhotoSeries) {
    return (
      <div className="d-flex align-items-center mr-2">
        <SkeletonUI type="radius-horizontal" width="6.875rem" height="2.188rem" classNames="mb-0" />
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center image-compare image-compare-fade-in mr-2">
      {
        compareModeOn
          ? (
            <Button
              className="btn d-flex align-items-center"
              variation="light-blue"
              type="button"
              onClick={handleCancelCompare}
              testId="cancelImageCompareBtn"
            >
              <IconImageCompare />
              Cancel compare
            </Button>)
          : (
            <Button
              className="btn d-flex align-items-center"
              variation="light-blue"
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
              testId="cancelImageCompareBtn"
            >
              <IconImageCompare />
              <span className="image-compare__text">Compare</span>
              <div className="image-compare__dropdown">
                {
                  showDropdown && (
                    <div className="image-compare__dropdown-content thin-scrollbar" onScroll={handleScroll} ref={listInnerRef}>
                      {
                        dropdownPhotoSeries.map((photoSeries) => (
                          <div
                            className="image-compare__photoSeries d-flex flex-column"
                            onClick={() => handleSelect(photoSeries.imageId)}
                            key={photoSeries.id}
                            role="button"
                            tabIndex="0"
                          >
                            <span className="image-compare__photoSeries-timeframe">
                              {photoSeries.timeframe}
                            </span>
                            <span className="image-compare__photoSeries-workflow">
                              {photoSeries.workflow}
                            </span>
                          </div>
                        ))
                      }
                      {
                        !loadingPhotoSeries && dropdownPhotoSeries.length === 0 && (
                          <span className="d-flex p-2 justify-content-center">
                            No photo series found
                          </span>
                        )
                      }
                    </div>
                  )
                }
              </div>
            </Button>
          )
      }
    </div>
  );
};

ImageCompareSearch.propTypes = {
  image: PropTypes.object.isRequired,
  setCompareTargetSrc: PropTypes.func.isRequired,
};

export default ImageCompareSearch;
