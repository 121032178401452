import React from 'react';
import PropTypes from 'prop-types';

import { QualityRating, SkeletonUI } from '..';
import { ReactComponent as IconYellowWarning } from '../../assets/img/icn-warning-yellow.svg';
import { ReactComponent as IconGreyWarning } from '../../assets/img/icn-warning-grey.svg';
import { constants as imagesConstants } from '../../redux/images';

import './sass/photoseries-safety-rating.scss';

const PhotoSeriesImageErrors = ({ photoSeriesImageErrors, isLoading, imageTypeErrors }) => (
  <ul className="photoseries-image-errors-wrapper">
    {
      imageTypeErrors.map((error, index) => {
        if (isLoading) {
          return (<li key={index.toString()}><SkeletonUI type="radius-horizontal" height="1rem" width="20rem" /></li>);
        }

        const isDisabled = (photoSeriesImageErrors && photoSeriesImageErrors.indexOf(error) < 0) || !!!photoSeriesImageErrors;

        return (
          <li key={index.toString()} className={isDisabled ? 'is-disabled' : 'is-enabled'}>
            <section>
              {isDisabled ? <IconGreyWarning className="svg-md" /> : <IconYellowWarning className="svg-md" />}
              &nbsp;
              <span>{imagesConstants.IMAGE_VERIFICATION_ERROR_DISPLAY_TEXT[error]}</span>
            </section>
          </li>
        );
      })
    }
  </ul>
);

PhotoSeriesImageErrors.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  photoSeriesImageErrors: PropTypes.array,
  imageTypeErrors: PropTypes.array
};

const PhotoSeriesQualityRating = ({ isLoading, qualityRating, photoSeriesImageErrors, imageTypeErrors }) => (
  <div className="quality-rating-container photo-series-card">
    <div className="quality-rating-container__row1">
      <div className="quality-rating-container__row1-col1">
        <span className="key heading">Quality Rating</span>
        <span className="value">
          {
            !isLoading && <QualityRating qualityRating={qualityRating} />
          }
        </span>
      </div>
    </div>
    <div className="quality-rating-container__row2">
      <PhotoSeriesImageErrors photoSeriesImageErrors={photoSeriesImageErrors} isLoading={isLoading} imageTypeErrors={imageTypeErrors} />
    </div>
  </div>
);

PhotoSeriesQualityRating.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  photoSeriesImageErrors: PropTypes.array,
  qualityRating: PropTypes.number,
  imageTypeErrors: PropTypes.array
};

export default PhotoSeriesQualityRating;
