import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '../../../../../components';
import { useCheckbox } from '../../../../../components/Checkbox/hooks/useCheckbox';

const HasFeedbackCommentCheckbox = ({ hasFeedbackComment, setHasFeedbackComment }) => {
  const { state, dispatchCheckYes, dispatchCheckNo } = useCheckbox();

  return (
    <div className="has-feedback-comment-checkbox-container form-group">
      <label htmlFor="hasFeedbackComment" className="mb-0 mt-1">Has feedback comment</label>
      <div className="mt-2 d-flex">
        <Checkbox
          id="yesHasFeedbackComment"
          label="Yes"
          name="yesHasFeedbackComment"
          checked={hasFeedbackComment === true ? true : state.yes}
          onClickHandler={({ value }) => {
            dispatchCheckYes();

            if (!value) setHasFeedbackComment(true);
            else setHasFeedbackComment(null);
          }}
        />
        <Checkbox
          className="ml-4"
          id="noHasFeedbackComment"
          label="No"
          name="noHasFeedbackComment"
          checked={hasFeedbackComment === false ? true : state.no}
          onClickHandler={({ value }) => {
            dispatchCheckNo();

            if (!value) setHasFeedbackComment(false);
            else setHasFeedbackComment(null);
          }}
        />
      </div>
    </div>
  );
};

HasFeedbackCommentCheckbox.propTypes = {
  hasFeedbackComment: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setHasFeedbackComment: PropTypes.func.isRequired
};
export default HasFeedbackCommentCheckbox;
