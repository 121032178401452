import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as IconHelp } from '../../../assets/img/icn-help.svg';

import './sass/tooltip.scss';

const Tooltip = ({ children, className }) => {
  const classes = classNames(className, 'help');
  return (
    <div className={classes}>
      <IconHelp />
      <span className="text">{children}</span>
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  className: PropTypes.string
};

export default Tooltip;
