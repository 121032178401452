import React, { useState } from 'react';

import PasswordForm from '../../containers/PasswordForm/PasswordForm';
import { EmptyPage } from '../../layouts';
import { PageTitle } from '../../components';

const ChangePassword = () => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  return (
    <EmptyPage>
      <div className="row">
        <div className="col col-sm-12">
          <PageTitle>
            <PageTitle.Left headingText="Change your password" />
          </PageTitle>
        </div>
      </div>
      <div className="row">
        <div className="col col-sm-12 col-md-4 mt-3">
          <PasswordForm redirectToHome setHasUnsavedChanges={setHasUnsavedChanges} hasUnsavedChanges={hasUnsavedChanges} />
        </div>
      </div>
    </EmptyPage>
  );
};

export default ChangePassword;
