import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from '../../routes/Routes';

import { MenuPage } from '../../layouts';
import { PageTitle, Button, Table, Badge } from '../../components';
import CustomDamageTypeModal from './containers/CustomDamageTypeModal';

import { selectors, actions } from '../../redux/damages';
import { constants as sessionConstants } from '../../redux/session';

import { makeDelete, makeGet, makePost, makePatch } from '../../api/ajax';

import { authenticationService } from '../../services';

const CustomDamageTypes = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [showAddNewSuccessMessage, setShowAddNewSuccessMessage] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditSuccessMessage, setShowEditSuccessMessage] = useState(false);
  const [customDamageType, setCustomDamageType] = useState({ name: '' });

  const dispatch = useDispatch();
  const customDamageTypes = useSelector(selectors.selectCustomDamageTypes);
  const { hasPermission } = authenticationService;

  const {
    VIEW_CUSTOM_DAMAGE_TYPES, CREATE_CUSTOM_DAMAGE_TYPE, DELETE_CUSTOM_DAMAGE_TYPE, EDIT_CUSTOM_DAMAGE_TYPE
  } = sessionConstants.PERMISSION_TYPES;

  useEffect(() => {
    if (!hasPermission(VIEW_CUSTOM_DAMAGE_TYPES)) return;
    if (!customDamageTypes) {
      makeGet('customDamageTypes?pageSize=150&includeDeleted=true').subscribe(({ response }) => {
        dispatch(actions.loadCustomDamageTypesSuccess(response.results));
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, hasPermission, VIEW_CUSTOM_DAMAGE_TYPES, customDamageTypes]);

  const handleAddNew = (values) => {
    if (!hasPermission(CREATE_CUSTOM_DAMAGE_TYPE)) return;
    makePost('customDamageTypes', values).subscribe(({ response }) => {
      dispatch(actions.addCustomDamageTypeSuccess([response]));
      setShowAddNewSuccessMessage(true);
    });
  };

  const handleDelete = (id) => {
    if (!hasPermission(DELETE_CUSTOM_DAMAGE_TYPE)) return;
    makeDelete(`customDamageTypes/${id}`).subscribe(() => {
      dispatch(actions.deleteCustomDamageTypeSuccess(id));
    });
  };

  const handleEdit = ({ id, name }) => {
    if (!hasPermission(EDIT_CUSTOM_DAMAGE_TYPE)) return;
    makePatch(`customDamageTypes/${id}`, { name }).subscribe(({ response }) => {
      dispatch(actions.editCustomDamageTypeSuccess(response));
      setShowEditSuccessMessage(true);
    });
  };

  if (!hasPermission(VIEW_CUSTOM_DAMAGE_TYPES)) return null;

  return (
    <MenuPage currentRoute={routes.customDamageTypes}>
      <PageTitle>
        <PageTitle.Left headingText="Custom damage types" isLoading={isLoading}>
          {
            !isLoading && (
              <Badge className="ml-2">
                { customDamageTypes !== undefined ? customDamageTypes.filter((d) => !d.isDeleted).length : 0 }
              </Badge>
            )
          }
        </PageTitle.Left>
        {hasPermission(CREATE_CUSTOM_DAMAGE_TYPE) && (
          <PageTitle.Right>
            <Button
              testId="AddNewCustomDamageTypeBtn"
              className="btn btn-primary"
              onClick={() => {
                setShowAddNewModal(true);
                setShowAddNewSuccessMessage(false);
              }}
            >
              + Add new
            </Button>
          </PageTitle.Right>
        )}
      </PageTitle>
      <div className="row">
        <div className="col-sm-12">
          <p>
            A list of custom damage types that are available after enabling custom
            damage types under workflow manual damage labelling settings.
          </p>
        </div>
      </div>
      {
        customDamageTypes && (
          <div className="row">
            <div className="col-sm-12">
              <Table
                rows={customDamageTypes}
                handleDelete={handleDelete}
                handleEdit={(customDamageType) => {
                  setShowEditModal(true);
                  setCustomDamageType(customDamageType);
                  setShowEditSuccessMessage(false);
                }}
              />
            </div>
          </div>
        )
      }
      <CustomDamageTypeModal
        title="Add new custom damage type"
        showModal={showAddNewModal}
        setShowModal={setShowAddNewModal}
        handleSubmit={handleAddNew}
        successMessage="New custom damage has been added"
        showSuccessMessage={showAddNewSuccessMessage}
        initialValues={{ name: '' }}
      />
      <CustomDamageTypeModal
        title="Edit custom damage type"
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        handleSubmit={handleEdit}
        successMessage="Custom damage changed"
        showSuccessMessage={showEditSuccessMessage}
        initialValues={customDamageType}
      />
    </MenuPage>
  );
};

export default CustomDamageTypes;
