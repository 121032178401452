import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './sass/toggle.scss';

export const Toggle = ({ on, disabled, toggleClick, className, ...props }) => {
  const [isOn, setIsOn] = useState(on);

  const toggleClickHandler = (isOn) => {
    setIsOn(() => isOn, toggleClick(!isOn, { ...props }));
  };

  const classes = classNames(`toggle ${className}`, {
    'toggle--on': on,
    'toggle--off': !on,
    disabled,
  });

  useEffect(() => setIsOn(on), [on]);

  return (
    <div
      role="button"
      tabIndex={0}
      className={classes}
      data-test="toggleSwitchBtn"
      disabled={disabled}
      onClick={() => !disabled && toggleClickHandler(isOn)}
    >
      <span className="toggle__btn" />
    </div>
  );
};

export const Toggler = ({ on, disabled, onToggle, className }) => {
  const classes = classNames(`toggle ${className}`, {
    'toggle--on': on,
    'toggle--off': !on,
    disabled,
  });

  return (
    <div
      role="button"
      tabIndex={0}
      className={classes}
      data-test="toggleSwitchBtn"
      disabled={disabled}
      onClick={onToggle}
    >
      <span className="toggle__btn" />
    </div>
  );
};

Toggle.propTypes = {
  on: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  toggleClick: PropTypes.func,
  className: PropTypes.string,
};

Toggler.propTypes = {
  on: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Toggle;
