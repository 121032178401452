export const copyToClipboard = (textToBeCopied, optionalFunction) => {
  const node = document.createElement('textarea');
  document.body.appendChild(node);

  node.value = textToBeCopied;

  node.select();

  document.execCommand('copy');
  document.body.removeChild(node);

  if (optionalFunction) {
    optionalFunction();
  }
};
