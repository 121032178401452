import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';

import { Modal, onHideHandler, renderShowConfirmationModal } from '../../../../components';
import { actions as customerConfigurationsActions } from '../../../../redux/customerConfigurations';
import { makePatch } from '../../../../api/ajax';

const SUB_WARNING_VALUES = [
  { id: 'swv01', value: 0 },
  { id: 'swv02', value: 50 },
  { id: 'swv03', value: 75 },
  { id: 'swv04', value: 90 },
];

const SubscriptionWarningModal = ({ customerConfiguration, shownModal, onModalClose }) => {
  const dispatch = useDispatch();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [completionMessage, setCompletionMessage] = useState({ type: '', message: '' });

  const subUsageWarning = customerConfiguration?.subscriptionUsageWarning;

  const onChangeHandler = ({ target }) => {
    if (target.value !== subUsageWarning && !hasUnsavedChanges) setHasUnsavedChanges(true);

    const updateCustomerConfigDto = {
      subscriptionUsageWarning: +target.value,
      inactivityTimeout: customerConfiguration?.inactivityTimeout,
    };

    makePatch('customerConfigurations/current', updateCustomerConfigDto).subscribe(
      ({ response }) => {
        dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(response));

        setCompletionMessage({ type: 'success', message: 'Subscription warning has been changed!' });
        setHasUnsavedChanges(false);
      },
      ({ response }) => {
        setCompletionMessage({ type: 'danger', message: response?.error || 'Subscription warning was not changed!' });
        setHasUnsavedChanges(false);
      }
    );
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, () => {
        onModalClose();
        setShowConfirmModal(false);
      })}
      <Modal
        headerText="Change subscription warning"
        size="md"
        backdrop="static"
        show={shownModal}
        onHide={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
      >
        <Modal.Body>
          <form>
            {completionMessage?.type && (
              <Alert variant={completionMessage.type} dismissible onClick={() => setCompletionMessage(null)}>
                {completionMessage.message}
              </Alert>
            )}
            <label htmlFor="subWarnSelector">
              {!subUsageWarning ? 'Warning is off' : `Warn me if ${subUsageWarning}% is used`}
            </label>
            <select
              name="subscriptionWarning"
              id="subWarnSelector"
              className="form-control form-select"
              value={subUsageWarning || ''}
              onChange={onChangeHandler}
            >
              {SUB_WARNING_VALUES.map(({ id, value }) => (
                // eslint-disable-next-line
                <option key={id} value={value}>
                  {!value ? 'Off' : `${value}%`}
                </option>
              ))}
            </select>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

SubscriptionWarningModal.propTypes = {
  shownModal: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  customerConfiguration: PropTypes.object,
};

export default SubscriptionWarningModal;
