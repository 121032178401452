import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';

import { routes } from '../../routes/Routes';

import { MenuPage } from '../../layouts';
import { PageTitle, Badge, Button } from '../../components';
import CustomImageTypeModal from './containers/CustomImageTypeModal';
import CustomImageTypesAccordion from './containers/CustomImageTypesAccordion/CustomImageTypesAccordion';

import { selectors, actions } from '../../redux/customImageTypes';
import { constants as sessionConstants } from '../../redux/session';
import {
  actions as translationsActions,
  selectors as translationsSelectors,
} from '../../redux/translations';
import {
  actions as customerTranslationsActions,
  selectors as customerTranslationsSelectors,
} from '../../redux/customerTranslations';
import {
  actions as customerConfigurationsActions,
  selectors as customerConfigurationsSelectors,
} from '../../redux/customerConfigurations';

import { makeGet } from '../../api/ajax';

import { authenticationService } from '../../services';

const loadLanguages = (dispatch) => {
  makeGet('languages').subscribe(({ response }) => {
    dispatch(translationsActions.getLanguagesSuccess(response));
  });
};

const loadCustomerTranslations = (dispatch, allLanguages) => {
  for (let i = 0; i < allLanguages.length; i++) {
    const lang = allLanguages[i];
    makeGet(`customerTranslations/${lang.id}`).subscribe(({ response }) => {
      dispatch(customerTranslationsActions.getAllTranslationsSuccess(lang.id, response));
    });
  }
};

const loadCustomerConfiguration = (dispatch) => {
  makeGet('customerConfigurations/current').subscribe(({ response }) => {
    dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(response));
  });
};

const CustomImageTypes = () => {
  const [showAddNewSuccessMessage, setShowAddNewSuccessMessage] = useState(null);

  const [showAddNewModal, setShowAddNewModal] = useState(false);

  const dispatch = useDispatch();
  const customImageTypePagedResults = useSelector(selectors.selectCustomImageTypes);
  const allLanguages = useSelector(translationsSelectors.selectLanguages);
  const customerTranslations = useSelector(customerTranslationsSelectors.selectAllTranslations);
  const customerConfiguration = useSelector(customerConfigurationsSelectors.selectCustomerConfiguration);
  const isLoading = !customImageTypePagedResults || !allLanguages || !customerTranslations || !customerConfiguration;

  const { hasPermission } = authenticationService;

  const {
    VIEW_CUSTOM_IMAGE_TYPES
  } = sessionConstants.PERMISSION_TYPES;

  useEffect(() => {
    if (!hasPermission(VIEW_CUSTOM_IMAGE_TYPES)) return;
    if (!customImageTypePagedResults) {
      makeGet('customImageTypes?pageSize=150').subscribe(({ response }) => {
        dispatch(actions.loadCustomImageTypesSuccess(response));
      });
    }
  }, [dispatch, hasPermission, VIEW_CUSTOM_IMAGE_TYPES, customImageTypePagedResults]);

  useEffect(() => {
    if (allLanguages?.length > 0) return;
    loadLanguages(dispatch);
  }, [allLanguages, dispatch]);

  useEffect(() => {
    // Only load after all languages are loaded
    if (customerTranslations || !allLanguages?.length) return;
    loadCustomerTranslations(dispatch, allLanguages);
  }, [customerTranslations, dispatch, allLanguages]);

  useEffect(() => {
    if (customerConfiguration) return;
    loadCustomerConfiguration(dispatch);
  }, [customerConfiguration, dispatch]);

  if (!hasPermission(VIEW_CUSTOM_IMAGE_TYPES)) return null;

  return (
    <MenuPage currentRoute={routes.customImageTypes}>
      <PageTitle>
        <PageTitle.Left headingText="Custom sub-images" isLoading={isLoading}>
          {
            !isLoading && (
              <Badge className="ml-2">
                { customImageTypePagedResults?.rowCount !== undefined ? customImageTypePagedResults.rowCount : 0 }
              </Badge>
            )
          }
        </PageTitle.Left>
        <PageTitle.Right>
          <Button
            testId="AddNewCustomImageTypeBtn"
            className="btn btn-primary"
            onClick={() => {
              setShowAddNewModal(true);
              setShowAddNewSuccessMessage(null);
            }}
          >
            + Add new
          </Button>
        </PageTitle.Right>
      </PageTitle>
      {
        !isLoading && (
          <>
            <div className="row">
              <div className="col-sm-12">
                <p>
                  A list of custom image types that are available in the "Custom images" section of workflow settings.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                {showAddNewSuccessMessage && (
                  <Alert variant="success" dismissible onClose={() => setShowAddNewSuccessMessage(null)}>
                    {showAddNewSuccessMessage}
                  </Alert>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                {customImageTypePagedResults?.results?.length
                  ? (<CustomImageTypesAccordion customImageTypes={customImageTypePagedResults?.results} />)
                  : (<div className="border-top w-100 pt-3">No custom image types have been configured.</div>)}
              </div>
            </div>
            <CustomImageTypeModal
              title="Add new custom image type"
              showModal={showAddNewModal}
              setShowModal={setShowAddNewModal}
              initialValues={{ name: '' }}
              onSuccess={() => loadCustomerTranslations(dispatch, allLanguages)}
              setShowAddNewSuccessMessage={setShowAddNewSuccessMessage}
            />
          </>
        )
      }
    </MenuPage>
  );
};

export default CustomImageTypes;
