import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import classNames from 'classnames';

const Pagination = ({
  resultCount, currentPage, pageCount, onPageChange,
}) => {
  const renderPage = (page, displayValue) => {
    const classes = classNames('page-item', {
      active: currentPage === page,
    });

    return (
      <li className={classes} key={page}>
        <button className="page-link" type="button" onClick={() => onPageChange(page)} disabled={currentPage === page}>
          {displayValue}
        </button>
      </li>
    );
  };

  const getPageRange = () => {
    const pageRange = [
      {
        displayValue: 1,
        page: 1,
      },
    ];

    const pageNumbers = [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];

    if (pageNumbers[0] > 2) {
      pageRange.push({
        displayValue: '...',
        page: pageNumbers[0] - 1,
      });
    }

    pageNumbers.forEach((pageNumber) => {
      if (pageNumber > 1 && pageNumber < pageCount) {
        pageRange.push({
          displayValue: pageNumber,
          page: pageNumber,
        });
      }
    });

    if (pageNumbers[4] < pageCount - 1) {
      pageRange.push({
        displayValue: '...',
        page: pageNumbers[4] + 1,
      });
    }

    pageRange.push({
      displayValue: pageCount,
      page: pageCount,
    });

    return pageRange;
  };

  const renderPageRange = () => {
    const pageRange = getPageRange();

    return _.map(pageRange, ({ page, displayValue }) => renderPage(page, displayValue));
  };

  const renderAllPages = () => _.map(_.range(1, pageCount + 1), (page) => renderPage(page, page));

  const renderPages = () => (pageCount < 11 ? renderAllPages() : renderPageRange());

  return (
    <>
      <div className="mt-3">{`${resultCount || 'No'} results found`}</div>
      {resultCount > 0 && (
        <nav aria-label="vehicles page navigation" className="mt-3">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                aria-label="Previous"
                type="button"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </button>
            </li>
            {renderPages()}
            <li className="page-item">
              <button
                className="page-link"
                aria-label="Next"
                type="button"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === pageCount}
              >
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </button>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

Pagination.propTypes = {
  resultCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};
export default Pagination;
