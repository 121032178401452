import React from 'react';

import { routes } from '../../routes/Routes';
import { MenuPage } from '../../layouts';
import { PageTitle } from '../../components';

import { constants as sessionConstants } from '../../redux/session';

import { authenticationService } from '../../services';
import ExternalCharts from './containers/ExternalCharts';

const Statistics = () => {
  const { hasPermission } = authenticationService;

  const { VIEW_STATISTICS } = sessionConstants.PERMISSION_TYPES;
  if (!hasPermission(VIEW_STATISTICS)) return null;

  return (
    <MenuPage currentRoute={routes.statistics}>
      <PageTitle>
        <PageTitle.Left headingText="Usage statistics" />
      </PageTitle>
      <ExternalCharts />
    </MenuPage>
  );
};

export default Statistics;
