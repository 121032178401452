import React from 'react';
import PropTypes from 'prop-types';

import './sass/lang-dropdown.scss';

/**
 * Renders a dropdown component for selecting a language.
 *
 * @param {Object} props - The props object.
 * @param {Function} props.selectLanguage - The function to call when a language is selected.
 * @param {Object} props.selectedLanguage - The currently selected language object.
 * @param {Array} props.languages - An array of language objects.
 * @return {JSX.Element} The rendered dropdown component.
 */
const LanguageDropDown = ({ selectLanguage, selectedLanguage, languages }) => (
  <select
    name={languages.length > 1 ? 'target_language' : 'source_language'}
    value={selectedLanguage?.code}
    className="lang-dropdown"
    title={`The selected language is ${selectedLanguage?.name}`}
    disabled={languages?.length === 1}
    onChange={({ target: { value } }) => {
      if (!selectLanguage) return;

      const newLanguage = languages.find(({ code }) => code === value);
      selectLanguage(newLanguage);
    }}
  >
    {
      languages
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ id, name, code }) => (
          <option value={code} key={id}>
            {name}
          </option>
        ))
    }
  </select>
);

LanguageDropDown.propTypes = {
  selectedLanguage: PropTypes.object,
  languages: PropTypes.array.isRequired,
  selectLanguage: PropTypes.func,
};

export default LanguageDropDown;
