import React from 'react';
import PropTypes from 'prop-types';
import PrismaZoom from 'react-prismazoom';
import { ReactCompareSlider } from 'react-compare-slider';

import BoundingBoxPresentation from '../BoundingBoxPresentation/BoundingBoxPresentation';

import { ReactComponent as ChevronLeftIcon } from '../../assets/img/icn-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/img/icn-chevron-right.svg';

import { Loader, Text } from '..';

import './sass/photo-viewer.scss';

const LeftRightImageCompare = ({ leftImageSrc, rightImageSrch, onZoomChange, zoomWrapperRef }) => {
  if (!leftImageSrc) return null;
  if (!rightImageSrch) return null;

  return (
    <ReactCompareSlider
      itemOne={
        <PrismaZoom
          onZoomChange={onZoomChange}
          ref={zoomWrapperRef}
        >
          <img src={leftImageSrc} alt="Vehicle left" />
        </PrismaZoom>
      }
      itemTwo={
        <PrismaZoom
          onZoomChange={onZoomChange}
          ref={zoomWrapperRef}
        >
          <img src={rightImageSrch} alt="Vehicle right" />
        </PrismaZoom>
      }
    />
  );
};

LeftRightImageCompare.propTypes = {
  leftImageSrc: PropTypes.string,
  rightImageSrch: PropTypes.string,
  onZoomChange: PropTypes.func.isRequired,
  zoomWrapperRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // This will cover the initial value of current property which is null and instance of a DOM native element
    PropTypes.shape({ current: PropTypes.any }),
  ])
};

const ImageRenderer = ({ src, compareTargetSrc, onZoomChange, zoomWrapperRef, srcPolygons }) => {
  if (!src) return <Loader />;

  if (src !== null && compareTargetSrc !== null) {
    return (
      <LeftRightImageCompare
        leftImageSrc={src}
        rightImageSrch={compareTargetSrc}
        onZoomChange={onZoomChange}
        zoomWrapperRef={zoomWrapperRef}
      />
    );
  }

  if (src && compareTargetSrc == null && srcPolygons.length > 0) {
    return (
      <PrismaZoom onZoomChange={onZoomChange} ref={zoomWrapperRef}>
        <BoundingBoxPresentation
          imageSrc={src}
          polygons={srcPolygons}
        />
      </PrismaZoom>
    );
  }

  return (
    <PrismaZoom onZoomChange={onZoomChange} ref={zoomWrapperRef}>
      <img src={src} alt="Vehicle large" />
    </PrismaZoom>
  );
};

ImageRenderer.propTypes = {
  src: PropTypes.string,
  compareTargetSrc: PropTypes.string,
  onZoomChange: PropTypes.func.isRequired,
  zoomWrapperRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // This will cover the initial value of current property which is null and instance of a DOM native element
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  srcPolygons: PropTypes.array,
};

const PhotoViewer = ({
  src, compareTargetSrc, onNextClick, onPrevClick, onZoomChange, zoom, zoomWrapperRef, srcPolygons, children
}) => (
  <div className="photo-viewer">
    <div className="photo-viewer__photo-section">
      <button className="large prev" onClick={onPrevClick} type="button">
        <ChevronLeftIcon />
      </button>
      <div className={`img__wrapper ${zoom > 1 ? 'is-zoomed' : ''}`}>
        <ImageRenderer
          src={src}
          srcPolygons={srcPolygons}
          compareTargetSrc={compareTargetSrc}
          onZoomChange={onZoomChange}
          zoomWrapperRef={zoomWrapperRef}
        />
        <div className="zoom__info">
          <Text weight="bold" size={2}>
            {parseInt(zoom * 100, 10)}
            %
          </Text>
        </div>
      </div>
      <button className="large next" onClick={onNextClick} type="button">
        <ChevronRightIcon />
      </button>
    </div>
    {children}
  </div>
);

PhotoViewer.propTypes = {
  src: PropTypes.string,
  compareTargetSrc: PropTypes.string,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onZoomChange: PropTypes.func.isRequired,
  zoom: PropTypes.number,
  children: PropTypes.node,
  zoomWrapperRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // This will cover the initial value of current property which is null and instance of a DOM native element
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  srcPolygons: PropTypes.array,
};

export default PhotoViewer;
