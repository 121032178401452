import { createSelector } from 'reselect';
import _ from 'lodash';

import constants from './constants';

const selectState = (state) => state[constants.NAME];

const selectCountries = (state) => state[constants.NAME].countries;

const getCountryId = (_, countryId) => countryId;
const selectCountry = createSelector(selectCountries, getCountryId, (countries, countryId) =>
  (_.isEmpty(countries) ? null : countries[countryId]));

const selectPagedResult = createSelector([selectState], ({ pagedResult }) =>
  (_.isEmpty(pagedResult)
    ? null
    : {
      ...pagedResult,
    }));

export default {
  selectCountry,
  selectPagedResult,
};
