import types from './types';

const searchVehiclesSuccess = (vehiclesPagedResult, vehiclesFilter) => ({
  type: types.SEARCH_VEHICLES_SUCCESS,
  payload: {
    vehiclesPagedResult,
    vehiclesFilter,
  },
});

const loadVehiclePhotoSeriesSuccess = (vehiclePhotoSeriesPagedResult, vehicleId) => ({
  type: types.LOAD_VEHICLE_PHOTOSERIES_SUCCESS,
  payload: {
    vehiclePhotoSeriesPagedResult,
    vehicleId,
  },
});

const setPagedResultLoaded = (loaded) => ({
  type: types.SET_PAGED_RESULT_LOADED,
  payload: {
    loaded,
  },
});

export default {
  searchVehiclesSuccess,
  loadVehiclePhotoSeriesSuccess,
  setPagedResultLoaded,
};
