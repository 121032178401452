const prefix = 'drivex/workflows';

const LOAD_WORKFLOWS_SUCCESS = `${prefix}/LOAD_WORKFLOWS_SUCCESS`;
const UPDATE_IMAGE_TYPES_SUCCESS = `${prefix}/UPDATE_IMAGE_TYPES_SUCCESS`;
const LOAD_CAPTURE_SETTINGS_SUCCESS = `${prefix}/LOAD_CAPTURE_SETTINGS_SUCCESS`;
const CREATE_WORKFLOW_SUCCESS = `${prefix}/CREATE_WORKFLOW_SUCCESS`;
const UPDATE_WORKFLOW_SUCCESS = `${prefix}/UPDATE_WORKFLOW_SUCCESS`;
const DELETE_WORKFLOW_SUCCESS = `${prefix}/DELETE_WORKFLOW_SUCCESS`;
const LOAD_SHARED_WORKFLOWS_SUCCESS = `${prefix}/LOAD_SHARED_WORKFLOWS_SUCCESS`;

export default {
  LOAD_WORKFLOWS_SUCCESS,
  UPDATE_IMAGE_TYPES_SUCCESS,
  LOAD_CAPTURE_SETTINGS_SUCCESS,
  CREATE_WORKFLOW_SUCCESS,
  UPDATE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_SUCCESS,
  LOAD_SHARED_WORKFLOWS_SUCCESS
};
