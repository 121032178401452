import { createSelector } from 'reselect';
import { getUtcToday } from '../../utils/date';

import constants from './constants';

const selectCustomerMonthlyConsumptionsState = (state) => state[constants.NAME];
const selectCustomerMonthlyConsumption = createSelector([selectCustomerMonthlyConsumptionsState],
  (customerMonthlyConsumptions) => customerMonthlyConsumptions.monthlyConsumptions);

const selectCurrentMonthConsumption = createSelector(
  selectCustomerMonthlyConsumption,
  (monthlyConsumptions) => {
    const todayString = getUtcToday().toISOString();
    return monthlyConsumptions && monthlyConsumptions[todayString];
  }
);
export default {
  selectCustomerMonthlyConsumption,
  selectCurrentMonthConsumption
};
