import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { constants as countriesConstants } from '../../redux/countries';
import { useUniqueIds } from '../../hooks/useUniqueIds/useUniqueIds';

import './sass/infinite-scroller.scss';

const InfiniteScroller = ({ children }) => <div className="infinite-scroller-container">{children}</div>;

InfiniteScroller.propTypes = {
  children: PropTypes.elementType.isRequired,
};

const Header = ({ children, enableSearch, searchedPhrase, setSearchedPhrase, setPage }) => {
  const [shallowSearchedPhrase, setShallowSearchedPhrase] = useState(searchedPhrase);
  const [searchPhraseInputId] = useUniqueIds(1);

  return (
    <div className="infinite-scroll-header">
      {enableSearch && (
        <div className="infinite-scroll-header__infinite-scroll-search-wrapper">
          <input
            type="search"
            name="searchedPhrase"
            id={searchPhraseInputId}
            value={shallowSearchedPhrase}
            placeholder="Search country"
            aria-label="Search here"
            onChange={({ target }) => {
              setPage(countriesConstants.DEFAULT_SORT.page);

              setShallowSearchedPhrase(target.value);

              if (target.value.length === 0) {
                setSearchedPhrase(target.value);
              }
            }}
          />

          <button type="button" onClick={() => setSearchedPhrase(shallowSearchedPhrase)} disabled={shallowSearchedPhrase === searchedPhrase}>
            Search
          </button>
        </div>
      )}

      {children && (
        <div className="selected-item-wrapper">
          {children}

          <div className="currently-selected">currently selected</div>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  enableSearch: PropTypes.bool,
  searchedPhrase: PropTypes.string,
  setPage: PropTypes.func,
  setSearchedPhrase: PropTypes.func,
};

const Body = ({ children, page, totalPages, setPage }) => {
  const onScrollHandler = (ev) => {
    const bottom = ev.currentTarget.scrollHeight - ev.currentTarget.scrollTop === ev.currentTarget.clientHeight;

    if (page !== totalPages && bottom) {
      setPage((page) => page + 1);
    }
  };

  return (
    <div className="infinite-scroll-body" onScroll={onScrollHandler}>
      {children}
    </div>
  );
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

InfiniteScroller.Body = Body;
InfiniteScroller.Header = Header;

export default InfiniteScroller;
