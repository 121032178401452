import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { makeGet } from '../../api/ajax';
import { composeApiUrl } from '../../api/composeApiUrl';

import { ReactComponent as Download } from '../../assets/img/icn-download.svg';

import { Container, ImageWithText } from '../../components';

import { constants as fileConstants } from '../../redux/files';

const DownloadArchive = () => {
  const location = useLocation();

  useEffect(() => {
    const { photoSeriesId } = queryString.parse(location.search);

    const fileEntityType = fileConstants.FILE_ENTITY_TYPES.PHOTO_SERIES_ZIP_ARCHIVE;

    makeGet(`files/${photoSeriesId}/token?entityType=${fileEntityType}`).subscribe(
      ({ response }) => {
        window.location = composeApiUrl(`files/${photoSeriesId}/download?token=${response.token}`);
      },
      ({ response: { error } }) => console.error(error),
    );
  });

  return (
    <Container fullscreen horizontalAlign="center" verticalAlign="center">
      <ImageWithText large primaryText="We are preparing your photos, download will start shortly">
        <Download />
      </ImageWithText>
    </Container>
  );
};

export default DownloadArchive;
