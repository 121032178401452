import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonUI, Text } from '..';
import { Tooltip } from '../Form';

import './sass/active-workflows-info.scss';

const ActiveWorkflowsInfo = ({ id, workflows, workflowIds }) => {
  if (!workflows) {
    return (
      <SkeletonUI type="radius-horizontal" height="1rem" width="15rem" />
    );
  }

  return (
    <>
      <Text color="gray" size={2}>
        {
          !workflowIds?.length
            ? <span>Not in use</span>
            : (
              <>
                <span>Active in {workflowIds?.length} workflows</span>
                <Tooltip className="active-workflows-info-tooltip">
                  <ul>
                    {
                      workflowIds.map((workflowId) => {
                        const workflow = workflows[workflowId];
                        return (
                          <li key={`${id}-${workflowId}`}>
                            <span>{ workflow !== undefined ? workflow.name : workflowId}</span>
                          </li>
                        );
                      })
                    }
                  </ul>
                </Tooltip>
              </>
            )
          }
      </Text>
    </>
  );
};

ActiveWorkflowsInfo.propTypes = {
  id: PropTypes.string.isRequired,
  workflows: PropTypes.object,
  workflowIds: PropTypes.array,
};

export default ActiveWorkflowsInfo;
