import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import _ from 'lodash';

import { useBeforeUnload } from '../../../../hooks';
import { makePatch } from '../../../../api/ajax';
import { Field } from '../../../../components/Form';
import { Modal, onHideHandler, renderShowConfirmationModal } from '../../../../components';
import { actions as customerActions, selectors as customerSelectors } from '../../../../redux/customers';

const DefaultLanguage = ({ onModalClose, languages, ...props }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useBeforeUnload(hasUnsavedChanges);

  const dispatch = useDispatch();

  const customerDetails = useSelector(customerSelectors.selectCustomerDetails);

  const languageOptions = _.map(
    languages,
    (lang) => ({ name: lang.name, value: lang.code })
  );

  const initialValues = {
    defaultLanguage: customerDetails?.defaultLanguage,
  };

  const handleSubmit = (data, setStatus, setShowSuccessMessage, resetForm, setHasUnsavedChanges) => {
    makePatch('customers', data).subscribe(
      ({ response }) => {
        dispatch(customerActions.loadCustomerDetailsSuccess(response));
        dispatch(customerActions.loadCustomerSuccess(response));

        setShowSuccessMessage('Language has been changed!');
        resetForm();

        setHasUnsavedChanges(false);
      },
      ({ response: { error } }) => {
        setStatus(error);

        setHasUnsavedChanges(false);
      },
    );
  };

  const hideDiscardConfirmation = () => {
    onModalClose();
    setShowConfirmModal(false);
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, hideDiscardConfirmation)}
      <Modal
        headerText="Change default language"
        size="md"
        backdrop="static"
        onHide={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
        {...props}
      >
        <Modal.Body>
          <div className="row">
            <div className="col">
              {showSuccessMessage && (
                <Alert variant="success" dismissible onClick={() => setShowSuccessMessage(null)}>
                  {showSuccessMessage}
                </Alert>
              )}
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={({ defaultLanguage }, { setStatus, resetForm }) =>
              handleSubmit({ defaultLanguage }, setStatus, setShowSuccessMessage, resetForm, setHasUnsavedChanges)}
            validateOnChange={false}
          >
            {({ values, touched }) => (
              <Form
                onChange={({ target }) => {
                  if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                    setHasUnsavedChanges(true);
                  }
                }}
              >
                <div className="row">
                  <div className="col-10 col-md-7">
                    <Field name="defaultLanguage" type="select" value={values.code} touched={touched.value} options={languageOptions} />
                  </div>
                  <div className="col-4 col-md-5 text-right">
                    <button
                      type="reset"
                      className="btn btn-secondary mr-1"
                      onClick={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

DefaultLanguage.propTypes = {
  onModalClose: PropTypes.func,
  languages: PropTypes.array
};

export default DefaultLanguage;
