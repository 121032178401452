import React, { useState } from 'react';

import { useParams, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { NotificationModal } from '../../../components';
import { Field } from '../../../components/Form';
import { routes } from '../../../routes/Routes';
import { useUniqueIds } from '../../../hooks/useUniqueIds/useUniqueIds';
import { makePost } from '../../../api/ajax';
import { trackEvent, EVENT_TYPES } from '../../../api/analytics';

const modalStatusInitialState = Object.freeze({
  isLoading: false,
  errorText: '',
  successText: '',
});

const ResetPasswordForm = () => {
  const [modalStatus, setModaLStatus] = useState(modalStatusInitialState);

  /** prefilled email */
  const { email } = useParams();

  const [userEmailId] = useUniqueIds(1);

  const onSubmitHandler = (userEmail, setSubmitting) => {
    setModaLStatus(modalStatusInitialState);

    makePost('users/forgotPassword', { email: userEmail }).subscribe(
      () => {
        setSubmitting(false);

        setModaLStatus({
          isLoading: false,
          errorText: null,
          successText: 'If your account is valid, you will find instructions to reset your password sent to your email.',
        });

        // Track event in Analytics
        trackEvent(EVENT_TYPES.USER_PASSWORD_RESET_REQUESTED, { userEmail });
      },
      (response) => {
        setSubmitting(false);

        setModaLStatus(() => {
          if (response.message || response.error) {
            return {
              isLoading: false,
              successText: null,
              errorText: response.message || response.error,
            };
          }

          return {
            isLoading: false,
            successText: null,
            errorText: 'Sorry an unexpected error occured.',
          };
        });
      },
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          userEmail: email,
        }}
        validationSchema={Yup.object().shape({
          userEmail: Yup.string().required('Please insert your e-mail before you continue').email('Please insert a valid e-mail and continue'),
        })}
        onSubmit={({ userEmail }, { setSubmitting }) => onSubmitHandler(userEmail, setSubmitting)}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form style={formStyle}>
            <Field
              id={userEmailId}
              name="userEmail"
              label="E-mail"
              labelStyle={labelStyle}
              type="email"
              placeholder="Insert e-mail"
              value={values.userEmail}
              errors={errors.userEmail}
              touched={touched.userEmail}
              required
            />

            <button type="submit" className="btn btn-primary" style={btnStyle} disabled={isSubmitting}>
              Send me a new password
            </button>

            <div className="d-flex justify-content-center" style={{ fontSize: '0.85rem' }}>
              <p className="pr-1">Found your password?</p>

              <Link to={routes.login} style={{ textDecoration: 'underline' }}>
                Log in!
              </Link>
            </div>
          </Form>
        )}
      </Formik>
      <NotificationModal
        type={modalStatus.errorText ? 'error' : 'success'}
        message={modalStatus.errorText || modalStatus.successText}
        showModal={!!modalStatus.errorText || !!modalStatus.successText}
        position="right"
        onCancel={() => setModaLStatus(modalStatusInitialState)}
      />
    </>
  );
};

const labelStyle = { display: 'block', textAlign: 'left', fontSize: '0.7rem' };
const btnStyle = { width: '100%', margin: '0.625rem auto' };
const formStyle = { width: '100%', maxWidth: '22.4375rem', margin: '0 auto' };

export default ResetPasswordForm;
