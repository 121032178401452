import { normalize } from 'normalizr';
import types from './types';

import sessionTypes from '../session/types';

import { workflowListSchema } from './schema';

const defaultState = {};

const workflowsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_SHARED_WORKFLOWS_SUCCESS:
      const { sharedWorkflows } = action.payload;
      return {
        ...state,
        sharedWorkflows: sharedWorkflows || {
          workflows: [],
          customers: []
        }
      };
    case types.LOAD_WORKFLOWS_SUCCESS:
      const { workflows } = action.payload;

      const normalizedWorkflows = normalize(workflows, workflowListSchema);

      return {
        ...state,
        workflows: (normalizedWorkflows.entities && normalizedWorkflows.entities.workflows) || {},
      };
    case types.UPDATE_IMAGE_TYPES_SUCCESS: {
      const { workflowId, imageTypeSettings } = action.payload;

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [workflowId]: {
            ...state.workflows[workflowId],
            imageTypeSettings,
          },
        },
      };
    }
    case types.LOAD_CAPTURE_SETTINGS_SUCCESS: {
      const { workflowId, imageType, captureSettings } = action.payload;

      return {
        ...state,
        captureSettings: {
          ...state.captureSettings,
          [`${workflowId}_${imageType}`]: captureSettings,
        },
      };
    }
    case types.UPDATE_WORKFLOW_SUCCESS: {
      const { workflowId, workflow } = action.payload;

      // Remove captureSettings for current workflow as image type settings might be expired after editing workflow
      // E.g disabling manual marking on workflow also disables it on 'Additional' images
      const newCaptureSettings = state.captureSettings
        && Object.keys(state.captureSettings)
          .filter((objKey) => !objKey.includes(workflowId))
          .reduce((newObj, key) =>
          {
            newObj[key] = state.captureSettings[key];
            return newObj;
          }, {});

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [workflowId]: {
            ...state.workflows[workflowId],
            ...workflow,
          },
        },
        captureSettings: {
          ...newCaptureSettings,
        }
      };
    }
    case types.CREATE_WORKFLOW_SUCCESS: {
      const { workflow } = action.payload;

      const { id: workflowId } = workflow;

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [workflowId]: {
            ...workflow,
          },
        },
      };
    }
    case types.DELETE_WORKFLOW_SUCCESS: {
      const { workflowId } = action.payload;

      const nextWorkflows = state.workflows;
      nextWorkflows[workflowId].deletedAt = new Date();

      return {
        ...state,
        workflows: {
          ...nextWorkflows,
        },
      };
    }
    case sessionTypes.LOG_OUT:
      return defaultState;
    default:
      return state;
  }
};

export default workflowsReducer;
