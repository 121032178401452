import types from './types';

const loadImageDamagesSuccess = (photoSeriesId, images) => ({
  type: types.LOAD_IMAGE_DAMAGES_SUCCESS,
  payload: {
    photoSeriesId,
    images
  },
});

const loadPhotoSeriesDamagesSuccess = (photoSeriesId, damages) => ({
  type: types.LOAD_PHOTO_SERIES_DAMAGES_SUCCESS,
  payload: {
    photoSeriesId,
    damages
  },
});

const showImageDamageBoundingBox = (photoSeriesId, imageId, damageId, show) => ({
  type: types.SHOW_IMAGE_DAMAGE_BOUNDING_BOX,
  payload: {
    photoSeriesId,
    imageId,
    damageId,
    show
  }
});

const loadCustomDamageTypesSuccess = (customDamageTypes) => ({
  type: types.LOAD_CUSTOM_DAMAGE_TYPES_SUCCESS,
  payload: {
    customDamageTypes
  }
});

const addCustomDamageTypeSuccess = (customDamageTypes) => ({
  type: types.ADD_CUSTOM_DAMAGE_TYPE_SUCCESS,
  payload: {
    customDamageTypes
  }
});

const editCustomDamageTypeSuccess = (customDamageType) => ({
  type: types.EDIT_CUSTOM_DAMAGE_TYPE_SUCCESS,
  payload: {
    customDamageType
  }
});

const deleteCustomDamageTypeSuccess = (customDamageTypeId) => ({
  type: types.DELETE_CUSTOM_DAMAGE_TYPE_SUCCESS,
  payload: {
    customDamageTypeId
  }
});

export default {
  loadImageDamagesSuccess,
  loadPhotoSeriesDamagesSuccess,
  showImageDamageBoundingBox,
  loadCustomDamageTypesSuccess,
  addCustomDamageTypeSuccess,
  editCustomDamageTypeSuccess,
  deleteCustomDamageTypeSuccess
};
