import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './sass/react-portal.scss';

const rootModal = document.getElementById('rootModal');

const ReactPortal = ({ children }) => {
  const modalContainer = document.createElement('div');
  modalContainer.setAttribute('class', 'modal-container');

  useEffect(() => {
    rootModal.appendChild(modalContainer);

    return () => {
      rootModal.removeChild(modalContainer);
    };
  }, [modalContainer]);
  return ReactDOM.createPortal(children, modalContainer);
};

ReactPortal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReactPortal;
