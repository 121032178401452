import { authenticationService } from '../services';

export const trackEvent = (eventType, data) => {
  // Rudimentary Segment integration code
  // Refactor in future when we use analytics again
  if (!window.analytics) return;

  const { currentUserValue } = authenticationService;

  if (!currentUserValue) return;

  const dataWithUserInformation = currentUserValue
    ? {
      ...data,
      currentUserId: currentUserValue.id,
      currentUserEmail: currentUserValue.email,
      currentCustomerCode: currentUserValue.customerCode,
    }
    : data;

  window.analytics.track(eventType, dataWithUserInformation);
};

export const identifyUser = ({ id, email, customerCode }) => {
  // Rudimentary Segment integration code
  // Refactor in future when we use analytics again
  if (!window.analytics) return;

  window.analytics.identify(id, { email, customerCode, app: 'DriveX Admin' });
};

export const EVENT_TYPES = {
  USER_LOGGED_IN: 'User logged in',
  USER_LOGGED_OUT: 'User logged out',
  USER_PASSWORD_RESET_REQUESTED: 'User password reset requested',
  USER_PASSWORD_CHANGED: 'User password changed',
  USER_INVITED: 'New user invited',
  ADDITIONAL_INFO_ADDED: 'User provided additional information',
  VERIFICATION_REQUEST_GENERATED: 'Verification request generated',
  VEHICLES_FILTER_SEARCHED: 'Vehicles filter searched',
  VEHICLE_DETAILS_OPENED: 'Vehicle detail view opened',
  PUBLIC_SHARING_CHANGED: 'Public sharing changed',
  PHOTO_SERIES_CONFIRMED: 'User confirmed photo series',
  IMAGE_COMMENT_ADDED: 'New image comment added',
  WORKFLOW_ADDED: 'New workflow added',
  WORKFLOW_EDITED: 'Existing workflow edited',
  WORKFLOW_DELETED: 'A workflow was deleted',
  WORKFLOW_IMAGE_TYPE_ENABLED: 'Workflow image type enabled',
  WORKFLOW_IMAGE_TYPE_DISABLED: 'Workflow image type disabled',
  WORKFLOW_VALIDATION_SETTINGS_EDITED: 'Workflow validation settings edited',
  STATISTICS_MONTH_CHANGED: 'Statistics month changed',
};
