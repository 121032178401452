import React from 'react';
import PropTypes from 'prop-types';

const renderLimitedChars = (string, chars) => string?.length > chars
  ? (
    <span title={string}>
      {`${string.substring(0, chars - 1)}...`}
    </span>
  )
  : string;

const LimitedCharsStringPresentation = ({ value, chars }) => renderLimitedChars(value, chars) || '-';

LimitedCharsStringPresentation.propTypes = {
  value: PropTypes.string,
  chars: PropTypes.number.isRequired
};

export default LimitedCharsStringPresentation;
