import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Alert } from 'react-bootstrap';
import { Modal, Text } from '../../../../components';
import { Field } from '../../../../components/Form';
import { OPERATING_SYSTEM, operatingSystemName } from '../../UserAgentBlacklist';

const UserAgentBlacklistModal = ({ showModal, setShowModal, handleSubmit, successMessage, setSuccessMessage }) => {
  const [operatingSystem, setOperatingSystem] = useState(null);

  const onClose = () => {
    setShowModal(false);
    setSuccessMessage(null);
    setOperatingSystem(OPERATING_SYSTEM.AndroidAndIos);
  };

  return (
    <Modal
      size="md"
      headerText="New user agent blacklist phrase"
      onHide={onClose}
      backdrop="static"
      show={showModal}
    >
      <Modal.Body>
        <div className="row">
          <div className="col">
            {successMessage && (
              <Alert variant="success" dismissible onClick={() => setSuccessMessage(null)}>
                {successMessage}
              </Alert>
            )}
          </div>
        </div>

        <Formik
          initialValues={{
            phrase: '',
            comment: ''
          }}
          validationSchema={Yup.object().shape({
            phrase: Yup.string()
              .required('Phrase is required')
              .max(50, 'Must not exceed 50 characters'),
            comment: Yup.string()
              .required('Phrase is required')
              .max(200, 'Must not exceed 200 characters')
          })}
          onSubmit={(formData) => handleSubmit({ ...formData, operatingSystem })}
          validateOnChange={false}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-10 col-sm-12">
                  <Field type="text" name="phrase" label="Phrase" touched={touched.phrase} errors={errors.phrase} />
                </div>
              </div>

              <div className="row">
                <div className="col-10 col-sm-12">
                  <Field type="text" name="comment" label="Comment" touched={touched.comment} errors={errors.comment} />
                </div>
              </div>

              <div className="row my-2">
                <div className="col-10 col-sm-12">
                  <Text>Operating system</Text>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <label htmlFor="androidAndIos">
                    <input
                      type="radio"
                      name="operatingSystem"
                      checked={operatingSystem === OPERATING_SYSTEM.AndroidAndIos}
                      id="androidAndIos"
                      onChange={() => setOperatingSystem(OPERATING_SYSTEM.AndroidAndIos)}
                    />
                    &nbsp; { operatingSystemName(OPERATING_SYSTEM.AndroidAndIos) }
                  </label>
                </div>

                <div className="col-sm-4">
                  <label htmlFor="android">
                    <input
                      type="radio"
                      name="operatingSystem"
                      checked={operatingSystem === OPERATING_SYSTEM.Android}
                      id="android"
                      onChange={() => setOperatingSystem(OPERATING_SYSTEM.Android)}
                    />
                    &nbsp; { operatingSystemName(OPERATING_SYSTEM.Android) }
                  </label>
                </div>

                <div className="col-sm-4">
                  <label htmlFor="ios">
                    <input
                      type="radio"
                      name="operatingSystem"
                      checked={operatingSystem === OPERATING_SYSTEM.Ios}
                      id="ios"
                      onChange={() => setOperatingSystem(OPERATING_SYSTEM.Ios)}
                    />
                    &nbsp; { operatingSystemName(OPERATING_SYSTEM.Ios) }
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-10 col-sm-12 pt-3 text-right">
                  <button
                    type="button"
                    className="btn btn-secondary mr-1"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

UserAgentBlacklistModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
  setSuccessMessage: PropTypes.func.isRequired
};

export default UserAgentBlacklistModal;
