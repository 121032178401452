import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../../../components';
import { operatingSystemName } from '../../UserAgentBlacklist';

import './sass/user-agent-blacklist-table.scss';

const UserAgentBlacklistTable = ({ userAgents, handleDelete }) => (
  <div className="user-agent-blacklist-table">
    {
      userAgents.map((userAgent) => (
        <div className="user-agent-blacklist-table-row" key={userAgent.id}>
          <div className="row">
            <div className="col-sm-4 user-agent-text">
              <Text size={2} weight="bold" inline>
                { userAgent.phrase }
              </Text>
            </div>

            <div className="col-sm-5 user-agent-text">
              <Text size={2}>
                { userAgent.comment }
              </Text>
            </div>

            <div className="col-sm-2 user-agent-text">
              <Text size={2} color="gray">
                { operatingSystemName(userAgent.operatingSystem) }
              </Text>
            </div>

            <div className="col-sm-1">
              <button
                type="button"
                className="btn btn-danger"
                data-testid="DeleteUserAgentBtn"
                onClick={() => handleDelete(userAgent.id)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ))
    }
  </div>
);

UserAgentBlacklistTable.propTypes = {
  userAgents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      phrase: PropTypes.string.isRequired,
      comment: PropTypes.string
    })
  ),
  handleDelete: PropTypes.func.isRequired
};

export default UserAgentBlacklistTable;
