const NAME = 'session';

const PERMISSION_TYPES = {
  VIEW_IMAGES: 0,
  CREATE_IMAGES: 1,
  CREATE_PHOTO_SERIES: 2,
  VIEW_VEHICLES: 3,
  VIEW_PHOTO_SERIES: 4,
  EDIT_CUSTOMER_CONFIGURATION: 5,
  VIEW_OTHER_CUSTOMERS: 6,
  MANAGE_USERS: 7,
  EDIT_OTHER_CUSTOMERS: 8,
  CREATE_CUSTOMERS: 9,
  EDIT_WORKFLOWS: 10,
  CONFIRM_PHOTO_SERIES: 11,
  SHARE_PHOTO_SERIES: 12,
  EDIT_PHOTOSERIES: 13,
  EDIT_SUBSCRIPTIONS: 14,
  VIEW_COMMENTS: 15,
  CREATE_COMMENTS: 16,
  EDIT_TRANSLATIONS: 17,
  EXPORT_PHOTO_SERIES: 22,
  IMPORT_PHOTO_SERIES: 23,
  VIEW_DAMAGES: 24,
  EDIT_IMAGE_DAMAGE: 25,
  EDIT_BLACKLISTED_USER_AGENT: 27,
  EDIT_GLOBAL_TRANSLATIONS: 28,
  COMPLETE_PHOTO_SERIES: 32,
  VIEW_CUSTOM_DAMAGE_TYPES: 33,
  CREATE_CUSTOM_DAMAGE_TYPE: 34,
  EDIT_CUSTOM_DAMAGE_TYPE: 35,
  DELETE_CUSTOM_DAMAGE_TYPE: 36,
  VIEW_CUSTOM_IMAGE_TYPES: 41,
  EDIT_CUSTOM_IMAGE_TYPE: 42,
  DELETE_CUSTOM_IMAGE_TYPE: 43,
  CREATE_CUSTOM_IMAGE_TYPE: 44,
  VIEW_STATISTICS: 45,
  EDIT_WORKFLOW_USER_FEEDBACK_SETTINGS: 46,
  REGENERATE_REPORTS: 47,
  DELETE_IMAGE: 48,
  VIEW_WINDSHIELD_REPAIRABILITY_PROFILES: 49,
  CREATE_WINDSHIELD_REPAIRABILITY_PROFILE: 50,
  EDIT_WINDSHIELD_REPAIRABILITY_PROFILE: 51,
  DELETE_WINDSHIELD_REPAIRABILITY_PROFILE: 52,
  EDIT_WINDSHIELD_REPAIR_THRESHOLDS: 54
};

const ROLE_TYPES = {
  SMART_SCAN_USER: 0,
  API_USER: 1,
  MODERATOR: 2,
  SUPER_USER: 3,
  PUBLIC_USER: 4,
  ANNOTATION_SPECIALIST: 5,
  ML_ENGINEER: 6,
  GOD: 7
};

const ONE_TIME_LOGIN_TYPE = {
  EMAIL_CONFIRMATION: 1,
  PASSWORD_RESET_CONFIRMATION: 2,
};

const ROLE_NAMES = {
  [ROLE_TYPES.SMART_SCAN_USER]: 'Smart scan user',
  [ROLE_TYPES.API_USER]: 'API user',
  [ROLE_TYPES.MODERATOR]: 'Moderator',
  [ROLE_TYPES.PUBLIC_USER]: 'Public user',
  [ROLE_TYPES.SUPER_USER]: 'Super user',
  [ROLE_TYPES.ANNOTATION_SPECIALIST]: 'Annotation specialist',
  [ROLE_TYPES.ML_ENGINEER]: 'Machine learning specialist',
  [ROLE_TYPES.GOD]: 'God',
};

export default {
  NAME,
  PERMISSION_TYPES,
  ROLE_NAMES,
  ROLE_TYPES,
  ONE_TIME_LOGIN_TYPE,
};
