import { createSelector } from 'reselect';

import { NAME } from './constants';

const selectSubscriptionState = (state) => state[NAME];

const selectSubscriptions = createSelector([selectSubscriptionState], (subscription) => subscription.subscriptions);

const selectEnabledFeatures = createSelector([selectSubscriptionState], (subscription) => subscription.enabledFeatures);

export default { selectSubscriptions, selectEnabledFeatures };
