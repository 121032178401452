import React from 'react';
import PropTypes from 'prop-types';

import './sass/donut-chart.scss';

const DonutChart = ({ label, range, value, tooltip, displayFraction, }) => {
  const percentage = !value || !range || range < 0
    ? 0
    : parseInt((value / range) * 100, 10);

  const strokeDashArray = `${percentage} ${100 - percentage}`;

  const statistic = displayFraction
    ? `${value}`
    : `${percentage}%`;

  const smallText = displayFraction
    ? `${range <= 0 ? '' : `of ${range}`} ${label}`
    : label;

  return (
    <div title={tooltip}>
      <svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
        <circle className="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff" />
        <circle className="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5" />
        <circle
          className="donut-segment"
          cx="20"
          cy="20"
          r="15.91549430918954"
          fill="transparent"
          strokeWidth="3.5"
          strokeDasharray={strokeDashArray}
          strokeDashoffset="25"
        />
        <g className="donut-text">
          <text y="47%" transform="translate(0, 2)">
            <tspan x="50%" textAnchor="middle" className="donut-percent">{statistic}</tspan>
          </text>
          <text y="55%" transform="translate(0, 2)">
            <tspan x="50%" textAnchor="middle" className="donut-data">{smallText}</tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

DonutChart.propTypes = {
  value: PropTypes.number,
  range: PropTypes.number,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  displayFraction: PropTypes.bool,
};

export default DonutChart;
