import { createSelector } from 'reselect';
import constants from './constants';

const selectAccessUrlsState = (state) => state[constants.NAME];

const selectAccessUrlsById = createSelector([selectAccessUrlsState], (accessUrls) => accessUrls.accessUrlsById);

export default {
  selectAccessUrlsById,
};
