import types from './types';

const loadCountrySuccess = (country) => ({
  type: types.LOAD_COUNTRY_SUCCESS,
  payload: {
    country,
  },
});

const loadCountriesSuccess = (countriesPagedResult) => ({
  type: types.LOAD_COUNTRIES_SUCCESS,
  payload: {
    countriesPagedResult,
  },
});

export default {
  loadCountrySuccess,
  loadCountriesSuccess,
};
