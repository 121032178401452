import types from './types';

const loadPhotoSeriesSuccess = (photoSeriesId, data) => ({
  type: types.LOAD_PHOTO_SERIES_SUCCESS,
  payload: {
    photoSeriesId,
    data
  },
});

const loadPhotoSeriesDamageSummarySuccess = (photoSeriesId, data) => ({
  type: types.LOAD_PHOTO_SERIES_DAMAGE_SUMMARY_SUCCESS,
  payload: {
    photoSeriesId,
    data
  }
});

const loadPhotoSeriesRepairEstimationsSuccess = (photoSeriesId, data) => ({
  type: types.LOAD_PHOTO_SERIES_REPAIR_ESTIMATIONS_SUCCESS,
  payload: {
    photoSeriesId,
    data
  }
});

const loadPhotoSeriesReportStatusSuccess = (photoSeriesId, data) => ({
  type: types.LOAD_PHOTO_SERIES_REPORT_STATUS_SUCCESS,
  payload: {
    photoSeriesId,
    data
  }
});

export default {
  loadPhotoSeriesSuccess,
  loadPhotoSeriesDamageSummarySuccess,
  loadPhotoSeriesRepairEstimationsSuccess,
  loadPhotoSeriesReportStatusSuccess
};
