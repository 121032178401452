import React from 'react';
import PropTypes from 'prop-types';

import IconWrench from '../../../../../../../../assets/img/IconWrench';

import { constants as sessionConstants } from '../../../../../../../../redux/session';
import { constants as imagesConstants } from '../../../../../../../../redux/images';

import { authenticationService } from '../../../../../../../../services';

import { composeApiUrl } from '../../../../../../../../api/composeApiUrl';

import { Sidebar, } from '../../../../../../../../components';

const {
  // Using existing SuperUser permission for temporary experimental feature
  EXPORT_PHOTO_SERIES
} = sessionConstants.PERMISSION_TYPES;

const AnalyzeImage = ({ photoSeriesId, imageType }) => {
  const { hasPermission } = authenticationService;

  if (imageType !== imagesConstants.IMAGE_TYPES.WINDSHIELD || !hasPermission(EXPORT_PHOTO_SERIES)) return null;

  return (
    <Sidebar.Section>
      <Sidebar.Section.Header text="Windshield repairability">
        <IconWrench color="black" />
      </Sidebar.Section.Header>
      <Sidebar.Section.Body>
        <a
          href={composeApiUrl(`capture/${photoSeriesId}/render-ws-repairability`)}
          className="btn btn-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          Render
        </a>
      </Sidebar.Section.Body>
    </Sidebar.Section>
  );
};

AnalyzeImage.propTypes = {
  photoSeriesId: PropTypes.string,
  imageType: PropTypes.number,
};

export default AnalyzeImage;
