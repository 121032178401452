import React from 'react';
import PropTypes from 'prop-types';
import ImageSubTypeCheckbox from './ImageSubTypeCheckbox';

import { constants as imageConstants } from '../../../../../redux/images';

const WindshieldSubTypeSelection = ({ imageSubTypes, setFieldValue }) => {
  // ImageSubTypeCheckbox orders by subtype ascending order.
  // In order to have correct order here: Outside, Factory label, Inside. We need to perform reorder.
  const setSubTypes = (field, subTypes) => {
    const outsideSubType = subTypes.find((config) => config.imageSubType === imageConstants.IMAGE_SUB_TYPES.FROM_OUTSIDE);
    const factoryLabelSubType = subTypes.find((config) => config.imageSubType === imageConstants.IMAGE_SUB_TYPES.FACTORY_LABEL);
    const insideSubType = subTypes.find((config) => config.imageSubType === imageConstants.IMAGE_SUB_TYPES.FROM_INSIDE);

    const orderedSubTypes = [];

    if (outsideSubType) orderedSubTypes.push(outsideSubType);
    if (factoryLabelSubType) orderedSubTypes.push(factoryLabelSubType);
    if (insideSubType) orderedSubTypes.push(insideSubType);

    setFieldValue(field, orderedSubTypes);
  };

  return (
    <>
      <h6>Windshield capture settings</h6>
      <ImageSubTypeCheckbox
        imageSubTypes={imageSubTypes}
        imageSubType={imageConstants.IMAGE_SUB_TYPES.FROM_OUTSIDE}
        label="Outside"
        setFieldValue={setSubTypes}
      />
      <ImageSubTypeCheckbox
        imageSubTypes={imageSubTypes}
        imageSubType={imageConstants.IMAGE_SUB_TYPES.FACTORY_LABEL}
        label="Factory label"
        setFieldValue={setSubTypes}
      />
      <ImageSubTypeCheckbox
        imageSubTypes={imageSubTypes}
        imageSubType={imageConstants.IMAGE_SUB_TYPES.FROM_INSIDE}
        label="Inside"
        setFieldValue={setSubTypes}
      />
    </>
  );
};

WindshieldSubTypeSelection.propTypes = {
  imageSubTypes: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
export default WindshieldSubTypeSelection;
