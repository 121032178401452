import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'querystring';
import { useDispatch, useSelector } from 'react-redux';

import { Combobox, Loader } from '../../components';
import { comboTypes } from '../../components/Combobox/Combobox';
import { makeGet } from '../../api/ajax';

const InfiniteComboBox = (props) => {
  const { onSelect, onChangeHandler, initialValue, loadUrl, onLoadSuccess, resultSelector,
    searchParam, vehicleId, photoSeriesId, placeholder, inputIcon } = props;

  const pagedResult = useSelector((state) => resultSelector(state, vehicleId, photoSeriesId));
  const dispatch = useDispatch();

  const comboRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchedPhrase, setSearchedPhrase] = useState('');

  useEffect(() => {
    let isMounted = true;

    if (!isMounted) return;

    const queryObj = { page, pageSize: 20 };

    if (searchParam) queryObj[searchParam] = searchedPhrase;

    const queryParams = queryString.stringify(queryObj);

    setIsLoading(true);

    makeGet(`${loadUrl}?${queryParams}`).subscribe(
      ({ response }) => {
        setIsLoading(false);

        dispatch(onLoadSuccess(response, vehicleId));
      },
      () => setIsLoading(false),
    );

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [loadUrl, page, searchParam, searchedPhrase, dispatch]);

  const getComboboxProps = () => {
    const values = pagedResult?.order?.map((id) => pagedResult.entities[id]);
    return {
      ...props,
      ref: comboRef,
      page,
      placeholder,
      setPage,
      setSearchedPhrase,
      type: comboTypes.infiniteCombo,
      values: values || [],
      totalPages: pagedResult?.pageCount,
      defaultValue: initialValue,
      inputIcon: isLoading ? <></> : inputIcon,
      setSelectedItem(selectedCountry) {
        onSelect(selectedCountry);
      },
    };
  };

  return (

    <div className="infinite-combobox-container" onChange={onChangeHandler} style={{ pointerEvents: `${isLoading ? 'none' : 'auto'}` }}>

      {isLoading && (
        <div className="infinite-combobox-container__loader-wrapper">
          <Loader isRelative size="small" />
        </div>
      )}

      <div className="infinite-combobox-container__combo-wrapper">
        <Combobox {...getComboboxProps()} />
      </div>
    </div>

  );
};

InfiniteComboBox.propTypes = {
  label: PropTypes.string,
  loadUrl: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  onLoadSuccess: PropTypes.func.isRequired,
  resultSelector: PropTypes.func.isRequired,
  customRender: PropTypes.func,
  onSelect: PropTypes.func,
  onChangeHandler: PropTypes.func,
  error: PropTypes.string,
  vehicleId: PropTypes.string,
  photoSeriesId: PropTypes.string,
  searchParam: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  inputIcon: PropTypes.element,
  searchingDisabled: PropTypes.bool,
};

InfiniteComboBox.defaultProps = {
  placeholder: ''
};

export default InfiniteComboBox;
