import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

import { sortBy } from 'lodash';
import { makePost } from '../../api/ajax';
import { PageTitle, Button, ConfirmationModal } from '../../components';
import { MenuPage } from '../../layouts';
import { useAjax, useModalAlerter, EFFECT_TYPES } from '../../hooks';
import { routes } from '../../routes/Routes';
import { selectors as translationsSelectors, constants as translationsConstants, actions as translationsActions } from '../../redux/translations';

import SourceTranslation from './containers/SourceTranslations/SourceTranslation';
import TargetedTranslation from './containers/TargetedTranslations/TargetedTranslations';

import { authenticationService } from '../../services';
import { constants as sessionConstants } from '../../redux/session';

import { TTranslations } from '../../@proptypes/proptypes';

import './sass/translation.scss';
import './containers/SourceTranslations/sass/source-translation.scss';

const TYPES = {
  ERROR_SAVING_STATUS_DISPATCHER: 'errorSavingStatusDispatcher',
  SUCCESS_SAVING_STATUS_DISPATCHER: 'successSavingStatusDispatcher'
};

const messages = {
  sourceGdprPrivacyPolicy: ' source GDPR privacy policy',
  targetedGdprPrivacyPolicy: ' targeted GDPR privacy policy',
  sourceTermsAndConditions: ' source terms & conditions,',
  targetedTermsAndConditions: ' targeted terms & conditions,',
  sourceTranslations: ' source translations,',
  targetedTranslations: ' targeted translations,'
};

const SAVING_STATUS_MESSAGE = {
  ERROR: {
    ...messages,
    type: 'danger'
  },
  SUCCESS: {
    ...messages,
    type: 'success'
  }
};

const savingStatusInitialState = {
  type: '',
  message: '',
};
const languagesInitialState = {
  sourceLanguage: {},
  targetedLanguage: {},
};
const translationsInitialState = {
  hasSourceTranslationsChanged: false,
  hasTargetedTranslationsChanged: false,
  sourceTranslations: [],
  targetedTranslations: [],
};

const sourceLanguageFilesInitialState = {
  termsAndConditions: {},
  gdprPrivacyPolicy: {}
};

const targetedLanguageFilesInitialState = {
  termsAndConditions: {},
  gdprPrivacyPolicy: {}
};

const updateLanguages = (selectedLanguages) => {
  if (!selectedLanguages.length) return;

  return {
    sourceLanguage: selectedLanguages?.find(({ code }) => code === translationsConstants.LANGUAGES_CODE.ENGLISH),
    targetedLanguage: selectedLanguages?.find(({ code }) => code !== translationsConstants.LANGUAGES_CODE.ENGLISH),
  };
};

const savingStatusHandler = (messageStateDispatcher, shownStateDispatcher, savingStatusMessageKey, value) => {
  messageStateDispatcher((prev) => {
    const { type, message } = prev;
    const key = SAVING_STATUS_MESSAGE[savingStatusMessageKey];

    return {
      type: type || key.type,
      message: `${!message.includes(key[value]) ? message.concat(key[value]) : message}`
    };
  });

  shownStateDispatcher((prev) => !prev ? !prev : prev);
};

const EnableEditing = ({ translations, setNewCopy, language }) => {
  const [showModal, setShowModal] = useState(false);
  const [hide, setHide] = useState(false);

  const { EDIT_GLOBAL_TRANSLATIONS } = sessionConstants.PERMISSION_TYPES;
  const { hasPermission } = authenticationService;
  const globalTranslations = translations
    .filter((translation) => translation.customImageTypeId === null)
    .every((translation) => translation.customerId === null);

  useEffect(() => setHide(false), [translations]);

  if (!language || translations.length === 0 || hide) return null;

  // NOTE: This is purely for users who have EDIT_GLOBAL_TRANSLATIONS permission in order
  //       to understand if customer has already language specific translations
  if (!globalTranslations && hasPermission(EDIT_GLOBAL_TRANSLATIONS)) {
    return (
      <Alert variant="info">
        These are DriveX SmartScan customer specific translations.
        <Button className="btn btn-secondary ml-2" onClick={() => setHide(true)} testId={`btnHide-${language.name}`}>
          Hide
        </Button>
      </Alert>
    );
  }

  if (!globalTranslations) return null;

  const handleCreateNewCopy = () => {
    makePost('customerTranslations', { languageId: language.id }).subscribe(({ response }) => {
      setNewCopy(response);
      setShowModal(false);
    });
  };

  return (
    <>
      <Alert variant="warning">
        <p>
          These are DriveX SmartScan default translations. If you need to edit translations, please create a new copy for your organization.
          The new copy will apply only to you and your translations will not automatically be updated when we modify default translations.
        </p>
        <Button className="btn btn-primary" onClick={() => setShowModal(true)} testId={`btnCreateCopy-${language.name}`}>
          Create a new copy
        </Button>
        <Button className="btn btn-secondary ml-1" onClick={() => setHide(true)} testId={`btnHide-${language.name}`}>
          Hide
        </Button>
      </Alert>
      <ConfirmationModal
        showModal={showModal}
        heading="Create a new copy"
        message="Are you sure? With your own copy of translations you will not receive automatic updates to default translations anymore."
        onConfirm={handleCreateNewCopy}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};

EnableEditing.propTypes = {
  translations: PropTypes.arrayOf(TTranslations),
  setNewCopy: PropTypes.func,
  language: PropTypes.object
};

function Translations() {
  const dispatch = useDispatch();
  const selectedLanguages = useSelector(translationsSelectors.selectLanguages);
  const selectedSourceTranslations = useSelector(translationsSelectors.selectSourceTranslations);
  const selectedTargetedTranslations = useSelector(translationsSelectors.selectTargetedTranslations);

  const [successSavingStatus, setSuccessSavingStatus] = useState(savingStatusInitialState);
  const [errorSavingStatus, setErrorSavingStatus] = useState(savingStatusInitialState);
  const [shownSuccessUseModalAlerter, setShownSuccessUseModalAlerter] = useState(false);
  const [shownErrorUseModalAlerter, setShownErrorUseModalAlerter] = useState(false);
  const [languages, setLanguages] = useState(languagesInitialState);
  const [translations, setTranslations] = useState(translationsInitialState);

  const [sourceLanguageFiles, setSourceLanguageFiles] = useState(sourceLanguageFilesInitialState);
  const [targetedLanguageFiles, setTargetedLanguageFiles] = useState(targetedLanguageFilesInitialState);

  const srcLangId = languages?.sourceLanguage?.id;
  const tarLangId = languages?.targetedLanguage?.id;

  const { hasPermission } = authenticationService;
  const { EDIT_GLOBAL_TRANSLATIONS } = sessionConstants.PERMISSION_TYPES;

  const showTermsAndConditions = hasPermission(EDIT_GLOBAL_TRANSLATIONS);
  const showGdprPrivacyPolicy = hasPermission(EDIT_GLOBAL_TRANSLATIONS);

  const sourceTranslationsDisabled = selectedSourceTranslations.every((translation) => translation.customerId === null)
                                     && !hasPermission(EDIT_GLOBAL_TRANSLATIONS);

  const targetedTranslationsDisabled = selectedTargetedTranslations.every((translation) => translation.customerId === null)
                                       && !hasPermission(EDIT_GLOBAL_TRANSLATIONS);

  /** ********************
   *AJAX OPERATIONS BELOW
   */
  const { isLoading: isLoadingLanguages } = useAjax({
    url: 'languages',
    effectType: !selectedLanguages.length ? EFFECT_TYPES.USE_EFFECT : null, // ajax optimizer
    successDispatch(response) {
      dispatch(translationsActions.getLanguagesSuccess(response));
    },
  });

  const { isLoading: isLoadingSourceTranslations } = useAjax({
    url: `customerTranslations/${srcLangId}`,
    effectType: srcLangId && !selectedSourceTranslations.length ? EFFECT_TYPES.USE_EFFECT : null, // ajax optimizer
    successDispatch(response) {
      dispatch(translationsActions.getSourceTranslationsSuccess(response));
    },
  });

  const getTargetedTranslationsUseAjaxEffectType = useCallback(() => {
    const { length } = selectedTargetedTranslations;

    if ((tarLangId && !length) || (tarLangId && length && tarLangId !== selectedTargetedTranslations[0].languageId)) {
      return EFFECT_TYPES.USE_EFFECT;
    }
    return null;
  }, [tarLangId, selectedTargetedTranslations]);

  const { isLoading: isLoadingTargetedTranslations } = useAjax({
    url: `customerTranslations/${tarLangId}`,
    effectType: getTargetedTranslationsUseAjaxEffectType(), // ajax optimizer
    successDispatch(response) {
      dispatch(translationsActions.getTargetedTranslationsSuccess(response));
    },
  });

  const { isLoading: isLoadingPatchSourceTranslations, patchRequest: putSourceTranslation } = useAjax({
    url: 'customerTranslations',
    successDispatch(response) {
      setTranslations((prevTranslations) => ({ ...prevTranslations, hasSourceTranslationsChanged: false }));
      savingStatusHandler(setSuccessSavingStatus, setShownSuccessUseModalAlerter, 'SUCCESS', 'sourceTranslations');
      dispatch(translationsActions.getSourceTranslationsSuccess(response));
    },
    errorDispatch() {
      savingStatusHandler(setErrorSavingStatus, setShownErrorUseModalAlerter, 'ERROR', 'sourceTranslations');
    }
  });

  const { isLoading: isLoadingPatchTargetedTranslations, patchRequest: putTargetedTranslations } = useAjax({
    url: 'customerTranslations',
    successDispatch(response) {
      setTranslations((prevTranslations) => ({ ...prevTranslations, hasTargetedTranslationsChanged: false }));
      savingStatusHandler(setSuccessSavingStatus, setShownSuccessUseModalAlerter, 'SUCCESS', 'targetedTranslations');
      dispatch(translationsActions.getTargetedTranslationsSuccess(response));
    },
    errorDispatch() {
      savingStatusHandler(setErrorSavingStatus, setShownErrorUseModalAlerter, 'ERROR', 'targetedTranslations');
    }
  });

  const { isLoading: isLoadingSourceLanguageFiles, postFileRequest: patchSourceLanguageFiles } = useAjax({
    url: `languages/${srcLangId}`,
    successDispatch(response) {
      setLanguages((prevLanguages) => ({ ...prevLanguages, sourceLanguage: response }));
      setSourceLanguageFiles(sourceLanguageFilesInitialState);

      const { smartScanTermsAndConditionsFileName, smartScanGdprPrivacyPolicyFileName } = response;

      if (smartScanTermsAndConditionsFileName) {
        savingStatusHandler(setSuccessSavingStatus, setShownSuccessUseModalAlerter, 'SUCCESS', 'sourceTermsAndConditions');
        dispatch(translationsActions.getSourceTermsAndConditionsSuccess(response));
      }

      if (smartScanGdprPrivacyPolicyFileName) {
        savingStatusHandler(setSuccessSavingStatus, setShownSuccessUseModalAlerter, 'SUCCESS', 'sourceGdprPrivacyPolicy');
        dispatch(translationsActions.getSourceGdprPrivacyPolicySuccess(response));
      }
    },
    errorDispatch() {
      savingStatusHandler(setErrorSavingStatus, setShownErrorUseModalAlerter, 'ERROR', 'sourceTermsAndConditions');
      savingStatusHandler(setErrorSavingStatus, setShownErrorUseModalAlerter, 'ERROR', 'sourceGdprPrivacyPolicy');
    }
  });

  const { isLoading: isLoadingTargetedLanguageFiles, postFileRequest: patchTargetedLanguageFiles } = useAjax({
    url: `languages/${tarLangId}`,
    successDispatch(response) {
      setLanguages((prevLanguages) => ({ ...prevLanguages, targetedLanguage: response }));
      setTargetedLanguageFiles(targetedLanguageFilesInitialState);

      const { smartScanTermsAndConditionsFileName, smartScanGdprPrivacyPolicyFileName } = response;

      if (smartScanTermsAndConditionsFileName) {
        savingStatusHandler(setSuccessSavingStatus, setShownSuccessUseModalAlerter, 'SUCCESS', 'targetedTermsAndConditions');
        dispatch(translationsActions.getTargetedTermsAndConditionsSuccess(response));
      }

      if (smartScanGdprPrivacyPolicyFileName) {
        savingStatusHandler(setSuccessSavingStatus, setShownSuccessUseModalAlerter, 'SUCCESS', 'targetedGdprPrivacyPolicy');
        dispatch(translationsActions.getTargetedGdprPrivacyPolicySuccess(response));
      }

      const languageIndex = selectedLanguages?.findIndex(({ code }) => code === response.code);

      const newLanguages = selectedLanguages;
      newLanguages.splice(languageIndex, 1, response);

      dispatch(translationsActions.getLanguagesSuccess(newLanguages));
    },
    errorDispatch() {
      savingStatusHandler(setErrorSavingStatus, setShownErrorUseModalAlerter, 'ERROR', 'targetedTermsAndConditions');
      savingStatusHandler(setErrorSavingStatus, setShownErrorUseModalAlerter, 'ERROR', 'targetedGdprPrivacyPolicy');
    }
  });

  const { ERROR_SAVING_STATUS_DISPATCHER, SUCCESS_SAVING_STATUS_DISPATCHER } = TYPES;

  const onResetSavingStatusState = (type) => {
    if (Object.values(successSavingStatus).length && type === SUCCESS_SAVING_STATUS_DISPATCHER) {
      setSuccessSavingStatus(savingStatusInitialState);
    }

    if (Object.values(errorSavingStatus).length && ERROR_SAVING_STATUS_DISPATCHER) {
      setErrorSavingStatus(savingStatusInitialState);
    }
  };

  const { renderModal: renderErrorSavingStatusAlerter } = useModalAlerter(
    shownErrorUseModalAlerter,
    setShownErrorUseModalAlerter,
    `Failed to update ${errorSavingStatus.message}`,
    errorSavingStatus.type,
    () => { onResetSavingStatusState(ERROR_SAVING_STATUS_DISPATCHER); }
  );

  const { renderModal: renderSuccessSavingStatusAlerter } = useModalAlerter(
    shownSuccessUseModalAlerter,
    setShownSuccessUseModalAlerter,
    `Successfully updated ${successSavingStatus.message}`,
    successSavingStatus.type,
    () => { onResetSavingStatusState(SUCCESS_SAVING_STATUS_DISPATCHER); }
  );

  /** ********************
   * STATE UPDATERS BELOW
   */
  const orderTranslationValues = (translations) => translations
    .filter((translation) => translation.categoryType !== translationsConstants.CATEGORY_TYPE.CUSTOM_IMAGE_TYPE)
    .sort((a, b) => a.categoryType - b.categoryType)
    .map((translation) => ({
      ...translation,
      translationValues: sortBy(translation.translationValues, [(translationValue) => translationValue.key.toLowerCase()])
    }));

  useEffect(() => {
    setLanguages(updateLanguages(selectedLanguages));
  }, [selectedLanguages]);

  useEffect(() => {
    setTranslations((translations) => ({ ...translations, sourceTranslations: orderTranslationValues(selectedSourceTranslations) }));
  }, [selectedSourceTranslations]);

  useEffect(() => {
    setTranslations((translations) => ({ ...translations, targetedTranslations: orderTranslationValues(selectedTargetedTranslations) }));
  }, [selectedTargetedTranslations]);

  const onInputChange = (args) => {
    const {
      target: { name, value },
      translations: _translations,
      translationIndex,
      translationsType,
      translationsIndex,
      ...restProps
    } = args;

    const copiedTranslations = _translations;
    const copiedTranslation = copiedTranslations[translationsIndex].translationValues;

    copiedTranslation.splice(translationIndex, 1, { key: name, value });

    setTranslations({ ...translations, _translationsType: [...copiedTranslations], ...restProps });
  };

  const translationsProps = {
    translations,
    onInputChange,
    showGdprPrivacyPolicy,
    showTermsAndConditions
  };

  return (
    <MenuPage currentRoute={routes.translations}>
      <div className="translations-container">
        <PageTitle
          isLoading={
            isLoadingLanguages
            || isLoadingSourceTranslations
            || isLoadingTargetedTranslations
            || isLoadingPatchSourceTranslations
            || isLoadingPatchTargetedTranslations
            || isLoadingSourceLanguageFiles
            || isLoadingTargetedLanguageFiles
          }
        >
          <PageTitle.Left headingText="Translations" />
        </PageTitle>
        <>{renderErrorSavingStatusAlerter()}</>
        <>{renderSuccessSavingStatusAlerter()}</>
        <form
          className="translations-container__containers-wrapper"
          onSubmit={(ev) => {
            ev.preventDefault();

            if (translations.hasSourceTranslationsChanged) {
              putSourceTranslation(translations.sourceTranslations);
            }

            if (translations.hasTargetedTranslationsChanged) {
              putTargetedTranslations(translations.targetedTranslations);
            }

            if (Object.values(sourceLanguageFiles.termsAndConditions).length
                || Object.values(sourceLanguageFiles.gdprPrivacyPolicy).length)
            {
              const data = {};

              if (Object.values(sourceLanguageFiles.termsAndConditions).length) data.termsAndConditions = sourceLanguageFiles.termsAndConditions;
              if (Object.values(sourceLanguageFiles.gdprPrivacyPolicy).length) data.gdprPrivacyPolicy = sourceLanguageFiles.gdprPrivacyPolicy;

              patchSourceLanguageFiles(null, data, null, 'PATCH');
            }

            if (Object.values(targetedLanguageFiles.termsAndConditions).length
                || Object.values(targetedLanguageFiles.gdprPrivacyPolicy).length)
            {
              const data = {};

              if (Object.values(targetedLanguageFiles.termsAndConditions).length) data.termsAndConditions = targetedLanguageFiles.termsAndConditions;
              if (Object.values(targetedLanguageFiles.gdprPrivacyPolicy).length) data.gdprPrivacyPolicy = targetedLanguageFiles.gdprPrivacyPolicy;

              patchTargetedLanguageFiles(null, data, null, 'PATCH');
            }
          }}
        >
          <div className="from-wrapper">
            <EnableEditing
              translations={selectedSourceTranslations}
              setNewCopy={(translations) => dispatch(translationsActions.getSourceTranslationsSuccess(translations))}
              language={languages?.sourceLanguage}
            />
            <SourceTranslation
              {...translationsProps}
              sourceLanguage={languages?.sourceLanguage}
              disableTranslationsInput={sourceTranslationsDisabled}
              termsAndConditions={sourceLanguageFiles}
              setTermsAndConditions={setSourceLanguageFiles}
              gdprPrivacyPolicy={sourceLanguageFiles}
              setGdprPrivacyPolicy={setSourceLanguageFiles}
            />
          </div>
          <div className="separator--vertical" />
          <div className="to-wrapper">
            <EnableEditing
              translations={selectedTargetedTranslations}
              setNewCopy={(translations) => dispatch(translationsActions.getTargetedTranslationsSuccess(translations))}
              language={languages?.targetedLanguage}
            />
            <TargetedTranslation
              {...translationsProps}
              allLanguages={selectedLanguages}
              targetedLanguage={languages?.targetedLanguage}
              setLanguages={setLanguages}
              disableTranslationsInput={targetedTranslationsDisabled}
              termsAndConditions={targetedLanguageFiles}
              setTermsAndConditions={setTargetedLanguageFiles}
              gdprPrivacyPolicy={targetedLanguageFiles}
              setGdprPrivacyPolicy={setTargetedLanguageFiles}
            />
          </div>
        </form>
      </div>
    </MenuPage>
  );
}
export default React.memo(Translations);
