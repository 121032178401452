import types from './types';

const defaultState = {
  customImageTypePagedResults: null
};

const customImageTypesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.ADD_CUSTOM_IMAGE_TYPE_SUCCESS: {
      const {
        newCustomImageType,
      } = action.payload;

      return {
        ...state,
        customImageTypePagedResults: {
          ...state.customImageTypePagedResults,
          results: [
            newCustomImageType,
            ...state.customImageTypePagedResults.results,
          ],
          rowCount: state.customImageTypePagedResults.rowCount + 1,
        }
      };
    }
    case types.DELETE_CUSTOM_IMAGE_TYPE_SUCCESS: {
      const {
        customImageTypeId,
      } = action.payload;

      const remainingImageTypes = state.customImageTypePagedResults.results
        .filter((customImageType) => customImageType.id !== customImageTypeId);

      return {
        ...state,
        customImageTypePagedResults: {
          ...state.customImageTypePagedResults,
          results: remainingImageTypes,
          rowCount: remainingImageTypes.length
        }
      };
    }
    case types.EDIT_CUSTOM_IMAGE_TYPE_SUCCESS: {
      const {
        customImageType,
      } = action.payload;

      return {
        ...state,
        customImageTypePagedResults: {
          ...state.customImageTypePagedResults,
          results: state.customImageTypePagedResults.results
            .map((cdt) => {
              if (cdt.id === customImageType.id) return customImageType;
              return cdt;
            }),
        },
      };
    }
    case types.LOAD_CUSTOM_IMAGE_TYPES_SUCCESS: {
      const {
        customImageTypePagedResults,
      } = action.payload;

      return {
        ...state,
        customImageTypePagedResults,
      };
    }
    default:
      return state;
  }
};

export default customImageTypesReducer;
