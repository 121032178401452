import React from 'react';
import PropTypes from 'prop-types';

import './sass/button.scss';

export const Button = ({ children, className, type, testId, shape, variation, ...props }) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    data-testid={testId}
    data-shape={shape}
    data-variation={variation}
    className={`button ${className}`}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variation: PropTypes.oneOf(['danger', 'warning', 'success', 'transparent', 'light-blue']),
  shape: PropTypes.oneOf(['border', 'curvy', 'border-curvy', 'round']),
};

Button.defaultProps = {
  type: 'button'
};
export default Button;
