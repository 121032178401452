const prefix = 'drivex/customers';

const LOAD_USERS_SUCCESS = `${prefix}/LOAD_USERS_SUCCESS`;
const UPDATE_USERS_SUCCESS = `${prefix}/UPDATE_USERS_SUCCESS`;
const CLEAR_USERS = `${prefix}/CLEAR_USERS`;

export default {
  LOAD_USERS_SUCCESS,
  UPDATE_USERS_SUCCESS,
  CLEAR_USERS,
};
