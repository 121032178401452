import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Accordion, ActiveWorkflowsInfo } from '../../../../components';
import WindshieldRepairabilityProfile from './WindshieldRepairabilityProfile';
import { selectors as workflowsSelectors, actions as workflowsActions } from '../../../../redux/workflows';
import { makeGet } from '../../../../api/ajax';

const WindshieldRepairabilityProfilesAccordion = ({ windshieldRepairabilityProfiles }) => {
  const dispatch = useDispatch();

  const [activeKey, setActiveKey] = useState(windshieldRepairabilityProfiles[0].id);
  const workflows = useSelector(workflowsSelectors.selectWorkFlows);

  useEffect(() => {
    if (!workflows) {
      makeGet('workflows/currentCustomer/admin?showDeleted=true').subscribe(
        ({ response }) => {
          dispatch(workflowsActions.loadWorkflowsSuccess(response));
        }
      );
    }
  }, [workflows, dispatch]);

  return (
    <Accordion
      defaultActiveKey={windshieldRepairabilityProfiles[0].id}
      activeKey={activeKey}
      onSelect={(key) => setActiveKey(key)}
    >
      {
        windshieldRepairabilityProfiles.map((windshieldRepairabilityProfile) => (
          <span key={windshieldRepairabilityProfile.id}>
            <Accordion.Header
              eventKey={windshieldRepairabilityProfile.id}
              childrenSize={6}
              caretSize={6}
              onClick={() => setActiveKey(windshieldRepairabilityProfile.id)}
              noFocus
            >
              <span>
                <h5>{windshieldRepairabilityProfile.name}</h5>
                <ActiveWorkflowsInfo
                  id={windshieldRepairabilityProfile.id}
                  workflows={workflows}
                  workflowIds={windshieldRepairabilityProfile.workflowIds}
                />
              </span>
            </Accordion.Header>
            <Accordion.Body eventKey={windshieldRepairabilityProfile.id} borderType="none">
              <div>
                {
                  activeKey === windshieldRepairabilityProfile.id && (
                    <WindshieldRepairabilityProfile windshieldRepairabilityProfile={windshieldRepairabilityProfile} />
                  )
                }
              </div>
            </Accordion.Body>
          </span>
        ))
      }
    </Accordion>
  );
};

WindshieldRepairabilityProfilesAccordion.propTypes = {
  windshieldRepairabilityProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      workflowIds: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  )
};

export default WindshieldRepairabilityProfilesAccordion;
