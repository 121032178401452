import { createSelector } from 'reselect';

import constants from './constants';

const selectState = (state) => state[constants.NAME];

const selectBlacklistedUserAgents = createSelector(
  [selectState],
  (state) => state.userAgents
);

export default {
  selectBlacklistedUserAgents
};
