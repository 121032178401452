import React from 'react';
import { useDispatch } from 'react-redux';

import { EmptyPage } from '../../layouts';
import { PageTitle } from '../../components';
import AskAdditionalInformationForm from './containers/AskAdditionalInformationForm';
import { actions as customerActions } from '../../redux/customers';
import { useDidMount } from '../../hooks/useDidMount/useDidMount';
import { makeGet } from '../../api/ajax';

const AskAdditionalInformation = () => {
  const dispatch = useDispatch();

  useDidMount(() => {
    makeGet('customers/current').subscribe(({ response }) => dispatch(customerActions.loadCustomerSuccess(response)));
  });

  return (
    <EmptyPage>
      <div className="row">
        <div className="col col-sm-12">
          <PageTitle>
            <PageTitle.Left headingText="Please provide some additional information" />
          </PageTitle>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="jumbotron">
            <AskAdditionalInformationForm />
          </div>
        </div>
      </div>
    </EmptyPage>
  );
};

export default AskAdditionalInformation;
