const prefix = 'drivex/photoSeries';

const LOAD_PHOTO_SERIES_SUCCESS = `${prefix}/LOAD_PHOTO_SERIES_SUCCESS`;
const LOAD_PHOTO_SERIES_DAMAGE_SUMMARY_SUCCESS = `${prefix}/LOAD_PHOTO_SERIES_DAMAGE_SUMMARY_SUCCESS`;
const LOAD_PHOTO_SERIES_REPAIR_ESTIMATIONS_SUCCESS = `${prefix}/LOAD_PHOTO_SERIES_REPAIR_ESTIMATIONS_SUCCESS`;
const LOAD_PHOTO_SERIES_REPORT_STATUS_SUCCESS = `${prefix}/LOAD_PHOTO_SERIES_REPORT_STATUS_SUCCESS`;

export default {
  LOAD_PHOTO_SERIES_SUCCESS,
  LOAD_PHOTO_SERIES_DAMAGE_SUMMARY_SUCCESS,
  LOAD_PHOTO_SERIES_REPAIR_ESTIMATIONS_SUCCESS,
  LOAD_PHOTO_SERIES_REPORT_STATUS_SUCCESS
};
