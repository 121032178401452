import React from 'react';
import PropTypes from 'prop-types';

import ReactPortal from '../ReactPortal/ReactPortal';

import './sass/confirmation-modal.scss';

const ConfirmationModal = ({
  showModal,
  heading,
  message,
  onConfirm,
  onCancel,
  confirmationBtnText,
  cancelBtnText,
  modalPosition,
  ...props
}) => (
  <>
    {showModal && (
      <ReactPortal>
        <div
          className={`confirmation-modal-container position--${modalPosition || 'top'}`}
          role="dialog"
          aria-live="assertive"
          aria-labelledby={heading ? `${heading.trim()}Modal` : 'Confirmation Modal'}
          aria-modal
          aria-atomic
          {...props}
        >
          {heading && (
            <h3 id={`${heading.trim()}Modal`} className="modal-heading">
              {heading}
            </h3>
          )}

          <p className="modal-message">{message}</p>

          <div className="modal-btn-wrapper">
            <button type="button" data-testid="cancelModal" className="cancel-btn btn btn-secondary" onClick={onCancel}>
              {cancelBtnText || ' Cancel'}
            </button>

            <button type="button" data-testid="confirmModal" className="btn btn-primary" onClick={onConfirm}>
              {confirmationBtnText || 'OK'}
            </button>
          </div>
        </div>
      </ReactPortal>
    )}
  </>
);

ConfirmationModal.propTypes = {
  modalPosition: PropTypes.oneOf(['top', 'bottom', 'center', 'left', 'right']),
  heading: PropTypes.string,
  confirmationBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationModal;
