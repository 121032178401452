import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap';

import { ReactComponent as CaretDown } from '../../assets/img/icn-caret-down.svg';

import './sass/accordion.scss';

const Header = ({ button, eventKey, callback, noFocus, children, caretSize, childrenSize, isDisabled, onClick }) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  const classes = classNames(null, { 'is-no-header-focus': noFocus, 'is-open': isCurrentEventKey });

  const handleClick = () => {
    onClick();
    if (!isDisabled) decoratedOnClick();
  };

  return (
    <div className={`accordion-header ${classes}`} role="button" tabIndex={0} onClick={handleClick}>
      <div className="row">
        <div className={`col col-sm-${childrenSize ? Math.floor(childrenSize) : 10} my-auto`}>{children}</div>

        <div className={`col col-sm-${caretSize ? Math.floor(caretSize) : 2} text-right`}>
          {button}
          <button type="button" className={`accordion-toggle ${isCurrentEventKey ? 'is-open' : ''}`} onClick={handleClick}>
            <CaretDown />
          </button>
        </div>
      </div>
    </div>
  );
};

const Body = ({ eventKey, children, borderType }) => (
  <Accordion.Collapse eventKey={eventKey} className={`accordion-body border--${borderType}`}>
    {children}
  </Accordion.Collapse>
);

const CustomAccordion = ({ defaultActiveKey, noBorder, onSelect, children, className, activeKey }) => {
  const classes = classNames(className, { 'is-no-border': noBorder });

  return (
    <Accordion defaultActiveKey={defaultActiveKey} activeKey={activeKey} className={classes} onSelect={onSelect}>
      {children}
    </Accordion>
  );
};

CustomAccordion.Header = Header;
CustomAccordion.Body = Body;

Header.propTypes = {
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node.isRequired,
  button: PropTypes.element,
  callback: PropTypes.func,
  noFocus: PropTypes.bool,
  caretSize: PropTypes.number,
  childrenSize: PropTypes.number,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func
};

Body.propTypes = {
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node.isRequired,
  borderType: PropTypes.oneOf(['dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'none', 'hidden']),
};

CustomAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  noBorder: PropTypes.bool,
  onSelect: PropTypes.func,
  className: PropTypes.string
};

export default CustomAccordion;
