export const NAME = 'translations';

export const STATE_KEYS = {
  LANGUAGES: 'languages',
  SOURCE_TRANSLATIONS: 'sourceTranslations',
  TARGETED_TRANSLATIONS: 'targetedTranslations',
  SOURCE_TERMS_AND_CONDITIONS: 'sourceTermsAndConditions',
  TARGETED_TERMS_AND_CONDITIONS: 'targetedTermsAndConditions',
  SOURCE_GDPR_PRIVACY_POLICY: 'sourceGdprPrivacyPolicy',
  TARGETED_GDPR_PRIVACY_POLICY: 'targetedGdprPrivacyPolicy'
};

const TRANSLATION_TYPES = {
  SOURCE_TRANSLATIONS: 'sourceTranslations',
  TARGETED_TRANSLATIONS: 'targetedTranslations',
};

const LANGUAGES_CODE = {
  ENGLISH: 'en',
  LATVIAN: 'lv',
  LITHUANIAN: 'lt',
  ESTONIAN: 'et',
  RUSSIAN: 'ru',
};

const CATEGORY_TYPE = {
  LOGIN: 0,
  INSTRUCTIONS: 2,
  INITIATE_SCAN: 3,
  VEHICLE_SCAN: 4,
  BURGER_MENU: 5,
  VALIDATION_WRAPPER: 6,
  ERROR: 7,
  SERVER_ERROR: 8,
  TIMEOUT_ERROR: 9,
  USER_FEEDBACK: 11,
  PHOTOSERIES: 12,
  WORKFLOW_SELECTION: 13,
  TERMS_OF_SERVICE: 14,
  PROCESS_FLOW: 15,
  ACCESSIBILITY: 16,
  DAMAGE_LABELLING: 17,
  REPORT: 18,
  CUSTOM_IMAGE_TYPE: 19
};

export default {
  NAME,
  STATE_KEYS,
  LANGUAGES_CODE,
  TRANSLATION_TYPES,
  CATEGORY_TYPE
};
