import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import { Alert } from 'react-bootstrap';

import ProcessModalView from './containers/ProcessModalView/ProcessModalView';
import CapturingView from './containers/CapturingView/CapturingView';

import { Loader, ConfirmationModal } from '../../../../components';
import SideBySideForm from './containers/SideBySideForm';

import { selectors as customerTranslationsSelectors, actions as customerTranslationsActions } from '../../../../redux/customerTranslations';
import { selectors as customerSelectors } from '../../../../redux/customers';
import { constants as sessionConstants } from '../../../../redux/session';
import { actions as customImageTypeActions } from '../../../../redux/customImageTypes';
import {
  selectors as translationsSelectors,
  constants as translationsConstants,
} from '../../../../redux/translations';

import { getFormKeyParts, composeFormKey, CAROUSEL_PROCESS_MODAL, CAROUSEL_CAPTURING_VIEW, CAPTURE_MODE_KEY } from '../../../../utils/customImages';
import { makePatch, makeDelete } from '../../../../api/ajax';
import { authenticationService } from '../../../../services';

const PreviewSettingsContext = React.createContext();
export const usePreviewSettingsContext = () => useContext(PreviewSettingsContext);

const CustomImageTypePreview = ({ imageTypeId, onCustomImageTypePreviewFormChanged }) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { LANGUAGES_CODE } = translationsConstants;

  const [activeCarouselImage, setActiveCarouselImage] = useState(CAROUSEL_PROCESS_MODAL);
  const [confirmDeleteImageTypeId, setConfirmDeleteImageTypeId] = useState(null);

  const englishLanguage = useSelector(translationsSelectors.selectLanguages)
    .find((lang) => lang.code === LANGUAGES_CODE.ENGLISH);

  const imageTypePreviewSettings = useSelector((state) => customerTranslationsSelectors.selectImageTypePreviewSettingsContext(
    state,
    imageTypeId,
    englishLanguage?.id,
  ));

  const imageTypeTranslations = useSelector((state) => customerTranslationsSelectors.selectImageTypeFormValues(state, imageTypeId));

  const [previewSettings, setPreviewSettings] = useState(imageTypePreviewSettings);

  const dispatch = useDispatch();

  const currentCustomer = useSelector(customerSelectors.selectCurrentCustomer);

  const { hasPermission } = authenticationService;

  const {
    DELETE_CUSTOM_IMAGE_TYPE, EDIT_CUSTOM_IMAGE_TYPE
  } = sessionConstants.PERMISSION_TYPES;

  useEffect(() => {
    if (!imageTypePreviewSettings.activeTranslationId) return;
    setPreviewSettings(imageTypePreviewSettings);
  }, [imageTypePreviewSettings, setPreviewSettings]);

  const handleError = (err) => {
    const { status, response: { error } } = err;
    switch (status) {
      case 400:
        setErrorMessage(error);
        break;
      default:
        setErrorMessage(null);
        break;
    }
  };

  const handleSubmit = (formValues, customerId, resetForm, setSubmitting, hasPermission) => {
    if (!hasPermission(EDIT_CUSTOM_IMAGE_TYPE)) return false;

    const customerTranslations = {};

    Object.keys(formValues)
      // Omit submitting "targetLanguage" and other extra form fields
      .filter((key) => key.includes('text'))
      .forEach((formKey) => {
        const { translationId, translationKey } = getFormKeyParts(formKey);

        const customerTranslation = customerTranslations[translationId] || { id: translationId, customerId, translationValues: [] };

        customerTranslations[translationId] = {
          ...customerTranslation,
          translationValues: [
            ...customerTranslation?.translationValues,
            { key: translationKey, value: translationKey === CAPTURE_MODE_KEY ? formValues[formKey].toUpperCase() : formValues[formKey] },
          ]
        };
      });

    setSuccessMessage(null);
    setErrorMessage(null);

    makePatch('customerTranslations', Object.values(customerTranslations)).subscribe(
      ({ response }) => {
        dispatch(customerTranslationsActions.saveCustomerTranslationsSuccess(response));

        resetForm();
        setSubmitting(false);
        setSuccessMessage('Custom image type changes saved!');
        onCustomImageTypePreviewFormChanged(imageTypeId, false);
      },
      (err) => {
        handleError(err);
        setSubmitting(false);
      },
    );
  };

  const handleDelete = (id) => {
    setSuccessMessage(null);
    setErrorMessage(null);

    if (!hasPermission(DELETE_CUSTOM_IMAGE_TYPE)) return;
    makeDelete(`customImageTypes/${id}`).subscribe(() => {
      setSuccessMessage('Custom image type removed!');
      dispatch(customImageTypeActions.deleteCustomImageTypeSuccess(id));
    }, (err) => {
      handleError(err);
    });
  };

  const handleOnChange = ({ target }, setFieldValue, previewSettings, setPreviewSettings, getFormKeyParts) => {
    const { name, value } = target;

    if (name.includes('language')) return;

    // Handle text field change
    const { translationId, translationKey } = getFormKeyParts(name);

    setPreviewSettings({
      ...previewSettings,
      translations: {
        ...previewSettings.translations,
        [translationId]: {
          ...previewSettings.translations[translationId],
          [translationKey]: value,
        },
      },
    });

    if (value !== imageTypeTranslations[target.name]) {
      setFieldValue(target.name, value);
    }

    onCustomImageTypePreviewFormChanged(imageTypeId, true);
  };

  const handleBlur = ({ target }, setFieldValue, previewSettings, setPreviewSettings, getFormKeyParts) => {
    const { name, value } = target;

    if (name.includes('language')) return;

    // Handle text field change
    const { translationId, translationKey } = getFormKeyParts(name);

    if (!previewSettings.additionalTranslations[translationId]) return;

    const { languageId } = previewSettings.additionalTranslations[translationId];
    const isEnglishLanguage = languageId === englishLanguage.id;

    const newPreviewSettings = { ...previewSettings };

    Object.keys(previewSettings.translations).forEach((id) => {
      const key = composeFormKey(id, translationKey);
      const isModifiedTranslation = translationId === id;
      const isMissingTranslation = !previewSettings.translations[id][translationKey].trim();

      if (isModifiedTranslation || (isEnglishLanguage && isMissingTranslation)) {
        newPreviewSettings.translations[id][translationKey] = value;
        setFieldValue(key, value);
      }
    });

    setPreviewSettings(newPreviewSettings);
  };

  if (!Object.keys(imageTypeTranslations)?.length) return null;

  const validationObject = {};

  for (let i = 0; i < Object.keys(imageTypeTranslations).length; i++) {
    const formFieldKey = Object.keys(imageTypeTranslations)[i];
    validationObject[formFieldKey] = Yup.string()
      .required('Text cannot be empty')
      .min(2, 'Text must be at least 2 characters long')
      .max(250, 'Text cannot be longer than 250 characters');
  }

  return (
    <>
      <div className="row">
        <div className="col">
          {successMessage && (
            <Alert variant="success" dismissible onClick={() => setSuccessMessage(null)}>
              {successMessage}
            </Alert>
          )}
          {
            errorMessage && (
              <Alert variant="danger" dismissible onClick={() => setErrorMessage(null)}>
                {errorMessage}
              </Alert>
            )
          }
        </div>
      </div>
      <PreviewSettingsContext.Provider value={[previewSettings, setPreviewSettings]}>
        <Formik
          initialValues={imageTypeTranslations}
          validationSchema={Yup.object().shape(validationObject)}
          onSubmit={(formValues, { resetForm, setSubmitting }) =>
            handleSubmit(formValues, currentCustomer.id, resetForm, setSubmitting, hasPermission)}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form
              onChange={(event) => handleOnChange(event, setFieldValue, previewSettings, setPreviewSettings, getFormKeyParts)}
              onBlur={(event) => handleBlur(event, setFieldValue, previewSettings, setPreviewSettings, getFormKeyParts)}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <Carousel
                        activeIndex={activeCarouselImage}
                        interval={null}
                        controls={false}
                        keyboard={false}
                        touch={false}
                        indicators={false}
                      >
                        <Carousel.Item style={{ border: '1px solid #E2E2E2' }}>
                          <ProcessModalView previewSettings={previewSettings} />
                        </Carousel.Item>
                        <Carousel.Item>
                          <CapturingView previewSettings={previewSettings} />
                        </Carousel.Item>
                      </Carousel>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <SideBySideForm
                        setActiveCarouselImage={setActiveCarouselImage}
                        CAROUSEL_CAPTURING_VIEW={CAROUSEL_CAPTURING_VIEW}
                        CAROUSEL_PROCESS_MODAL={CAROUSEL_PROCESS_MODAL}
                        imageTypeId={imageTypeId}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 text-right mt-2">
                      {hasPermission(DELETE_CUSTOM_IMAGE_TYPE) && (
                        <button
                          type="button"
                          className="button btn"
                          data-variation="light-blue"
                          onClick={() => setConfirmDeleteImageTypeId(imageTypeId)}
                        >
                          Remove
                        </button>
                      )}
                      {hasPermission(EDIT_CUSTOM_IMAGE_TYPE) && (
                        <button type="submit" className="button btn btn-primary ml-2" disabled={isSubmitting}>
                          {isSubmitting
                            ? (<Loader size="tiny" isRelative noDelay white />)
                            : 'Save'}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {confirmDeleteImageTypeId && (
          <ConfirmationModal
            showModal={confirmDeleteImageTypeId}
            heading="Delete custom image type"
            message="Are you sure you wish to delete this image type?"
            onConfirm={() => {
              handleDelete(confirmDeleteImageTypeId);
              setConfirmDeleteImageTypeId(null);
            }}
            onCancel={() => setConfirmDeleteImageTypeId(null)}
            style={{ top: '6.25rem' }}
          />
        )}
      </PreviewSettingsContext.Provider>
    </>
  );
};

CustomImageTypePreview.propTypes = {
  imageTypeId: PropTypes.string.isRequired,
  onCustomImageTypePreviewFormChanged: PropTypes.func
};

export default CustomImageTypePreview;
