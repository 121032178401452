import React from 'react';
import PropTypes from 'prop-types';

import './sass/settings-list.scss';

const SettingsListItem = ({ children }) => <li className="settings-list-item">{children}</li>;

const SettingsList = ({ children }) => <ul className="settings-list">{children}</ul>;

SettingsList.Item = SettingsListItem;

SettingsListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

SettingsList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SettingsList;
