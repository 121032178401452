import { createSelector } from 'reselect';

import constants from './constants';

const selectCustomImageTypesState = (state) => state[constants.NAME];

const selectCustomImageTypes = createSelector(
  selectCustomImageTypesState,
  ({ customImageTypePagedResults }) => customImageTypePagedResults
);

export default {
  selectCustomImageTypes
};
