import React from 'react';

import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

export const onHideHandler = (hasUnsavedChanges, onModalClose, setShowConfirmModal) => {
  if (!hasUnsavedChanges) onModalClose();
  else setShowConfirmModal(true);
};

export const renderShowConfirmationModal = (showConfirmModal, setShowConfirmModal, onModalClose) => (
  <ConfirmationModal
    showModal={showConfirmModal}
    heading="Unsaved data!"
    message="You have unsaved changes. Are you sure you want to leave?"
    onCancel={() => setShowConfirmModal(false)}
    onConfirm={onModalClose}
  />
);
