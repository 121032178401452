import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconClose } from '../../assets/img/icn-close-icon.svg';

import './sass/filter-label.scss';

const FilterLabel = ({ labelText, values, onDismiss }) => {
  const [valuesCopy, setValuesCopy] = useState(() => values);

  const removeValue = (valueTobeRemove) => {
    const shallowValuesCopy = valuesCopy;
    const indexOfValueToBeRemoved = shallowValuesCopy.findIndex((value) => value.id === valueTobeRemove.id);

    shallowValuesCopy.splice(indexOfValueToBeRemoved, 1);

    setValuesCopy([...shallowValuesCopy]);
  };

  useEffect(() => {
    setValuesCopy(values);
  }, [values]);

  return (
    valuesCopy.length > 0 && (
      <div className="label-filter-container">
        {labelText && (
          <div className="label-wrapper">
            <label>
              {labelText}
              : &nbsp;
            </label>
          </div>
        )}

        <div className="values-wrapper">
          {valuesCopy.map((value) => (
            <div className="item-wrapper" key={value.id}>
              <span className="value-wrapper">{value.value}</span>
              <span
                className="close-icon-wrapper"
                role="button"
                tabIndex={0}
                onClick={() => {
                  removeValue(value);
                  onDismiss(value);
                }}
              >
                <IconClose />
              </span>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

FilterLabel.propTypes = {
  labelText: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.node,
    }).isRequired,
  ),
  onDismiss: PropTypes.func.isRequired,
};

export default FilterLabel;
