import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './sass/tabs.scss';

const Tabs = ({ tabs, activeTab, onTabClick }) => {
  const [active, setActive] = useState(activeTab);
  return (
    <ul className="tabs">
      {
        tabs.map((name) => {
          const classes = [];
          if (name === active) {
            classes.push('active');
          } else {
            classes.push('not-active');
          }

          return (
            <li className="nav-item" key={name}>
              <input
                type="button"
                className={classes.join(' ')}
                onClick={() => {
                  setActive(name);
                  if (onTabClick) onTabClick(name);
                }}
                value={name}
              />
            </li>
          );
        })
      }
    </ul>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.string,
  onTabClick: PropTypes.func
};

export default Tabs;
