import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconLocation } from '../../assets/img/icn-address.svg';
import { SkeletonUI, QualityRating, VehicleDamagedIndicator, LimitedCharsStringPresentation } from '..';
import { composeLocationApiUrl } from '../../api/composeApiUrl';
import { constants as workflowsConstants } from '../../redux/workflows';
import { constants as photoSeriesConstants } from '../../redux/photoSeries';

import ExternalIdModal from '../../screens/Vehicles/containers/PhotoSeriesDetails/container/ExternalIdModal/ExternalIdModal';

import './sass/photoseries-vehicle-details.scss';
import Tags from '../Tags/Tags';
import { Tooltip } from '../Form';

const VisibilityRating = ({ visibilityRating }) => {
  let visibilityRatingDisplayValue;
  let visibilityRatingValueClassNames;

  if (!visibilityRating) {
    visibilityRatingDisplayValue = '-';
    visibilityRatingValueClassNames = 'value';
  } else {
    visibilityRatingDisplayValue = photoSeriesConstants.VISIBILIY_RATING_NAME[visibilityRating];
    visibilityRatingValueClassNames = `value ${visibilityRating === photoSeriesConstants.VISIBILIY_RATING.SAFE ? 'high' : 'average'}-rating`;
  }

  return (
    <li>
      <section>
        <span className="key">Visibility</span>
        <span className={visibilityRatingValueClassNames}>{visibilityRatingDisplayValue}</span>
      </section>
    </li>
  );
};

VisibilityRating.propTypes = {
  visibilityRating: PropTypes.number
};

const PhotoSeriesVehicleDetailsSkeleton = () => (
  <div className="vehicle-details-container photo-series-card">
    <div className="vehicle-details-container__row1 mb-3">
      <span className="key heading">Vehicle information</span>
    </div>
    <div className="vehicle-details-container__row2">
      <ul className="vehicle-details-container-ul vehicle-details-container__row2-col1">
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
      </ul>
      <ul className="vehicle-details-container-ul vehicle-details-container__row2-col2">
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
      </ul>
      <ul className="vehicle-details-container-ul vehicle-details-container__row2-col3">
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
        <li><SkeletonUI type="radius-horizontal" height="0.813rem" width="20rem" /></li>
      </ul>
    </div>
  </div>
);

const PhotoSeriesVehicleDetails = ({
  loading,
  photoSeries,
  damages,
  permissions,
  shownEIdModal,
  setShownEIdModal,
  timeFrame,
  duration,
  showExternalIdModal,
  sharedWith,
  setShownSharePhotoSeriesModal,
  showSharedWith,
  expectedVin,
  showOwner
}) => {
  const gpsEnabled = photoSeries?.workflow?.trackGeolocationEnabled;
  const gpsMissingAndEnabled = !photoSeries?.location?.latitude && gpsEnabled;
  const vinTooltip = expectedVin && expectedVin !== photoSeries.vehicle.vin
    && (<Tooltip>Expected VIN on capture link: {expectedVin}</Tooltip>);

  if (loading) return <PhotoSeriesVehicleDetailsSkeleton />;

  return (
    <div className="vehicle-details-container photo-series-card">
      <div className="vehicle-details-container__row1 mb-3">
        <span className="key heading">Vehicle information</span>
      </div>
      <div className="vehicle-details-container__row2">
        <ul className="vehicle-details-container-ul vehicle-details-container__row2-col1">
          <li>
            <section>
              <span className="key">Plate nr</span>
              <span className="value"><LimitedCharsStringPresentation value={photoSeries.vehicle.registrationNo} chars={10} /></span>
            </section>
          </li>
          <li>
            <section>
              <span className="key">Vin {vinTooltip}</span>
              <span className="value"><LimitedCharsStringPresentation value={photoSeries.vehicle.vin} chars={17} /></span>
            </section>
          </li>
          <li>
            <section>
              <span className="key">Vehicle type</span>
              <span className="value">{ workflowsConstants.VEHICLE_TYPE_NAME[photoSeries.workflow.vehicleType]}</span>
            </section>
          </li>
          <li>
            { (photoSeries?.location?.latitude || gpsEnabled) && (
            <section>
              <span className="key d-flex">Location</span>
              {gpsMissingAndEnabled
                ? (<span className="value">GPS validation required but not available</span>)
                : (
                  <a
                    href={composeLocationApiUrl(photoSeries.location)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="value map"
                  >
                    <IconLocation className="svg-md" />
                    View on Google Maps
                  </a>
                )}
            </section>
            )}
          </li>
        </ul>
        <ul className="vehicle-details-container-ul vehicle-details-container__row2-col2">
          {
            photoSeries?.qualityRating !== null
              ? (
                <li>
                  <section>
                    <span className="key">Image quality</span>
                    <span className="value">
                      <QualityRating qualityRating={photoSeries.qualityRating} />
                    </span>
                  </section>
                </li>
              )
              : null
          }
          <VisibilityRating visibilityRating={photoSeries.visibilityRating} />
          <li>
            <section>
              <span className="key">Damages</span>
              <span className="value">
                <VehicleDamagedIndicator damages={damages} />
              </span>
            </section>
          </li>
          <li>
            <section>
              <span className="key">Browser</span>
              {
                photoSeries?.unsupportedBrowser === true
                  ? (<span className="value" style={{ color: '#DD3243' }}>Unsupported browser</span>)
                  : (<span className="value" style={{ color: '#00BE4A' }}>Supported browser</span>)
              }
            </section>
          </li>
        </ul>
        <ul className="vehicle-details-container-ul vehicle-details-container__row2-col3">
          <li>
            <section>
              <span className="key">Workflow</span>
              <span className="value">{photoSeries.workflow.name || '-'}</span>
            </section>
          </li>
          <li>
            <section>
              <span className="key">Date</span>
              <span className="value">{timeFrame}</span>
            </section>
          </li>
          <li>
            <section>
              <span className="key">Duration</span>
              <span className="value">{ duration !== undefined ? `${duration} minutes` : '-'}</span>
            </section>
          </li>
          <li>
            <section>
              <span className="key">Created by</span>
              <span className="value">{photoSeries.createdByUserEmail || 'Public user'}</span>
            </section>
          </li>
          {
            showExternalIdModal && (
              <li>
                <section>
                  <span className="key">External ID</span>
                  <span className="value">{photoSeries.externalId || '-'}</span>
                  {permissions.canEditPhotoSeries && (
                    <span role="button" tabIndex={0} className="edit-btn" onClick={() => setShownEIdModal(true)}>Edit</span>
                  )}
                </section>
              </li>
            )
          }
          {
            showSharedWith && (
              <li>
                <section>
                  <span className="key">Shared with</span>
                  <div className="value">
                    { photoSeries.sharedWithCustomerIds && photoSeries.sharedWithCustomerIds.length > 0 ? <Tags tags={sharedWith} /> : '-'}
                  </div>
                  {permissions.canEditPhotoSeries && (
                    <span role="button" tabIndex={0} className="edit-btn" onClick={() => setShownSharePhotoSeriesModal(true)}>Edit</span>
                  )}
                </section>
              </li>
            )
          }
          {
            showOwner && (
              <li>
                <section>
                  <span className="key">Owner</span>
                  <span className="value">{photoSeries.sharedByCustomerName}</span>
                </section>
              </li>
            )
          }
        </ul>
      </div>
      {
        showExternalIdModal === true && (
          <ExternalIdModal id="editEIdModal" show={shownEIdModal} onModalClose={() => setShownEIdModal(false)} photoSeries={photoSeries} />
        )
      }
    </div>
  );
};

PhotoSeriesVehicleDetails.propTypes = {
  loading: PropTypes.bool,
  photoSeries: PropTypes.object,
  shownEIdModal: PropTypes.bool.isRequired,
  setShownEIdModal: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    canEditPhotoSeries: PropTypes.bool,
    canViewDamages: PropTypes.bool
  }).isRequired,
  damages: PropTypes.arrayOf(PropTypes.shape({
    bodyPart: PropTypes.number,
    damageType: PropTypes.number
  })).isRequired,
  timeFrame: PropTypes.string,
  duration: PropTypes.number,
  showExternalIdModal: PropTypes.bool.isRequired,
  sharedWith: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })),
  setShownSharePhotoSeriesModal: PropTypes.func,
  showSharedWith: PropTypes.bool,
  expectedVin: PropTypes.string,
  showOwner: PropTypes.bool
};

export default PhotoSeriesVehicleDetails;
