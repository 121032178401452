import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

import { constants as imagesConstants } from '../../../../../redux/images';
import { constants as workflowConstants } from '../../../../../redux/workflows';

const { FIRST_VIN, INSPECTION_STICKER, SECOND_VIN } = imagesConstants.IMAGE_SUB_TYPES;
const { CAR, TRAILER, TRUCK } = workflowConstants.VEHICLE_TYPES;
const { INSTRUCTION_TYPE, INSTRUCTION_TYPE_NAMES } = imagesConstants;

const SELECTION = {
  VIN: 0,
  INSPECTION_STICKER: 1
};

const InstructionTypeSelection = ({ imageSubType, instructionTypes, imageSubTypes, onChangeHandler }) => {
  if (instructionTypes.length === 0) return null;
  const subType = imageSubTypes.find((c) => c.imageSubType === imageSubType);
  return (
    <>
      {
        subType.instructions.length === 0 && (
          <div className="row ml-2">
            <Alert variant="warning" className="mt-2 mb-2">
              Select at least 1 instruction to display!
            </Alert>
          </div>
        )
      }
      {
        instructionTypes.map((instructionType) => (
          <div className="row ml-4" key={`${imageSubType}-${instructionType}`}>
            <div className="col-sm-12 form-check ml-2">
              <input
                className="form-check-input"
                type="checkbox"
                id={instructionType}
                checked={subType.instructions.some((i) => i === instructionType)}
                onChange={(event) => {
                  if (event.target.checked) {
                    subType.instructions.push(instructionType);
                    subType.instructions = subType.instructions.sort((a, b) => a - b);
                  } else {
                    subType.instructions = subType.instructions.filter((i) => i !== instructionType);
                  }
                  onChangeHandler(imageSubTypes);
                }}
              />
              <label className="form-check-label" htmlFor={instructionType}>{INSTRUCTION_TYPE_NAMES[instructionType]}</label>
            </div>
          </div>
        ))
      }
    </>
  );
};

InstructionTypeSelection.propTypes = {
  imageSubType: PropTypes.number,
  imageSubTypes: PropTypes.array,
  instructionTypes: PropTypes.array,
  onChangeHandler: PropTypes.func
};

const VinSubTypeSelection = ({ imageSubTypes, setFieldValue, vehicleType }) => {
  const [selection, setSelection] = useState(false);
  useEffect(() => {
    if (imageSubTypes.some((subType) => subType.imageSubType === FIRST_VIN || subType.imageSubType === SECOND_VIN)) {
      setSelection(SELECTION.VIN);
    } else if (imageSubTypes.some((subType) => subType.imageSubType === INSPECTION_STICKER)) {
      setSelection(SELECTION.INSPECTION_STICKER);
    }
  }, [imageSubTypes]);

  const onChangeHandler = (imageSubTypes) => {
    setFieldValue('imageSubTypes', imageSubTypes);
  };

  return (
    <>
      <h6>Image subtype</h6>
      <div className="row">
        <div className="col-sm-12">
          <label htmlFor="vin">
            <input
              type="radio"
              name="vinSubTypeSelection"
              checked={selection === SELECTION.VIN}
              id="vin"
              onChange={() => {
                onChangeHandler([]);
                setSelection(SELECTION.VIN);
              }}
            />
            &nbsp; VIN capture
          </label>
        </div>
      </div>
      {
        selection === SELECTION.VIN && (
          <div className="mb-2">
            <div className="row">
              <div className="col-sm-12 form-check ml-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="firstVin"
                  checked={imageSubTypes.some((subType) => subType.imageSubType === FIRST_VIN)}
                  onChange={(event) => {
                    if (event.target.checked) {
                      onChangeHandler(
                        [...imageSubTypes, { imageSubType: FIRST_VIN, instructions: [] }]
                          .sort((a, b) => a.imageSubType - b.imageSubType)
                      );
                    } else {
                      onChangeHandler([...imageSubTypes].filter((c) => c.imageSubType !== FIRST_VIN));
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="firstVin">Image 1</label>
              </div>
            </div>
            {
              imageSubTypes.some((subType) => subType.imageSubType === FIRST_VIN) && <InstructionTypeSelection
                imageSubType={FIRST_VIN}
                imageSubTypes={imageSubTypes}
                instructionTypes={
                  vehicleType === CAR
                    ? [
                      INSTRUCTION_TYPE.FIRST_WINDSHIELD,
                      INSTRUCTION_TYPE.DOOR_JAMB,
                      INSTRUCTION_TYPE.UNDER_DRIVER_SEAT,
                      INSTRUCTION_TYPE.PASSENGER_DOOR_AND_SEAT,
                      INSTRUCTION_TYPE.ENGINE_ROOM,
                      INSTRUCTION_TYPE.PASSENGER_SIDE
                    ]
                    : vehicleType === TRUCK ? [INSTRUCTION_TYPE.FIRST_WINDSHIELD, INSTRUCTION_TYPE.SECOND_WINDSHIELD] : []
                }
                onChangeHandler={onChangeHandler}
              />
            }
            {
              vehicleType === CAR && (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-check ml-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="secondVin"
                        checked={imageSubTypes.some((subType) => subType.imageSubType === SECOND_VIN)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChangeHandler(
                              [...imageSubTypes, { imageSubType: SECOND_VIN, instructions: [] }]
                                .sort((a, b) => a.imageSubType - b.imageSubType)
                            );
                          } else {
                            onChangeHandler([...imageSubTypes].filter((c) => c.imageSubType !== SECOND_VIN));
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="firstVin">Image 2</label>
                    </div>
                  </div>
                  {
                    imageSubTypes.some((subType) => subType.imageSubType === SECOND_VIN) && <InstructionTypeSelection
                      imageSubType={SECOND_VIN}
                      imageSubTypes={imageSubTypes}
                      instructionTypes={[
                        INSTRUCTION_TYPE.FIRST_WINDSHIELD,
                        INSTRUCTION_TYPE.DOOR_JAMB,
                        INSTRUCTION_TYPE.UNDER_DRIVER_SEAT,
                        INSTRUCTION_TYPE.PASSENGER_DOOR_AND_SEAT,
                        INSTRUCTION_TYPE.ENGINE_ROOM,
                        INSTRUCTION_TYPE.PASSENGER_SIDE
                      ]}
                      onChangeHandler={onChangeHandler}
                    />
                  }
                </>
              )
            }
          </div>
        )
      }
      {
        vehicleType !== TRAILER && (
          <div className="row">
            <div className="col-sm-12">
              <label htmlFor="inspectionSticker">
                <input
                  type="radio"
                  name="vinSubTypeSelection"
                  checked={selection === SELECTION.INSPECTION_STICKER}
                  id="inspectionSticker"
                  onChange={() => {
                    onChangeHandler([{
                      imageSubType: INSPECTION_STICKER,
                      instructions: []
                    }]);
                    setSelection(SELECTION.INSPECTION_STICKER);
                  }}
                />
                &nbsp; Inspection sticker
              </label>
            </div>
          </div>
        )
      }
    </>
  );
};

VinSubTypeSelection.propTypes = {
  imageSubTypes: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  vehicleType: PropTypes.number.isRequired
};

export default VinSubTypeSelection;
