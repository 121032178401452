import _ from 'lodash';
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Subscription, Loader } from '../../../../components';
import { SUBSCRIPTION_TYPES } from '../../../../components/Cards/SubscriptionCard/SubscriptionCard';
import {
  selectors as customerMonthlyConsumptionsSelectors,
  actions as customerMonthlyConsumptionsActions
} from '../../../../redux/customerMonthlyConsumptions';
import { actions as customerConfigurationsActions } from '../../../../redux/customerConfigurations';
import { constants as sessionConstants } from '../../../../redux/session';
import { selectors as subscriptionSelectors, actions as subscriptionActions } from '../../../../redux/subscriptions/index';
import { actions as customerActions, selectors as customerSelectors } from '../../../../redux/customers/index';
import { makePut, makeGet } from '../../../../api/ajax';
import { authenticationService } from '../../../../services';
import { getUtcToday, formatDate, getNextMonth } from '../../../../utils/date';
import { composeApiUrl } from '../../../../api/composeApiUrl';
import { constants as fileConstants } from '../../../../redux/files';

import '../../../../sass/subscription-section.scss';

const SubscriptionsSection = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const subscriptions = useSelector(subscriptionSelectors.selectSubscriptions);
  const subscriptionEnabledFeatures = useSelector(subscriptionSelectors.selectEnabledFeatures);
  const customer = useSelector(customerSelectors.selectCurrentCustomer);
  const monthlyConsumption = useSelector(customerMonthlyConsumptionsSelectors.selectCurrentMonthConsumption);
  const canEditSubscription = authenticationService.hasPermission(sessionConstants.PERMISSION_TYPES.EDIT_SUBSCRIPTIONS);
  const currentSubscription = _.find(subscriptions, (s) => s.id === monthlyConsumption?.subscriptionId);
  const subscriptionWillChange = customer && monthlyConsumption && customer.subscriptionId !== monthlyConsumption?.subscriptionId;
  const todayString = getUtcToday();

  const processedSubscriptions = currentSubscription?.type === SUBSCRIPTION_TYPES.CORPORATE
    // If current sub is CORPORATE, show only CORPORATE
    ? subscriptions
      .filter((s) => s?.type === SUBSCRIPTION_TYPES.CORPORATE)
    : _.orderBy(subscriptions, (s) => s.enabledFeatures?.scanVolume)
      .filter((s) => {
        const showTrialOnlyIfCurrent = s?.type !== SUBSCRIPTION_TYPES.TRIAL || currentSubscription?.type === SUBSCRIPTION_TYPES.TRIAL;

        return showTrialOnlyIfCurrent && s?.type !== SUBSCRIPTION_TYPES.CORPORATE;
      });

  const newSubscription = _.find(processedSubscriptions, (s) => s.id === customer?.subscriptionId)?.name;

  const putSubscription = (id, data) => {
    setIsLoading(true);

    makePut(`subscriptions/${id}`, data).subscribe(({ response }) => {
      setIsLoading(false);
      dispatch(customerActions.loadCustomerSuccess({ ...customer, subscriptionId: response.id }));

      makeGet(`customerMonthlyConsumptions/${todayString.toISOString()}`).subscribe(({ response }) => {
        dispatch(customerMonthlyConsumptionsActions.loadMonthlyConsumptionSuccess(response, todayString.toISOString()));
      });
    }, () => setIsLoading(false));
  };

  const putDamageDetectionOn = (value, reloadConfig) => {
    setIsLoading(true);
    makePut('customerConfigurations/current/enabledFeatures', {
      damageDetectionEnabled: value
    }).subscribe(({ response: enabledFeaturesResponse }) => {
      if (reloadConfig) {
        // When changing from another subscription to CORPORATE, many data objects need to be reloaded in Redux
        makeGet('customers/current').subscribe(({ response: customerResponse }) => {
          makeGet('subscriptions').subscribe(({ response: subscriptionsResponse }) => {
            makeGet('customerConfigurations/current').subscribe(({ response: customerConfigResponse }) => {
              makeGet(`customerMonthlyConsumptions/${todayString.toISOString()}`).subscribe(({ response: monthlyConsumptionResponse }) => {
                dispatch(subscriptionActions.loadSubscriptionsSuccess(subscriptionsResponse));
                dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(customerConfigResponse));
                dispatch(customerActions.loadCustomerSuccess(customerResponse));
                dispatch(customerMonthlyConsumptionsActions.loadMonthlyConsumptionSuccess(monthlyConsumptionResponse, todayString.toISOString()));

                setIsLoading(false);
              });
            });
          });
        });
      } else {
        dispatch(subscriptionActions.loadEnabledFeaturesSuccess(enabledFeaturesResponse));
        setIsLoading(false);
      }
    });
  };

  const handleGeneralTermsAndConditionsDownload = (downloadComplete) => {
    makeGet(`files/subscription/token?entityType=${fileConstants.FILE_ENTITY_TYPES.GENERAL_TERMS_AND_CONDITIONS}`).subscribe(({ response }) => {
      window.location = composeApiUrl(`files/subscription/download?token=${response.token}`);
      downloadComplete();
    });
  };

  return (
    <div className="subscription-section">
      {(isLoading || !subscriptions) && <Loader />}

      <div className="subscription-section__heading">
        <h3>
          <span className="mr-2">Subscription:</span>
          {currentSubscription?.name}
        </h3>
        <a
          href="mailto:sales@drivex.ee?subject=Corporate offer request"
          className="btn btn-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ask for corporate offer
        </a>
      </div>

      {subscriptionWillChange && (
        <Alert variant="warning" className="ml-0">
          Your subscription will be changed to {newSubscription} on {formatDate(getNextMonth())}.
        </Alert>
      )}

      <div className="subscription-card-wrapper">
        {processedSubscriptions?.map((data) => {
          const { id, type, enabledFeatures } = data;
          const isActiveSubscription = id === monthlyConsumption?.subscriptionId;

          return (
            <div className="subscription-card-wrapper__row" key={id}>
              <Subscription isActiveSubscription={isActiveSubscription}>
                <Subscription.Header
                  type={type}
                  canEdit={canEditSubscription}
                  enabledFeatures={enabledFeatures}
                  onActivateSubscription={() => putSubscription(id, data)}
                />
                <Subscription.Body enabledFeatures={enabledFeatures} />
                <div className="d-flex flex-column justify-content-end h-100">
                  {
                    subscriptionEnabledFeatures.damageDetectionEnabled !== undefined
                    && isActiveSubscription
                    && (
                      <Subscription.DamageDetection
                        damageDetectionEnabled={subscriptionEnabledFeatures.damageDetectionEnabled}
                        disabled={!canEditSubscription}
                        setDamageDetectionOn={(value) => putDamageDetectionOn(value, currentSubscription?.type !== SUBSCRIPTION_TYPES.CORPORATE)}
                      />
                    )
                  }
                  {
                    type === SUBSCRIPTION_TYPES.TRIAL && (
                      <div className="mt-3">
                        <Subscription.GeneralTermsAndConditions onDownloadClick={handleGeneralTermsAndConditionsDownload} />
                      </div>
                    )
                  }
                </div>
              </Subscription>
            </div>
          );
        })}
      </div>

      <p>
        <b>Disclaimer: </b>
        Prices presented above do not include VAT
      </p>
    </div>
  );
};
export default SubscriptionsSection;
