import React from 'react';
import PropTypes from 'prop-types';

import ReactPortal from '../ReactPortal/ReactPortal';

import './sass/notification-modal.scss';

const NotificationModal = ({
  showModal, type, message, onCancel, position, extraContent,
}) => (
  <>
    {showModal && (
      <ReactPortal>
        <div
          className={`notification-modal-container notification--type--${type} position--${position || 'top'}`}
          role="dialog"
          aria-live="polite"
          aria-atomic
          aria-modal
        >
          <div className="row-1">
            <h4>{type === 'error' ? 'Error' : 'Success'}</h4>

            <span role="button" className="close-icon" tabIndex={0} onClick={onCancel}>
              &#xd7;
            </span>
          </div>

          <p>{message}</p>

          {extraContent}
        </div>
      </ReactPortal>
    )}
  </>
);

NotificationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['error', 'success', 'warning']).isRequired,
  extraContent: PropTypes.node,
  position: PropTypes.oneOf(['top', 'bottom', 'center', 'left', 'right']),
};
export default NotificationModal;
