import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import './sass/custom-dropdown.scss';

const CustomToggle = React.forwardRef(({ children, onClick, round }, ref) => (
  <button
    className={`toggle-btn btn btn-link btn-sm ${round && 'round'}`}
    type="button"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </button>
));

const CustomMenu = React.forwardRef(({ children, className, 'aria-labelledby': labeledBy }, ref) => (
  <div ref={ref} className={className} aria-labelledby={labeledBy}>
    {React.Children.toArray(children)}
  </div>
));

const ActionsDropDownItem = ({ onClick, href, children }) => (
  <Dropdown.Item eventKey="2" onClick={onClick} href={href}>
    {children}
  </Dropdown.Item>
);

const ActionsDropDown = ({ children, toggle, toggleText, round, className }) => (
  <Dropdown className={className || ''}>
    <Dropdown.Toggle as={CustomToggle} round={round}>
      {toggleText && <span className="toggle-text">{toggleText}</span>}
      {toggle && <span className="toggle-icon icon baseline">{toggle}</span>}
    </Dropdown.Toggle>
    <Dropdown.Menu as={CustomMenu}>{children}</Dropdown.Menu>
  </Dropdown>
);

ActionsDropDown.Item = ActionsDropDownItem;

CustomToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  round: PropTypes.bool,
};

CustomMenu.propTypes = {
  className: PropTypes.string,
  'aria-labelledby': PropTypes.string,
  children: PropTypes.node.isRequired,
};

ActionsDropDownItem.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ActionsDropDown.propTypes = {
  toggle: PropTypes.node.isRequired,
  toggleText: PropTypes.string,
  round: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default ActionsDropDown;
