import types from './types';

import sessionTypes from '../session/types';

const defaultState = {};

const customerConfigurationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_CURRENT_CUSTOMER_CONFIGURATION_SUCCESS: {
      const { customerConfiguration } = action.payload;

      return {
        ...state,
        customerConfiguration,
      };
    }
    case types.LOAD_CURRENT_CUSTOMER_LOGO_SUCCESS: {
      const { logoFile } = action.payload;

      const { localLogoUrl: prevLocalLogoUrl } = state;

      if (prevLocalLogoUrl) window.URL.revokeObjectURL(prevLocalLogoUrl);

      const localLogoUrl = window.URL.createObjectURL(logoFile);

      return {
        ...state,
        localLogoUrl,
      };
    }

    case sessionTypes.LOG_OUT: {
      const { localLogoUrl } = state;

      if (localLogoUrl) window.URL.revokeObjectURL(localLogoUrl);

      return defaultState;
    }
    default:
      return state;
  }
};

export default customerConfigurationsReducer;
