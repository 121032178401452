export const NAME = 'subscription';

export const SUBSCRIPTION_MESSAGES = {
  cannotAddAccounts: 'You have reached the maximum number of users available in your subscription.',
  cannotConfigureCustomTheme: 'Your subscription does not include the custom theming feature.',
  cannotManageImages: 'Your subscription does not include managing image validation settings.',
  cannotCreateWorkflows: 'Your subscription does not include creating new workflows.',
};

export default {
  NAME,
  SUBSCRIPTION_MESSAGES,
};
