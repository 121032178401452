import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { authenticationService } from '../../../../services';

import { useBeforeUnload } from '../../../../hooks';
import { makePost, makePut, makeGet } from '../../../../api/ajax';
import { Field, Tooltip } from '../../../../components/Form';
import { Modal, onHideHandler, renderShowConfirmationModal, Card, Checkbox } from '../../../../components';
import { actions as workflowsActions, constants as workflowsConstants } from '../../../../redux/workflows';
import { selectors as subsscriptionSelectors } from '../../../../redux/subscriptions';
import { constants as sessionConstants } from '../../../../redux/session';

import { selectors as customerConfigurationsSelectors } from '../../../../redux/customerConfigurations';
import { selectors as countriesSelectors, actions as countriesActions } from '../../../../redux/countries';
import {
  selectors as windshieldRepairProfileSelectors,
  actions as windshieldRepairProfileActions
} from '../../../../redux/windshieldRepairabilityProfiles';

import './sass/workflow-modal.scss';

const { EDIT_WORKFLOW_USER_FEEDBACK_SETTINGS, EDIT_WINDSHIELD_REPAIR_THRESHOLDS } = sessionConstants.PERMISSION_TYPES;

const handleSubmit = (formData, setSuccessMessage, setErrorMessage, dispatch, setHasUnsavedChanges, setSubmitting) => {
  const workflowId = formData.id;

  setSuccessMessage(null);
  setErrorMessage(null);
  setSubmitting(true);

  const handleError = (err) => {
    setHasUnsavedChanges(false);

    const { status, response: { error } } = err;

    switch (status) {
      case 400:
        setErrorMessage(error);
        break;
      default:
        setErrorMessage(null);
        break;
    }

    setSubmitting(false);
  };

  if (formData.expectedCountryId === '') formData.expectedCountryId = null;
  if (formData.windshieldRepairReplaceBookingUrl === '') formData.windshieldRepairReplaceBookingUrl = null;
  if (formData.windshieldRepairProfileId === '') formData.windshieldRepairProfileId = null;
  if (formData.publicUserReturnUrl === '') formData.publicUserReturnUrl = null;
  if (formData.reportingEmail === '') formData.reportingEmail = null;

  if (formData.windshieldRepairConfidenceThreshold === 0 || formData.windshieldRepairConfidenceThreshold === '') {
    formData.windshieldRepairConfidenceThreshold = null;
  }

  if (formData.windshieldRepairDamagePredictionConfidenceThreshold === 0 || formData.windshieldRepairDamagePredictionConfidenceThreshold === '') {
    formData.windshieldRepairDamagePredictionConfidenceThreshold = null;
  }

  if (workflowId) {
    // Edit workflow
    makePut(`workflows/${workflowId}`, formData).subscribe(
      ({ response }) => {
        dispatch(workflowsActions.updateWorkflowSuccess(workflowId, response));

        setHasUnsavedChanges(false);
        setSuccessMessage('Workflow changes saved!');
        setSubmitting(false);
      },
      (err) => handleError(err)
    );
  } else {
    // Add workflow
    makePost('workflows', formData).subscribe(
      ({ response }) => {
        dispatch(workflowsActions.createWorkflowSuccess(response));

        setHasUnsavedChanges(false);
        setSuccessMessage('New workflow added!');
        setSubmitting(false);
      },
      (err) => handleError(err)
    );
  }
};

/* Damage detection type */
const DAMAGE_DETECTION_TYPE = {
  NONE: 0,
  MANUAL: 1,
  MANUAL_WITH_CUSTOM_DAMAGES: 2,
  AUTOMATIC: 3
};

const DAMAGE_DETECTION_TYPE_NAME = {
  [DAMAGE_DETECTION_TYPE.MANUAL]: 'Manual',
  [DAMAGE_DETECTION_TYPE.MANUAL_WITH_CUSTOM_DAMAGES]: 'Manual (Custom damages)',
  [DAMAGE_DETECTION_TYPE.AUTOMATIC]: 'Automatic'
};

/* Repair decision type */
const REPAIR_DECISION_TYPE = {
  NONE: 0,
  WINDSHIELD: 1
};

const REPAIR_DECISION_TYPE_NAME = {
  [REPAIR_DECISION_TYPE.WINDSHIELD]: 'Windshield'
};

/* Location tracking */
const GEOLOCATION_LOCATION_TRACKING = {
  NONE: 0,
  HIDDEN: 1,
  BLOCKER: 2
};

const GEOLOCATION_LOCATION_TRACKING_NAME = {
  [GEOLOCATION_LOCATION_TRACKING.NONE]: 'None',
  [GEOLOCATION_LOCATION_TRACKING.HIDDEN]: 'Optional',
  [GEOLOCATION_LOCATION_TRACKING.BLOCKER]: 'Mandatory'
};

const WorkflowModal = ({ show, onHide, workflow, isSavingDisabled }) => {
  const [asset, setAsset] = useState(workflowsConstants.ASSET.VEHICLE);

  const [damageDetectionType, setDamageDetectionType] = useState(DAMAGE_DETECTION_TYPE.NONE);
  const [repairDecisionType, setRepairDecisionType] = useState(REPAIR_DECISION_TYPE.NONE);
  const [geolocationLocationTracking, setGeolocationLocationTracking] = useState(GEOLOCATION_LOCATION_TRACKING.NONE);

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { hasPermission } = authenticationService;

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const countriesFromPagedResult = useSelector(countriesSelectors.selectPagedResult)?.entities;
  const countries = countriesFromPagedResult !== undefined
    ? Object.entries(countriesFromPagedResult).map((key) => key[1])
    : [];

  const enabledFeatures = useSelector(subsscriptionSelectors.selectEnabledFeatures);
  const windshieldRepairProfiles = useSelector(windshieldRepairProfileSelectors.selectWindshieldRepairabilityProfiles);

  useBeforeUnload(hasUnsavedChanges);

  const dispatch = useDispatch();
  const customerConfiguration = useSelector(customerConfigurationsSelectors.selectCustomerConfiguration);

  /* Setting default values that does not exist explicitly in workflow settings */
  const getDefaultDamageDetectionType = useCallback(() => {
    if (workflow.damageDetectionEnabled) return DAMAGE_DETECTION_TYPE.AUTOMATIC;
    if (workflow.manualDamageLabellingEnabled && workflow.manualDamageLabellingCustomDamagesEnabled) {
      return DAMAGE_DETECTION_TYPE.MANUAL_WITH_CUSTOM_DAMAGES;
    }

    if (workflow.manualDamageLabellingEnabled) return DAMAGE_DETECTION_TYPE.MANUAL;
    return DAMAGE_DETECTION_TYPE.NONE;
  }, [workflow]);

  const getDefaultRepairDecisionType = useCallback(() => {
    if (workflow.windshieldRepairReplaceDecisionEnabled) return REPAIR_DECISION_TYPE.WINDSHIELD;
    return REPAIR_DECISION_TYPE.NONE;
  }, [workflow]);

  const getDefaultGeolocationLocationTracking = useCallback(() => {
    if (workflow.trackGeolocationEnabled && !workflow.trackGeolocationRequired) return GEOLOCATION_LOCATION_TRACKING.HIDDEN;
    if (workflow.trackGeolocationRequired) return GEOLOCATION_LOCATION_TRACKING.BLOCKER;
    return GEOLOCATION_LOCATION_TRACKING.NONE;
  }, [workflow]);

  useEffect(() => {
    if (!workflow) return;
    setDamageDetectionType(getDefaultDamageDetectionType());
    setRepairDecisionType(getDefaultRepairDecisionType());
    setGeolocationLocationTracking(getDefaultGeolocationLocationTracking());
  }, [workflow, getDefaultDamageDetectionType, getDefaultRepairDecisionType, getDefaultGeolocationLocationTracking]);
  /* End of setting default values that does not exist explicitly in workflow settings */

  useEffect(() => {
    if (customerConfiguration?.expectedCountryValidationEnabled && show && countries.find(((country) => country.name === 'Estonia')) === undefined) {
      makeGet('countries?countryName=Estonia').subscribe(
        ({ response }) => dispatch(countriesActions.loadCountriesSuccess(response))
      );
    }
  }, [customerConfiguration, show, countries, dispatch]);

  useEffect(() => {
    if (!windshieldRepairProfiles) {
      makeGet('windshieldRepairProfiles?pageSize=150').subscribe(
        ({ response }) => dispatch(windshieldRepairProfileActions.loadWindshieldRepairabilityProfilesSuccess(response))
      );
    }
  }, [windshieldRepairProfiles, dispatch]);

  const hideModal = () => {
    setDamageDetectionType(DAMAGE_DETECTION_TYPE.NONE);
    setRepairDecisionType(REPAIR_DECISION_TYPE.NONE);
    setGeolocationLocationTracking(GEOLOCATION_LOCATION_TRACKING.NONE);
    setSuccessMessage(null);
    setErrorMessage(null);
    onHide();
  };

  const displayVehicleTypes = !workflow || (workflow && !workflow.id);
  const editingWorkflow = workflow && workflow.id;

  const initialValues = {
    ...workflow,
    timeLimitValidationEnabled: editingWorkflow
      ? workflow.timeLimitValidationEnabled
      : true,
    smartScanImageDownloadEnabled: editingWorkflow
      ? workflow.smartScanImageDownloadEnabled
      : true,
    windshieldRepairReplaceDecisionEnabled: editingWorkflow
      ? workflow.windshieldRepairReplaceDecisionEnabled
      : false,
    userFeedbackSettings: {
      satisfactionRatingEnabled: editingWorkflow
        ? workflow.userFeedbackSettings.satisfactionRatingEnabled
        : true,
      commentEnabled: editingWorkflow
        ? workflow.userFeedbackSettings.commentEnabled
        : true,
    },
    reportingEmail: workflow?.reportingEmail || '',
    publicUserReturnUrl: workflow?.publicUserReturnUrl || '',
    windshieldRepairReplaceBookingUrl: workflow?.windshieldRepairReplaceBookingUrl || '',
    expectedCountryId: workflow?.expectedCountryId || '',
    windshieldRepairProfileId: workflow?.windshieldRepairProfileId || '',
    vehicleType: editingWorkflow
      ? workflow.vehicleType
      : workflowsConstants.VEHICLE_TYPES.CAR,
    windshieldRepairConfidenceThreshold: workflow?.windshieldRepairConfidenceThreshold || 0,
    // This value is set through additional image type settings
    windshieldRepairDamagePredictionConfidenceThreshold: workflow?.windshieldRepairDamagePredictionConfidenceThreshold || 0
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, () => {
        hideModal();
        setHasUnsavedChanges(false);
        setShowConfirmModal(false);
      })}

      <Modal
        show={show}
        size="lg"
        backdrop="static"
        headerText="Workflow settings"
        onHide={() => {
          onHideHandler(hasUnsavedChanges, hideModal, setShowConfirmModal);
        }}
      >
        <Modal.Body className="create-edit-workflow-modal">
          <div className="row">
            <div className="col">
              {successMessage && (
                <Alert variant="success" dismissible onClick={() => setSuccessMessage(null)}>
                  {successMessage}
                </Alert>
              )}
              {
                errorMessage && (
                  <Alert variant="danger" dismissible onClick={() => setErrorMessage(null)}>
                    {errorMessage}
                  </Alert>
                )
              }
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Workflow name is required'),
              reportingEmail: Yup.string().email('Please enter a correct email').nullable(),
              publicUserReturnUrl: Yup.string().url('Please enter a correct url').nullable(),
              windshieldRepairReplaceBookingUrl: Yup.string().url('Please enter a correct url').nullable(),
              windshieldRepairConfidenceThreshold: Yup
                .number()
                .min(0, 'The repairability threshold must be at least 0.')
                .max(100, 'The repairability threshold cannot exceed 100.'),
              windshieldRepairDamagePredictionConfidenceThreshold: Yup
                .number()
                .min(0, 'The damage confidence threshold must be at least 0.')
                .max(100, 'The damage confidence threshold cannot exceed 100.')
            })}
            onSubmit={(formData, { setSubmitting }) => {
              handleSubmit(
                { ...formData },
                setSuccessMessage, setErrorMessage, dispatch, setHasUnsavedChanges, setSubmitting
              );
            }}
            validateOnChange={false}
          >
            {({ values, errors, touched, setFieldValue, isSubmitting }) => (
              <Form
                onChange={({ target }) => {
                  if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                    setHasUnsavedChanges(true);
                  }
                }}
              >
                <div className="row">
                  <div className="col-sm-12">
                    <Card>
                      <Card.Header>General settings</Card.Header>
                      <Card.Body>
                        <div className="row">
                          <div className="col-sm-4">
                            <Field
                              name="name"
                              label="Workflow name"
                              placeholder="New workflow name"
                              type="text"
                              touched={touched.name}
                              errors={errors.name}
                            />
                          </div>
                          <div className="col-sm-4">
                            <Field
                              name="reportingEmail"
                              label="Report e-mail"
                              placeholder="Add report e-mail"
                              type="text"
                              touched={touched.reportingEmail}
                              errors={errors.reportingEmail}
                            />
                          </div>
                          <div className="col-sm-4">
                            <Field
                              name="publicUserReturnUrl"
                              label="Redirect URL"
                              placeholder="Add redirect URL"
                              type="text"
                              touched={touched.publicUserReturnUrl}
                              errors={errors.publicUserReturnUrl}
                            />
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                {
                  displayVehicleTypes && (
                    <div className="row mt-3">
                      <div className="col-sm-12">
                        <Card>
                          <Card.Header>The asset</Card.Header>
                          <Card.Body>
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label htmlFor="asset" className="mb-0">
                                    Asset
                                  </label>
                                  <select
                                    className="form-control"
                                    value={asset}
                                    onChange={(e) => setAsset(Number(e.target.value))}
                                    name="asset"
                                    id="asset"
                                  >
                                    <option value={workflowsConstants.ASSET.VEHICLE}>
                                      {workflowsConstants.ASSET_NAME[workflowsConstants.ASSET.VEHICLE]}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label htmlFor="assetType" className="mb-0">
                                    Type
                                  </label>
                                  <select
                                    className="form-control"
                                    value={values.vehicleType}
                                    onChange={(e) => setFieldValue('vehicleType', Number(e.target.value))}
                                    name="vehicleType"
                                    id="vehicleType"
                                  >
                                    {
                                      Object.keys(workflowsConstants.VEHICLE_TYPES).map((key) => (
                                        <option key={workflowsConstants.VEHICLE_TYPES[key]} value={workflowsConstants.VEHICLE_TYPES[key]}>
                                          {workflowsConstants.VEHICLE_TYPE_NAME[workflowsConstants.VEHICLE_TYPES[key]]}
                                        </option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  )
                }
                <div className="row mt-3">
                  <div className="col-sm-12">
                    <Card>
                      <Card.Header>Features</Card.Header>
                      <Card.Body>
                        <div className="row mt-2">
                          <div className="col-sm-12 sub-section">
                            <span className="sub-section-header">DAMAGES</span>
                            <Tooltip>
                              You can configure damage related features for each workflow if your subscription includes it.
                            </Tooltip>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="damageDetectionType" className="mb-0">
                                Damage detection
                                <Tooltip>
                                  Off - no damages are detected on the image. Manual - Damages are marked by the user of the SmartScan application.
                                  Manual (custom damages) - Damages are marked by the user of the SmartScan application.
                                  Selectable damages must be configured via DirveX admin.
                                  Automatic - Damage detection AI searches damages from the images. Processing time 2 minutes per image.
                                </Tooltip>
                              </label>
                              <select
                                className="form-control"
                                value={damageDetectionType}
                                onChange={(e) => {
                                  setDamageDetectionType(Number(e.target.value));

                                  setRepairDecisionType(REPAIR_DECISION_TYPE.NONE);

                                  setFieldValue('windshieldRepairProfileId', '');
                                  setFieldValue('windshieldRepairReplaceBookingUrl', '');
                                  setFieldValue(
                                    'manualDamageLabellingCustomDamagesEnabled',
                                    Number(e.target.value) === DAMAGE_DETECTION_TYPE.MANUAL_WITH_CUSTOM_DAMAGES
                                  );
                                  setFieldValue(
                                    'manualDamageLabellingEnabled',
                                    Number(e.target.value) === DAMAGE_DETECTION_TYPE.MANUAL
                                    || Number(e.target.value) === DAMAGE_DETECTION_TYPE.MANUAL_WITH_CUSTOM_DAMAGES
                                  );
                                  setFieldValue(
                                    'damageDetectionEnabled', Number(e.target.value) === DAMAGE_DETECTION_TYPE.AUTOMATIC
                                  );

                                  setFieldValue('windshieldRepairReplaceDecisionEnabled', false);
                                  setFieldValue('windshieldRepairProfileId', '');
                                  setFieldValue('windshieldRepairReplaceBookingUrl', '');
                                }}
                                name="damageDetectionType"
                                id="damageDetectionType"
                              >
                                <option value={DAMAGE_DETECTION_TYPE.NONE}>
                                  Off
                                </option>
                                <option value={DAMAGE_DETECTION_TYPE.MANUAL}>
                                  {DAMAGE_DETECTION_TYPE_NAME[DAMAGE_DETECTION_TYPE.MANUAL]}
                                </option>
                                <option value={DAMAGE_DETECTION_TYPE.MANUAL_WITH_CUSTOM_DAMAGES}>
                                  {DAMAGE_DETECTION_TYPE_NAME[DAMAGE_DETECTION_TYPE.MANUAL_WITH_CUSTOM_DAMAGES]}
                                </option>
                                {
                                  enabledFeatures.damageDetectionEnabled && (
                                    <option value={DAMAGE_DETECTION_TYPE.AUTOMATIC}>
                                      {DAMAGE_DETECTION_TYPE_NAME[DAMAGE_DETECTION_TYPE.AUTOMATIC]}
                                    </option>
                                  )
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="repairDecisionType" className="mb-0">
                                Repair decision
                                <Tooltip>
                                  Select a vehicle part you want to have DriveX repair AI to analyze the reparability of.
                                </Tooltip>
                              </label>
                              <select
                                className="form-control"
                                value={repairDecisionType}
                                onChange={(e) => {
                                  setRepairDecisionType(Number(e.target.value));

                                  setFieldValue('windshieldRepairProfileId', '');
                                  setFieldValue('windshieldRepairReplaceBookingUrl', '');
                                  setFieldValue('windshieldRepairReplaceDecisionEnabled', false);
                                }}
                                name="repairDecisionType"
                                id="repairDecisionType"
                                disabled={damageDetectionType !== DAMAGE_DETECTION_TYPE.MANUAL}
                              >
                                <option value={REPAIR_DECISION_TYPE.NONE}>
                                  Off
                                </option>
                                <option value={REPAIR_DECISION_TYPE.WINDSHIELD}>
                                  {REPAIR_DECISION_TYPE_NAME[REPAIR_DECISION_TYPE.WINDSHIELD]}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="windshieldRepairProfileId" className="mb-0">
                                Windshield profile
                                <Tooltip>
                                  Select the windshield profile for AI to take as a the base to predict the reparability of the damages.
                                </Tooltip>
                              </label>
                              <select
                                className="form-control"
                                value={values.windshieldRepairProfileId}
                                onChange={(event) => {
                                  setFieldValue('windshieldRepairProfileId', event.target.value);
                                  setFieldValue('windshieldRepairReplaceBookingUrl', '');
                                  setFieldValue('windshieldRepairReplaceDecisionEnabled', event.target.value !== '');
                                }}
                                name="windshieldRepairProfileId"
                                id="windshieldRepairProfileId"
                                disabled={repairDecisionType !== REPAIR_DECISION_TYPE.WINDSHIELD}
                              >
                                <option key="defaultWindshieldRepairProfileId" value="">Not selected</option>
                                {
                                  windshieldRepairProfiles?.results?.map((x) => <option key={x.id} value={x.id}>{x.name}</option>)
                                }
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <Field
                              type="text"
                              name="windshieldRepairReplaceBookingUrl"
                              label="Booking url"
                              touched={touched.windshieldRepairReplaceBookingUrl}
                              errors={errors.windshieldRepairReplaceBookingUrl}
                              disabled={repairDecisionType !== REPAIR_DECISION_TYPE.WINDSHIELD}
                              // eslint-disable-next-line max-len
                              tooltip="Enables user to navigate to the repair shop booking link, after AI predicted the reparability of the windshield."
                            />
                          </div>
                        </div>
                        {
                          hasPermission(EDIT_WINDSHIELD_REPAIR_THRESHOLDS) && (
                            <div className="row">
                              <div className="col-sm-4">
                                <Field
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="windshieldRepairConfidenceThreshold"
                                  label="Repairability threshold"
                                  touched={touched.windshieldRepairConfidenceThreshold}
                                  errors={errors.windshieldRepairConfidenceThreshold}
                                  disabled={repairDecisionType !== REPAIR_DECISION_TYPE.WINDSHIELD}
                                  // eslint-disable-next-line max-len
                                  tooltip="Enter value between 0-100. If repair decision confidence below value then 'Repair confidence below threshold' verification error will be added."
                                />
                              </div>
                            </div>
                          )
                        }
                        <div className="row">
                          <div className="col-sm-12">
                            <hr />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 sub-section">
                            <span className="sub-section-header">GEOLOCATION</span>
                            <Tooltip>
                              SmartScan enables you to save the location of the user when they capture pictures.
                            </Tooltip>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="geolocationLocationTracking" className="mb-0">
                                Location tracking
                              </label>
                              <select
                                className="form-control"
                                value={geolocationLocationTracking}
                                onChange={(e) => {
                                  setGeolocationLocationTracking(Number(e.target.value));

                                  setFieldValue(
                                    'trackGeolocationEnabled',
                                    Number(e.target.value) === GEOLOCATION_LOCATION_TRACKING.HIDDEN
                                    || Number(e.target.value) === GEOLOCATION_LOCATION_TRACKING.BLOCKER
                                  );
                                  setFieldValue('trackGeolocationRequired', Number(e.target.value) === GEOLOCATION_LOCATION_TRACKING.BLOCKER);

                                  // Reset other fields
                                  if (Number(e.target.value) === GEOLOCATION_LOCATION_TRACKING.NONE
                                      || Number(e.target.value) === GEOLOCATION_LOCATION_TRACKING.HIDDEN) {
                                    setFieldValue('expectedCountryId', '');
                                    setFieldValue('expectedCountryGeolocationRequired', false);
                                  }
                                }}
                                name="geolocationLocationTracking"
                                id="geolocationLocationTracking"
                              >
                                <option value={GEOLOCATION_LOCATION_TRACKING.NONE}>
                                  Off
                                </option>
                                <option value={GEOLOCATION_LOCATION_TRACKING.HIDDEN}>
                                  {GEOLOCATION_LOCATION_TRACKING_NAME[GEOLOCATION_LOCATION_TRACKING.HIDDEN]}
                                </option>
                                <option value={GEOLOCATION_LOCATION_TRACKING.BLOCKER}>
                                  {GEOLOCATION_LOCATION_TRACKING_NAME[GEOLOCATION_LOCATION_TRACKING.BLOCKER]}
                                </option>
                              </select>
                            </div>
                          </div>
                          {
                            customerConfiguration?.expectedCountryValidationEnabled && (
                              <>
                                <div className="col-sm-4">
                                  <div className="form-group">
                                    <label htmlFor="expectedCountryId" className="mb-0">
                                      Service available in
                                    </label>
                                    <select
                                      className="form-control"
                                      value={values.expectedCountryId}
                                      onChange={(event) => {
                                        setFieldValue('expectedCountryId', event.target.value);
                                        setFieldValue('expectedCountryGeolocationRequired', event.target.value !== '');
                                      }}
                                      name="expectedCountryId"
                                      id="expectedCountryId"
                                      disabled={geolocationLocationTracking !== GEOLOCATION_LOCATION_TRACKING.BLOCKER}
                                    >
                                      <option key="defaultExpectedCountryId" value="">All countries</option>
                                      {
                                        countries.map((country) => <option key={country.id} value={country.id}>{country.name}</option>)
                                      }
                                    </select>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <hr />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 sub-section">
                            <span className="sub-section-header">CAPTURING TIME</span>
                            <Tooltip>
                              We can track how much time did user take between the images. Those features help you
                              detect suspicious activity of the user.
                            </Tooltip>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-sm-4">
                            <Checkbox
                              id="timeLimitValidationEnabled"
                              label="Time limit"
                              name="timeLimitValidationEnabled"
                              checked={values.timeLimitValidationEnabled}
                              onClickHandler={() => {
                                setFieldValue('timeLimitValidationEnabled', !values.timeLimitValidationEnabled);
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <hr />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 sub-section">
                            <span className="sub-section-header">SMARTSCAN SETTINGS</span>
                            <Tooltip>
                              Those settings change the way how users interact with photo capturing application in the end of the process.
                            </Tooltip>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-sm-4">
                            <Checkbox
                              id="smartScanImageDownloadEnabled"
                              label="Image download"
                              name="smartScanImageDownloadEnabled"
                              checked={values.smartScanImageDownloadEnabled}
                              onClickHandler={() => {
                                setFieldValue('smartScanImageDownloadEnabled', !values.smartScanImageDownloadEnabled);
                              }}
                            />
                          </div>
                          {
                            hasPermission(EDIT_WORKFLOW_USER_FEEDBACK_SETTINGS) && (
                              <>
                                <div className="col-sm-4 checkbox">
                                  <Checkbox
                                    id="userFeedbackSettings.satisfactionRatingEnabled"
                                    label="Customer rating"
                                    name="userFeedbackSettings.satisfactionRatingEnabled"
                                    checked={values.userFeedbackSettings.satisfactionRatingEnabled}
                                    onClickHandler={() => {
                                      setFieldValue(
                                        'userFeedbackSettings.satisfactionRatingEnabled',
                                        !values.userFeedbackSettings.satisfactionRatingEnabled
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-sm-4 checkbox">
                                  <Checkbox
                                    id="userFeedbackSettings.commentEnabled"
                                    label="Customer feedback"
                                    name="userFeedbackSettings.commentEnabled"
                                    checked={values.userFeedbackSettings.commentEnabled}
                                    onClickHandler={() => {
                                      setFieldValue('userFeedbackSettings.commentEnabled', !values.userFeedbackSettings.commentEnabled);
                                    }}
                                  />
                                </div>
                              </>
                            )
                          }
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-sm-12 pt-3 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary mr-1"
                      disabled={isSubmitting}
                      onClick={() => {
                        onHideHandler(hasUnsavedChanges, hideModal, setShowConfirmModal);
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={isSavingDisabled || isSubmitting}>
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

WorkflowModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  workflow: PropTypes.object,
  isSavingDisabled: PropTypes.bool,
};

export default WorkflowModal;
