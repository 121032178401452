import constants from './constants';

const prefix = `drivex/${constants.NAME}`;

const LOAD_COMMENTS_SUCCESS = `${prefix}/LOAD_COMMENTS_SUCCESS`;
const CREATE_COMMENT_SUCCESS = `${prefix}/CREATE_COMMENT_SUCCESS`;

export default {
  LOAD_COMMENTS_SUCCESS,
  CREATE_COMMENT_SUCCESS,
};
