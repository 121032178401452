import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { PhotoSeriesImages, PublicAccessLink, SkeletonUI } from '../../../../../../components';
import { actions as photoSeriesActions, constants as photoSeriesConstants } from '../../../../../../redux/photoSeries';
import { actions as imagesActions, selectors as imagesSelectors } from '../../../../../../redux/images';
import { actions as damagesActions } from '../../../../../../redux/damages';
import { makeGet, makePost } from '../../../../../../api/ajax';
import { composeApiUrl } from '../../../../../../api/composeApiUrl';
import { trackEvent, EVENT_TYPES } from '../../../../../../api/analytics';
import { LANG_PARAM, ENGLISH_CODE } from '../../../../../../utils/translationsConstants';
import {
  selectors as customerConfigurationsSelectors,
  actions as customerConfigurationsActions
} from '../../../../../../redux/customerConfigurations';

const downloadFile = (photoSeriesId, fileEntityType) => {
  makeGet(`files/${photoSeriesId}/token?entityType=${fileEntityType}`).subscribe(({ response }) => {
    window.location = composeApiUrl(`files/${photoSeriesId}/download?token=${response.token}`);
  });
};

const PhotoSeriesImagesSection = ({ photoSeries, permissions }) => {
  const [isLoadingPhotoSeriesImages, setIsLoadingPhotoSeriesImages] = useState(false);
  const [isLoadingConfirmPhotoSeries, setIsLoadingConfirmPhotoSeries] = useState(false);
  const [showRequestAdditonalImagesModal, setShowRequestAdditonalImagesModal] = useState(false);
  const [additionalImagesPublicLink, setAdditionalImagesPublicLink] = useState(null);

  const { photoSeriesId } = useParams();
  const dispatch = useDispatch();

  const images = useSelector(imagesSelectors.selectImages);
  const photoSeriesImages = images && images[photoSeriesId];
  const customerConfiguration = useSelector(customerConfigurationsSelectors.selectCustomerConfiguration);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && !photoSeriesImages) {
      setIsLoadingPhotoSeriesImages(true);
      makeGet(`images?photoSeriesId=${photoSeriesId}&pageSize=50&sortColumn=CreatedAt&sortDirection=asc`).subscribe(
        ({ response }) => {
          setIsLoadingPhotoSeriesImages(false);
          dispatch(imagesActions.loadImagesSuccess(photoSeriesId, response.results));
          dispatch(damagesActions.loadImageDamagesSuccess(photoSeriesId, response.results));
        },
        () => setIsLoadingPhotoSeriesImages(false),
      );
    }

    const photoSeriesIsShared = photoSeries && photoSeries.sharedWithCustomerIds && photoSeries.sharedWithCustomerIds.length > 0;
    if (isMounted && photoSeriesIsShared && !customerConfiguration) {
      makeGet('customerConfigurations/current').subscribe(({ response }) => {
        dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(response));
      });
    }

    return () => {
      isMounted = false;
    };
  }, [photoSeriesId, photoSeries, customerConfiguration, photoSeriesImages, dispatch]);

  const confirmPhotoSeries = (value) => {
    setIsLoadingConfirmPhotoSeries(true);
    makePost(`photoSeries/${photoSeriesId}/confirm`, { confirmationType: value }).subscribe(
      ({ response }) => {
        setIsLoadingConfirmPhotoSeries(false);
        dispatch(photoSeriesActions.loadPhotoSeriesSuccess(photoSeriesId, response));

        // Track event in Analytics
        const data = {
          confirmationType: value === photoSeriesConstants.CONFIRMATION_TYPE.ACCEPTED
            ? 'Accept'
            : 'Decline',
        };

        trackEvent(EVENT_TYPES.PHOTO_SERIES_CONFIRMED, data);
      },
      () => setIsLoadingConfirmPhotoSeries(false),
    );
  };

  const handleRequestAdditonalImages = () => {
    setShowRequestAdditonalImagesModal(true);

    if (!additionalImagesPublicLink) {
      const lang = photoSeries?.reportStatus?.reportLanguage || ENGLISH_CODE;
      const params = {
        photoSeriesId,
        requestAdditionalImages: true
      };
      makePost('accessUrls', params).subscribe(
        ({ response }) => {
          const publicAccessUrl = `${response.url}&${LANG_PARAM}=${lang}`;
          setAdditionalImagesPublicLink(publicAccessUrl);
        },
      );
    }
  };

  if (!photoSeries) return null;

  const showRequestAdditionalImages = !!(photoSeries
  && (!photoSeries.sharedWithCustomerIds
    || photoSeries.sharedWithCustomerIds.length === 0
    || !photoSeries.sharedWithCustomerIds.includes(customerConfiguration?.customerId)
  )
  && !photoSeries.purgedAt
  && photoSeries.completedAt);

  const PhotoSeriesImagesProps = {
    photoSeries,
    permissions,
    isLoadingPhotoSeriesImages,
    isLoadingConfirmPhotoSeries,
    confirmPhotoSeries,
    downloadFile,
    photoSeriesImages,
    onRequestAdditonalImages: handleRequestAdditonalImages,
    showRequestAdditionalImages
  };

  return (
    <>
      <PhotoSeriesImages {...PhotoSeriesImagesProps} />
      <Modal
        size="md"
        backdrop="static"
        show={showRequestAdditonalImagesModal}
        onHide={() => setShowRequestAdditonalImagesModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <h5>Request additional images</h5>
        </Modal.Header>
        <Modal.Body>
          {
            additionalImagesPublicLink
              ? <PublicAccessLink link={additionalImagesPublicLink} />
              : <SkeletonUI type="radius-horizontal" height="10rem" width="100%" />
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

PhotoSeriesImagesSection.propTypes = {
  photoSeries: PropTypes.object,
  permissions: PropTypes.shape({
    canConfirmPhotoSeries: PropTypes.bool,
    canViewDamages: PropTypes.bool
  }).isRequired,
};
export default PhotoSeriesImagesSection;
