import { createSelector } from 'reselect';

import constants from './constants';

const selectWindshieldRepairabilityProfilesState = (state) => state[constants.NAME];

const selectWindshieldRepairabilityProfiles = createSelector(
  selectWindshieldRepairabilityProfilesState,
  ({ windshieldRepairabilityProfilesPagedResults }) => windshieldRepairabilityProfilesPagedResults
);

export default {
  selectWindshieldRepairabilityProfiles
};
