import { normalize } from 'normalizr';

import types from './types';
import constants from './constants';

import { commentListSchema } from './schema';

import sessionTypes from '../session/types';

const defaultState = {};

const commentsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_COMMENTS_SUCCESS: {
      const {
        comments: {
          results, page, pageCount, pageSize, rowCount,
        },
        imageId,
      } = action.payload;

      const replace = page === 1;
      const normalizedComments = normalize(results, commentListSchema);

      if (replace) {
        return {
          ...state,
          [imageId]: {
            page,
            pageCount,
            pageSize,
            rowCount,
            entities: normalizedComments.entities.comments,
            order: normalizedComments.result,
          },
        };
      }

      return {
        ...state,
        [imageId]: {
          ...state[imageId],
          page,
          pageCount,
          pageSize,
          rowCount,
          entities: {
            ...(state[imageId] && state[imageId].entities),
            ...normalizedComments.entities.comments,
          },

          order:
            state[imageId]?.order?.length > 0
              ? [...state[imageId].order, ...normalizedComments.result]
              : normalizedComments.result,
        },
      };
    }

    case types.CREATE_COMMENT_SUCCESS: {
      const { comment } = action.payload;

      const { imageId } = comment;

      if (state[imageId]) {
      // Previous comments loaded
        return {
          ...state,
          [imageId]: {
            ...state[imageId],
            rowCount: state[imageId].rowCount + 1,
            entities: {
              ...state[imageId].entities,
              [comment.id]: comment,
            },
            order: [
              comment.id,
              ...state[imageId].order,
            ],
          },
        };
      }

      return {
        ...state,
        [imageId]: {
          page: constants.DEFAULT_SORT.page,
          pageCount: 1,
          pageSize: constants.DEFAULT_SORT.pageSize,
          rowCount: 1,
          entities: {
            [comment.id]: comment,
          },
          order: [comment.id],
        },
      };
    }

    case sessionTypes.LOG_OUT: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default commentsReducer;
