import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import windshield from '../../../../assets/img/windshield.jpeg';
import { constants } from '../../../../redux/windshieldRepairabilityProfiles';

// Chip locations
const chipLocationAtLeft = { x: 200, y: 200 };
const chipLocationAtRight = { x: 450, y: 200 };

// Windshield dimensions in cm
const topWidthCm = 105;
const bottomWidthCm = 145;
const heightCm = 98;

// Windshield outline
const windshieldOutlineTopLeftCorner = { x: 160, y: 65 };
const windshieldOutlineTopRightCorner = { x: 500, y: 65 };
const windshieldOutlineBottomRightCorner = { x: 610, y: 270 };
const windshieldOutlineBottomLeftCorner = { x: 40, y: 270 };

const windshieldOutline = [
  windshieldOutlineTopLeftCorner,
  windshieldOutlineTopRightCorner,
  { x: 620, y: 260 },
  windshieldOutlineBottomRightCorner,
  { x: 590, y: 280 },
  { x: 550, y: 290 },
  { x: 480, y: 300 },
  { x: 340, y: 308 },
  { x: 200, y: 305 },
  { x: 130, y: 300 },
  { x: 75, y: 290 },
  { x: 50, y: 280 },
  windshieldOutlineBottomLeftCorner,
  { x: 160, y: 65 },
  windshieldOutlineTopLeftCorner
];

const windshieldTopWidth = windshieldOutlineTopRightCorner.x - windshieldOutlineTopLeftCorner.x;
const windshieldBottomWidth = windshieldOutlineBottomRightCorner.x - windshieldOutlineBottomLeftCorner.x;
const windshieldHeight = Math.max(...windshieldOutline.map((p) => p.y)) - Math.min(...windshieldOutline.map((p) => p.y));

const windshieldOutlineTopCenter = {
  x: windshieldOutlineTopLeftCorner.x + (windshieldTopWidth / 2),
  y: Math.min(...windshieldOutline.map((p) => p.y))
};

const calculateRepairArea = (points, repairAreaCm) => {
  const topHorizontalPxPerCm = windshieldTopWidth / topWidthCm;
  const bottomHorizontalPxPerCm = windshieldBottomWidth / bottomWidthCm;
  const heightPxPerCm = windshieldHeight / heightCm;

  // Calculate the vertical distortion on the image
  // Each cm in top line contains less pixels than in the bottom line (top is further from camera than bottom - visual perspective)
  const horizontalPxPerCmDiff = Math.abs(bottomHorizontalPxPerCm - topHorizontalPxPerCm) / 2;

  // Get number of vertical pixels per 1 cm near the top line
  const topVerticalPxPerCm = Math.abs(heightPxPerCm - horizontalPxPerCmDiff);

  // Get number of vertical pixels per 1 cm near the bottom line
  const bottomVerticalPxPerCm = heightPxPerCm + horizontalPxPerCmDiff;
  const shrinkTopPx = topVerticalPxPerCm * repairAreaCm;
  const shrinkBottomPx = bottomVerticalPxPerCm * repairAreaCm;
  const horizontalPxPerCm = (topHorizontalPxPerCm + bottomHorizontalPxPerCm) / 2;
  const shrinkSidesPx = horizontalPxPerCm * repairAreaCm;

  return resizePolygon(points, shrinkTopPx, shrinkBottomPx, shrinkSidesPx);
};

const resizePolygon = (points, shrinkTop, shrinkBottom, shrinkSides) => {
  if (!points || points.length === 0) {
    throw new Error('The points array must not be null or empty.');
  }

  // Calculate the bounding box of the polygon
  const minX = Math.min(...points.map((p) => p.x));
  const maxX = Math.max(...points.map((p) => p.x));
  const minY = Math.min(...points.map((p) => p.y));
  const maxY = Math.max(...points.map((p) => p.y));

  // Calculate the center of the polygon
  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;

  // Calculate new boundaries after shrinking
  const newMinX = minX + shrinkSides;
  const newMaxX = maxX - shrinkSides;
  const newMinY = minY + shrinkTop;
  const newMaxY = maxY - shrinkBottom;

  if (newMinX > newMaxX || newMinY > newMaxY) {
    throw new Error('The shrink values are too large, resulting in invalid dimensions.');
  }

  // Calculate scaling factors
  const scaleX = (newMaxX - newMinX) / (maxX - minX);
  const scaleY = (newMaxY - newMinY) / (maxY - minY);

  // Create the new array of points with adjusted coordinates
  const newPoints = points.map((p) => {
    const newX = Math.round(centerX + (p.x - centerX) * scaleX);
    const newY = Math.round(centerY + (p.y - centerY) * scaleY);
    return { x: newX, y: newY };
  });

  return newPoints;
};

const convertToPoints = (points) => points.map((p) => `${p.x},${p.y}`).join(' ');

const calculatePointsFromPercent = (fovPoints, originPoint) => fovPoints.map((p) => {
  const width = p.y <= 50 ? windshieldTopWidth : windshieldBottomWidth;
  return {
    x: originPoint.x + getPercentage(width, p.x),
    y: originPoint.y + getPercentage(windshieldHeight, p.y)
  };
});

const getPercentage = (valueFrom, percentage) => (valueFrom / 100) * percentage;

const calculatePercentFromPoints = (realPoints, originPoint) => realPoints.map((p) => {
  const relativeY = p.y - originPoint.y;
  const relativeX = p.x - originPoint.x;
  const percentageY = Math.round(getPercentageOf(windshieldHeight, relativeY));
  const width = percentageY <= 50 ? windshieldTopWidth : windshieldBottomWidth;
  const percentageX = Math.round(getPercentageOf(width, relativeX));

  return {
    x: percentageX,
    y: percentageY
  };
});

const getPercentageOf = (total, value) => (value / total) * 100;
const calculateDriverFovPoints = (fovPoints, driverFieldOfView) => calculatePointsFromPercent(
  fovPoints, getDriverFieldOfViewOriginPoint(driverFieldOfView)
);

const getDriverFieldOfViewOriginPoint = (driverFieldOfView) => {
  if (driverFieldOfView === constants.DRIVER_FIELD_OF_VIEW.LEFT) {
    return windshieldOutlineTopRightCorner;
  }

  if (driverFieldOfView === constants.DRIVER_FIELD_OF_VIEW.RIGHT) {
    return windshieldOutlineTopLeftCorner;
  }

  return windshieldOutlineTopCenter;
};

const WindshieldRepairabilityProfileAreas = ({
  repairAreaCm, safetyAreaAroundTheChipInCm, adasFieldOfViewMask, driverFieldOfViewMask,
  driverFieldOfView, setAdasFieldOfViewMask, setDriverFieldOfViewMask
}) => {
  const windshieldOutlinePoints = convertToPoints(windshieldOutline);
  const repairAreaOutlinePoints = convertToPoints(calculateRepairArea(windshieldOutline, repairAreaCm));

  // Lets not over engineer this into px per cm for now
  const chipRadius = 5;
  const chipSafetyRadius = chipRadius + safetyAreaAroundTheChipInCm;
  const chipLocation = driverFieldOfView === constants.DRIVER_FIELD_OF_VIEW.CENTER || driverFieldOfView === constants.DRIVER_FIELD_OF_VIEW.LEFT
    ? chipLocationAtLeft
    : chipLocationAtRight;

  // Field of views
  const [fovs, setFovs] = useState([
    calculatePointsFromPercent(adasFieldOfViewMask, windshieldOutlineTopCenter),
    calculateDriverFovPoints(driverFieldOfViewMask, driverFieldOfView)
  ]);

  useEffect(() => {
    setFovs([
      calculatePointsFromPercent(adasFieldOfViewMask, windshieldOutlineTopCenter),
      calculateDriverFovPoints(driverFieldOfViewMask, driverFieldOfView)
    ]);
  }, [adasFieldOfViewMask, driverFieldOfViewMask, driverFieldOfView]);

  // Dragging functionality
  const [dragging, setDragging] = useState({ fovIndex: null, pointIndex: null });

  const handleOnMouseUp = () => {
    setDragging({ fovIndex: null, pointIndex: null });
  };

  const handleOnMouseDown = (fovIndex, pointIndex) => {
    setDragging({ fovIndex, pointIndex });
  };

  const handleOnMouseMove = (e) => {
    const { fovIndex, pointIndex } = dragging;
    if (fovIndex !== null && pointIndex !== null) {
      const svg = e.target.ownerSVGElement || e.target;
      const svgRect = svg.getBoundingClientRect();

      const mouseX = e.clientX - svgRect.left;
      const mouseY = e.clientY - svgRect.top;

      const newFovs = [...fovs];
      const newFov = [...newFovs[fovIndex]];
      newFov[pointIndex] = { x: mouseX, y: mouseY };
      newFovs[fovIndex] = newFov;

      setFovs(newFovs);
      setAdasFieldOfViewMask(calculatePercentFromPoints(newFovs[0], windshieldOutlineTopCenter));
      setDriverFieldOfViewMask(calculatePercentFromPoints(newFovs[1], getDriverFieldOfViewOriginPoint(driverFieldOfView)));
    }
  };

  return (
    <svg
      width="667"
      height="375"
      viewBox="0 0 667 375"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseMove={handleOnMouseMove}
      onMouseUp={handleOnMouseUp}
      onMouseLeave={handleOnMouseUp}
    >
      <image
        width="667"
        height="375"
        href={windshield}
      />
      { /* Space between windshield outline and repair area outline */ }
      <g>
        <defs>
          <mask id="polygon-mask">
            <polygon points={windshieldOutlinePoints} fill="white" />
            <polygon points={repairAreaOutlinePoints} fill="black" />
          </mask>
        </defs>

        {/* Apply the red background with the transparent cut-out area */}
        <rect width="100%" height="100%" fill="rgba(255, 0, 0, 0.5)" mask="url(#polygon-mask)" />

        {/* Outer polygon with stroke */}
        <polygon
          points={windshieldOutlinePoints}
          stroke="red"
          strokeWidth="2"
          fill="none"
        />

        {/* Inner polygon with stroke */}
        <polygon
          points={repairAreaOutlinePoints}
          stroke="red"
          strokeWidth="2"
          fill="none"
        />
      </g>

      {/* Safety area around the chip */ }
      <g>
        <defs>
          <mask id="circle-mask">
            {/* Outer circle fully visible (white) */}
            <circle cx={chipLocation.x} cy={chipLocation.y} r={chipSafetyRadius} fill="white" />
            {/* Inner circle cut-out area (transparent) */}
            <circle cx={chipLocation.x} cy={chipLocation.y} r={chipRadius} fill="black" />
          </mask>
        </defs>

        {/* Apply the red background with the transparent cut-out area */}
        <rect width="100%" height="100%" fill="rgba(255, 0, 0, 0.5)" mask="url(#circle-mask)" />

        {/* Outer circle with stroke */}
        <circle
          cx={chipLocation.x}
          cy={chipLocation.y}
          r={chipSafetyRadius}
          stroke="red"
          strokeWidth="2"
          fill="none"
        />

        {/* Inner circle with stroke */}
        <circle
          cx={chipLocation.x}
          cy={chipLocation.y}
          r={chipRadius}
          stroke="red"
          strokeWidth="2"
          fill="none"
        />
      </g>

      {/* Fovs */ }
      {
        fovs.map((fovPoints, fovIndex) => (
          <g key={fovIndex}>
            { /* Fov */ }
            <polygon
              points={convertToPoints(fovPoints)}
              stroke="red"
              strokeWidth="2"
              fill="rgba(255, 0, 0, 0.5)"
            />

            { /* Draggable points */ }
            {
              fovPoints.map((p, pointIndex) => (
                <circle
                  key={pointIndex}
                  cx={p.x}
                  cy={p.y}
                  r="4"
                  fill="white"
                  onMouseDown={() => handleOnMouseDown(fovIndex, pointIndex)}
                />
              ))
            }
          </g>
        ))
      }
    </svg>
  );
};

WindshieldRepairabilityProfileAreas.propTypes = {
  repairAreaCm: PropTypes.number.isRequired,
  safetyAreaAroundTheChipInCm: PropTypes.number.isRequired,
  adasFieldOfViewMask: PropTypes.array.isRequired,
  driverFieldOfViewMask: PropTypes.array.isRequired,
  setAdasFieldOfViewMask: PropTypes.func.isRequired,
  setDriverFieldOfViewMask: PropTypes.func.isRequired,
  driverFieldOfView: PropTypes.number
};

export default WindshieldRepairabilityProfileAreas;
