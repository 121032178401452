import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Modal, renderShowConfirmationModal, onHideHandler } from '../../../../../../components';
import { Field } from '../../../../../../components/Form';
import { useBeforeUnload } from '../../../../../../hooks/useBeforeUnload/useBeforeUnload';
import { actions as photoSeriesActions } from '../../../../../../redux/photoSeries';
import { makePatch } from '../../../../../../api/ajax';

const ExternalIdModal = ({ onModalClose, photoSeries, ...props }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dispatch = useDispatch();

  useBeforeUnload(hasUnsavedChanges);

  const initialValues = {
    externalId:
      (photoSeries && photoSeries.externalId && photoSeries.externalId.match(/^-$/)
        ? photoSeries?.externalId.replace('-', '')
        : photoSeries?.externalId) || '',
  };

  const handleSubmit = (data, setStatus, setShowSuccessMessage) => {
    const message = data.externalId === '' ? 'removed' : 'changed';

    makePatch(`photoSeries/${photoSeries.id}`, data).subscribe(
      ({ response }) => {
        dispatch(photoSeriesActions.loadPhotoSeriesSuccess(photoSeries.id, response));
        setShowSuccessMessage(`External id has been ${message}!`);
        setHasUnsavedChanges(false);
      },
      ({ response: { error } }) => {
        setStatus(error);
        setHasUnsavedChanges(false);
      },
    );
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, () => {
        onModalClose();
        setShowConfirmModal(false);
        setHasUnsavedChanges(false);
      })}
      <Modal
        size="md"
        onHide={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
        headerText="Change external identifier"
        backdrop="static"
        {...props}
      >
        <Modal.Body>
          <div className="row">
            <div className="col">
              {showSuccessMessage && (
                <Alert variant="success" dismissible onClick={() => setShowSuccessMessage(null)}>
                  {showSuccessMessage}
                </Alert>
              )}
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              externalId: Yup.string(),
            })}
            onSubmit={({ externalId }, { setStatus, setFieldValue }) => handleSubmit({ externalId }, setStatus, setShowSuccessMessage, setFieldValue)}
            validateOnChange={false}
          >
            {({ values, errors, status, touched, setFieldValue }) => (
              <Form
                onChange={({ target }) => {
                  if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                    setHasUnsavedChanges(true);
                  }
                }}
              >
                <div className="row">
                  <div className="col-10 col-md-7">
                    <Field type="text" name="externalId" touched={touched.externalId} errors={errors.externalId || status} />
                  </div>
                  <div className="col-4 col-md-5 text-right">
                    <button type="submit" onClick={() => setFieldValue('externalId', '')} className="btn btn-secondary mr-1">
                      Remove
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={!values.externalId}>
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

ExternalIdModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  photoSeries: PropTypes.object,
};

export default ExternalIdModal;
