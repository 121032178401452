const KEY_SEPARATOR = '__';

export const getFormKeyParts = (formKey) => {
  const parts = formKey.split(KEY_SEPARATOR);
  return { translationId: parts[0], translationKey: parts[1] };
};

export const composeFormKey = (translationsId, translationsKey) => `${translationsId}${KEY_SEPARATOR}${translationsKey}`;

export const CAROUSEL_PROCESS_MODAL = 0;
export const CAROUSEL_CAPTURING_VIEW = 1;
export const CAPTURE_MODE_KEY = 'capturing_helper_text';
export const INSTRUCTIONS_TEXT_KEY = 'instructions_text';
