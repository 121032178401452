import { createSelector } from 'reselect';

import constants from './constants';

const selectImagesState = (state) => state[constants.NAME];
const selectImageLocalUrls = createSelector([selectImagesState], (images) => images.imageLocalUrls);
const selectImages = createSelector([selectImagesState], (images) => images.images);

export default {
  selectImageLocalUrls,
  selectImages,
};
