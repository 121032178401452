import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import _ from 'lodash';
import * as queryString from 'querystring';
import JwtDecode from 'jwt-decode';
import ReCAPTCHA from 'react-google-recaptcha';

import { actions as sessionActions, constants as sessionConstants } from '../../../redux/session';
import { PageTitle } from '../../../components';
import { EmptyPage } from '../../../layouts';
import { makePost } from '../../../api/ajax';
import { getSearchParam } from '../../../utils/url';
import { routes } from '../../../routes/Routes';

import authenticationService from '../../../services/authentication.service';

const ConfirmPasswordReset = () => {
  const history = useHistory();
  const location = useLocation();

  const { search } = location;
  const userId = getSearchParam(search, 'userId');
  const accessToken = getSearchParam(search, 'accessToken');

  const handleRecaptchaChange = (captchaToken) => {
    const query = queryString.parse(location.search.substring(1));
    const decodedJwt = JwtDecode(query.accessToken);
    const isTokenExpired = new Date(decodedJwt.exp * 1000) < Date.now();

    if (isTokenExpired) {
      history.replace(`${routes.login}?passwordReset=false`);
    } else if (!isTokenExpired) {
      makePost(`session/${userId}`, {
        token: accessToken,
        loginType: sessionConstants.ONE_TIME_LOGIN_TYPE.PASSWORD_RESET_CONFIRMATION,
        captchaToken,
      }).subscribe(
        ({ response }) => {
          authenticationService.storeUserData(response);

          history.push(`${routes.home}`);
        },
        () => {
          history.replace(`${routes.login}?passwordReset=false`);
        },
      );
    }
  };

  useEffect(() => {
    let isMounted = true;
    const { currentUserValue } = authenticationService;

    if (isMounted && currentUserValue && !_.isEmpty(currentUserValue)) {
      authenticationService.logout();

      sessionActions.logOut();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <EmptyPage currentRoute={routes.account}>
      <PageTitle>
        <PageTitle.Left headingText="Change your password" />
      </PageTitle>
      <div className="jumbotron">
        <p>Check the box to continue</p>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleRecaptchaChange}
        />
      </div>
    </EmptyPage>
  );
};

export default ConfirmPasswordReset;
