import types from './types';

const loadImageSuccess = (imageId, data) => ({
  type: types.LOAD_IMAGE_SUCCESS,
  payload: {
    imageId,
    data,
  },
});

const loadImagesSuccess = (photoSeriesId, images) => ({
  type: types.LOAD_IMAGES_SUCCESS,
  payload: {
    photoSeriesId,
    images,
  },
});

const clearImage = (imageId) => ({
  type: types.CLEAR_IMAGE,
  payload: {
    imageId
  },
});

export default {
  loadImageSuccess,
  loadImagesSuccess,
  clearImage
};
