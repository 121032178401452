import { normalize } from 'normalizr';

import types from './types';
import { vehicleListSchema, photoSeriesListSchema } from './schema';

import sessionTypes from '../session/types';

const defaultState = {};

const vehiclesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SEARCH_VEHICLES_SUCCESS:
      const {
        vehiclesPagedResult: {
          results,
          page,
          pageCount,
          pageSize,
          rowCount,
        },
        vehiclesFilter,
      } = action.payload;

      const normalizedVehicles = normalize(results, vehicleListSchema);

      return {
        ...state,
        pagedResult: {
          ...state.pagedResult,
          page,
          pageCount,
          pageSize,
          rowCount,
          loaded: true,
          filter: {
            ...vehiclesFilter,
          },
          entities: {
            ...(state.pagedResult && state.pagedResult?.entities),
            ...normalizedVehicles.entities.vehicles,
          },
          order: normalizedVehicles.result,
        },
      };
    case types.LOAD_VEHICLE_PHOTOSERIES_SUCCESS: {
      const { vehicleId } = action.payload;
      const {
        results, page, pageCount, pageSize, rowCount
      } = action.payload.vehiclePhotoSeriesPagedResult;

      const normalizedPhotoSeries = normalize(results, photoSeriesListSchema);

      const replace = page === 1;

      if (replace) {
        return {
          ...state,
          vehiclePhotoSeries: {
            ...state.vehiclePhotoSeries,
            [vehicleId]: {
              page,
              pageCount,
              pageSize,
              rowCount,
              entities: normalizedPhotoSeries.entities.vehiclePhotoSeries,
              order: normalizedPhotoSeries.result,
            }
          },
        };
      }

      return {
        ...state,
        vehiclePhotoSeries: {
          ...state.vehiclePhotoSeries,
          [vehicleId]: {
            ...state.vehiclePhotoSeries[vehicleId],
            page,
            pageCount,
            pageSize,
            rowCount,
            entities: {
              ...state.vehiclePhotoSeries[vehicleId]?.entities,
              ...normalizedPhotoSeries.entities.vehiclePhotoSeries,
            },
            order: state.vehiclePhotoSeries[vehicleId]?.order?.length > 0
              ? [...state.vehiclePhotoSeries[vehicleId].order, ...normalizedPhotoSeries.result]
              : normalizedPhotoSeries.result,
          }

        },

      };
    }
    case types.SET_PAGED_RESULT_LOADED: {
      const { loaded } = action.payload;

      return {
        ...state,
        pagedResult: {
          ...state.pagedResult,
          loaded,
        },
      };
    }
    case sessionTypes.LOG_OUT:
      return defaultState;
    default:
      return state;
  }
};

export default vehiclesReducer;
