import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { drivexStorage } from '../../hooks/useLocalStorage/useLocalStorage';
import { routes } from '../../routes/Routes';
import { makeGet } from '../../api/ajax';

import authenticationService from '../../services/authentication.service';

const PublicAccess = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { accessUrlId } = useParams();
  const { replace } = useHistory();

  useEffect(() => {
    if (accessUrlId) {
      makeGet(`session/${accessUrlId}`).subscribe(
        ({ response }) => {
          const {
            photoSeries: {
              id,
              vehicle: { id: vehicleId },
            },
          } = response;

          authenticationService.storeUserData(response);
          authenticationService.storePublicUserView(`/vehicles/${vehicleId}/photoSeries/${id}`);

          replace(`/vehicles/${vehicleId}/photoSeries/${id}`);
        },
        (error) => {
          drivexStorage.setItem('error', {
            isError: true,
            message: 'You are using an expired or invalid link',
            fileName: undefined,
            lineNo: undefined,
            colNo: undefined,
            errorObject: error,
            details: [error.request.url],
          });

          setShouldRedirect(true);
        },
      );
    }
  }, [replace, accessUrlId]);

  return shouldRedirect && <Redirect to={routes.login} />;
};

export default PublicAccess;
