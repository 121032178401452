const apiUrl = process.env.REACT_APP_API_URL;

const thumbsApiUrl = process.env.REACT_APP_THUMBNAIL_API_URL;
const thumbsApiCode = process.env.REACT_APP_THUMBNAIL_API_CODE;

const locationApiUrl = 'https://www.google.com/maps/search/?api=1&query=';

export const composeApiUrl = (url) => `${apiUrl}${url}`;

export const composeThumbsApiUrl = (queryString) => `${thumbsApiUrl}?code=${thumbsApiCode}&${queryString}`;

export const composeLocationApiUrl = (location) => (location && `${locationApiUrl}${location.latitude},${location.longitude}`);
