import types from './types';

const loadWindshieldRepairabilityProfilesSuccess = (windshieldRepairabilityProfilesPagedResults) => ({
  type: types.LOAD_WINDSHIELD_REPAIRABILITY_PROFILES_SUCCESS,
  payload: {
    windshieldRepairabilityProfilesPagedResults
  }
});

const addWindshieldRepairabilityProfileSuccess = (windshieldRepairabilityProfile) => ({
  type: types.ADD_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS,
  payload: {
    windshieldRepairabilityProfile,
  }
});

const deleteWindshieldRepairabilityProfileSuccess = (id) => ({
  type: types.DELETE_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS,
  payload: {
    id
  }
});

const editWindshieldRepairabilityProfileSiccess = (windshieldRepairabilityProfile) => ({
  type: types.EDIT_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS,
  payload: {
    updatedWindshieldRepairabilityProfile: windshieldRepairabilityProfile
  }
});

export default {
  loadWindshieldRepairabilityProfilesSuccess,
  addWindshieldRepairabilityProfileSuccess,
  deleteWindshieldRepairabilityProfileSuccess,
  editWindshieldRepairabilityProfileSiccess
};
