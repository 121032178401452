import types from './types';

const loadCustomImageTypesSuccess = (customImageTypePagedResults) => ({
  type: types.LOAD_CUSTOM_IMAGE_TYPES_SUCCESS,
  payload: {
    customImageTypePagedResults
  }
});

const addCustomImageTypeSuccess = (newCustomImageType) => ({
  type: types.ADD_CUSTOM_IMAGE_TYPE_SUCCESS,
  payload: {
    newCustomImageType,
  }
});

const editCustomImageTypeSuccess = (customImageTypes) => ({
  type: types.EDIT_CUSTOM_IMAGE_TYPE_SUCCESS,
  payload: {
    customImageTypes
  }
});

const deleteCustomImageTypeSuccess = (customImageTypeId) => ({
  type: types.DELETE_CUSTOM_IMAGE_TYPE_SUCCESS,
  payload: {
    customImageTypeId
  }
});

export default {
  loadCustomImageTypesSuccess,
  addCustomImageTypeSuccess,
  editCustomImageTypeSuccess,
  deleteCustomImageTypeSuccess
};
