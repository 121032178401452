import types from './types';

const defaultState = {};

const customerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_CUSTOMER_DETAILS_SUCCESS: {
      const {
        customerDetails,
      } = action.payload;

      return {
        ...state,
        customerDetails,
      };
    }
    case types.LOAD_CUSTOMER_SUCCESS: {
      const {
        customer,
      } = action.payload;

      return {
        ...state,
        customer,
      };
    }
    default:
      return state;
  }
};

export default customerReducer;
