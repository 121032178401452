import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconRoundCheck } from '../../../assets/img/icn-round-check.svg';
import { ReactComponent as IconDownload } from '../../../assets/img/icn-download-square.svg';

import { ConfirmationModal, Toggle, Button } from '../..';

import './sass/subscription-card.scss';

const defaultCurrency = <>&euro;</>;

export const SUBSCRIPTION_TYPES = {
  TRIAL: 1,
  LITE: 2,
  PRO: 4,
  ENTERPRISE: 5,
  CORPORATE: 6,
};
export const SUBSCRIPTION_NAMES = {
  [SUBSCRIPTION_TYPES.TRIAL]: 'Trial',
  [SUBSCRIPTION_TYPES.LITE]: 'Lite',
  [SUBSCRIPTION_TYPES.PRO]: 'Pro',
  [SUBSCRIPTION_TYPES.ENTERPRISE]: 'Enterprise',
  [SUBSCRIPTION_TYPES.CORPORATE]: 'Corporate',
};

const Subscription = ({ children, isActiveSubscription }) => (
  <section className={`subscription-card-container ${isActiveSubscription ? 'subscription-card-container--active' : ''}`}>
    {React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return null;

      return React.cloneElement(child, { isActiveSubscription });
    })}

  </section>
);

Subscription.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isActiveSubscription: PropTypes.bool.isRequired,
};

const Header = ({ type, isActiveSubscription, enabledFeatures, onActivateSubscription, canEdit }) => {
  const [shownConfirmationModal, setShownConfirmationModal] = useState(false);
  const billingConfiguration = enabledFeatures?.billingConfiguration;
  const isDisabled = isActiveSubscription || !canEdit;
  const isUnlimited = enabledFeatures.scanVolume === -1;

  return (
    <>
      <div className="subscription-header">
        <div
          className={`subscription-header__type ${isActiveSubscription ? 'subscription-header__type--disabled' : ''}`}
          role="button"
          tabIndex={0}
          onClick={() => setShownConfirmationModal(!isDisabled)}
          disabled={isDisabled}
        >
          {!isActiveSubscription
            ? <div className="radio-placeholder" />
            : <IconRoundCheck className="icn-sub-check" />}
           &nbsp;
          {SUBSCRIPTION_NAMES[type]}
        </div>

        <div className="subscription-header_prices">
          <div className="subscription-header__per-scan break">
            {isUnlimited
              ? (billingConfiguration.additionalScanPrice)
              : (billingConfiguration.price / enabledFeatures.scanVolume).toFixed(2)}
            {defaultCurrency}
            <span className="per-scan-text">per scan</span>
          </div>

          {!isUnlimited && (
            <div className="subscription-header__per-month break ml-3">
              {billingConfiguration.price}
              {defaultCurrency}
              <span className="per-month-text">per month</span>
            </div>
          )}
        </div>
      </div>

      {shownConfirmationModal && (
      <ConfirmationModal
        showModal={shownConfirmationModal}
        heading="Change Subscription"
        message="Are you sure you wish to change the subscription?"
        onConfirm={() => {
          onActivateSubscription();
          setShownConfirmationModal(false);
        }}
        onCancel={() => setShownConfirmationModal(false)}
        style={{ top: '6.25rem' }}
      />
      )}
    </>
  );
};

Header.propTypes = {
  type: PropTypes.number.isRequired,
  onActivateSubscription: PropTypes.func.isRequired,
  isActiveSubscription: PropTypes.bool,
  canEdit: PropTypes.bool,
  enabledFeatures: PropTypes.object
};

const Body = ({ enabledFeatures }) => {
  const { customTheme, manageImages, customWorkflows, scanVolume, supportConfiguration } = enabledFeatures;
  const { monthlySupport, onboardingSupport, customDevelopment } = supportConfiguration;
  const accountLimit = enabledFeatures?.accountLimit;
  return (
    <div className="subscription-body">
      <ul className="subscription-body-details">
        <li className="subscription-body-details__li">{`${accountLimit || 'Unlimited'} team accounts`}</li>
        <li className="subscription-body-details__li">
          {scanVolume === -1
            ? 'Unlimited vehicle scans'
            : `Scan up to ${scanVolume} vehicles`}
        </li>
        {manageImages && <li className="subscription-body-details__li">Manage required images and validations</li>}
        {monthlySupport > 0 && (
        <li className="subscription-body-details__li">
          {`Up to ${monthlySupport}${monthlySupport > 1 ? 'hrs' : 'hr'} technical support time`}
        </li>
        )}
        {onboardingSupport > 0 && (
        <li className="subscription-body-details__li">
          {`Up to ${onboardingSupport}${onboardingSupport > 1 ? 'hrs' : 'hr'} one-time onboarding support`}
        </li>
        )}
        {customDevelopment && <li className="subscription-body-details__li">Custom development requests</li>}
        {customTheme && <li className="subscription-body-details__li">Custom theming</li>}
        {customWorkflows && <li className="subscription-body-details__li">Create multiple wokflows</li>}
      </ul>
    </div>
  );
};

Body.propTypes = {
  enabledFeatures: PropTypes.shape({
    accountLimit: PropTypes.number,
    customTheme: PropTypes.bool,
    manageImages: PropTypes.bool,
    customWorkflows: PropTypes.bool,
    scanVolume: PropTypes.number,
    supportConfiguration: PropTypes.shape({
      monthlySupport: PropTypes.number,
      onboardingSupport: PropTypes.number,
      customDevelopment: PropTypes.bool,
    }),
    billingConfiguration: PropTypes.shape({ price: PropTypes.number, additionalScanPrice: PropTypes.number, }),
    damageDetectionEnabled: PropTypes.bool
  }).isRequired
};

const DamageDetection = ({ damageDetectionEnabled, disabled, setDamageDetectionOn }) => (
  <div className="d-flex align-items-center">
    <Toggle on={damageDetectionEnabled} disabled={disabled} toggleClick={setDamageDetectionOn} />
    <span className="ml-1">Damage detection</span>
  </div>
);

DamageDetection.propTypes = {
  damageDetectionEnabled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  setDamageDetectionOn: PropTypes.func.isRequired
};

const GeneralTermsAndConditions = ({ onDownloadClick }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <div className="d-flex w-100">
      <Button
        className="btn btn-link d-flex pl-0"
        disabled={isDownloading}
        onClick={() => {
          if (onDownloadClick) {
            setIsDownloading(true);
            onDownloadClick(() => setIsDownloading(false));
          }
        }}
      >
        <IconDownload className="md-small" />
        Download terms & conditions
      </Button>
    </div>
  );
};

GeneralTermsAndConditions.propTypes = {
  onDownloadClick: PropTypes.func
};

Subscription.Header = Header;
Subscription.Body = Body;
Subscription.DamageDetection = DamageDetection;
Subscription.GeneralTermsAndConditions = GeneralTermsAndConditions;

export default Subscription;
