import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { constants as sessionConstants } from '../../../../../redux/session';
import { selectors as photoSeriesSelectors } from '../../../../../redux/photoSeries';
import { authenticationService } from '../../../../../services';

const useCanViewPhotoSeriesDamagesByRoute = () => {
  const { photoSeriesId } = useParams();
  const canViewPhotoSeriesDamages = useCanViewPhotoSeriesDamages(photoSeriesId);
  return canViewPhotoSeriesDamages();
};

const useCanViewPhotoSeriesDamages = (photoSeriesId) => {
  const { hasPermission } = authenticationService;
  const { VIEW_DAMAGES } = sessionConstants.PERMISSION_TYPES;

  const selectPhotoseries = useSelector(photoSeriesSelectors.selectPhotoSeries);
  const hasViewDamagesPermission = hasPermission(VIEW_DAMAGES);
  const photoSeries = selectPhotoseries && selectPhotoseries[photoSeriesId];

  return useCallback((checkPhotoSeriesDamages = true) => {
    if (checkPhotoSeriesDamages) return hasViewDamagesPermission && photoSeries?.damages?.length > 0;

    return hasViewDamagesPermission;
  }, [photoSeries, hasViewDamagesPermission]);
};

export {
  useCanViewPhotoSeriesDamagesByRoute,
  useCanViewPhotoSeriesDamages
};
