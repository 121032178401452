import { LOAD_SUBSCRIPTIONS_SUCCESS, LOAD_ACTIVE_SUBSCRIPTION_SUCCESS } from './types';

const initialState = {
  subscriptions: [],
  enabledFeatures: {},
};

const subscriptionReducer = (state = initialState, action) => {
  if (action.type === LOAD_SUBSCRIPTIONS_SUCCESS) {
    return { ...state, subscriptions: action.payload };
  }

  if (action.type === LOAD_ACTIVE_SUBSCRIPTION_SUCCESS) {
    return { ...state, enabledFeatures: action.payload };
  }

  return state;
};

export default subscriptionReducer;
