import React from 'react';
import PropTypes from 'prop-types';

import { FileUploadDropzone } from '../../../../containers';
import IconPdf from '../../../../assets/img/icn-file.svg';

const TermsAndConditionsFieldset = ({ name, termsAndConditions, setTermsAndConditions }) => {
  const fileName = termsAndConditions[name]?.name;
  const label = <>Drag and drop or upload your <b>.pdf</b>&nbsp;{name === 'sourceTermsAndConditions' ? 'source' : 'targeted'} language file.</>;
  const pdfDropHandler = (acceptedFiles) => {
    const uploadedFile = acceptedFiles && acceptedFiles[0];

    if (uploadedFile) setTermsAndConditions({ ...termsAndConditions, [name]: uploadedFile });
  };

  return (
    <fieldset>
      <h5 className="category-title">Terms and conditions</h5>
      <FileUploadDropzone
        accept="application/pdf"
        name={name}
        label={<p>{label}</p>}
        buttonLabel="Upload file"
        handleDrop={pdfDropHandler}
        fileName={fileName}
        objectUrl={fileName && IconPdf}
        resetFile={() => setTermsAndConditions({ ...termsAndConditions, [name]: {} })}
      />
    </fieldset>
  );
};

TermsAndConditionsFieldset.propTypes = {
  name: PropTypes.string.isRequired,
  termsAndConditions: PropTypes.object.isRequired,
  setTermsAndConditions: PropTypes.func.isRequired
};

export default TermsAndConditionsFieldset;
