import { createSelector } from 'reselect';

import { NAME, STATE_KEYS } from './constants';
import translationsSelectors from '../translations/selectors';
import customerConfigurationsSelectors from '../customerConfigurations/selectors';

import { composeFormKey } from '../../utils/customImages';

const selectState = (state) => state[NAME];

const { ALL_TRANSLATIONS } = STATE_KEYS;

const selectAllTranslations = createSelector([selectState], (translations) => translations[ALL_TRANSLATIONS]);

const getCustomImageTypeId = (_, customImageTypeId) => customImageTypeId;

const { selectLanguages } = translationsSelectors;
const { selectCustomerConfiguration } = customerConfigurationsSelectors;

const selectImageTypeFormValues = createSelector(
  [
    selectAllTranslations,
    getCustomImageTypeId,
    selectLanguages,
    selectCustomerConfiguration
  ],
  (allTranslations, customImageTypeId, languages, customerConfiguration) => {
    const allTranslationValues = {};

    if (!allTranslations || !customerConfiguration) return allTranslationValues;

    const allTranslationsKeys = Object.keys(allTranslations || {});
    const imageTypeCategories = [];
    const allowedLanguages = languages
      .filter((lang) => customerConfiguration.enabledLanguages.includes(lang.id));

    for (let i = 0; i < allTranslationsKeys.length; i++) {
      const langId = allTranslationsKeys[i];
      if (!allowedLanguages.find((lang) => lang.id === langId)) continue;

      const imageTypeCategory = allTranslations[langId].find((cat) => cat.customImageTypeId === customImageTypeId);
      if (imageTypeCategory) imageTypeCategories.push(imageTypeCategory);
    }

    imageTypeCategories.forEach((customImageCategory) => (
      customImageCategory.translationValues
        .forEach((translationValue) => {
          const formKey = composeFormKey(customImageCategory.id, translationValue.key);
          allTranslationValues[formKey] = translationValue.value || ' ';
        })
    ));

    return allTranslationValues;
  }
);

const getLanguageId = (_, languageId) => languageId;
const getImageTypeId = (_, __, imageTypeId) => imageTypeId;

const selectImageTypeTranslationCategory = createSelector(
  selectAllTranslations,
  getLanguageId,
  getImageTypeId,
  (allTranslations, languageId, customImageTypeId) => {
    if (!allTranslations) return null;

    const languageTranslations = allTranslations[languageId];
    if (!languageTranslations?.length) return null;

    return languageTranslations.find((category) => category.customImageTypeId === customImageTypeId);
  }
);

const selectLanguageTranslations = createSelector(
  selectAllTranslations,
  getLanguageId,
  (allTranslations, languageId) => {
    if (!allTranslations) return null;

    const languageTranslations = allTranslations[languageId];
    if (!languageTranslations?.length) return null;

    return languageTranslations;
  }
);

const getEnglishLangId = (_, __, englishLangId) => englishLangId;

const selectImageTypePreviewSettingsContext = createSelector(
  selectAllTranslations,
  getCustomImageTypeId,
  getEnglishLangId,
  selectCustomerConfiguration,
  selectLanguages,
  (allTranslations, customImageTypeId, englishLangId, customerConfiguration, languages) => {
    if (allTranslations?.length < 1) return null;

    const imageTypePreviewSettings = {
      activeTranslationId: '',
      activeCheckbox: 'all_captured_checkbox_text',
      translations: {},
      additionalTranslations: {}
    };

    if (!allTranslations || !englishLangId || !customerConfiguration) return imageTypePreviewSettings;

    const allowedLanguages = languages
      .filter((lang) => customerConfiguration.enabledLanguages.includes(lang.id));

    const activeLanguageId = !customerConfiguration.enabledLanguages.includes(englishLangId)
      ? allowedLanguages[0].id
      : englishLangId;

    for (let i = 0; i < Object.keys(allTranslations).length; i++) {
      const langId = Object.keys(allTranslations)[i];
      if (!allowedLanguages.find((lang) => lang.id === langId)) continue;

      const langTranslations = allTranslations[langId].find((translationCategory) => translationCategory.customImageTypeId === customImageTypeId);
      if (!langTranslations) return imageTypePreviewSettings;

      if (langTranslations.languageId === activeLanguageId) {
        imageTypePreviewSettings.activeTranslationId = langTranslations.id;
      }

      const vehicleScanTranslations = allTranslations[langId]
        .find((translationCategory) => translationCategory.categoryName === 'vehicle_scan')
        ?.translationValues;

      const continueBtnTranslation = vehicleScanTranslations?.find((translationValue) => translationValue.key === 'continue_btn');
      const captureBtnTranslation = vehicleScanTranslations?.find((translationValue) => translationValue.key === 'capture');

      const continueButtonText = continueBtnTranslation && continueBtnTranslation.value ? continueBtnTranslation.value : '-';
      const captureButtonText = captureBtnTranslation && captureBtnTranslation.value ? captureBtnTranslation.value : '-';

      imageTypePreviewSettings.additionalTranslations[langTranslations.id] = {
        languageId: langId,
        continueButtonText,
        captureButtonText
      };

      imageTypePreviewSettings.translations[langTranslations.id] = {};
      langTranslations.translationValues
        .forEach((translationValue) => {
          imageTypePreviewSettings.translations[langTranslations.id][translationValue.key] = translationValue.value || ' ';
        });
    }

    return imageTypePreviewSettings;
  }
);

export default {
  selectAllTranslations,
  selectLanguageTranslations,
  selectImageTypeFormValues,
  selectImageTypeTranslationCategory,
  selectImageTypePreviewSettingsContext,
};
