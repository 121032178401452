import types from './types';

const defaultState = {
  windshieldRepairabilityProfilesPagedResults: null
};

const windshieldRepairabilityProfilesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.ADD_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS: {
      const {
        windshieldRepairabilityProfile,
      } = action.payload;

      return {
        ...state,
        windshieldRepairabilityProfilesPagedResults: {
          ...state.windshieldRepairabilityProfilesPagedResults,
          results: [
            windshieldRepairabilityProfile,
            ...state.windshieldRepairabilityProfilesPagedResults.results,
          ],
          rowCount: state.windshieldRepairabilityProfilesPagedResults.rowCount + 1,
        }
      };
    }
    case types.DELETE_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS: {
      const {
        id
      } = action.payload;

      return {
        ...state,
        windshieldRepairabilityProfilesPagedResults: {
          ...state.windshieldRepairabilityProfilesPagedResults,
          results: state.windshieldRepairabilityProfilesPagedResults.results
            .filter((windshieldRepairabilityProfile) => windshieldRepairabilityProfile.id !== id),
          rowCount: state.windshieldRepairabilityProfilesPagedResults.rowCount - 1,
        }
      };
    }
    case types.EDIT_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS: {
      const {
        updatedWindshieldRepairabilityProfile
      } = action.payload;

      return {
        ...state,
        windshieldRepairabilityProfilesPagedResults: {
          ...state.windshieldRepairabilityProfilesPagedResults,
          results: state.windshieldRepairabilityProfilesPagedResults.results
            .map((windshieldRepairabilityProfile) => (
              windshieldRepairabilityProfile.id === updatedWindshieldRepairabilityProfile.id
                ? updatedWindshieldRepairabilityProfile
                : windshieldRepairabilityProfile
            )),
        }
      };
    }
    case types.LOAD_WINDSHIELD_REPAIRABILITY_PROFILES_SUCCESS: {
      const {
        windshieldRepairabilityProfilesPagedResults,
      } = action.payload;

      return {
        windshieldRepairabilityProfilesPagedResults,
      };
    }
    default:
      return state;
  }
};

export default windshieldRepairabilityProfilesReducer;
