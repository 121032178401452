import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './sass/theme-settings-form.scss';
import ColorPicker from './containers/ColorPicker/ColorPicker';
import { useThemeContext } from '../../ThemeSettings';
import { makeFileUpload } from '../../../../../../api/ajax';
import { FileUploadDropzone } from '../../../../../../containers';
import { getColorObjectFromHex } from '../../../../../../utils/color';
import { useUniqueIds, useModalAlerter } from '../../../../../../hooks';
import { errorMessages, THEMES_STATE_KEYS } from '../../../../../../utils/themes';
import {
  actions as customerConfigurationsActions,
  selectors as customerConfigurationsSelectors,
} from '../../../../../../redux/customerConfigurations';

function ThemeSettingsForm() {
  const headingColorRef = useRef(null);
  const paragraphColorRef = useRef(null);
  const primaryButtonBackgroundColorRef = useRef(null);
  const primaryButtonTextColorRef = useRef(null);
  const secondaryButtonBackgroundColorRef = useRef(null);
  const secondaryButtonTextColorRef = useRef(null);
  const successColorRef = useRef(null);
  const errorColorRef = useRef(null);
  const warningColorRef = useRef(null);

  const dispatch = useDispatch();
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { themes, setTheme, setThemes } = useThemeContext();
  const localLogoUrl = useSelector(customerConfigurationsSelectors.selectLocalLogoUrl);
  const [
    headingColorId,
    paragraphColorId,
    primaryButtonBackgroundColorId,
    primaryButtonTextColorId,
    secondaryButtonBackgroundColorId,
    secondaryButtonTextColorId,
    successColorId,
    errorColorId,
    warningColorId,
  ] = useUniqueIds(9);
  const { renderModal: renderSettingsSavedAlert } = useModalAlerter(showSaveMessage, setShowSaveMessage, ' Settings have been saved!');

  const handleLogoSvgDrop = (acceptedFiles) => {
    const uploadedLogo = acceptedFiles && acceptedFiles[0];

    if (uploadedLogo) {
      setTheme(THEMES_STATE_KEYS.logoFile, uploadedLogo);
      dispatch(customerConfigurationsActions.loadCurrentCustomerLogoSuccess(uploadedLogo));
    }
  };

  const onSubmitHandler = (ev) => {
    ev.preventDefault();

    let foundEmptyData = false;

    for (const key in themes) {
      if (!themes[key] && ![THEMES_STATE_KEYS.logoFile, THEMES_STATE_KEYS.errorObj].includes(key)) {
        if (!foundEmptyData) foundEmptyData = true;

        setThemes((prevState) => ({
          ...prevState,
          error: prevState.error
            ? {
              ...prevState.error,
              [key]: errorMessages[key],
            }
            : {
              [key]: errorMessages[key],
            },
        }));
      }
    }

    const postData = {
      logoFile: themes[THEMES_STATE_KEYS.logoFile],
      headingColor: getColorObjectFromHex(themes[THEMES_STATE_KEYS.headingColor]),
      paragraphColor: getColorObjectFromHex(themes[THEMES_STATE_KEYS.paragraphColor]),
      primaryButtonBackgroundColor: getColorObjectFromHex(themes[THEMES_STATE_KEYS.primaryButtonBackgroundColor]),
      primaryButtonTextColor: getColorObjectFromHex(themes[THEMES_STATE_KEYS.primaryButtonTextColor]),
      secondaryButtonBackgroundColor: getColorObjectFromHex(themes[THEMES_STATE_KEYS.secondaryButtonBackgroundColor]),
      secondaryButtonTextColor: getColorObjectFromHex(themes[THEMES_STATE_KEYS.secondaryButtonTextColor]),
      successColor: getColorObjectFromHex(themes[THEMES_STATE_KEYS.successColor]),
      errorColor: getColorObjectFromHex(themes[THEMES_STATE_KEYS.errorColor]),
      warningColor: getColorObjectFromHex(themes[THEMES_STATE_KEYS.warningColor]),
    };

    setShowSaveMessage(false);

    if (!foundEmptyData) {
      setIsSubmitting(true);

      makeFileUpload('customerConfigurations/current/themingSettings', null, postData, 'logoFile').subscribe(
        ({ response }) => {
          setIsSubmitting(false);
          setShowSaveMessage(true);
          Boolean(themes.error) && setTheme(THEMES_STATE_KEYS.errorObj, null);
          dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(response));
        },
        () => {
          setIsSubmitting(false);
          Boolean(themes.error) && setTheme(THEMES_STATE_KEYS.errorObj, null);
        }
      );
    }
  };

  return (
    <form className="theme-settings-form-wrapper" onSubmit={onSubmitHandler}>
      <div className="theme-settings-form-wrapper__row1 upload-wrapper">
        <h2>Preference</h2>
        <div className="upload-wrapper__row1">
          <FileUploadDropzone
            name={THEMES_STATE_KEYS.logoFile}
            label={
              <p>
                Drag and drop or upload your
                <b>.svg</b> logo file.
              </p>
            }
            buttonLabel="Upload logo"
            handleDrop={handleLogoSvgDrop}
            objectUrl={localLogoUrl}
          />
        </div>
      </div>
      <div className="theme-settings-form-wrapper__row2 modal-wrapper">{renderSettingsSavedAlert()}</div>
      <div className="theme-settings-form-wrapper__row3 texts-wrapper">
        <h2>Texts</h2>
        <div className="texts-wrapper__row1">
          <ColorPicker
            ref={headingColorRef}
            id={headingColorId}
            label="Header color"
            value={themes.headingColor}
            name={THEMES_STATE_KEYS.headingColor}
            error={themes.error?.headingColor}
          />
          <ColorPicker
            ref={paragraphColorRef}
            id={paragraphColorId}
            label="Paragraph color"
            value={themes.paragraphColor}
            name={THEMES_STATE_KEYS.paragraphColor}
            error={themes.error?.paragraphColor}
          />
        </div>
      </div>
      <div className="theme-settings-form-wrapper__row3 buttons-wrapper">
        <h2>Buttons</h2>
        <div className="buttons-wrapper__row1">
          <ColorPicker
            ref={primaryButtonBackgroundColorRef}
            id={primaryButtonBackgroundColorId}
            label="Primary button color"
            value={themes.primaryButtonBackgroundColor}
            name={THEMES_STATE_KEYS.primaryButtonBackgroundColor}
            error={themes.error?.primaryButtonBackgroundColor}
          />
          <ColorPicker
            ref={primaryButtonTextColorRef}
            id={primaryButtonTextColorId}
            label="Primary button text color"
            value={themes.primaryButtonTextColor}
            name={THEMES_STATE_KEYS.primaryButtonTextColor}
            error={themes.error?.primaryButtonTextColor}
          />
        </div>
        <div className="buttons-wrapper__row2">
          <ColorPicker
            ref={secondaryButtonBackgroundColorRef}
            id={secondaryButtonBackgroundColorId}
            label="Secondary button color"
            value={themes.secondaryButtonBackgroundColor}
            name={THEMES_STATE_KEYS.secondaryButtonBackgroundColor}
            error={themes.error?.secondaryButtonBackgroundColor}
          />
          <ColorPicker
            ref={secondaryButtonTextColorRef}
            id={secondaryButtonTextColorId}
            label="Secondary button text color"
            value={themes.secondaryButtonTextColor}
            name={THEMES_STATE_KEYS.secondaryButtonTextColor}
            error={themes.error?.secondaryButtonTextColor}
          />
        </div>
      </div>
      <div className="theme-settings-form-wrapper__row4 warning-wrapper">
        <h2>Warnings</h2>
        <div className="warning-wrapper__row1">
          <ColorPicker
            ref={successColorRef}
            id={successColorId}
            label="Success color"
            value={themes.successColor}
            name={THEMES_STATE_KEYS.successColor}
            error={themes.error?.successColor}
          />
          <ColorPicker
            ref={warningColorRef}
            id={warningColorId}
            label="Warning color"
            value={themes.warningColor}
            name={THEMES_STATE_KEYS.warningColor}
            error={themes.error?.warningColor}
          />
        </div>
        <div className="warning-wrapper__row2">
          <ColorPicker
            ref={errorColorRef}
            id={errorColorId}
            label="Error color"
            value={themes.errorColor}
            name={THEMES_STATE_KEYS.errorColor}
            error={themes.error?.errorColor}
          />
        </div>
      </div>
      <div className="button-wrapper">
        <button className="btn btn-primary mt-3" type="submit" disabled={isSubmitting}>
          Save settings
        </button>
      </div>
    </form>
  );
}

export default ThemeSettingsForm;
