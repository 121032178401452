import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useBeforeUnload } from '../../../../hooks/useBeforeUnload/useBeforeUnload';
import { makePatch } from '../../../../api/ajax';
import { Field } from '../../../../components/Form';
import { Modal, onHideHandler, renderShowConfirmationModal } from '../../../../components';
import { actions as customerConfigurationsActions } from '../../../../redux/customerConfigurations';

const InactivityTimeoutModal = ({ customerConfiguration, onModalClose, ...props }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useBeforeUnload(hasUnsavedChanges);

  const dispatch = useDispatch();

  const initialValues = {
    inactivityTimeout: customerConfiguration?.inactivityTimeout || 0,
  };

  const handleSubmit = ({ inactivityTimeout }, setStatus, setShowSuccessMessage, resetForm, setHasUnsavedChanges) => {
    const updateCustomerConfigDto = {
      subscriptionUsageWarning: customerConfiguration?.subscriptionUsageWarning,
      inactivityTimeout,
    };

    makePatch('customerConfigurations/current', updateCustomerConfigDto).subscribe(
      ({ response }) => {
        dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(response));

        setShowSuccessMessage('Inactivity timeout has been changed!');
        resetForm();

        setHasUnsavedChanges(false);
      },
      ({ response: { error } }) => {
        setStatus(error);

        setHasUnsavedChanges(false);
      }
    );
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, () => {
        setShowConfirmModal(false);
        onModalClose();
      })}
      <Modal
        headerText="Change inactivity timeout minutes"
        size="md"
        backdrop="static"
        onHide={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
        {...props}
      >
        <Modal.Body>
          <div className="row">
            <div className="col">
              {showSuccessMessage && (
                <Alert variant="success" dismissible onClick={() => setShowSuccessMessage(null)}>
                  {showSuccessMessage}
                </Alert>
              )}
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              inactivityTimeout: Yup.number(),
            })}
            onSubmit={({ inactivityTimeout }, { setStatus, resetForm }) =>
              handleSubmit({ inactivityTimeout }, setStatus, setShowSuccessMessage, resetForm, setHasUnsavedChanges)}
            validateOnChange={false}
          >
            {({ errors, status, touched }) => (
              <Form
                onChange={({ target }) => {
                  if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                    setHasUnsavedChanges(true);
                  }
                }}
              >
                <div className="row">
                  <div className="col-10 col-md-7">
                    <Field
                      type="number"
                      min="0"
                      name="inactivityTimeout"
                      touched={touched.inactivityTimeout}
                      errors={errors.inactivityTimeout || status}
                    />
                  </div>
                  <div className="col-4 col-md-5 text-right">
                    <button
                      type="reset"
                      className="btn btn-secondary mr-1"
                      onClick={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

InactivityTimeoutModal.propTypes = {
  onModalClose: PropTypes.func,
  customerConfiguration: PropTypes.object,
};

export default InactivityTimeoutModal;
