import React from 'react';
import PropTypes from 'prop-types';

import { Container, Footer, Text, Menu } from '../../components';

import './sass/empty-page.scss';

const EmptyPage = ({ children }) => (
  <div className="empty-page">
    <Menu />
    <Container>
      <div className="row">
        <div className="col-sm">
          <div className="content-wrapper">{children}</div>
          <Footer>
            <Text align="center">DriveX Technologies &copy;2021 All Rights Reserved</Text>
          </Footer>
        </div>
      </div>
    </Container>
  </div>
);

EmptyPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EmptyPage;
