import React from 'react';
import PropTypes from 'prop-types';

import './sass/dropzone-container.scss';

const DropzoneContainer = React.forwardRef(({ children, ...props }, ref) => (
  <div ref={ref} className="dropzone-container" {...props}>
    {children}
  </div>
));

DropzoneContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DropzoneContainer;
