import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Combobox } from '../../components';
import { comboTypes } from '../../components/Combobox/Combobox';
import { makeGet } from '../../api/ajax';
import { selectors as customerSelectors } from '../../redux/customers';
import { selectors as tagsSelectors, actions as tagsActions } from '../../redux/tags';

const CustomersTagCombobox = ({ initialSelection, setSelectedCustomers, label }) => {
  const dispatch = useDispatch();

  const comboboxRef = useRef(null);
  const customer = useSelector(customerSelectors.selectCurrentCustomer);
  const customersTags = useSelector(tagsSelectors.selectCustomersTags);
  const comboboxValues = customersTags != null
    ? customersTags
      .filter((tag) => tag.customerId !== customer.id)
      .map((tag) => ({ id: tag.customerId, name: tag.value }))
    : [];

  useEffect(() => {
    if (customer && customersTags == null) {
      makeGet('tags').subscribe(({ response }) => {
        dispatch(tagsActions.loadCustomersTagsSuccess(response));
      });
    }
  }, [customer, dispatch, customersTags]);

  return (
    <Combobox
      type={comboTypes.multiCombo}
      label={label}
      values={comboboxValues}
      initialSelection={initialSelection}
      getSelectedItems={(selectedItems) => setSelectedCustomers(selectedItems)}
      ref={comboboxRef}
      searchingDisabled={false}
    />
  );
};

CustomersTagCombobox.propTypes = {
  label: PropTypes.string,
  initialSelection: PropTypes.array,
  setSelectedCustomers: PropTypes.func
};

export default CustomersTagCombobox;
