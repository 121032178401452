import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeGet, makePut } from '../../api/ajax';
import { composeApiUrl } from '../../api/composeApiUrl';

import {
  constants as fileConstants
} from '../../redux/files';

import ActionsDropDown from '../ActionsDropDown/ActionsDropDown';
import { ReactComponent as IconArrowDropDown } from '../../assets/img/icn-arrow-drop-down.svg';
import Loader from '../Loader/Loader';

import './sass/photo-series-report-button.scss';

const ReportStatus = {
  GENERATING: 0,
  COMPLETED: 1
};

/**
 * Renders a button for generating and downloading a report for a photo series.
 *
 * @param {string} props.photoSeriesId - The ID of the photo series.
 * @param {Object} props.reportStatus - The status of the report.
 * @param {number} props.reportStatus.status - The status code of the report.
 * @param {boolean} props.reportStatus.generateIsExpired - Indicates if the report generation is expired.
 * @param {boolean} props.reportStatus.canRegenerate - Indicates if the report can be regenerated.
 * @param {boolean} props.hasRegenerateReportsPermission - Indicates if the user has permission to regenerate reports.
 * @param {Function} props.onReportCreated - The callback function when the report is created.
 * @param {Function} props.onReportGenerating - The callback function when the report is generating.
 *                                              The function is called every 15 seconds until the report is completed.
 * @return {JSX.Element|null} The rendered report button component.
 */
const PhotoSeriesReportButton = ({ photoSeriesId, reportStatus, hasRegenerateReportsPermission, onReportCreated, onReportGenerating }) => {
  const { status, generateIsExpired, canRegenerate } = reportStatus;
  const [isCreatingReport, setIsCreatingReport] = useState(false);
  const canRegeneratePhotoSeriesReports = hasRegenerateReportsPermission && canRegenerate;

  const isGeneratingReport = status === ReportStatus.GENERATING && !generateIsExpired;

  useEffect(() => {
    let intervalId = null;
    if (isGeneratingReport) {
      intervalId = setInterval(() => onReportGenerating(), [15000]);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isGeneratingReport, onReportGenerating]);

  const downloadReport = (photoSeriesId) => {
    makeGet(`files/${photoSeriesId}/token?entityType=${fileConstants.FILE_ENTITY_TYPES.PHOTO_SERIES_PDF_REPORT}`)
      .subscribe(({ response }) => {
        window.location = composeApiUrl(`files/${photoSeriesId}/download?token=${response.token}`);
      });
  };

  const createReport = (photoSeriesId) => {
    if (isCreatingReport) return;
    setIsCreatingReport(true);
    makePut(`photoSeries/${photoSeriesId}/reportStatus`)
      .subscribe(({ response }) => {
        if (onReportCreated) {
          setIsCreatingReport(false);
          onReportCreated(response);
        }
      });
  };

  // Show only one button if generating and generation is not expired
  if (isGeneratingReport) {
    return (
      <div className="photo-series-report-button">
        <button
          type="button"
          className="button btn btn-secondary ml-2"
          disabled
          style={{ maxWidth: '13rem' }}
        >
          <div className="mr-1">
            <Loader size="small" isRelative white />
          </div>
          Generating report
        </button>
      </div>
    );
  }

  // Show only one button if completed and can't regenerate, just like old logic
  if (status === ReportStatus.COMPLETED && !canRegeneratePhotoSeriesReports) {
    return (
      <button
        type="button"
        className="btn btn-primary ml-2"
        data-testid="viewReportBtn"
        onClick={() => downloadReport(photoSeriesId)}
      >
        View report
      </button>
    );
  }

  const generatingFailed = status === ReportStatus.GENERATING && generateIsExpired;
  return (
    <div className="photo-series-report-button">
      <ActionsDropDown
        className={generatingFailed ? 'generating-failed' : 'generating-success'}
        toggleText={generatingFailed ? 'No report' : 'Report'}
        toggle={<IconArrowDropDown className="actions-dropdown-toggle" />}
      >
        {
          generatingFailed && (
            <ActionsDropDown.Item onClick={() => createReport(photoSeriesId)}>
              <span>Create report</span>
            </ActionsDropDown.Item>
          )
        }
        {
          !generatingFailed && (
            <>
              <ActionsDropDown.Item onClick={() => downloadReport(photoSeriesId)}>
                <span>View report</span>
              </ActionsDropDown.Item>
              {
                canRegeneratePhotoSeriesReports && (
                  <ActionsDropDown.Item onClick={() => createReport(photoSeriesId)}>
                    <span>Re-create report</span>
                  </ActionsDropDown.Item>
                )
              }
            </>
          )
        }
      </ActionsDropDown>
    </div>
  );
};

PhotoSeriesReportButton.propTypes = {
  photoSeriesId: PropTypes.string,
  reportStatus: PropTypes.shape({
    status: PropTypes.number,
    generateIsExpired: PropTypes.bool,
    canRegenerate: PropTypes.bool
  }),
  onReportCreated: PropTypes.func,
  hasRegenerateReportsPermission: PropTypes.bool,
  onReportGenerating: PropTypes.func
};

export default PhotoSeriesReportButton;
