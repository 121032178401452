import types from './types';

const getLanguagesSuccess = (payload) => ({ type: types.GET_LANGUAGES_SUCCESS, payload });
const getSourceTranslationsSuccess = (payload) => ({ type: types.GET_SOURCE_TRANSLATIONS_SUCCESS, payload });
const getTargetedTranslationsSuccess = (payload) => ({ type: types.GET_TARGETED_TRANSLATIONS_SUCCESS, payload });
const getSourceTermsAndConditionsSuccess = (payload) => ({ type: types.GET_SOURCE_TERMS_AND_CONDITIONS_SUCCESS, payload });
const getTargetedTermsAndConditionsSuccess = (payload) => ({ type: types.GET_TARGETED_TERMS_AND_CONDITIONS_SUCCESS, payload });
const getSourceGdprPrivacyPolicySuccess = (payload) => ({ type: types.GET_SOURCE_GDPR_PRIVACY_POLICY, payload });
const getTargetedGdprPrivacyPolicySuccess = (payload) => ({ type: types.GET_TARGETED_GDPR_PRIVACY_POLICY, payload });

export default {
  getLanguagesSuccess,
  getSourceTranslationsSuccess,
  getTargetedTranslationsSuccess,
  getSourceTermsAndConditionsSuccess,
  getTargetedTermsAndConditionsSuccess,
  getSourceGdprPrivacyPolicySuccess,
  getTargetedGdprPrivacyPolicySuccess
};
