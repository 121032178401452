import { normalize } from 'normalizr';
import types from './types';
import { userListSchema } from './schema';

const defaultState = {};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_USERS_SUCCESS: {
      const { users, page, pageCount } = action.payload;

      const normalizedData = normalize(users, userListSchema);

      return {
        ...state,
        users: {
          ...state.users,
          [page]: normalizedData.entities.users,
          pageCount,
          totalUsers: users.length,
        },
      };
    }
    case types.UPDATE_USERS_SUCCESS: {
      const { user, page } = action.payload;

      return {
        ...state,
        users: {
          [page]: {
            ...state.users[page],
            [user.id]: {
              ...state.users[page][user.id],
              ...user
            },
          },
          pageCount: state.users.pageCount,
        },
      };
    }
    case types.CLEAR_USERS: {
      return {
        ...state,
        users: null,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
