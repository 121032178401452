import constants from './constants';

const prefix = `drivex/${constants.NAME}`;

const LOAD_COUNTRY_SUCCESS = `${prefix}/LOAD_COUNTRY_SUCCESS`;

const LOAD_COUNTRIES_SUCCESS = `${prefix}/LOAD_COUNTRIES_SUCCESS`;

export default {
  LOAD_COUNTRY_SUCCESS,
  LOAD_COUNTRIES_SUCCESS,
};
