import React, { useState, createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

import './sass/theme-settings.scss';
import { getHexFromColorObject } from '../../../../utils/color';
import SmartScanPreview from './containers/SmartScanPreview/SmartScanPreview';
import ThemeSettingsForm from './containers/ThemeSettingsForm/ThemeSettingsForm';
import { selectors as customerConfigurationsSelectors } from '../../../../redux/customerConfigurations';

const getThemingValues = (themingSettings, localLogoUrl) => ({
  logoFile: localLogoUrl || null,
  headingColor: getHexFromColorObject(themingSettings?.headingColor) || '#003CA3',
  paragraphColor: getHexFromColorObject(themingSettings?.paragraphColor) || '#003CA3',
  successColor: getHexFromColorObject(themingSettings?.successColor) || '#00BD4B',
  errorColor: getHexFromColorObject(themingSettings?.errorColor) || '#D72356',
  warningColor: getHexFromColorObject(themingSettings?.warningColor) || '#FFBC1F',
  primaryButtonBackgroundColor: getHexFromColorObject(themingSettings?.primaryButtonBackgroundColor) || '#007BFF',
  primaryButtonTextColor: getHexFromColorObject(themingSettings?.primaryButtonTextColor) || '#FFF',
  secondaryButtonBackgroundColor: getHexFromColorObject(themingSettings?.secondaryButtonBackgroundColor) || '#E3E5E8',
  secondaryButtonTextColor: getHexFromColorObject(themingSettings?.secondaryButtonTextColor) || '#607289',
  error: null
});

/* \***********************!*\
   ThemeSettings Context
\* !************************! */
const ThemeSettingsContext = createContext(null);
const ThemeSettingsProvider = ThemeSettingsContext.Provider;
ThemeSettingsContext.displayName = 'ThemesSettingsContext';

export function useThemeContext() {
  const context = useContext(ThemeSettingsContext);

  if (!context) {
    const error = new Error();
    error.name = 'ThemeSettingsContext';
    error.message = 'To use useContext, you must be within the Provider scope';
    Error?.captureStackTrace && Error.captureStackTrace(error);
    throw error;
  }

  return context;
}

function ThemeSettings() {
  const localLogoUrl = useSelector(customerConfigurationsSelectors.selectLocalLogoUrl);
  const customerConfiguration = useSelector(customerConfigurationsSelectors.selectCustomerConfiguration);
  const [themes, setThemes] = useState(getThemingValues(customerConfiguration?.themingSettings, localLogoUrl));

  function setTheme(key, value) {
    setThemes({ ...themes, [key]: value });
  }

  return (
    <ThemeSettingsProvider value={{ themes, setTheme, setThemes }}>
      <div className="theme-settings-wrapper">
        <div className="theme-settings-wrapper__col1">
          <ThemeSettingsForm />
        </div>
        <div className="theme-settings-wrapper__col2">
          <SmartScanPreview />
        </div>
      </div>
    </ThemeSettingsProvider>
  );
}
export default ThemeSettings;
