import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { routes } from '../../routes/Routes';

import { selectors as workflowsSelectors, actions as workflowsActions } from '../../redux/workflows';

import { MenuPage } from '../../layouts';
import { PageTitle, Loader } from '../../components';

import { makeFileUpload, makeGet } from '../../api/ajax';
import { FileUploadDropzone } from '../../containers';

const PhotoSeriesImport = () => {
  const [isUploading, setIsImporting] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [importedPhotoSeries, setImportedPhotoSeries] = useState(null);
  const [performingWrr, setPerformingWrr] = useState(false);

  const [workflowId, setWorkflowId] = useState('0');

  const dispatch = useDispatch();
  const workflows = useSelector(workflowsSelectors.selectWorkFlows);

  useEffect(() => {
    if (!workflows) {
      makeGet('workflows/currentCustomer/admin').subscribe(
        ({ response }) => {
          dispatch(workflowsActions.loadWorkflowsSuccess(response));
        }
      );
    }
  }, [workflows, dispatch]);

  const handlePerformWrr = () => {
    setPerformingWrr(true);
    makeGet(`capture/${importedPhotoSeries.photoSeriesId}/detect-ws-repairability`).subscribe(
      () => {
        setPerformingWrr(false);
      }
    );
  };

  const handleImportPhotoSeries = (acceptedFiles) => {
    const photoSeriesArchive = acceptedFiles && acceptedFiles[0];
    const url = workflowId === '0' ? 'photoSeries/import' : `photoSeries/import?workflowId=${workflowId}`;

    if (photoSeriesArchive) {
      setIsImporting(true);
      setFileUploaded(false);
      setImportedPhotoSeries(null);
      makeFileUpload(url, null, photoSeriesArchive, 'file').subscribe(
        ({ response }) => {
          const { vehicleId, photoSeriesId } = response;
          setImportedPhotoSeries({
            vehicleId,
            photoSeriesId
          });
          setIsImporting(false);
          setFileUploaded(true);
        }
      );
    }
  };

  return (
    <MenuPage currentRoute={routes.photoSeriesImport}>
      <PageTitle>
        <PageTitle.Left headingText="Import photo series" />
      </PageTitle>
      <div className="row mb-3">
        <div className="col-sm-12">
          <label htmlFor="asset" className="mb-0">
            Select workflow
          </label>
          <select
            className="form-control"
            value={workflowId}
            onChange={(e) => setWorkflowId(e.target.value)}
            name="asset"
            id="asset"
          >
            <option value="0">-</option>
            {
              workflows && Object.values(workflows).map((workflow) => (
                <option key={workflow.id} value={workflow.id}>{workflow.name}</option>
              ))
            }
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          {
            isUploading ? (
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: '75%' }}
                />
              </div>
            ) : (
              <>
                {
                  importedPhotoSeries && (
                    <div className="alert alert-success" role="alert">
                      Photo series was successfully imported!
                      <br />
                      <a
                        href={`/vehicles/${importedPhotoSeries.vehicleId}/photoSeries/${importedPhotoSeries.photoSeriesId}`}
                        className="alert-link"
                      >
                        Open photo series {importedPhotoSeries.photoSeriesId}
                      </a>
                    </div>
                  )
                }
                <FileUploadDropzone
                  name="Photo series import"
                  label={
                    <p>
                      Drag and drop or upload your
                      <b>.zip</b> photo series.
                    </p>
                  }
                  buttonLabel="Import photo series"
                  handleDrop={handleImportPhotoSeries}
                  accept=".zip"
                />
              </>
            )
          }
        </div>
      </div>
      {
        fileUploaded && (
          <>
            <div className="row mt-3">
              <div className="col-sm-12">
                <h4>Actions</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={performingWrr}
                  onClick={handlePerformWrr}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  {
                    performingWrr && (
                      <div className="mr-1">
                        <Loader size="small" isRelative white />
                      </div>
                    )
                  }
                  Perform Windshield R&R
                </button>
              </div>
            </div>
          </>
        )
      }
    </MenuPage>
  );
};

export default PhotoSeriesImport;
