const NAME = 'windshieldRepairabilityProfiles';

const DRIVER_FIELD_OF_VIEW = {
  RIGHT: 0,
  LEFT: 1,
  CENTER: 2
};

const DRIVER_FIELD_OF_VIEW_NAME = {
  [DRIVER_FIELD_OF_VIEW.RIGHT]: 'Right',
  [DRIVER_FIELD_OF_VIEW.LEFT]: 'Left',
  [DRIVER_FIELD_OF_VIEW.CENTER]: 'Center'
};

// Default settings for ADAS non-repairable area
// Distances measured in % from windshield segment's top edge center point
const DEFAULT_ADAS_FOV = [
  // TopLeft
  { x: -22, y: 0 },
  // TopRight
  { x: 22, y: 0 },
  // BottomRight
  { x: 22, y: 18 },
  // BottomLeft
  { x: -22, y: 18 },
];

// Default settings for left-sided drivers' (US, Europe, most of the world) field-of-view non-repairable area
// Distances measured in % from windshield segment's top right corner
const DEFAULT_DRIVER_FOV_LEFT = [
  // TopLeft
  { x: -35, y: 20 },
  // TopRight
  { x: 0, y: 20 },
  // BottomRight
  { x: 5, y: 79 },
  // BottomLeft
  { x: -22, y: 83 },
];

// Default settings for right-sided drivers' (UK, Australia etc) field-of-view non-repairable area
// Distances measured in % from windshield segment's top left corner
const DEFAULT_DRIVER_FOV_RIGHT = [
  // TopLeft
  { x: 0, y: 20 },
  // TopRight
  { x: 35, y: 20 },
  // BottomRight
  { x: 22, y: 83 },
  // BottomLeft
  { x: -5, y: 79 },
];

// Default settings for centrally located drivers' field-of-view non-repairable area
// Distances measured in % from windshield segment's top edge center point
const DEFAULT_DRIVER_FOV_CENTER = [
  // TopLeft
  { x: -16, y: 20 },
  // TopRight
  { x: 16, y: 20 },
  // BottomRight
  { x: 12, y: 81 },
  // BottomLeft
  { x: -12, y: 81 },
];

export default {
  NAME,
  DRIVER_FIELD_OF_VIEW,
  DRIVER_FIELD_OF_VIEW_NAME,
  DEFAULT_ADAS_FOV,
  DEFAULT_DRIVER_FOV_LEFT,
  DEFAULT_DRIVER_FOV_RIGHT,
  DEFAULT_DRIVER_FOV_CENTER
};
