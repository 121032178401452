import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { debounceTime, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { useOutsideAlerter } from '../../hooks';
import { ReactComponent as DropDownArrow } from '../../assets/img/icn-arrow-drop-down.svg';
import { getTimeFrame } from '../../utils/date';
import { Error } from '../Form';

import './css/combobox.css';
import Tags from '../Tags/Tags';

export const comboTypes = {
  multiCombo: 'multiCombo',
  infiniteCombo: 'infiniteCombo',
};

const { multiCombo, infiniteCombo } = comboTypes;

const Combobox = React.forwardRef((props, ref) => {
  const {
    type,
    defaultValue,
    values,
    label,
    getSelectedItems,
    initialSelection,
    totalPages,
    page,
    inputId,
    setPage,
    setSearchedPhrase,
    setSelectedItem,
    isInternalFiltration,
    disableInfiniteScroll,
    onChangeHandler,
    error,
    customRender,
    placeholder,
    autoComplete,
    inputIcon,
    searchingDisabled,
    testId
  } = props;

  const searchItemInputRef = useRef(null);
  const [itemsDropped, setItemsDropped] = useState(false);
  const [searchedItem, setSearchedItem] = useState(defaultValue);
  const [selectedItems, setSelectedItems] = useState(initialSelection || []);

  useOutsideAlerter(ref, setItemsDropped);

  useEffect(() => {
    if (getSelectedItems) {
      getSelectedItems(selectedItems);
    }
  }, [selectedItems, getSelectedItems]);

  /** this hook resets the searched text for multiCombo-type when we close the dropdown */
  useEffect(() => {
    if (!itemsDropped && !!searchedItem && type === multiCombo) {
      setSearchedItem('');
    }
  }, [itemsDropped, searchedItem, type]);

  /** @renderItemsJsx is a function that renders the list of items */
  const renderItemsJsx = (values) => {
    if (!!!searchedItem || (searchedItem && type === infiniteCombo && !isInternalFiltration)) {
      const jsx = values.map((item, i) => (

        customRender ? <Fragment key={item?.id}>{customRender(item, onItemClickHandler)}</Fragment> : (
          <li key={item?.id + i} className="item" onClick={() => onItemClickHandler(item)}>
            {item?.name}
          </li>
        )
      ));

      return jsx;
    }

    // isInternalFiltration tells if we want to do api or frontend filtration
    if ((searchedItem && type === multiCombo) || isInternalFiltration) {
      const jsx = values
        .filter((item) => item.name.toLowerCase().includes(searchedItem.toLowerCase()))
        .map((item) => (
          <li
            key={item.id}
            className={`item ${item.name.toLowerCase().includes(searchedItem.toLowerCase()) ? 'matched-item' : ''}`}
            onClick={() => onItemClickHandler(item)}
          >
            {item.name}
          </li>
        ));

      return jsx;
    }

    return null;
  };

  /** @onInfiniteComboOnscrollHandler is a function that handles the scrolling of infiniteCombo */
  const onInfiniteComboOnscrollHandler = (ev) => {
    if (type !== infiniteCombo || disableInfiniteScroll) return;

    const bottom = ev.currentTarget.scrollHeight - ev.currentTarget.scrollTop === ev.currentTarget.clientHeight;
    if (page !== totalPages && bottom) {
      setPage((page) => page + 1);
    }
  };

  /** @removeItem is a function used to deleted selected items for multiCombo */
  const removeItem = (itemTobeRemoved) => {
    const shallowSelectedItems = selectedItems;

    const itemIndexTobeRemoved = shallowSelectedItems.findIndex((item) => item.id === itemTobeRemoved.id);

    shallowSelectedItems.splice(itemIndexTobeRemoved, 1);

    setSelectedItems([...shallowSelectedItems]);
  };

  /** @onInfiniteComboItemClick is a function that handles the clicking of infiniteCombo item aka single-selector */
  const onInfiniteComboItemClick = (item) => {
    searchItemInputRef.current.value = item.name || getTimeFrame(item.createdAt, item.completedAt);

    setSelectedItem(item);

    setItemsDropped(false);
  };

  /** @onMultiComboItemClick is a function that handles the multiple clicking of multiCombo items aka multi-selector */
  const onMultiComboItemClick = (item) => {
    const itemIndex = selectedItems.findIndex((userItem) => userItem.id === item.id);

    if (itemIndex < 0) {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const onItemClickHandler = (item) => {
    if (type === infiniteCombo) {
      onInfiniteComboItemClick(item);
    }

    if (type === multiCombo) {
      onMultiComboItemClick(item);
    }
  };

  const onSearchItemOnChangeHandler = () => {
    if (!searchItemInputRef.current) return;

    itemsDropped && setItemsDropped(true);
    setSearchedItem(searchItemInputRef.current.value);

    if (type === infiniteCombo && !isInternalFiltration) {
      const subject = new BehaviorSubject(searchItemInputRef.current.value);

      subject.pipe(
        map((value) => value),
        debounceTime(500),
      )
        .subscribe((value) => {
          setPage(1);
          setSearchedPhrase(value);
        });
    }
  };

  return (
    <div
      className="combobox-container"
      id="comboboxContainer"
      role="combobox"
      aria-controls="comboListbox"
      aria-expanded={itemsDropped}
      aria-owns="combo-listbox"
      aria-haspopup="listbox"
      onChange={onChangeHandler}
    >
      <div role="row" className="row-1">
        {label && (
          <label htmlFor={inputId} className="row1__col1 label" id="comboLabel">
            {label}
          </label>
        )}

        <div className="row-1__col2">
          <div ref={ref}>
            <div
              className={`box-wrapper ${error ? 'border-red' : ''}`}
              role="button"
              tabIndex={0}
              onMouseDown={() => setItemsDropped(!itemsDropped)}
            >
              <input
                ref={searchItemInputRef}
                autoComplete={autoComplete}
                readOnly={searchingDisabled}
                type="search"
                name="searchedItem"
                defaultValue={defaultValue}
                className={`combo-input ${itemsDropped ? 'combo-input--blured' : ''}`}
                id={inputId}
                placeholder={placeholder}
                aria-label={label || `Dropdown ${searchingDisabled ? 'currently selected value' : 'search field'}`}
                aria-autocomplete="list"
                aria-controls="comboListbox"
                onChange={onSearchItemOnChangeHandler}
                data-testid={testId}
              />

              <div className="icon-wrapper">
                {inputIcon || <DropDownArrow className="svg-md" />}
                &nbsp;
              </div>
            </div>

            {error && <Error>{error}</Error>}

            {itemsDropped && values && (
              <ul
                className="items-wrapper thin-scrollbar"
                aria-labelledby="comboLabel"
                role="listbox"
                id="comboListbox"
                onScroll={onInfiniteComboOnscrollHandler}
              >
                {renderItemsJsx(values)}
              </ul>
            )}
          </div>

          {type === multiCombo && selectedItems.length > 0 && (
            <Tags tags={selectedItems} onRemoveTag={removeItem} />
          )}
        </div>
      </div>
    </div>
  );
});

Combobox.propTypes = {
  type: PropTypes.oneOf(['multiCombo', 'infiniteCombo']),
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  getSelectedItems: PropTypes.func,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  inputId: PropTypes.string,
  setPage: PropTypes.func,
  setSearchedPhrase: PropTypes.func,
  setSelectedItem: PropTypes.func,
  isInternalFiltration: PropTypes.bool,
  disableInfiniteScroll: PropTypes.bool,
  error: PropTypes.string,
  onChangeHandler: PropTypes.func,
  initialSelection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  customRender: PropTypes.func,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  inputIcon: PropTypes.element,
  searchingDisabled: PropTypes.bool,
  testId: PropTypes.string
};

Combobox.defaultProps = {
  inputId: 'searchItemInput',
  placeholder: 'Search',
  autoComplete: 'off',
  defaultValue: '',
  searchingDisabled: true,
  testId: 'not-set'
};
export default Combobox;
