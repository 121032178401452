const NAME = 'customers';

const SUBSCRIPTION_TYPES = {
  LITE: 2,
  BASIC: 3,
  PRO: 4,
  ENTERPRISE: 5,
};

export default {
  NAME,
  SUBSCRIPTION_TYPES,
};
