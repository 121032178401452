const prefix = 'drivex/customerConfigurations';

const LOAD_CURRENT_CUSTOMER_CONFIGURATION = `${prefix}/LOAD_CURRENT_CUSTOMER_CONFIGURATION`;
const LOAD_CURRENT_CUSTOMER_CONFIGURATION_SUCCESS = `${prefix}/LOAD_CURRENT_CUSTOMER_CONFIGURATION_SUCCESS`;
const LOAD_CURRENT_CUSTOMER_LOGO_SUCCESS = `${prefix}/LOAD_CURRENT_CUSTOMER_LOGO_SUCCESS`;
const CLEAR_CUSTOMER_CONFIGURATION = `${prefix}/CLEAR_CUSTOM_THEME`;

export default {
  LOAD_CURRENT_CUSTOMER_CONFIGURATION,
  LOAD_CURRENT_CUSTOMER_CONFIGURATION_SUCCESS,
  LOAD_CURRENT_CUSTOMER_LOGO_SUCCESS,
  CLEAR_CUSTOMER_CONFIGURATION
};
