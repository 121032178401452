import { createSelector } from 'reselect';

import constants from './constants';

const selectCustomersState = (state) => state[constants.NAME];
const selectCustomerDetails = createSelector([selectCustomersState], (customers) => customers.customerDetails);
const selectCurrentCustomer = createSelector([selectCustomersState], (customers) => customers.customer);

export default {
  selectCustomerDetails,
  selectCurrentCustomer,
};
