import React from 'react';
import PropTypes from 'prop-types';

import './sass/footer.scss';

const Footer = ({ children }) => <div role="contentinfo" className="footer">{children}</div>;

Footer.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Footer;
