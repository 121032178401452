import types from './types';

const loadCommentsSuccess = (imageId, comments) => ({
  type: types.LOAD_COMMENTS_SUCCESS,
  payload: {
    imageId,
    comments,
  },
});

const createCommentSuccess = (comment) => ({
  type: types.CREATE_COMMENT_SUCCESS,
  payload: {
    comment,
  },
});

export default {
  loadCommentsSuccess,
  createCommentSuccess,
};
