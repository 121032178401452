import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import VehiclesFilterModal from './containers/VehiclesFilterModal';
import { Button } from '../../../../components';
import { ReactComponent as IconFilter } from '../../../../assets/img/icn-filter.svg';
import { ReactComponent as IconSearch } from '../../../../assets/img/icn-search.svg';
import { useVehicleFilterContext } from '../../Vehicles';
import { actions as vehiclesActions } from '../../../../redux/vehicles';
import { trackEvent, EVENT_TYPES } from '../../../../api/analytics';

const VehiclesFilter = () => {
  const [vehiclesFilter, setVehiclesFilter] = useVehicleFilterContext();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const dispatch = useDispatch();

  const searchPhraseFormik = useFormik({
    initialValues: {
      searchPhrase: '',
    },
    onSubmit: ({ searchPhrase }) => handleSearchPhraseSubmit(searchPhrase),
    validateOnChange: false,
  });

  useEffect(() => {
    searchPhraseFormik.setFieldValue('searchPhrase', vehiclesFilter.searchPhrase || '');
    // eslint-disable-next-line
  }, [vehiclesFilter]);

  const handleSearchPhraseSubmit = (searchPhrase) => {
    const newVehiclesFilter = {
      ...vehiclesFilter,
    };

    if (!searchPhrase) {
      delete newVehiclesFilter.searchPhrase;
    } else {
      newVehiclesFilter.searchPhrase = searchPhrase;
    }

    if (vehiclesFilter.searchPhrase === newVehiclesFilter.searchPhrase) {
      dispatch(vehiclesActions.setPagedResultLoaded(false));
    } else {
      setVehiclesFilter({
        ...newVehiclesFilter,
      });

      // Track event in Analytics
      trackEvent(EVENT_TYPES.VEHICLES_FILTER_SEARCHED);
    }
  };

  return (
    <>
      <form onSubmit={searchPhraseFormik.handleSubmit} style={{ position: 'relative', display: 'inline-block' }}>
        <div className="form-group-inline mr-2">
          <div className="input-group">
            <input
              id="searchPhrase"
              name="searchPhrase"
              type="text"
              className="form-control"
              placeholder="Search vehicles"
              value={searchPhraseFormik.values.searchPhrase}
              onChange={searchPhraseFormik.handleChange}
              style={{ borderRadius: '0.1875rem' }}
            />
            <button type="submit" className="btn btn-link is-field-button">
              <IconSearch />
            </button>
          </div>
        </div>
      </form>
      <Button
        className="btn mr-2 filter-btn"
        variation="light-blue"
        type="button"
        testId="vehicleFilterBtn"
        onClick={() => setShowFilterModal(true)}
      >
        <span className="d-flex align-items-center">
          <IconFilter />
          {' '}
          Filter
        </span>
      </Button>
      {showFilterModal && (
        <VehiclesFilterModal searchPhrase={searchPhraseFormik.values.searchPhrase} setShowModal={setShowFilterModal} />
      )}
    </>
  );
};

export default VehiclesFilter;
