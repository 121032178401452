import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import * as serviceWorker from './serviceWorker';
import App from './App';

import { rootReducer } from './redux';

const store = createStore(
  rootReducer,
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

if (process.env.REACT_APP_SPRIG_ENVIRONMENT_ID) {
  window.loadSprig(process.env.REACT_APP_SPRIG_ENVIRONMENT_ID);
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('rootApp'),
);

serviceWorker.unregister();
