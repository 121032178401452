import React, { useEffect, useCallback } from 'react';

export const USE_MODAL_ALERTER_TYPES = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
};

export function useModalAlerter(shouldShowModal, setShouldShowModal, message, type = USE_MODAL_ALERTER_TYPES.SUCCESS, onOptionalClickCallback) {
  useEffect(() => {
    setShouldShowModal(shouldShowModal);
  }, [shouldShowModal, setShouldShowModal]);

  const renderModal = useCallback(() => {
    if (!shouldShowModal || !message) return;

    return (
      <div className={`alert alert-${type} mt-3`} role="alert">
        {message}
        <button
          type="button"
          className="close"
          onClick={() => {
            setShouldShowModal(false);
            if (onOptionalClickCallback) {
              onOptionalClickCallback();
            }
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }, [shouldShowModal, message, type, setShouldShowModal, onOptionalClickCallback]);

  return { renderModal };
}
