import React from 'react';
import { useSelector } from 'react-redux';

import { selectors as subscriptionSelectors } from '../../redux/subscriptions';
import { selectors as usersSelectors } from '../../redux/users';

export const FEATURES = {
  accountLimit: 'accountLimit',
  customTheme: 'customTheme',
  manageImages: 'manageImages',
  customWorkflows: 'customWorkflows',
  scanVolume: 'scanVolume',
  monthlySupport: 'monthlySupport',
  onboardingSupport: 'onboardingSupport',
  customDevelopment: 'customDevelopment',
};

export const useSubscriptionLimit = (feature) => {
  const users = useSelector(usersSelectors.selectUsers);
  const enabledFeatures = useSelector(subscriptionSelectors.selectEnabledFeatures);

  const isFeatureDisabledHandler = () => {
    if (feature === FEATURES.accountLimit) {
      if ((enabledFeatures && enabledFeatures[feature] === null) || !users) {
        return false;
      }

      if (users.totalUsers >= enabledFeatures && enabledFeatures[feature]) {
        return true;
      }
    }

    return enabledFeatures && !enabledFeatures[feature];
  };

  const [isFeatureDisabled, setIsFeatureDisabled] = React.useState(() => isFeatureDisabledHandler());

  React.useEffect(() => {
    setIsFeatureDisabled(isFeatureDisabledHandler());

    // eslint-disable-next-line
  }, [enabledFeatures, users]);

  return { isFeatureDisabled };
};
