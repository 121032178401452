import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MenuPage } from '../../layouts';
import { routes } from '../../routes/Routes';
import { makeGet, makeGetFile } from '../../api/ajax';
import { useSubscriptionLimit, FEATURES } from '../../hooks';
import { constants as subscriptionConstants } from '../../redux/subscriptions';
import { PageTitle, InfoModal } from '../../components';
import { actions as customerConfigurationsActions, selectors as customerConfigurationsSelectors } from '../../redux/customerConfigurations';

import ThemeSettings from './containers/ThemeSettings/ThemeSettings';

const Customization = () => {
  const dispatch = useDispatch();
  const customerConfiguration = useSelector(customerConfigurationsSelectors.selectCustomerConfiguration);
  const isLoading = !customerConfiguration;

  const { isFeatureDisabled: isThemingSubscriptionLimited } = useSubscriptionLimit(FEATURES.customTheme);

  useEffect(() => {
    if (!customerConfiguration) {
      makeGet('customerConfigurations/current').subscribe(({ response }) => {
        dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(response));
      });

      makeGetFile('customerConfigurations/current/custom-logo').subscribe(({ response }) => {
        // When custom logo is configured, display it
        if (response && response.size > 0) {
          dispatch(customerConfigurationsActions.loadCurrentCustomerLogoSuccess(response));
        }
      });
    }
  }, [customerConfiguration, dispatch]);

  const brandingMarkup = (
    <>
      <PageTitle>
        <PageTitle.Left headingText="Theme tool - your branding" isLoading={isLoading} />
      </PageTitle>
      {
        !isLoading && (
          <>
            {
              !isThemingSubscriptionLimited ? (
                <ThemeSettings />
              ) : (
                <>
                  {isThemingSubscriptionLimited && (
                    <InfoModal
                      type="warning"
                      message={subscriptionConstants.SUBSCRIPTION_MESSAGES.cannotConfigureCustomTheme}
                      shownModal={isThemingSubscriptionLimited}
                    />
                  )}
                </>
              )
            }
          </>
        )
      }
    </>
  );

  return <MenuPage currentRoute={routes.branding}>{brandingMarkup}</MenuPage>;
};

export default Customization;
