import React from 'react';
import PropTypes from 'prop-types';

import { getUtcToday, isCurrentMonth } from '../../utils/date';

import './sass/month-pager.scss';

const MonthPager = ({ switchMonth, activeMonth, disableSwitching }) => {
  if (!activeMonth) return null;

  const monthLabel = isCurrentMonth(activeMonth)
    ? 'This month'
    : `${activeMonth.getFullYear()} ${activeMonth.toLocaleString('en-EN', { month: 'long' })}`;

  const utcToday = getUtcToday();
  const prevButtonDisabled = disableSwitching && activeMonth < utcToday;
  const nextButtonDisabled = disableSwitching && activeMonth > utcToday;

  return (
    <nav className="month-pager">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            type="button"
            onClick={() => switchMonth(-1)}
            disabled={prevButtonDisabled}
          >
            &lt;
          </button>
        </li>
        <li className="page-item">
          <span className="month-label">
            {monthLabel}
          </span>
        </li>
        <li className="page-item">
          <button
            className="page-link"
            type="button"
            onClick={() => switchMonth(1)}
            disabled={nextButtonDisabled}
          >
            &gt;
          </button>
        </li>
      </ul>
    </nav>
  );
};

MonthPager.propTypes = {
  switchMonth: PropTypes.func.isRequired,
  disableSwitching: PropTypes.bool,
  activeMonth: PropTypes.shape({ getFullYear: PropTypes.func, })
};

export default MonthPager;
