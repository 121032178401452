import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard, QrCode } from '..';

const PublicAccessLink = ({ link }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ paddingRight: '0.3125rem' }}>
      <QrCode url={link} />
    </div>
    <div style={{ paddingRight: '1rem' }}>
      <div style={{ marginBottom: '0.3125rem' }}>
        <a href={link} target="_blank" rel="noopener noreferrer" className="link" style={{ fontWeight: 500 }}>
          {link}
        </a>
      </div>
      <div style={{ marginBottom: '0.3125rem' }}>
        <CopyToClipboard targetText={link} buttonText="Copy link to clipboard" textAreaId="publicLinkCopyTextArea" />
      </div>
    </div>
  </div>
);

PublicAccessLink.propTypes = {
  link: PropTypes.string.isRequired,
};

export default PublicAccessLink;
