import types from './types';

const defaultState = {
  userAgents: []
};

const imagesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_USER_AGENT_BLACKLIST_SUCCESS: {
      const { userAgents } = action.payload;
      return {
        ...state,
        userAgents
      };
    }
    case types.DELETE_BLACKLISTED_USER_AGENT: {
      const { id } = action.payload;
      return {
        ...state,
        userAgents: state.userAgents.filter((userAgent) => userAgent.id !== id)
      };
    }
    case types.NEW_BLACKLISTED_USER_AGENT: {
      const { id, phrase, comment, operatingSystem } = action.payload;
      const newUserAgents = [...state.userAgents];
      newUserAgents.push({ id, phrase, comment, operatingSystem });
      return {
        ...state,
        userAgents: newUserAgents
      };
    }
    default:
      return state;
  }
};

export default imagesReducer;
