import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

import './sass/color-picker.scss';
import { useThemeContext } from '../../../../ThemeSettings';
import { Error } from '../../../../../../../../components/Form';
import { THEMES_STATE_KEYS } from '../../../../../../../../utils/themes';
import { composeEvents } from '../../../../../../../../utils/composeEvents';

const ColorPicker = React.forwardRef((props, ref) => {
  const { setTheme } = useThemeContext();
  const [showColorPicker, setShowColorPicker] = useState(false);

  const { id, label, name, value, error } = props;
  const isControlled = props.onChange !== undefined;

  function mousedownEventHandler(ev) {
    if (!showColorPicker) return;

    ref.current && !ref.current.contains(ev.target) && setShowColorPicker(false);
  }

  useEffect(() => {
    document.addEventListener('mousedown', mousedownEventHandler);

    return () => {
      document.removeEventListener('mousedown', mousedownEventHandler);
    };
  });

  function onChangeHandler() {
    return isControlled
      ? composeEvents(props.onChange, undefined)
      : composeEvents(undefined, (value) => {
        setTheme(name, value.hex);
      });
  }

  return (
    <div ref={ref} className="color-picker-wrapper">
      <label
        htmlFor={id}
        className="label"
      >
        {label}
        <div
          role="button"
          tabIndex={-1}
          className="label__row1"
          aria-label="Click to open and close color selection panel"
          onClick={() => setShowColorPicker((prevState) => !prevState)}
        >
          <input
            type="text"
            value={value}
            aria-label={label}
            className="color-figure"
            onChange={({ target }) => {
              if (isControlled) {
                props.onChange({ hex: target.value });
              } else {
                setTheme(name, target.value);
              }
            }}
            required
          />
          <span className="select-color" style={{ backgroundColor: value }} />
        </div>
        {error && <Error>{error}</Error>}
      </label>
      {showColorPicker && (
        <div className="color-picker-pallet">
          <ChromePicker
            color={value}
            aria-label={label}
            className="color-picker"
            onChange={onChangeHandler()}
            disableAlpha
          />
        </div>
      )}
    </div>
  );
});

ColorPicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.oneOf(Object.keys(THEMES_STATE_KEYS)).isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

export default ColorPicker;
