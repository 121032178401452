import { createSelector } from 'reselect';

import constants from './constants';

const selectDamagesState = (state) => state[constants.NAME];

const getPhotoSeriesIdParam = (_, photoSeriesId) => photoSeriesId;
const getImageIdParam = (_, __, imageId) => imageId;

const selectPhotoSeriesDamages = createSelector(
  selectDamagesState,
  getPhotoSeriesIdParam,
  ({ byPhotoSeriesId }, photoSeriesId) => {
    if (!byPhotoSeriesId) return [];

    const damages = byPhotoSeriesId[photoSeriesId]?.damages;
    if (!damages) return [];

    return damages;
  },
);

const selectImageDamages = createSelector(
  selectDamagesState,
  getPhotoSeriesIdParam,
  getImageIdParam,
  ({ byPhotoSeriesId }, photoSeriesId, imageId) => {
    if (!byPhotoSeriesId) return [];

    const byImageId = byPhotoSeriesId[photoSeriesId]?.byImageId;
    if (!byImageId) return [];

    const damages = byImageId[imageId];
    if (!damages) return [];

    return damages;
  }
);

const selectCustomDamageTypes = createSelector(selectDamagesState, ({ customDamageTypes }) => customDamageTypes);

export default {
  selectPhotoSeriesDamages,
  selectImageDamages,
  selectCustomDamageTypes
};
