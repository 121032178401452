import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import classNames from 'classnames';

const Pagination = ({ users, page, changePage }) => {
  if (!users) return null;
  return (
    <nav aria-label="Users page navigation">
      <ul className="pagination m-0">
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Previous"
            type="button"
            onClick={() => changePage(page - 1)}
            disabled={page === 1}
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </button>
        </li>
        {_.map(_.range(1, users.pageCount + 1), (u, i) => {
          const classes = classNames('page-item', {
            active: page === i + 1,
          });
          return (
            <li className={classes} key={i}>
              <button className="page-link" type="button" onClick={() => changePage(i + 1)}>
                {parseInt(i + 1, 10)}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Next"
            type="button"
            onClick={() => changePage(page + 1)}
            disabled={page === users.pageCount}
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  users: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  page: PropTypes.number,
  changePage: PropTypes.func,
};

export default Pagination;
