import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import './sass/qr-code.scss';

const QrCode = ({ url }) => {
  if (!url) return null;

  return <div className="qr-code-wrapper"><QRCode value={url} size={140} /></div>;
};

QrCode.propTypes = {
  url: PropTypes.string.isRequired,
};

export default QrCode;
