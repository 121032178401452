const FILE_ENTITY_TYPES = {
  PHOTO_SERIES_ZIP_ARCHIVE: 1,
  PHOTO_SERIES_PDF_REPORT: 2,
  PHOTO_SERIES_PURGE_TRANSFER_LOG: 5,
  PHOTO_SERIES_ZIP_ARCHIVE_WITH_ATTEMPTED_IMAGES: 6,
  GENERAL_TERMS_AND_CONDITIONS: 7
};

export default {
  FILE_ENTITY_TYPES
};
