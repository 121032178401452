import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './sass/vehicle-table.scss';

const rowIndicatorColours = {
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
};

const VehicleTableRow = ({ rowIndicatorColour, children, vehicle, onSelectVehicle, selected, isLoadingTableData }) => {
  const location = useLocation();

  const { pathname, search } = location;

  const photoSeriesRoute = {
    pathname: `/vehicles/${vehicle && vehicle.id}/photoSeries/${vehicle && vehicle.photoSeriesId}`,
    state: { from: `${pathname}${search}`.trim() },
  };

  const classes = classNames('vehicle-table-row', {
    [`row-indicator-${rowIndicatorColour}`]: rowIndicatorColour,
    selected,
  });

  return (
    <Link to={photoSeriesRoute} className="photoseries-link" aria-disabled style={{ pointerEvents: `${isLoadingTableData ? 'none' : 'auto'}` }}>
      <div className={classes} role="button" tabIndex={0} onClick={onSelectVehicle}>
        {children}
      </div>
    </Link>
  );
};

const VehicleTable = ({ children }) => <div className="vehicle-table">{children}</div>;

VehicleTable.VehicleTableRow = VehicleTableRow;
VehicleTable.rowIndicatorColours = rowIndicatorColours;

VehicleTableRow.propTypes = {
  rowIndicatorColour: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onSelectVehicle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  vehicle: PropTypes.object,
  selected: PropTypes.bool,
  isLoadingTableData: PropTypes.bool
};

VehicleTable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VehicleTable;
