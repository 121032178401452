import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { routes } from '../../routes/Routes';
import { MenuPage } from '../../layouts';
import { PageTitle, Badge, Button } from '../../components';
import { selectors, actions } from '../../redux/windshieldRepairabilityProfiles';
import { authenticationService } from '../../services';
import { constants as sessionConstants } from '../../redux/session';
import { makeGet } from '../../api/ajax';
import { NewWindshieldRepairabilityProfileModal, WindshieldRepairabilityProfilesAccordion } from './containers';

const WindshieldRepairabilityProfiles = () => {
  const [showAddNewSuccessMessage, setShowAddNewSuccessMessage] = useState(null);
  const [showAddNewModal, setShowAddNewModal] = useState(false);

  const {
    VIEW_WINDSHIELD_REPAIRABILITY_PROFILES,
    CREATE_WINDSHIELD_REPAIRABILITY_PROFILE
  } = sessionConstants.PERMISSION_TYPES;

  const dispatch = useDispatch();
  const windshieldRepairabilityProfiles = useSelector(selectors.selectWindshieldRepairabilityProfiles);
  const isLoading = !windshieldRepairabilityProfiles;

  useEffect(() => {
    if (windshieldRepairabilityProfiles) return;
    if (authenticationService.hasPermission(VIEW_WINDSHIELD_REPAIRABILITY_PROFILES)) {
      makeGet('windshieldRepairProfiles?pageSize=150').subscribe(({ response }) => {
        dispatch(actions.loadWindshieldRepairabilityProfilesSuccess(response));
      });
    }
  }, [windshieldRepairabilityProfiles, VIEW_WINDSHIELD_REPAIRABILITY_PROFILES, dispatch]);

  return (
    <MenuPage currentRoute={routes.windshieldRepairabilityProfiles}>
      <PageTitle>
        <PageTitle.Left headingText="Windshield repairability profiles" isLoading={isLoading}>
          {
            !isLoading && (
              <Badge className="ml-2">
                { windshieldRepairabilityProfiles?.rowCount !== undefined ? windshieldRepairabilityProfiles.rowCount : 0 }
              </Badge>
            )
          }
        </PageTitle.Left>
        {
          authenticationService.hasPermission(CREATE_WINDSHIELD_REPAIRABILITY_PROFILE) && (
            <PageTitle.Right>
              <Button
                testId="AddNewWindshieldRepairabilityProfileBtn"
                className="btn btn-primary"
                onClick={() => {
                  setShowAddNewSuccessMessage(null);
                  setShowAddNewModal(true);
                }}
              >
                + Add new
              </Button>
            </PageTitle.Right>
          )
        }
      </PageTitle>
      {
        !isLoading && (
          <>
            <div className="row">
              <div className="col-sm-12">
                <p>
                  A list of windshield repairability profiles that are selectable under workflow settings
                  for vehicle windshield repairability decision making.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                {
                  showAddNewSuccessMessage && (
                    <Alert variant="success" dismissible onClose={() => setShowAddNewSuccessMessage(null)}>
                      {showAddNewSuccessMessage}
                    </Alert>
                  )
                }
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                {
                  windshieldRepairabilityProfiles?.results?.length
                    ? <WindshieldRepairabilityProfilesAccordion windshieldRepairabilityProfiles={windshieldRepairabilityProfiles.results} />
                    : <div className="border-top w-100 pt-3">No windshield repairability profiles have been configured.</div>
                }
              </div>
            </div>
            <NewWindshieldRepairabilityProfileModal
              title="Add new windshield repairability profile"
              showModal={showAddNewModal}
              setShowAddNewModal={setShowAddNewModal}
              initialValues={{ name: '' }}
              setShowAddNewSuccessMessage={setShowAddNewSuccessMessage}
            />
          </>
        )
      }
    </MenuPage>
  );
};

export default WindshieldRepairabilityProfiles;
