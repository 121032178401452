import React from 'react';
import PropTypes from 'prop-types';

import './sass/settings-summary.scss';

const maxContentLength = 15;

const SettingsSummary = ({ title, testId, content, onEditClick, tooltipTitle }) => {
  const visible = !onEditClick ? 'hidden' : '';
  return (
    <div className="row no-gutters border mb-2 py-1 px-3 rounded settings-summary">
      <div className="col col-sm-6 title" title={tooltipTitle}>{title}</div>
      <div className="col col-sm-6 text-right">
        <span className="text-muted" title={content}>
          {content?.length > maxContentLength
            ? `${content.substring(0, maxContentLength - 3)}...`
            : content}
        </span>
        <button
          type="button"
          data-testid={testId}
          className="btn btn-link text-right"
          onClick={onEditClick}
          style={{ visibility: visible }}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

SettingsSummary.propTypes = {
  title: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  content: PropTypes.string,
  onEditClick: PropTypes.func,
  tooltipTitle: PropTypes.string
};

export default SettingsSummary;
