export const getHexFromColorObject = (colorObject) =>
  (colorObject ? RGBToHex(colorObject.red, colorObject.green, colorObject.blue) : '');

export const getColorObjectFromHex = (hex) => {
  const rgb = hexToRGB(hex);
  const hsl = RGBToHSL(rgb);

  return {
    red: rgb.red,
    green: rgb.green,
    blue: rgb.blue,
    hue: hsl.hue,
    saturation: hsl.saturation,
    lightness: hsl.lightness,
  };
};

const RGBToHex = (red, green, blue) => {
  red = red.toString(16);
  green = green.toString(16);
  blue = blue.toString(16);

  if (red.length === 1) {
    red = `0${red}`;
  }
  if (green.length === 1) {
    green = `0${green}`;
  }
  if (blue.length === 1) {
    blue = `0${blue}`;
  }

  return `#${red}${green}${blue}`;
};

const hexToRGB = (hex) => {
  let red = 0;
  let green = 0;
  let blue = 0;

  // 3 digits
  if (hex.length === 4) {
    red = `0x${hex[1]}${hex[1]}`;
    green = `0x${hex[2]}${hex[2]}`;
    blue = `0x${hex[3]}${hex[3]}`;
    // 6 digits
  } else if (hex.length === 7) {
    red = `0x${hex[1]}${hex[2]}`;
    green = `0x${hex[3]}${hex[4]}`;
    blue = `0x${hex[5]}${hex[6]}`;
  }

  return { red: +red, green: +green, blue: +blue };
};

const RGBToHSL = (rgb) => {
  let { red } = rgb;
  let { green } = rgb;
  let { blue } = rgb;

  // Make r, g, and b fractions of 1
  red /= 255;
  green /= 255;
  blue /= 255;

  // Find greatest and smallest channel values
  const cmin = Math.min(red, green, blue);
  const cmax = Math.max(red, green, blue);
  const delta = cmax - cmin;
  let hue = 0;
  let saturation = 0;
  let lightness = 0;

  // Calculate hue
  // No difference
  if (delta === 0) {
    hue = 0;
  }
  // Red is max
  else if (cmax === red) {
    hue = ((green - blue) / delta) % 6;
  }
  // Green is max
  else if (cmax === green) {
    hue = (blue - red) / delta + 2;
  }
  // Blue is max
  else {
    hue = (red - green) / delta + 4;
  }

  hue = Math.round(hue * 60);

  // Make negative hues positive behind 360°
  if (hue < 0) {
    hue += 360;
  }

  // Calculate lightness
  lightness = (cmax + cmin) / 2;

  // Calculate saturation
  saturation = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));

  // Multiply l and s by 100
  saturation = +(saturation * 100).toFixed(0);
  lightness = +(lightness * 100).toFixed(0);

  return { hue, saturation, lightness };
};
