import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Table, Text, Toggle, Checkbox } from '../../../../../components';
import { selectors, actions } from '../../../../../redux/customImageTypes';
import { makeGet } from '../../../../../api/ajax';
import { constants as workflowConstants } from '../../../../../redux/workflows';

const MANUAL_DAMAGE_LABELLING_TYPE = {
  OFF: 0,
  BLOCKER: 1,
  WARNING: 2,
  HIDDEN: 3
};

const CustomImageTypeSelection = ({ imageSubTypes, setFieldValue, showDamageMarking }) => {
  const dispatch = useDispatch();
  const customImageTypes = useSelector(selectors.selectCustomImageTypes);

  useEffect(() => {
    if (!customImageTypes) {
      makeGet('customImageTypes?pageSize=150').subscribe(({ response }) => {
        dispatch(actions.loadCustomImageTypesSuccess(response));
      });
    }
  }, [customImageTypes, dispatch]);

  if (customImageTypes === null) return null;

  return (
    <>
      <Table
        header={
          <>
            <div className="col-sm-1 row-text">
              <Text size={2} weight="bold" inline>Order</Text>
            </div>
            <div className={`col-sm-${showDamageMarking ? 3 : 5} row-text`}>
              <Text size={2} weight="bold" inline>Name</Text>
            </div>
            <div className="col-sm-1 row-text">
              <Text size={2} inline>Mandatory</Text>
            </div>
            {
              showDamageMarking && (
                <div className="col-sm-2 row-text">
                  <Text size={2} inline>Damage marking</Text>
                </div>
              )
            }
            <div className="col-sm-1 row-text">
              <Text size={2} inline>Comments allowed</Text>
            </div>
            <div className="col-sm-2 row-text">
              <Text size={2} inline>Ask comment automatically</Text>
            </div>
            <div className="col-sm-2 row-text">
              <Text size={2} inline>Show comment in report</Text>
            </div>
          </>
        }
        rows={customImageTypes.results.map((customImageType) => ({
          id: customImageType.id,
          name: customImageType.name,
          deleted: false,
          row: <CustomImageTypeRow
            key={customImageType.id}
            customImageType={customImageType}
            imageSubTypes={imageSubTypes}
            setFieldValue={setFieldValue}
            showDamageMarking={showDamageMarking}
          />
        }))}
      />
    </>
  );
};

CustomImageTypeSelection.propTypes = {
  imageSubTypes: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  showDamageMarking: PropTypes.bool.isRequired
};

const getDefaultManualDamageLabellingType = (customImageType, imageSubTypes) => {
  const imageSubType = imageSubTypes.find((imageSubType) => imageSubType.customImageSubTypeId === customImageType.id);
  if (!imageSubType) return MANUAL_DAMAGE_LABELLING_TYPE.OFF;
  if (imageSubType.manualDamageLabelling?.enabled !== undefined && imageSubType.manualDamageLabelling.enabled) {
    switch (imageSubType.manualDamageLabelling.damageMarkingLevel) {
      case workflowConstants.DAMAGE_MARKING_LEVEL.BLOCKER:
        return MANUAL_DAMAGE_LABELLING_TYPE.BLOCKER;
      case workflowConstants.DAMAGE_MARKING_LEVEL.WARNING:
        return MANUAL_DAMAGE_LABELLING_TYPE.WARNING;
      case workflowConstants.DAMAGE_MARKING_LEVEL.HIDDEN:
        return MANUAL_DAMAGE_LABELLING_TYPE.HIDDEN;
      default:
        return MANUAL_DAMAGE_LABELLING_TYPE.OFF;
    }
  }

  return MANUAL_DAMAGE_LABELLING_TYPE.OFF;
};

const CustomImageTypeRow = ({ customImageType, imageSubTypes, setFieldValue, showDamageMarking }) => {
  const { name } = customImageType;
  const [manualDamageLabellingType, setManualDamageLabellingType] = useState(getDefaultManualDamageLabellingType(customImageType, imageSubTypes));
  const imageSubType = imageSubTypes.find((imageSubType) => imageSubType.customImageSubTypeId === customImageType.id);
  const imageSubTypeEnabled = imageSubType !== undefined;
  const order = imageSubTypeEnabled
    ? imageSubTypes.indexOf(imageSubType) + 1
    : '-';

  const optional = imageSubType?.optional !== undefined
    ? imageSubType.optional
    : true;

  const commentsEnabled = imageSubType?.smartScanCommentSettings?.enabled !== undefined
    ? imageSubType.smartScanCommentSettings.enabled
    : false;

  const commentsAutomaticPopup = imageSubType?.smartScanCommentSettings?.automaticPopup !== undefined
    ? imageSubType.smartScanCommentSettings.automaticPopup
    : false;

  const commentsVisibleInReport = imageSubType?.smartScanCommentSettings?.visibleInReport !== undefined
    ? imageSubType.smartScanCommentSettings.visibleInReport
    : false;

  const imageSubTypeSettings = {
    customImageSubTypeId: customImageType.id,
    instructions: [],
    smartScanCommentSettings: {
      enabled: false,
      automaticPopup: false,
      visibleInReport: false
    }
  };

  return (
    <>
      <div className="col-sm-1 row-text d-flex justify-content-center">
        <Text size={2} inline>
          { order }
        </Text>
      </div>
      <div className={`col-sm-${showDamageMarking ? 3 : 5} row-text row-toggle`}>
        <Toggle
          on={imageSubTypeEnabled}
          toggleClick={() => {
            setFieldValue(
              'imageSubTypes',
              !imageSubTypeEnabled
                ? [...imageSubTypes, imageSubTypeSettings]
                : imageSubTypes.filter((c) => c.customImageSubTypeId !== customImageType.id)
            );
          }}
        />
        <div className="ml-2">
          <Text size={2} inline>
            { name }
          </Text>
        </div>
      </div>
      <div className="col-sm-1 row-toggle">
        <Toggle
          on={!optional}
          toggleClick={() => {
            setFieldValue('imageSubTypes', imageSubTypes.map((imageSubType) => {
              if (imageSubType.customImageSubTypeId === customImageType.id) {
                imageSubType.optional = !optional;
              }
              return imageSubType;
            }));
          }}
          disabled={!imageSubTypeEnabled}
        />
      </div>
      {
        showDamageMarking && (
          <div className="col-sm-2 row-toggle">
            <select
              className="form-control"
              value={manualDamageLabellingType}
              onChange={(event) => {
                const manualDamageLabellingType = Number(event.target.value);

                setManualDamageLabellingType(manualDamageLabellingType);
                setFieldValue('imageSubTypes', imageSubTypes.map((imageSubType) => {
                  if (imageSubType.customImageSubTypeId === customImageType.id) {
                    if (!imageSubType.manualDamageLabelling) {
                      imageSubType.manualDamageLabelling = {};
                    }

                    switch (manualDamageLabellingType) {
                      case MANUAL_DAMAGE_LABELLING_TYPE.OFF:
                        imageSubType.manualDamageLabelling.enabled = false;
                        break;
                      case MANUAL_DAMAGE_LABELLING_TYPE.BLOCKER:
                        imageSubType.manualDamageLabelling.enabled = true;
                        imageSubType.manualDamageLabelling.damageMarkingLevel = workflowConstants.DAMAGE_MARKING_LEVEL.BLOCKER;
                        break;
                      case MANUAL_DAMAGE_LABELLING_TYPE.WARNING:
                        imageSubType.manualDamageLabelling.enabled = true;
                        imageSubType.manualDamageLabelling.damageMarkingLevel = workflowConstants.DAMAGE_MARKING_LEVEL.WARNING;
                        break;
                      case MANUAL_DAMAGE_LABELLING_TYPE.HIDDEN:
                        imageSubType.manualDamageLabelling.enabled = true;
                        imageSubType.manualDamageLabelling.damageMarkingLevel = workflowConstants.DAMAGE_MARKING_LEVEL.HIDDEN;
                        break;
                      default:
                        imageSubType.manualDamageLabelling.enabled = false;
                        break;
                    }
                  }
                  return imageSubType;
                }));
              }}
              name="damageDetectionType"
              id="damageDetectionType"
            >
              <option value={MANUAL_DAMAGE_LABELLING_TYPE.OFF}>
                Off
              </option>
              <option value={MANUAL_DAMAGE_LABELLING_TYPE.HIDDEN}>
                Hidden
              </option>
              <option value={MANUAL_DAMAGE_LABELLING_TYPE.WARNING}>
                Warning
              </option>
              <option value={MANUAL_DAMAGE_LABELLING_TYPE.BLOCKER}>
                Blocker
              </option>
            </select>
          </div>
        )
      }
      <div className="col-sm-1 row-toggle">
        <Toggle
          on={commentsEnabled}
          toggleClick={() => {
            setFieldValue('imageSubTypes', imageSubTypes.map((imageSubType) => {
              if (imageSubType.customImageSubTypeId === customImageType.id) {
                imageSubType.smartScanCommentSettings.enabled = !commentsEnabled;
                if (!commentsEnabled === false) {
                  imageSubType.smartScanCommentSettings.automaticPopup = false;
                  imageSubType.smartScanCommentSettings.visibleInReport = false;
                }
              }
              return imageSubType;
            }));
          }}
          disabled={!imageSubTypeEnabled}
        />
      </div>
      <div className="col-sm-2 row-checkbox">
        <Checkbox
          id={`${customImageType.id}-comment-automatic`}
          name={`${customImageType.id}-comment-automatic`}
          checked={commentsAutomaticPopup}
          onClickHandler={() => {
            setFieldValue('imageSubTypes', imageSubTypes.map((imageSubType) => {
              if (imageSubType.customImageSubTypeId === customImageType.id) {
                imageSubType.smartScanCommentSettings.automaticPopup = !commentsAutomaticPopup;
              }
              return imageSubType;
            }));
          }}
          disabled={!commentsEnabled}
        />
      </div>
      <div className="col-sm-2 row-checkbox">
        <Checkbox
          id={`${customImageType.id}-comment-visible-in-report`}
          name={`${customImageType.id}-comment-visible-in-report`}
          checked={commentsVisibleInReport}
          onClickHandler={() => {
            setFieldValue('imageSubTypes', imageSubTypes.map((imageSubType) => {
              if (imageSubType.customImageSubTypeId === customImageType.id) {
                imageSubType.smartScanCommentSettings.visibleInReport = !commentsVisibleInReport;
              }
              return imageSubType;
            }));
          }}
          disabled={!commentsEnabled}
        />
      </div>
    </>
  );
};

CustomImageTypeRow.propTypes = {
  customImageType: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  imageSubTypes: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  showDamageMarking: PropTypes.bool.isRequired
};

export default CustomImageTypeSelection;
