import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import * as queryString from 'querystring';

import { ReactComponent as IconSend } from '../../../../../../../../assets/img/icn-send.svg';
import { makeGet, makePost } from '../../../../../../../../api/ajax';
import { authenticationService } from '../../../../../../../../services';

import { constants as sessionConstants } from '../../../../../../../../redux/session';
import { selectors as customerSelectors } from '../../../../../../../../redux/customers';

import {
  actions as commentsActions,
  constants as commentsConstants,
  selectors as commentsSelectors,
} from '../../../../../../../../redux/comments';

import { selectors as photoSeriesSelectors, helpers as photoSeriesHelpers } from '../../../../../../../../redux/photoSeries';

import { Comment, Loader, Text } from '../../../../../../../../components';

import { Field } from '../../../../../../../../components/Form';
import { trackEvent, EVENT_TYPES } from '../../../../../../../../api/analytics';

const initialValues = { text: '' };

const handleSubmitComment = (data, setHasUnsavedChanges, resetForm, storeComment, setSubmitting) => {
  setSubmitting(true);

  const { isPublicUserView } = authenticationService;
  data.origin = isPublicUserView()
    ? commentsConstants.COMMENT_ORIGIN.PUBLIC
    : commentsConstants.COMMENT_ORIGIN.ADMIN;

  makePost('comments', data)
    .subscribe(
      ({ response }) => {
        storeComment(response);

        setHasUnsavedChanges(false);
        resetForm(initialValues);
        setSubmitting(false);

        // Track event in Analytics
        trackEvent(EVENT_TYPES.IMAGE_COMMENT_ADDED, data);
      },
    );
};

const ResetForm = ({ imageId }) => {
  const { resetForm } = useFormikContext();
  useEffect(() => resetForm(), [imageId, resetForm]);
  return null;
};

ResetForm.propTypes = {
  imageId: PropTypes.string.isRequired
};

const CommentsSection = ({ photoSeriesId, imageId, hasUnsavedChanges, setHasUnsavedChanges, setLoading }) => {
  const dispatch = useDispatch();
  const comments = useSelector((state) => commentsSelectors.selectImageComments(state, imageId));
  const { hasPermission } = authenticationService;

  const photoSeries = useSelector(photoSeriesSelectors.selectPhotoSeries);
  const customer = useSelector(customerSelectors.selectCurrentCustomer);

  const currentPhotoSeries = photoSeries && photoSeries[photoSeriesId];

  const isPhotoSeriesShared = photoSeriesHelpers.isPhotoSeriesShared(currentPhotoSeries, customer);
  const hasCreateCommentsPermission = hasPermission(sessionConstants.PERMISSION_TYPES.CREATE_COMMENTS)
    && !isPhotoSeriesShared;

  const apiGetComments = useCallback(
    (page) => {
      setLoading(true);

      const queryParams = queryString.stringify({
        page,
        pageSize: commentsConstants.DEFAULT_SORT.pageSize,
        imageId,
      });

      makeGet(`comments?${queryParams}`).subscribe(({ response }) => {
        dispatch(commentsActions.loadCommentsSuccess(imageId, response));
        setLoading(false);
      });
    },
    [imageId, dispatch, setLoading],
  );

  useEffect(() => {
    if (comments) return;

    apiGetComments(1);
  }, [comments, apiGetComments]);

  const moreCommentsCount = comments && (comments.rowCount - comments.order.length);

  return (
    <>
      {hasCreateCommentsPermission && (
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            text: Yup.string()
              .required('Comment text cannot be empty')
              .min(2, 'Comment text must be at least 2 characters long')
              .max(250, 'Comment text cannot be longer than 250 characters'),
          })}
          onSubmit={({ text }, { resetForm, setSubmitting }) =>
            handleSubmitComment(
              { text, imageId },
              setHasUnsavedChanges,
              resetForm,
              (comment) => dispatch(commentsActions.createCommentSuccess(comment)),
              setSubmitting,
            )}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form
              onChange={({ target }) => {
                if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                  setHasUnsavedChanges(true);
                }
              }}
            >
              <Field
                type="textarea"
                id="text"
                name="text"
                className="input-group mt-2"
                placeholder="Write your comment"
                wrap="hard"
                rows="2"
                style={{ borderRadius: '0.375rem' }}
                errors={errors.text}
                touched={touched.text}
                as="textarea"
              >
                <button type="submit" className="btn is-textarea-button" disabled={isSubmitting}>
                  {isSubmitting
                    ? <Loader size="tiny" isRelative noDelay white />
                    : <IconSend />}
                </button>
              </Field>
              <ResetForm imageId={imageId} />
            </Form>
          )}
        </Formik>
      )}

      {comments && (
        <div className="overflow-auto h-100 thin-scrollbar mt-3">
          {comments.order.map((commentId) => {
            const comment = comments.entities[commentId];

            return (<Comment key={comment.id} {...comment} />);
          })}

          {moreCommentsCount > 0 && (
            <div className="d-flex">
              <button
                className="btn btn-link flex-grow-1"
                type="button"
                onClick={() => apiGetComments(comments.page + 1)}
              >
                <Text size={2} weight="bold" inline>
                  View
                  &nbsp;
                  {moreCommentsCount}
                  &nbsp;
                  more comment
                  {moreCommentsCount > 1 && 's'}
                </Text>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

CommentsSection.propTypes = {
  photoSeriesId: PropTypes.string.isRequired,
  imageId: PropTypes.string.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  setHasUnsavedChanges: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default CommentsSection;
