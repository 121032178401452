/** *****************************************************************************************
 * @oDateInputValue is a polyfill that creates todays date to calendar standard
 * @usage you can call this Date constructor menthod any where in the application this way:
 * new Date().toDateInputValue();
 ****************************************************************************************** */

(function toDateInputValuePolyfill() {
  if (window.Date.prototype.toDateInputValue) return;

  // eslint-disable-next-line
  Date.prototype.toDateInputValue = function () {
    const local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  };
}());
