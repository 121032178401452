import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import { DropzoneContainer } from '../../components/Form';

const renderDropzoneContent = (objectUrl, label, buttonLabel, fileName, resetFile) => {
  const Button = (
    <button type="button" className="btn btn-primary">
      {buttonLabel}
    </button>
  );

  if (objectUrl) {
    return (
      <div className="row">
        <div className="col-sm-6">
          <img src={objectUrl} alt="uploaded file document" className="img-md" />
          <span className="pl-2">{fileName}</span>
          {fileName && resetFile && (
            <span
              tabIndex={0}
              role="button"
              type="button"
              className="pl-3"
              onClick={(ev) => {
                ev.stopPropagation();
                resetFile();
              }}
            >
              &#10006;
            </span>
          )}
        </div>
        <div className="col-sm-6">{Button}</div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        {label}
        {Button}
      </div>
    </div>
  );
};

const FileUploadDropzone = ({ accept, name, label, buttonLabel, objectUrl, fileName, handleDrop, resetFile }) => (
  <Dropzone onDrop={handleDrop} accept={accept || 'image/svg+xml'} maxFiles={1}>
    {({ getRootProps, getInputProps }) => (
      <DropzoneContainer {...getRootProps()}>
        <input {...getInputProps()} name={name} aria-label={`${name} input `} />
        {renderDropzoneContent(objectUrl, label, buttonLabel, fileName, resetFile)}
      </DropzoneContainer>
    )}
  </Dropzone>
);

FileUploadDropzone.propTypes = {
  accept: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.shape({}),
  buttonLabel: PropTypes.string,
  objectUrl: PropTypes.string,
  fileName: PropTypes.string,
  handleDrop: PropTypes.func,
  resetFile: PropTypes.func,
};

export default FileUploadDropzone;
