import types from './types';

const loadCustomersTagsSuccess = (tags) => ({
  type: types.LOAD_CUSTOMERS_TAGS_SUCCESS,
  payload: { tags },
});

const loadCustomerTagSuccess = (tag) => ({
  type: types.LOAD_CUSTOMER_TAG_SUCCESS,
  payload: { tag }
});

export default {
  loadCustomersTagsSuccess,
  loadCustomerTagSuccess
};
