import React from 'react';
import PropTypes from 'prop-types';

import { constants as imageConstants } from '../../../../../redux/images';

import ImageSubTypeCheckbox from './ImageSubTypeCheckbox';

const InteriorAngleSelection = ({ imageSubTypes, setFieldValue }) => (
  <>
    <h6>Subimages</h6>
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.TRUNK}
      label="Trunk"
      setFieldValue={setFieldValue}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.DASHBOARD}
      label="Dashboard"
      setFieldValue={setFieldValue}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.FRONT_LEFT_SEAT}
      label="Front left seat"
      setFieldValue={setFieldValue}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.BACK_LEFT_SEAT}
      label="Back left seat"
      setFieldValue={setFieldValue}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.BACK_RIGHT_SEAT}
      label="Back right seat"
      setFieldValue={setFieldValue}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.FRONT_RIGHT_SEAT}
      label="Front right seat"
      setFieldValue={setFieldValue}
    />
  </>
);

InteriorAngleSelection.propTypes = {
  imageSubTypes: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default InteriorAngleSelection;
