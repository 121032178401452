import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { timer, combineLatest } from 'rxjs';

import './sass/loader.scss';

/**
 * @Loader a visual loading component that take in 3 arguments:
 * @small results to smaller ring loader
 * @isTextLoader results to text loader & !ring
 * @isRelative this helps the consumer to position the
 * Loader anywhere in the webpage by just wrapping the loader in
 * a div element-node and then style the div to position relative:
 * <div className="position-relative"><Loader isRelative/></div>
 */
const Loader = ({ size, isTextLoader, isRelative, noDelay, white }) => {
  const [template, setTemplate] = useState(null);

  const classes = classNames('lds-ring', {
    [`is-${size}`]: size,
    'is-white': white,
  });

  useEffect(() => {
    const setLoaderTemplate = () => {
      if (isTextLoader) {
        setTemplate(<span>Loading...</span>);
      } else {
        setTemplate(
          <div className={`${classes} ${isRelative ? 'relative-measure' : 'generic-measure'}`}>
            <div />
            <div />
            <div />
            <div />
          </div>,
        );
      }
    };

    let subscription = null;

    if (noDelay) setLoaderTemplate();
    else {
      subscription = combineLatest([timer(500)]).subscribe(setLoaderTemplate);
    }

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [classes, isRelative, isTextLoader, noDelay]);

  return template;
};

Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'tiny']),
  textLoader: PropTypes.bool,
  isRelative: PropTypes.bool,
  noDelay: PropTypes.bool,
  white: PropTypes.bool,
};

export default Loader;
