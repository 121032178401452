import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { DonutChart } from '../../../../../components';

import { constants as photoSeriesConstants } from '../../../../../redux/photoSeries';

const Statistics = ({ currentMonthConsumption }) => {
  if (_.isEmpty(currentMonthConsumption)) return 'No data found.';

  const safePhotoSeriesStats = _.find(
    currentMonthConsumption.photoSeriesByQualityRating,
    (photoSeriesStats) => photoSeriesStats.qualityRating === photoSeriesConstants.QUALITY_RATING.HIGH,
  );

  const unsafePhotoSeriesStats = _.find(
    currentMonthConsumption.photoSeriesByQualityRating,
    (photoSeriesStats) => photoSeriesStats.qualityRating === photoSeriesConstants.QUALITY_RATING.LOW,
  );

  const warningPhotoSeriesStats = _.find(
    currentMonthConsumption.photoSeriesByQualityRating,
    (photoSeriesStats) => photoSeriesStats.qualityRating === photoSeriesConstants.QUALITY_RATING.AVERAGE,
  );

  return (
    <div className="row">
      <div className="col-sm-2" />
      <div className="col-sm-8">
        <div className="row">
          <div className="col-sm-6 col-md-4">
            <DonutChart
              label="scans used"
              range={currentMonthConsumption.subscriptionLimit}
              value={currentMonthConsumption.completedPhotoSeries}
              displayFraction
              tooltip={`Subscription limit:
                ${currentMonthConsumption.subscriptionLimit}\nUsed scans:
                ${currentMonthConsumption.completedPhotoSeries}`}
            />
          </div>
          <div className="col-sm-6 col-md-4">
            <DonutChart
              label="Public links used"
              range={currentMonthConsumption.accessUrls}
              value={currentMonthConsumption.photoSeriesCompletedWithAccessUrl}
              tooltip={`Links generated:
                ${currentMonthConsumption.accessUrls}\nLinks visited:
                ${currentMonthConsumption.photoSeriesCreatedWithAccessUrl}\nCompleted:
                ${currentMonthConsumption.photoSeriesCompletedWithAccessUrl}`}
            />
          </div>
          <div className="col-sm-6 col-md-4">
            <DonutChart
              label="High quality reports"
              range={currentMonthConsumption.completedPhotoSeries}
              value={safePhotoSeriesStats.count}
              tooltip={`High quality reports:
                ${safePhotoSeriesStats.count}\nAverage quality reports:
                ${warningPhotoSeriesStats.count}\nLow quality reports:
                ${unsafePhotoSeriesStats.count}`}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-2" />
    </div>
  );
};

Statistics.propTypes = {
  currentMonthConsumption: PropTypes.shape({
    photoSeriesByQualityRating: PropTypes.array,
    completedPhotoSeries: PropTypes.number,
    accessUrls: PropTypes.number,
    photoSeriesCompletedWithAccessUrl: PropTypes.number,
    photoSeriesCreatedWithAccessUrl: PropTypes.number,
    subscriptionLimit: PropTypes.number,
  }),
};
export default Statistics;
