import React, { useEffect } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { drivexStorage } from '../../hooks/useLocalStorage/useLocalStorage';
import { routes } from '../../routes/Routes';
import { authenticationService } from '../../services';

const PrivateRoute = ({ component: Component, goFullScreen, requiredPermissions, logOut = false, ...rest }) => {
  const location = useLocation();
  const { replace } = useHistory();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !!drivexStorage.getItem('publicUserView')) {
      replace(drivexStorage.getItem('publicUserView'));
    }

    return () => {
      isMounted = false;
    };
  }, [replace]);

  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = authenticationService.currentUserValue;

        /** @CondtionExplanation Not logged in so redirect to login page with the return url */
        if (!currentUser) {
          return <Redirect to={{ pathname: routes.login, state: { from: location } }} />;
        }

        /** @CondtionExplanation If password change is required */
        if (currentUser && currentUser.mustChangePassword) {
          return <Redirect to={{ pathname: routes.changePassword, state: { from: location } }} />;
        }

        /** @CondtionExplanation Lacks permissions for route so redirect to home. Log out flag prevents redirect loop */
        if (requiredPermissions && _.difference(requiredPermissions, currentUser.permissions).length !== 0) {
          if (logOut) {
            return <Redirect to={{ pathname: routes.login, state: { from: location } }} />;
          }

          replace(routes.home);
        }

        /** @CondtionExplanation If additional info is required & user is not in that route then we navigate to the route */
        if (currentUser && currentUser.askAdditionalInformation && location.pathname !== routes.askAdditionalInformation) {
          return <Redirect to={{ pathname: routes.askAdditionalInformation, state: { from: location } }} />;
        }

        /** @CondtionExplanation If authorized return component */
        return <Component goFullScreen={goFullScreen} {...props} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  goFullScreen: PropTypes.bool,
  requiredPermissions: PropTypes.arrayOf(PropTypes.number),
  logOut: PropTypes.bool
};
export default PrivateRoute;
