import React from 'react';
import { useSelector } from 'react-redux';

import './sass/smartscan-preview.scss';
import { useThemeContext } from '../../ThemeSettings';
import { THEMES_STATE_KEYS } from '../../../../../../utils/themes';
import { ReactComponent as Logo } from '../../../../../../assets/img/icn-blue-logo.svg';
import IconThemePreview1 from '../../../../../../assets/img/theme-preview/IconThemePreview1';
import IconThemePreview2 from '../../../../../../assets/img/theme-preview/IconThemePreview2';
import IconThemePreview3 from '../../../../../../assets/img/theme-preview/IconThemePreview3';
import { selectors as customerConfigurationsSelectors } from '../../../../../../redux/customerConfigurations';

function SmartScanPreview() {
  const { themes } = useThemeContext();
  const localLogoUrl = useSelector(customerConfigurationsSelectors.selectLocalLogoUrl);

  return (
    <div className="smartscan-preview-wrapper">
      <div className="smartscan-preview-wrapper__row1">
        <h2>Theme preview</h2>
      </div>
      <div className="smartscan-preview-wrapper__row2 img-wrapper">
        <IconThemePreview1
          headingColor={themes[THEMES_STATE_KEYS.headingColor]}
          paragraphColor={themes[THEMES_STATE_KEYS.paragraphColor]}
          primaryBtnTextColor={themes[THEMES_STATE_KEYS.primaryButtonTextColor]}
          primaryBtnBgColor={themes[THEMES_STATE_KEYS.primaryButtonBackgroundColor]}
        />
        <div className="preview-logo-container">
          {localLogoUrl ? (
            <img className="default-preview-logo" src={localLogoUrl} alt="" />
          ) : (
            <Logo className="default-preview-logo" />
          )}
        </div>
      </div>
      <div className="smartscan-preview-wrapper__row3 img-wrapper">
        <IconThemePreview2
          errorColor={themes[THEMES_STATE_KEYS.errorColor]}
          headingColor={themes[THEMES_STATE_KEYS.headingColor]}
          paragraphColor={themes[THEMES_STATE_KEYS.paragraphColor]}
          primaryBtnTextColor={themes[THEMES_STATE_KEYS.primaryButtonTextColor]}
          primaryBtnBgColor={themes[THEMES_STATE_KEYS.primaryButtonBackgroundColor]}
          SecondaryBtnTextColor={themes[THEMES_STATE_KEYS.secondaryButtonTextColor]}
          SecondaryBtnBgColor={themes[THEMES_STATE_KEYS.secondaryButtonBackgroundColor]}
        />
      </div>
      <div className="smartscan-preview-wrapper__row4 img-wrapper">
        <IconThemePreview3
          headingColor={themes[THEMES_STATE_KEYS.headingColor]}
          paragraphColor={themes[THEMES_STATE_KEYS.paragraphColor]}
          primaryBtnTextColor={themes[THEMES_STATE_KEYS.primaryButtonTextColor]}
          primaryBtnBgColor={themes[THEMES_STATE_KEYS.primaryButtonBackgroundColor]}
        />
      </div>
    </div>
  );
}
export default SmartScanPreview;
