import types from './types';

const defaultState = null;

const damagesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_EXPERIMENTAL_FEATURES_SUCCESS: {
      return [...action.payload];
    }
    default:
      return state;
  }
};

export default damagesReducer;
