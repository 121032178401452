import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../../components';
import { useModalAlerter, useAjax } from '../../../../hooks';
import { ReactComponent as IconDownload } from '../../../../assets/img/icn-download-square.svg';
import { composeApiUrl } from '../../../../api/composeApiUrl';

const DOWNLOAD_COMPLETION_MESSAGE = {
  ERROR: { type: 'danger', message: 'Failed to download terms and conditions' },
  SUCCESS: { type: 'success', message: 'Successfully downloaded terms and conditions' },
};

function DownloadTermsAndConditions({ shouldEnableDownloading, btnTestId, languageId }) {
  const [shownUseModalAlerter, setShownUseModalAlerter] = useState(false);
  const [downloadCompletionMessage, setDownloadCompletionMessage] = useState({ type: '', message: '' });

  const url = `languages/${languageId}/termsAndConditions`;
  const { ERROR, SUCCESS } = DOWNLOAD_COMPLETION_MESSAGE;

  const { isLoading, getRequest } = useAjax({
    url,
    successDispatch() {
      window.location = composeApiUrl(url);
      setDownloadCompletionMessage({
        type: SUCCESS.type,
        message: SUCCESS.message,
      });
      setShownUseModalAlerter(true);
    },
    errorDispatch() {
      setDownloadCompletionMessage({
        type: ERROR.type,
        message: ERROR.message,
      });
      setShownUseModalAlerter(true);
    },
  });

  const { renderModal } = useModalAlerter(
    shownUseModalAlerter,
    setShownUseModalAlerter,
    downloadCompletionMessage.message,
    downloadCompletionMessage.type
  );

  return (
    shouldEnableDownloading && (
      <>
        {renderModal()}
        <div className="my-2 d-flex justify-content-end w-100">
          <Button
            className="btn btn-link d-flex justify-content-end"
            style={style}
            testId={btnTestId}
            disabled={isLoading || !languageId}
            onClick={() => {
              getRequest();
            }}
          >
            <IconDownload className="md-small" />
            Download terms & conditions
          </Button>
        </div>
      </>
    )
  );
}

DownloadTermsAndConditions.propTypes = {
  shouldEnableDownloading: PropTypes.bool.isRequired,
  btnTestId: PropTypes.string.isRequired,
  languageId: PropTypes.string,
};

const style = {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
};

export default DownloadTermsAndConditions;
