import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as queryString from 'querystring';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';

import { useBeforeUnload, useSubscriptionLimit, FEATURES } from '../../../../hooks';
import { renderShowConfirmationModal, onHideHandler, InfoModal } from '../../../../components';
import { actions as usersActions, constants as userConstants, selectors as usersSelectors } from '../../../../redux/users';
import { constants as subscriptionConstants } from '../../../../redux/subscriptions';
import { makeGet } from '../../../../api/ajax';

import UserDetails from './containers/UserDetails';
import Pagination from './containers/Pagination';

const UsersTable = ({ id, onHide, show }) => {
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [page, setPage] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [allowedRoles, setAllowedRoles] = useState([]);

  const dispatch = useDispatch();
  const users = useSelector(usersSelectors.selectUsers);

  const { isFeatureDisabled: usersMaxReached } = useSubscriptionLimit(FEATURES.accountLimit);
  useBeforeUnload(hasUnsavedChanges);

  const changePage = (nextPage) => {
    const recentlyAddedUser = Object.keys(users[page]).length > userConstants.PAGE_SIZE;

    if (recentlyAddedUser) {
      dispatch(usersActions.clearUsers());
    }

    if (nextPage > users.pageCount || nextPage < 1) {
      return;
    }

    setPage(nextPage);

    if (users[nextPage]) {
      return;
    }

    apiGetUsers(nextPage);
  };

  const apiGetUsers = useCallback(
    (page) => {
      const queryParams = queryString.stringify({
        page,
        pageSize: userConstants.PAGE_SIZE,
        customerId: id,
      });

      setIsLoadingUsers(true);
      makeGet(`users?${queryParams}`).subscribe(({ response }) => {
        setIsLoadingUsers(false);

        dispatch(usersActions.loadUsersSuccess(response.results, response.page, response.pageCount));
      }, () => {
        setIsLoadingUsers(false);
      });
    },
    [id, dispatch],
  );

  useEffect(() => {
    makeGet('users/allowedRoles').subscribe(({ response }) => setAllowedRoles(response));
  }, []);

  useEffect(() => {
    if (users) return;
    apiGetUsers(1);
  }, [users, apiGetUsers]);

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, onHide)}
      <Modal
        id={id}
        size="lg"
        backdrop="static"
        show={show}
        onHide={() => onHideHandler(hasUnsavedChanges, onHide, setShowConfirmModal)}
        centered
      >
        <Modal.Header closeButton>
          <h6>Access management</h6>
          <hr />
        </Modal.Header>
        <Modal.Body>
          <div className="row no-gutters">
            <div className="col">
              {usersMaxReached && (
                <InfoModal
                  type="warning"
                  message={subscriptionConstants.SUBSCRIPTION_MESSAGES.cannotAddAccounts}
                  shownModal={usersMaxReached}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {showSuccessMessage && (
                <div className="alert alert-success" role="alert">
                  {showSuccessMessage}
                  <button type="button" className="close" onClick={() => setShowSuccessMessage(null)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              <table className="table table-borderless m-0">
                <tbody>
                  <UserDetails
                    isLoadingUsers={isLoadingUsers}
                    showAlert={setShowSuccessMessage}
                    setPage={setPage}
                    hasUnsavedChanges={hasUnsavedChanges}
                    setHasUnsavedChanges={(hasUnsavedChanges) => setHasUnsavedChanges(hasUnsavedChanges)}
                    hasReachedSubscriptionLimit={usersMaxReached}
                    allowedRoles={allowedRoles}
                  />
                  {users
                    && _.map(users[page], (u, i) =>
                      <UserDetails
                        isLoadingUsers={isLoadingUsers}
                        user={u}
                        editUser={setUserToEdit}
                        showAlert={setShowSuccessMessage}
                        edit={userToEdit === u.id}
                        cancel={() => setUserToEdit(null)}
                        page={page}
                        key={i}
                        setPage={setPage}
                        hasUnsavedChanges={hasUnsavedChanges}
                        setHasUnsavedChanges={(hasUnsavedChanges) => setHasUnsavedChanges(hasUnsavedChanges)}
                        allowedRoles={allowedRoles}
                      />)}
                </tbody>
                <tfoot>
                  <tr>
                    <td style={{ padding: '.75rem 0 0 0' }}>
                      <Pagination page={page} changePage={changePage} users={users} />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

UsersTable.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default UsersTable;
