import React from 'react';
import PropTypes from 'prop-types';
import BoundingBox from 'react-bounding-box';

import './sass/bounding-box-presentation.scss';

const BOUNDING_BOX_COLOR = 'rgba(255,0,0)';
const BOUNDING_BOX = 'rgba(255,0,0,0.2)';
const BOUNDING_BOX_BORDER_WIDTH = 3;

const drawLabel = (canvas, box) => {
  if (!box || typeof box === 'undefined') return null;

  // const ctx = canvas.getContext('2d');
  // const coord = box.coord ? box.coord : box;
  // const label = box.label;

  // for future self, implement labels
};

const drawBox = (canvas, box, color, drawnBoundingBoxes) => {
  if (!box || typeof box === 'undefined') return null;

  const ctx = canvas.getContext('2d');
  const coord = box.coord ? box.coord : box;

  let [x, y, width, height] = [0, 0, 0, 0];

  if (typeof coord.xmin !== 'undefined'
      && typeof coord.xmax !== 'undefined'
      && typeof coord.ymin !== 'undefined'
      && typeof coord.ymax !== 'undefined')
  {
    [x, y, width, height] = [
      Math.min(coord.xmin, coord.xmax),
      Math.min(coord.ymin, coord.ymax),
      Math.max(coord.xmin, coord.xmax) - Math.min(coord.xmin, coord.xmax),
      Math.max(coord.ymin, coord.ymax) - Math.min(coord.ymin, coord.ymax),
    ];
  } else {
    [x, y, width, height] = coord;
  }

  if (x < BOUNDING_BOX_BORDER_WIDTH / 2) x = BOUNDING_BOX_BORDER_WIDTH / 2;
  if (y < BOUNDING_BOX_BORDER_WIDTH / 2) y = BOUNDING_BOX_BORDER_WIDTH / 2;

  if (x + width > canvas.width) {
    width = canvas.width - BOUNDING_BOX_BORDER_WIDTH - x;
  }
  if (y + height > canvas.height) {
    height = canvas.height - BOUNDING_BOX_BORDER_WIDTH - y;
  }

  ctx.strokeStyle = color;
  ctx.lineWidth = BOUNDING_BOX_BORDER_WIDTH;

  if (drawnBoundingBoxes.some((box) => box.x === x && box.y === y && box.width === width && box.height === height)) return;

  // Left
  ctx.beginPath();
  ctx.lineTo(x, y);
  ctx.lineTo(x, y + height);
  ctx.stroke();

  // Right
  ctx.beginPath();
  ctx.lineTo(x + width, y);
  ctx.lineTo(x + width, y + height);
  ctx.stroke();

  // Top
  ctx.beginPath();
  ctx.lineTo(x, y);
  ctx.lineTo(x + width, y);
  ctx.stroke();

  // Bottom
  ctx.beginPath();
  ctx.lineTo(x, y + height);
  ctx.lineTo(x + width, y + height);
  ctx.stroke();

  // Fill
  ctx.fillStyle = BOUNDING_BOX;
  ctx.fillRect(x, y, width, height);

  drawnBoundingBoxes.push({ x, y, width, height });
};

const calculateBoundingBox = (points) => {
  const xCoordinates = points.map((point) => point.x);
  const yCoordinates = points.map((point) => point.y);

  const xMin = Math.min(...xCoordinates);
  const yMin = Math.min(...yCoordinates);

  const xMax = Math.max(...xCoordinates);
  const yMax = Math.max(...yCoordinates);

  return [xMin, yMin, xMax - xMin, yMax - yMin];
};

const calculateBoundingBoxes = (polygons) => {
  if (!polygons || polygons.length === 0) return [];

  return polygons.map(((polygon) => calculateBoundingBox(polygon)));
};

const BoundingBoxPresentation = ({ imageSrc, polygons }) => {
  if (!polygons || polygons.length === 0) return <img src={imageSrc} alt="Bounding box presentation" />;

  const boundingBoxes = calculateBoundingBoxes(polygons);
  const drawnBoundingBoxes = [];

  const properties = {
    className: 'bounding-box-wrapper',
    image: imageSrc,
    boxes: boundingBoxes,
    options: {
      colors: {
        normal: BOUNDING_BOX_COLOR,
        selected: BOUNDING_BOX_COLOR,
        unselected: BOUNDING_BOX_COLOR
      },
      style: {
        maxHeight: '100vh',
        maxWidth: '100%'
      }
    }
  };

  return <BoundingBox
    {...properties}
    drawBox={(canvas, box, color) => drawBox(canvas, box, color, drawnBoundingBoxes)}
    drawLabel={drawLabel}
  />;
};

BoundingBoxPresentation.propTypes = {
  polygons: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
      })
    )
  ),
  imageSrc: PropTypes.string,
};

export default BoundingBoxPresentation;
