import { createSelector } from 'reselect';
import _ from 'lodash';

import constants from './constants';

const selectState = (state) => state[constants.NAME];

const getImageId = (_, imageId) => imageId;

const selectImageComments = createSelector(
  selectState,
  getImageId,
  (state, imageId) => (_.isEmpty(state) ? null : state[imageId]),
);

export default {
  selectImageComments,
};
