import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Alert } from 'react-bootstrap';
import { Modal } from '../../../components';
import { Field } from '../../../components/Form';

const CustomDamageTypeModal = ({ title, showModal, setShowModal, handleSubmit, successMessage, showSuccessMessage, initialValues }) => {
  const onClose = () => {
    setShowModal(false);
  };

  return (
    <Modal
      size="md"
      headerText={title}
      onHide={onClose}
      backdrop="static"
      show={showModal}
    >
      <Modal.Body>
        <div className="row">
          <div className="col">
            {showSuccessMessage && (
              <Alert variant="success">
                {successMessage}
              </Alert>
            )}
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required('Name is required')
              .max(50, 'Must not exceed 50 characters'),
          })}
          onSubmit={(formData) => handleSubmit({ ...formData })}
          validateOnChange
        >
          {({ errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-10 col-sm-12">
                  <Field type="text" name="name" label="Name" touched={touched.name} errors={errors.name} />
                </div>
              </div>

              <div className="row">
                <div className="col-10 col-sm-12 pt-3 text-right">
                  <button
                    type="button"
                    className="btn btn-secondary mr-1"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

CustomDamageTypeModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
  showSuccessMessage: PropTypes.bool,
  initialValues: PropTypes.object
};

export default CustomDamageTypeModal;
