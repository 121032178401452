import PropTypes from 'prop-types';

export const TTranslation = PropTypes.shape({
  id: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryType: PropTypes.number.isRequired,
  languageId: PropTypes.string.isRequired,
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  )
});

export const TTranslations = PropTypes.shape({
  hasSourceTranslationsChanged: PropTypes.bool,
  hasTargetedTranslationsChanged: PropTypes.bool,
  sourceTranslations: PropTypes.arrayOf(TTranslation),
  targetedTranslations: PropTypes.arrayOf(TTranslation),
}).isRequired;
