import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Form, Formik } from 'formik';
import { Alert } from 'react-bootstrap';
import * as Yup from 'yup';

import { useBeforeUnload } from '../../hooks/useBeforeUnload/useBeforeUnload';
import { Error, Field } from '../../components/Form';
import { makePost } from '../../api/ajax';
import { routes } from '../../routes/Routes';
import { authenticationService } from '../../services';
import { trackEvent, EVENT_TYPES } from '../../api/analytics';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const handleSubmit = (data, setStatus, setShowSuccessMessage, redirectToHome, setHasUnsavedChanges, setSubmitting) => {
  setStatus(null);
  setShowSuccessMessage(null);
  setSubmitting(true);

  makePost('users/changePassword', data).subscribe(
    ({ response }) => {
      setShowSuccessMessage('Password changed!');

      setHasUnsavedChanges(false);
      setSubmitting(false);

      // Track event in Analytics
      trackEvent(EVENT_TYPES.USER_PASSWORD_CHANGED);

      if (redirectToHome) {
        setTimeout(() => {
          authenticationService.storeUserData(response);

          window.location = routes.home;
        }, 2000);
      } else {
        authenticationService.storeUserData(response);
      }
    },
    ({ response: { error } }) => {
      setStatus(error);

      setHasUnsavedChanges(false);
      setSubmitting(false);
    },
  );
};

const PasswordForm = ({ children, redirectToHome, buttonPosition, hasUnsavedChanges, setHasUnsavedChanges }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);

  useBeforeUnload(hasUnsavedChanges);

  const validationSchema = {
    newPassword: Yup.string()
      .required('Password is required')
      .min(12, 'Must be at least 12 characters long')
      .max(128, 'Must not contain more than 128 characters')
      .notOneOf([Yup.ref('oldPassword'), null], 'New password cannot be same as old')
      .matches('^[\\w\\W][\x00-\x7F]+$', 'Latin characters only'),
    confirmPassword: Yup.string()
      .required('Confirming password is required')
      .min(8)
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  };

  const currentUser = authenticationService.currentUserValue;

  // Only ask for old password is user cannot set a new password
  if (!currentUser.canSetNewPassword) {
    validationSchema.oldPassword = Yup.string().required('Password is required');
  }

  return (
    <>
      <div>
        <div className="row">
          <div className="col">
            {showSuccessMessage && (
              <Alert variant="success" dismissible onClick={() => setShowSuccessMessage(null)}>
                {showSuccessMessage}
              </Alert>
            )}
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationSchema)}
          onSubmit={({ oldPassword, newPassword }, { setStatus, setSubmitting }) =>
            handleSubmit({ oldPassword, newPassword }, setStatus, setShowSuccessMessage, redirectToHome, setHasUnsavedChanges, setSubmitting)}
          validateOnChange={false}
        >
          {({ errors, status, touched, isSubmitting }) => (
            <Form
              onChange={({ target }) => {
                if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                  setHasUnsavedChanges(true);
                }
              }}
            >
              {/* Only ask for old password is user cannot set a new password */}
              {!currentUser.canSetNewPassword && (
                <div className="row">
                  <div className="col-12 col-md-12">
                    <Field type="password" name="oldPassword" label="Old password" errors={errors.oldPassword} touched={touched.oldPassword} />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12 col-md-12 pt-1">
                  <Field type="password" name="newPassword" label="New password" errors={errors.newPassword} touched={touched.newPassword} />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12 pt-1">
                  <Field
                    type="password"
                    name="confirmPassword"
                    label="Confirm password"
                    errors={errors.confirmPassword}
                    touched={touched.confirmPassword}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12">{status && <Error>{status}</Error>}</div>
              </div>
              <div className="row">
                <div className={`col-12 col-md-12 ${buttonPosition} pt-3`}>
                  {children}
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

PasswordForm.propTypes = {
  children: PropTypes.node,
  redirectToHome: PropTypes.bool,
  buttonPosition: PropTypes.string,
  hasUnsavedChanges: PropTypes.bool,
  setHasUnsavedChanges: PropTypes.func,
};

export default PasswordForm;
