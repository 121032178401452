import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as FullscreenIcon } from '../../assets/img/icn-fullscreen.svg';
import { ReactComponent as WarningIcon } from '../../assets/img/icn-warning.svg';
import { ReactComponent as AttemptedImagesIcon } from '../../assets/img/icn-attempted-images.svg';

import { composeThumbsApiUrl } from '../../api/composeApiUrl';

import './sass/thumbnail.scss';

const WarningIndicator = ({ large, errors, damages }) => {
  if (!errors && !damages) return null;

  if (damages) {
    return (
      <span className={`damages-icon ${large ? 'large-img-damages-icon' : 'small-img-damages-icon'}`}>
        <WarningIcon className="svg-md damages" />
      </span>
    );
  }

  return (
    <span className={`error-icon ${large ? 'large-img-error-icon' : 'small-img-error-icon'}`}>
      <WarningIcon className="svg-md error" />
    </span>
  );
};

WarningIndicator.propTypes = {
  large: PropTypes.bool,
  errors: PropTypes.bool,
  damages: PropTypes.bool
};

const AttemptedNoIndicator = ({ large, attemptNo }) => {
  if (!attemptNo || attemptNo === 1) return null;
  return (
    <div className={`attempt-no ${large ? 'attempt-no-large' : 'attempt-no-small'}`}>
      <AttemptedImagesIcon />
      {attemptNo}
    </div>
  );
};

AttemptedNoIndicator.propTypes = {
  large: PropTypes.bool,
  attemptNo: PropTypes.number
};

const Thumbnail = ({ fileName, small, large, onClick, errors, damages, attemptNo, isDefaultThumbnail }) => {
  const encodedFileName = encodeURIComponent(fileName);
  return (
    <div className="thumb-container" role="button" tabIndex={0} onClick={onClick}>
      {large && (
        <span className="thumb-icon">
          <FullscreenIcon />
        </span>
      )}
      <WarningIndicator large={large} errors={errors} damages={damages} />
      <AttemptedNoIndicator large={large} attemptNo={attemptNo} />
      <img
        src={isDefaultThumbnail ? fileName : composeThumbsApiUrl(`f=${encodedFileName}&w=${small ? 'small' : 'large'}`)}
        alt={`this is a ${fileName} thumbnail`}
      />
    </div>
  );
};

Thumbnail.propTypes = {
  fileName: PropTypes.string,
  onClick: PropTypes.func,
  isDefaultThumbnail: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  errors: PropTypes.bool,
  damages: PropTypes.bool,
  attemptNo: PropTypes.number
};

export default Thumbnail;
