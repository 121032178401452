import React from 'react';
import { Tabs } from '../../../../components';
import { useVehicleFilterContext } from '../../Vehicles';

const VehiclesQuickFilter = () => {
  const All = 'All';
  const MyVehicles = 'My vehicles';
  const SharedWithMe = 'Shared with me';
  const SharedTo = 'Shared to others';

  const [vehiclesFilter, setVehiclesFilter] = useVehicleFilterContext();

  const getActiveTab = () => {
    const { sharedWithMe, sharedTo } = vehiclesFilter;

    if (sharedWithMe === true) return SharedWithMe;
    if (sharedWithMe === false) return MyVehicles;
    if (sharedTo === true) return SharedTo;
    return All;
  };

  const setFilter = (key, value, filter) => {
    filter[key] = value;
  };

  const unsetFilters = (keys, filter) => {
    keys.forEach((key) => delete filter[key]);
  };

  const handleTabClick = (name) => {
    const filter = { ...vehiclesFilter };
    switch (name) {
      case All:
        unsetFilters(['sharedWithMe', 'sharedTo'], filter);
        break;
      case MyVehicles:
        unsetFilters(['sharedTo'], filter);
        setFilter('sharedWithMe', false, filter);
        break;
      case SharedWithMe:
        unsetFilters(['sharedTo'], filter);
        setFilter('sharedWithMe', true, filter);
        break;
      case SharedTo:
        unsetFilters(['sharedWithMe'], filter);
        setFilter('sharedTo', true, filter);
        break;
      default:
    }
    setVehiclesFilter(filter);
  };

  return <Tabs tabs={[All, MyVehicles, SharedWithMe, SharedTo]} activeTab={getActiveTab()} onTabClick={(name) => handleTabClick(name)} />;
};

export default VehiclesQuickFilter;
