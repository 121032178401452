import { normalize } from 'normalizr';

import types from './types';

import { countryListSchema } from './schema';

import sessionTypes from '../session/types';

const defaultState = {};

const countriesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_COUNTRY_SUCCESS: {
      const { country } = action.payload;

      return {
        ...state,
        countries: {
          [country.id]: {
            ...country,
          },
        },
      };
    }

    case types.LOAD_COUNTRIES_SUCCESS: {
      const {
        results, page, pageCount, pageSize, rowCount,
      } = action.payload.countriesPagedResult;

      const replace = page === 1;

      const normalizedCountries = normalize(results, countryListSchema);

      if (replace) {
        return {
          ...state,
          pagedResult: {
            ...state.pagedResult,
            page,
            pageCount,
            pageSize,
            rowCount,
            entities: normalizedCountries.entities.countries,
            order: normalizedCountries.result,
          },
        };
      }

      return {
        ...state,
        pagedResult: {
          ...state.pagedResult,
          page,
          pageCount,
          pageSize,
          rowCount,
          entities: {
            ...(state.pagedResult && state.pagedResult.entities),
            ...normalizedCountries.entities.countries,
          },

          order:
            state.pagedResult && state.pagedResult.order.length > 0
              ? [...state.pagedResult.order, ...normalizedCountries.result]
              : normalizedCountries.result,
        },
      };
    }

    case sessionTypes.LOG_OUT: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default countriesReducer;
