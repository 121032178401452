import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as StarIcon } from '../../assets/img/icn-star.svg';
import { ReactComponent as HalfStarIcon } from '../../assets/img/icn-half-star.svg';

import './sass/rating.scss';

let starCount = 0;

export const StarRating = ({ satisfactionRating, displayFigure, ...props }) => (
  <div className="star-rating-container" aria-label={`The rating is ${satisfactionRating}`} {...props}>
    <span className="star-wrapper">
      {[...new Array(satisfactionRating || 0)].map(() => {
        starCount += 1;
        return <StarIcon className="svg-small" key={starCount} />;
      })}
    </span>

    {satisfactionRating && displayFigure ? (
      <span className="rating-wrapper">{satisfactionRating}</span>
    ) : (
      <>
        {((!satisfactionRating && displayFigure) || (!satisfactionRating && !displayFigure)) && (
          <span className="empty-rating-wrapper">
            No rating
            {' '}
            <HalfStarIcon className="svg-small" />
          </span>
        )}
      </>
    )}
  </div>
);

export const QualityRating = ({ qualityRating, ...props }) => {
  switch (qualityRating) {
    case 0:
      return (
        <span className="q-rating high-rating" {...props}>
          High
        </span>
      );

    case 1:
      return (
        <span className="q-rating low-rating" {...props}>
          Low
        </span>
      );

    case 2:
      return (
        <span className="q-rating average-rating" {...props}>
          Average
        </span>
      );

    default:
      return (
        <span className="q-rating null-rating" {...props}>
          No rating
        </span>
      );
  }
};

StarRating.propTypes = {
  satisfactionRating: PropTypes.any,
  displayFigure: PropTypes.bool.isRequired,
};

QualityRating.propTypes = {
  qualityRating: PropTypes.oneOfType([PropTypes.any]),
};

export default StarRating;
