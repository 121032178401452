import types from './types';

import sessionTypes from '../session/types';

const defaultState = {};

const accessUrlsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_ACCESS_URL_SUCCESS: {
      const {
        accessUrlId,
        data,
      } = action.payload;

      return {
        ...state,
        accessUrlsById: {
          ...state.accessUrlsById,
          [accessUrlId]: data,
        },
      };
    }
    case sessionTypes.LOG_OUT: {
      return defaultState;
    }
    default:
      return state;
  }
};

export default accessUrlsReducer;
