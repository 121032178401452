import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Lock } from '../../assets/img/icn-lock.svg';
import { ReactComponent as LogOut } from '../../assets/img/icn-logout.svg';
import { ReactComponent as IconArrow } from '../../assets/img/icn-arrow.svg';
import { ReactComponent as IconPortfolio } from '../../assets/img/icn-portfolio.svg';
import { ReactComponent as IconBrush } from '../../assets/img/icn-brush.svg';
import { ReactComponent as IconPhoto } from '../../assets/img/icn-photo.svg';
import { ReactComponent as IconDamage } from '../../assets/img/icn-damage.svg';
import IconWrench from '../../assets/img/IconWrench';

import { SUBSCRIPTION_TYPES } from '../../components/Cards/SubscriptionCard/SubscriptionCard';
import { PasswordModal } from '../../containers';
import { Footer, Text, Menu, ActionsDropDown, Container } from '../../components';
import {
  selectors as customerMonthlyConsumptionsSelectors,
  actions as customerMonthlyConsumptionsActions
} from '../../redux/customerMonthlyConsumptions';
import { actions as sessionActions, constants as sessionConstants } from '../../redux/session';
import { actions as customerActions, selectors as customerSelectors } from '../../redux/customers';
import { actions as subscriptionActions, selectors as subscriptionSelectors } from '../../redux/subscriptions/index';
import { authenticationService } from '../../services';
import { makeGet, makePost } from '../../api/ajax';
import { getUtcToday } from '../../utils/date';
import { history } from '../../utils/history';
import { routes } from '../../routes/Routes';
import { trackEvent, EVENT_TYPES } from '../../api/analytics';

import './sass/menu-page.scss';

const {
  EDIT_CUSTOMER_CONFIGURATION, EDIT_WORKFLOWS, EDIT_PHOTOSERIES, EDIT_TRANSLATIONS,
  IMPORT_PHOTO_SERIES, EDIT_BLACKLISTED_USER_AGENT, VIEW_CUSTOM_DAMAGE_TYPES, EDIT_CUSTOM_IMAGE_TYPE, VIEW_STATISTICS,
  VIEW_WINDSHIELD_REPAIRABILITY_PROFILES
} = sessionConstants.PERMISSION_TYPES;

const getCustomizationClassnames = (currentRoute) => {
  let customizationClassNames = 'nav-item';

  if ([routes.customization, routes.customDamageTypes].indexOf(currentRoute) !== -1) {
    customizationClassNames += ' active';
  }

  return customizationClassNames;
};

const renderCustomizationMenuItem = (hasPermission, currentRoute) => (
  <li className={getCustomizationClassnames(currentRoute)}>
    <ActionsDropDown toggle={<IconArrow />} toggleText="Customization">
      {hasPermission(EDIT_WORKFLOWS) && (
        <ActionsDropDown.Item href={routes.workflows}>
          <IconPortfolio />
          <p className="p-3">
            Workflows
          </p>
        </ActionsDropDown.Item>)}
      {hasPermission(EDIT_CUSTOMER_CONFIGURATION) && (
        <ActionsDropDown.Item href={routes.branding}>
          <IconBrush />
          <p className="pl-3">
            Branding
          </p>
        </ActionsDropDown.Item>)}
      {hasPermission(EDIT_CUSTOM_IMAGE_TYPE) && (
        <ActionsDropDown.Item href={routes.customImageTypes}>
          <IconPhoto />
          <p className="pl-3">
            Image types
          </p>
        </ActionsDropDown.Item>)}
      {hasPermission(VIEW_CUSTOM_DAMAGE_TYPES) && (
        <ActionsDropDown.Item href={routes.customDamageTypes}>
          <IconDamage />
          <p className="pl-3">
            Damages
          </p>
        </ActionsDropDown.Item>)}
      {hasPermission(VIEW_WINDSHIELD_REPAIRABILITY_PROFILES) && (
        <ActionsDropDown.Item href={routes.windshieldRepairabilityProfiles}>
          <IconWrench color="blue" />
          <p className="pl-3">
            Repairability profiles
          </p>
        </ActionsDropDown.Item>
      )}
    </ActionsDropDown>
  </li>
);

const MenuPage = ({ currentRoute, children }) => {
  const [modalShow, setModalShow] = useState(false);

  const dispatch = useDispatch();
  const customer = useSelector(customerSelectors.selectCurrentCustomer);
  const subscriptions = useSelector(subscriptionSelectors.selectSubscriptions);
  const monthlyConsumption = useSelector(customerMonthlyConsumptionsSelectors.selectCurrentMonthConsumption);

  // customer dispatcher useEffect
  useEffect(() => {
    if (customer) return;

    makeGet('customers/current').subscribe(({ response }) => dispatch(customerActions.loadCustomerSuccess(response)));
  }, [customer, dispatch]);

  const todayString = getUtcToday().toISOString();

  useEffect(() => {
    if (!monthlyConsumption) {
      makeGet(`customerMonthlyConsumptions/${todayString}`).subscribe(({ response }) => {
        dispatch(customerMonthlyConsumptionsActions.loadMonthlyConsumptionSuccess(response, todayString));
      });
    }
  }, [todayString, monthlyConsumption, dispatch]);

  /// subscriptions dispatcher useEffect
  useEffect(() => {
    let isMounted = true;

    if (subscriptions.length > 0 || !isMounted) return;

    makeGet('subscriptions').subscribe(({ response }) => {
      dispatch(subscriptionActions.loadSubscriptionsSuccess(response));
    });

    return () => {
      isMounted = false;
    };
  }, [dispatch, subscriptions.length]);

  useEffect(() => {
    if (subscriptions.length === 0 || !monthlyConsumption) return;
    const { enabledFeatures, type } = subscriptions.find(({ id }) => id === monthlyConsumption?.subscriptionId);

    if (type === SUBSCRIPTION_TYPES.CORPORATE) {
      makeGet('customerConfigurations/current').subscribe(({ response }) => {
        dispatch(subscriptionActions.loadEnabledFeaturesSuccess(response.enabledFeatures));
      });
    } else {
      dispatch(subscriptionActions.loadEnabledFeaturesSuccess(enabledFeatures));
    }
  }, [dispatch, customer, subscriptions, monthlyConsumption]);

  const handleLogOutClick = () => {
    makePost('session/logout', {}).subscribe(() => {
      // Track event in Analytics
      trackEvent(EVENT_TYPES.USER_LOGGED_OUT);

      authenticationService.logout();

      dispatch(sessionActions.logOut());

      history.push(routes.login);
    });
  };

  if (!customer) return null;

  const { hasPermission } = authenticationService;

  const hasCustomizationPermission = hasPermission(EDIT_WORKFLOWS) || hasPermission(EDIT_CUSTOMER_CONFIGURATION)
    || hasPermission(VIEW_CUSTOM_DAMAGE_TYPES) || hasPermission(EDIT_CUSTOM_IMAGE_TYPE);

  return (
    <div className="menu-page">
      <Menu>
        {/* Only public users don't have editPhotoSeries permission */}
        {hasPermission(EDIT_PHOTOSERIES) && (
          <ul className="navbar-nav">
            <Menu.MenuItem name="Dashboard" route={routes.home} currentRoute={currentRoute} />
            <Menu.MenuItem name="Vehicles" route={routes.vehicles} currentRoute={currentRoute} />
            {hasPermission(EDIT_CUSTOMER_CONFIGURATION)
            && <Menu.MenuItem name="Account" route={routes.account} currentRoute={currentRoute} />}
            {hasCustomizationPermission && renderCustomizationMenuItem(hasPermission, currentRoute)}
            {(hasPermission(EDIT_TRANSLATIONS))
            && <Menu.MenuItem name="Translations" route={routes.translations} currentRoute={currentRoute} />}
            {hasPermission(EDIT_BLACKLISTED_USER_AGENT)
            && <Menu.MenuItem name="User agent blacklist" route={routes.userAgentBlacklist} currentRoute={currentRoute} />}
            {hasPermission(IMPORT_PHOTO_SERIES)
            && <Menu.MenuItem name="Import" route={routes.photoSeriesImport} currentRoute={currentRoute} />}
            {hasPermission(VIEW_STATISTICS)
            && <Menu.MenuItem name="Statistics" route={routes.statistics} currentRoute={currentRoute} />}
          </ul>
        )}

        <div className="ml-auto">
          <ActionsDropDown toggle={<IconArrow />} toggleText={customer.name}>
            {/* Only public users don't have editPhotoSeries permission */}
            {hasPermission(EDIT_PHOTOSERIES) && (
              <ActionsDropDown.Item onClick={() => setModalShow(true)}>
                <Lock />
                <span className="pl-3">Change password</span>
              </ActionsDropDown.Item>
            )}

            <ActionsDropDown.Item onClick={() => handleLogOutClick()}>
              <LogOut />

              <span className="pl-3">Log out</span>
            </ActionsDropDown.Item>
          </ActionsDropDown>
        </div>
      </Menu>

      <div>
        <PasswordModal show={modalShow} onModalClose={() => setModalShow(false)} />
      </div>

      <Container>
        <div className="row">
          <div className="col-sm">
            <div className="content-wrapper">{children}</div>
            <Footer>
              <Text align="center">DriveX Technologies &copy;2021 All Rights Reserved</Text>
            </Footer>
          </div>
        </div>
      </Container>
    </div>
  );
};

MenuPage.propTypes = {
  children: PropTypes.node,
  currentRoute: PropTypes.string,
};

export default MenuPage;
