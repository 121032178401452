import React from 'react';
import PropTypes from 'prop-types';
import { constants as workflowConstants } from '../../../../../redux/workflows';

const ManualMarkingSettings = ({ setFieldValue, manualDamageLabelling }) => (
  <>
    <h6>Manual marking settings</h6>
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id="manualDamageLabellingEnabled"
        checked={manualDamageLabelling.enabled}
        onChange={(event) => setFieldValue('manualDamageLabelling.enabled', event.target.checked)}
      />
      <label className="form-check-label" htmlFor="manualDamageLabellingEnabled">Enable damage marking</label>
    </div>
    <>
      <div className="ml-4">
        <label htmlFor="damage-marking-level-blocker" className="mb-0">
          <input
            type="radio"
            name="damage-marking-level"
            checked={manualDamageLabelling.damageMarkingLevel === workflowConstants.DAMAGE_MARKING_LEVEL.BLOCKER}
            id="damage-marking-level-blocker"
            onChange={() => setFieldValue('manualDamageLabelling.damageMarkingLevel', workflowConstants.DAMAGE_MARKING_LEVEL.BLOCKER)}
            disabled={!manualDamageLabelling.enabled}
          />
          &nbsp; Blocker
        </label>
      </div>
      <div className="ml-4">
        <label htmlFor="damage-marking-level-warning" className="mb-0">
          <input
            type="radio"
            name="damage-marking-level"
            checked={manualDamageLabelling.damageMarkingLevel === workflowConstants.DAMAGE_MARKING_LEVEL.WARNING}
            id="damage-marking-level-warning"
            onChange={() => setFieldValue('manualDamageLabelling.damageMarkingLevel', workflowConstants.DAMAGE_MARKING_LEVEL.WARNING)}
            disabled={!manualDamageLabelling.enabled}
          />
          &nbsp; Warning
        </label>
      </div>
      <div className="ml-4">
        <label htmlFor="damage-marking-level-hidden" className="mb-0">
          <input
            type="radio"
            name="damage-marking-level"
            checked={manualDamageLabelling.damageMarkingLevel === workflowConstants.DAMAGE_MARKING_LEVEL.HIDDEN}
            id="damage-marking-level-hidden"
            onChange={() => setFieldValue('manualDamageLabelling.damageMarkingLevel', workflowConstants.DAMAGE_MARKING_LEVEL.HIDDEN)}
            disabled={!manualDamageLabelling.enabled}
          />
          &nbsp; Hidden
        </label>
      </div>
    </>
  </>
);

export default ManualMarkingSettings;

ManualMarkingSettings.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  manualDamageLabelling: PropTypes.shape({
    enabled: PropTypes.bool,
    damageMarkingLevel: PropTypes.number
  })
};
