import { createSelector } from 'reselect';

import constants from './constants';

const selectUsersState = (state) => state[constants.NAME];
const selectUsers = createSelector([selectUsersState], (users) => users.users);

export default {
  selectUsers,
};
