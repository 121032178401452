import { useEffect } from 'react';

export const isLocalStorageKeyExist = (key) => window.localStorage.getItem(key) !== null;

export const drivexStorage = {
  setItem(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  },

  getItem(key) {
    if (isLocalStorageKeyExist(key)) {
      return JSON.parse(window.localStorage.getItem(key));
    }

    return undefined;
  },

  removeItem(key) {
    if (isLocalStorageKeyExist(key)) {
      window.localStorage.removeItem(key);
    }
  },

  clearItems() {
    window.localStorage.clear();
  },
};

export const useLocalStorage = ({ type, key, value }) => {
  useEffect(() => {
    if (type === 'set') {
      drivexStorage.setItem(key, value);
    } else if (type === 'get') {
      drivexStorage.getItem(key);
    } else if (type === 'remove') {
      drivexStorage.removeItem(key);
    } else if (type === 'clear') {
      drivexStorage.clearItems();
    }
  }, [type, key, value]);
};
