import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Alert } from 'react-bootstrap';

import { ReactComponent as IconLink } from '../../assets/img/icn-link.svg';
import { ReactComponent as IconCheck } from '../../assets/img/icn-green-check.svg';
import { Loader, NotificationModal, Combobox } from '..';
import { Toggler } from '../Toggle/Toggle';
import { useUniqueIds } from '../../hooks/useUniqueIds/useUniqueIds';
import { copyToClipboard } from '../../utils/dom';

import { comboTypes } from '../Combobox/Combobox';

import { constants as commentsConstants } from '../../redux/comments';

import './sass/photoseries-share-modal.scss';

const PhotoSeriesShareModal = ({
  show,
  onHide,
  toggleOn,
  isCopied,
  isLoadingSharePhotoSeries,
  accessUrlToBeCopied,
  ajaxError,
  expiringDate,
  shownCopiedSuccessMessage,
  sharedComments,
  setSharedComments,
  setIsCopied,
  setAjaxError,
  sharePhotoSeriesHandler,
  setToggleOn,
  setExpiringDate,
  publishToCompanyToggleOn,
  setPublishToCompanyToggleOn,
  companiesSelection,
  initialCompaniesSelection,
  setSelectedCompanies,
  saveSharedWith,
  sharedWithSavedMessage
}) => {
  const [expiresDateInputId] = useUniqueIds(1);
  const comboboxRef = useRef(null);

  return (
    <>
      {isLoadingSharePhotoSeries && <Loader />}
      <Modal
        id="editExternalIdModal"
        size="lg"
        backdrop="static"
        show={show}
        onHide={() => {
          onHide();
          setIsCopied(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>Share to web</h5>
        </Modal.Header>

        <Modal.Body className="photoseries-share">
          <div className="photoseries-share__col1">
            <h6>Publish this link</h6>
            <div className="photoseries-share__row1">
              <div className="content">Anyone with the link can view this photo series!</div>

              <div className="toggle">
                <Toggler
                  on={toggleOn}
                  disabled={false}
                  onToggle={() => {
                    sharePhotoSeriesHandler(expiringDate, !toggleOn, sharedComments);
                    setToggleOn(!toggleOn);
                  }}
                />
              </div>
            </div>

            {
              toggleOn && (
                <div>
                  <div>
                    <div className="form-check ml-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="smartScanComments"
                        checked={sharedComments.some((sharedComment) => sharedComment === commentsConstants.COMMENT_ORIGIN.SMART_SCAN)}
                        onChange={(event) => {
                          const newSharedComments = event.target.checked
                            ? [...sharedComments, commentsConstants.COMMENT_ORIGIN.SMART_SCAN]
                            : sharedComments.filter((sharedComment) => sharedComment !== commentsConstants.COMMENT_ORIGIN.SMART_SCAN);

                          setSharedComments(newSharedComments);
                          sharePhotoSeriesHandler(expiringDate, toggleOn, newSharedComments);
                        }}
                      />
                      <label className="form-check-label" htmlFor="smartScanComments">Show SmartScan comments while published</label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check ml-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="adminComments"
                        checked={sharedComments.some((sharedComment) => sharedComment === commentsConstants.COMMENT_ORIGIN.ADMIN)}
                        onChange={(event) => {
                          const newSharedComments = event.target.checked
                            ? [...sharedComments, commentsConstants.COMMENT_ORIGIN.ADMIN]
                            : sharedComments.filter((sharedComment) => sharedComment !== commentsConstants.COMMENT_ORIGIN.ADMIN);

                          setSharedComments(newSharedComments);
                          sharePhotoSeriesHandler(expiringDate, toggleOn, newSharedComments);
                        }}
                      />
                      <label className="form-check-label" htmlFor="adminComments">Show Admin comments while published</label>
                    </div>
                  </div>
                </div>
              )
            }

            {toggleOn && (
              <div className="photoseries-share__row2">
                <label htmlFor={expiresDateInputId}>
                  Publishing ends after
                  <sup aria-hidden>&#x2a;</sup>
                </label>

                <div className="photoseries-share__row2-input-wrapper">
                  <input
                    type="date"
                    name="expiresDate"
                    className="datepicker-input"
                    id={expiresDateInputId}
                    placeholder="Select date"
                    aria-label="Publishing ends after"
                    value={expiringDate}
                    min={new Date().toDateInputValue()}
                    onChange={({ target }) => {
                      sharePhotoSeriesHandler(target.value, toggleOn, sharedComments);
                      setExpiringDate(target.value);
                    }}
                  />
                </div>
              </div>
            )}

            {!!accessUrlToBeCopied && toggleOn && (
              <div
                className="photoseries-share__row3"
                role="button"
                tabIndex={0}
                onClick={() => {
                  copyToClipboard(accessUrlToBeCopied, () => {
                    setIsCopied(true);
                  });
                }}
              >
                {shownCopiedSuccessMessage && (
                  <>
                    {isCopied ? (
                      <span className="photoseries-share__row3-row copy-success">
                        <IconCheck className="svg-md" />
                        Successfully copied link
                      </span>
                    ) : (
                      <span className="photoseries-share__row3-row copy-note">
                        <IconLink className="svg-md" />
                        Copy this link to clipboard
                      </span>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className="photoseries-share__col2">
            <h6>Publish to a company</h6>
            <div className="photoseries-share__row1">
              <div className="content">Selected companies can view this photo series!</div>
              <div className="toggle">
                <Toggler
                  on={publishToCompanyToggleOn}
                  disabled={false}
                  onToggle={() => setPublishToCompanyToggleOn(!publishToCompanyToggleOn)}
                />
              </div>
            </div>
            { sharedWithSavedMessage && (
              <Alert variant="success" className="mt-1 mb-1">
                {sharedWithSavedMessage}
              </Alert>
            )}
            {
              publishToCompanyToggleOn && (
                <>
                  <div className="photoseries-share__row2">
                    <Combobox
                      type={comboTypes.multiCombo}
                      label="Shared with"
                      values={companiesSelection}
                      initialSelection={initialCompaniesSelection}
                      getSelectedItems={(selectedItems) => setSelectedCompanies(selectedItems)}
                      ref={comboboxRef}
                      searchingDisabled={false}
                    />
                  </div>
                  <div className="photoseries-share_row3">
                    <button type="button" className="btn btn-primary mt-2" onClick={saveSharedWith}>
                      Save
                    </button>
                  </div>
                </>
              )
            }
          </div>
        </Modal.Body>
      </Modal>

      <NotificationModal showModal={!!ajaxError} message={ajaxError} type="error" position="right" onCancel={() => setAjaxError('')} />
    </>
  );
};
PhotoSeriesShareModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  isCopied: PropTypes.bool.isRequired,
  toggleOn: PropTypes.bool.isRequired,
  ajaxError: PropTypes.string.isRequired,
  setIsCopied: PropTypes.func.isRequired,
  setAjaxError: PropTypes.func.isRequired,
  expiringDate: PropTypes.string.isRequired,
  isLoadingSharePhotoSeries: PropTypes.bool.isRequired,
  sharePhotoSeriesHandler: PropTypes.func.isRequired,
  shownCopiedSuccessMessage: PropTypes.bool.isRequired,
  accessUrlToBeCopied: PropTypes.string,
  setToggleOn: PropTypes.func.isRequired,
  setExpiringDate: PropTypes.func.isRequired,
  sharedComments: PropTypes.array.isRequired,
  setSharedComments: PropTypes.func.isRequired,
  publishToCompanyToggleOn: PropTypes.bool.isRequired,
  setPublishToCompanyToggleOn: PropTypes.func.isRequired,
  companiesSelection: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })).isRequired,
  initialCompaniesSelection: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })).isRequired,
  setSelectedCompanies: PropTypes.func.isRequired,
  saveSharedWith: PropTypes.func.isRequired,
  sharedWithSavedMessage: PropTypes.string
};

export default PhotoSeriesShareModal;
