import types from './types';

const getAllTranslationsSuccess = (langId, customerTranslations) =>
  ({ type: types.GET_ALL_TRANSLATIONS_SUCCESS, payload: { langId, customerTranslations } });

const saveCustomerTranslationsSuccess = (customerTranslations) =>
  ({ type: types.SAVE_CUSTOMER_TRANSLATIONS_SUCCESS, payload: { customerTranslations } });

export default {
  getAllTranslationsSuccess,
  saveCustomerTranslationsSuccess,
};
