const prefix = 'drivex/images';

const LOAD_IMAGE_SUCCESS = `${prefix}/LOAD_IMAGE_SUCCESS`;
const LOAD_IMAGES_SUCCESS = `${prefix}/LOAD_IMAGES_SUCCESS`;
const CLEAR_IMAGE = `${prefix}/CLEAR_IMAGE`;

export default {
  LOAD_IMAGE_SUCCESS,
  LOAD_IMAGES_SUCCESS,
  CLEAR_IMAGE
};
