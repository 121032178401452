import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { constants as sessionConstants } from '../redux/session';
import { PrivateRoute } from '../components';
import {
  Account,
  ChangePassword,
  ConfirmPasswordReset,
  DownloadArchive,
  FallbackPage,
  Home,
  Login,
  PhotoSeriesDetails,
  ResetPassword,
  Vehicles,
  PublicAccess,
  AskAdditionalInformation,
  Translations,
  PhotoSeriesImport,
  UserAgentBlacklist,
  CustomDamageTypes,
  Workflows,
  Branding,
  CustomImageTypes,
  Statistics,
  WindshieldRepairabilityProfiles
} from '../screens';
import { GlobalErrorEngine } from '../containers';

import ConfirmEmail from '../screens/ConfirmEmail/ConfirmEmail';

import '../assets/css/bootstrap.min.css';
import '../app.scss';

export const routes = {
  account: '/account',
  askAdditionalInformation: '/askAdditionalInformation',
  changePassword: '/changePassword',
  confirmEmail: '/confirmEmail',
  confirmPasswordReset: '/confirmPasswordReset',
  customization: '/customization',
  downloadArchive: '/downloadArchive',
  fallback: '/error',
  home: '/',
  login: '/login',
  photoSeriesDetails: '/vehicles/:vehicleId/photoSeries/:photoSeriesId',
  publicAccess: '/access/:accessUrlId',
  resetPassword: '/resetPassword/:email',
  translations: '/translations',
  vehicles: '/vehicles',
  photoSeriesImport: '/photoSeries/import',
  userAgentBlacklist: '/userAgentBlacklist',
  customDamageTypes: '/customDamageTypes',
  customImageTypes: '/customImageTypes',
  workflows: '/workflows',
  branding: '/branding',
  statistics: '/statistics',
  windshieldRepairabilityProfiles: '/windshieldRepairabilityProfiles',
};

const {
  EDIT_CUSTOMER_CONFIGURATION, EDIT_PHOTOSERIES, EDIT_TRANSLATIONS,
  IMPORT_PHOTO_SERIES, EDIT_BLACKLISTED_USER_AGENT, VIEW_CUSTOM_DAMAGE_TYPES,
  EDIT_WORKFLOWS, EDIT_CUSTOM_IMAGE_TYPE, VIEW_STATISTICS, VIEW_WINDSHIELD_REPAIRABILITY_PROFILES
} = sessionConstants.PERMISSION_TYPES;

const Routes = () => (
  <GlobalErrorEngine>
    <Router>
      <Switch>
        <Route exact path={routes.publicAccess} component={PublicAccess} />
        <Route exact path={routes.login} component={Login} />
        <Route exact path={routes.changePassword} component={ChangePassword} />
        <Route exact path={routes.resetPassword} component={ResetPassword} />
        <Route exact path={routes.confirmPasswordReset} component={ConfirmPasswordReset} />
        <PrivateRoute exact path={routes.home} component={Home} requiredPermissions={[EDIT_PHOTOSERIES]} logOut />
        <PrivateRoute exact path={routes.account} component={Account} requiredPermissions={[EDIT_CUSTOMER_CONFIGURATION]} />
        <PrivateRoute exact path={routes.downloadArchive} component={DownloadArchive} />
        <PrivateRoute exact path={routes.vehicles} component={Vehicles} requiredPermissions={[EDIT_PHOTOSERIES]} />
        <PrivateRoute exact path={routes.translations} component={Translations} requiredPermissions={[EDIT_TRANSLATIONS]} />
        <PrivateRoute exact path={routes.photoSeriesDetails} component={PhotoSeriesDetails} />
        <PrivateRoute exact path={routes.askAdditionalInformation} component={AskAdditionalInformation} />
        <PrivateRoute exact path={routes.photoSeriesImport} component={PhotoSeriesImport} requiredPermissions={[IMPORT_PHOTO_SERIES]} />
        <PrivateRoute exact path={routes.userAgentBlacklist} component={UserAgentBlacklist} requiredPermissions={[EDIT_BLACKLISTED_USER_AGENT]} />
        <PrivateRoute exact path={routes.customDamageTypes} component={CustomDamageTypes} requiredPermissions={[VIEW_CUSTOM_DAMAGE_TYPES]} />
        <PrivateRoute exact path={routes.workflows} component={Workflows} requiredPermissions={[EDIT_WORKFLOWS]} />
        <PrivateRoute exact path={routes.branding} component={Branding} requiredPermissions={[EDIT_CUSTOMER_CONFIGURATION]} />
        <PrivateRoute exact path={routes.customImageTypes} component={CustomImageTypes} requiredPermissions={[EDIT_CUSTOM_IMAGE_TYPE]} />
        <PrivateRoute exact path={routes.statistics} component={Statistics} requiredPermissions={[VIEW_STATISTICS]} />
        <PrivateRoute
          exact
          path={routes.windshieldRepairabilityProfiles}
          component={WindshieldRepairabilityProfiles}
          requiredPermissions={[VIEW_WINDSHIELD_REPAIRABILITY_PROFILES]}
        />
        <Route exact path={routes.confirmEmail} component={ConfirmEmail} />
        <Route exact path={routes.fallback} component={FallbackPage} />
        <Route component={FallbackPage} />
      </Switch>
    </Router>
  </GlobalErrorEngine>
);

export default Routes;
