import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'formik';

import { ColorPicker, ColorIndicator } from '../../components';
import { Error } from '../../components/Form';

const ColorPickerField = ({ name, label, color, setFieldValue, error, setHasUnsavedChanges }) => {
  const classes = classNames('form-control d-inline-block', {
    'is-invalid': error,
  });

  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Field name={name} type="text" className={classes} value={color} onClick={() => setShowColorPicker(!showColorPicker)} />
      {color && <ColorIndicator color={color} handleClick={() => setShowColorPicker(!showColorPicker)} />}
      {error && (
        <Error>
          <p>{error}</p>
        </Error>
      )}
      {showColorPicker && (
        <ColorPicker
          color={color}
          handleChange={(color) => {
            setFieldValue(name, color.hex);
            setHasUnsavedChanges(true);
          }}
          handleClose={() => setShowColorPicker(false)}
        />
      )}
    </div>
  );
};

ColorPickerField.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setHasUnsavedChanges: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  label: PropTypes.string,
  color: PropTypes.string,
};

export default ColorPickerField;
