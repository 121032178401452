import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { SkeletonUI, TranslationsInput } from '../../../../components';
import { TTranslations } from '../../../../@proptypes/proptypes';
import { constants as translationsConstants } from '../../../../redux/translations';

import DownloadTermsAndConditions from '../DownloadTermsAndConditions/DownloadTermsAndConditions';
import TermsAndConditionsFieldset from '../TermsAndConditionsFieldset/TermsAndConditionsFieldset';

import DownloadGdprPrivacyPolicy from '../DownloadGdprPrivacyPolicy/DownloadGdprPrivacyPolicy';
import GdprPrivacyPolicyFieldset from '../GdprPrivacyPolicyFieldset/GdprPrivacyPolicyFieldset';

import './sass/source-translation.scss';

function SourceTranslations({
  sourceLanguage, translations, termsAndConditions, setTermsAndConditions,
  gdprPrivacyPolicy, setGdprPrivacyPolicy, onInputChange,
  showGdprPrivacyPolicy, showTermsAndConditions, disableTranslationsInput
}) {
  const { sourceTranslations, hasSourceTranslationsChanged } = translations;
  const { TRANSLATION_TYPES: { SOURCE_TRANSLATIONS } } = translationsConstants;

  return (
    <div className="from-container">
      <div className="source-language" aria-live="assertive" aria-atomic="true" title="The original (source) language" disabled>
        {`${sourceLanguage?.name} translations (source)` || <SkeletonUI type="radius-horizontal" maxWidth="90%" />}
      </div>
      {
        showTermsAndConditions && (
          <>
            <TermsAndConditionsFieldset
              name="termsAndConditions"
              termsAndConditions={termsAndConditions}
              setTermsAndConditions={setTermsAndConditions}
              label={
                <>
                  Drag and drop or upload your <b>.pdf</b>&nbsp;source language file.
                </>
              }
            />
            <DownloadTermsAndConditions
              shouldEnableDownloading={sourceLanguage?.smartScanTermsAndConditionsFileName?.length > 0}
              btnTestId="sourceTermsAndConditionsDownloadBtn"
              languageId={sourceLanguage?.id}
            />
          </>
        )
      }
      {
        showGdprPrivacyPolicy && (
          <>
            <GdprPrivacyPolicyFieldset
              name="gdprPrivacyPolicy"
              gdprPrivacyPolicy={gdprPrivacyPolicy}
              setGdprPrivacyPolicy={setGdprPrivacyPolicy}
              label={
                <>
                  Drag and drop or upload your <b>.pdf</b>&nbsp;source language file.
                </>
              }
            />
            <DownloadGdprPrivacyPolicy
              shouldEnableDownloading={sourceLanguage?.smartScanGdprPrivacyPolicyFileName?.length > 0}
              btnTestId="sourceGdprPrivacyPolicyDownloadBtn"
              languageId={sourceLanguage?.id}
            />
          </>
        )
      }
      {sourceTranslations?.map(({ id, categoryName, translationValues }, translationsIndex) => (
        <fieldset key={id}>
          <h5 className="category-title">{categoryName}</h5>
          {translationValues.map(({ key, value }, translationIndex) => (
            <Fragment key={key}>
              <label htmlFor={key}>{key}</label>
              <TranslationsInput
                objValue={value}
                objKey={key}
                onChange={({ target }) => {
                  onInputChange({
                    target,
                    translationsIndex,
                    translationIndex,
                    translations: sourceTranslations,
                    translationsType: SOURCE_TRANSLATIONS,
                    hasSourceTranslationsChanged: !hasSourceTranslationsChanged ? true : hasSourceTranslationsChanged,
                  });
                }}
                disabled={disableTranslationsInput}
              />
            </Fragment>
          ))}
        </fieldset>
      ))}
    </div>
  );
}

SourceTranslations.propTypes = {
  translations: TTranslations,
  sourceLanguage: PropTypes.object,
  onInputChange: PropTypes.func.isRequired,
  termsAndConditions: PropTypes.object.isRequired,
  setTermsAndConditions: PropTypes.func.isRequired,
  gdprPrivacyPolicy: PropTypes.object.isRequired,
  setGdprPrivacyPolicy: PropTypes.func.isRequired,
  showGdprPrivacyPolicy: PropTypes.bool.isRequired,
  showTermsAndConditions: PropTypes.bool.isRequired,
  disableTranslationsInput: PropTypes.bool.isRequired
};
export default SourceTranslations;
