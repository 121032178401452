import { useEffect } from 'react';

export const useBeforeUnload = (hasUnsavedChanges = false) => {
  useEffect(() => {
    const beforeUnloadListener = (ev) => {
      if (!hasUnsavedChanges) {
        delete ev.returnValue;
      } else {
        ev.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [hasUnsavedChanges]);
};
