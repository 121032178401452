import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PhotoSeriesExportModal } from '../../../../../../components';
import { composeApiUrl } from '../../../../../../api/composeApiUrl';
import { makeGet } from '../../../../../../api/ajax';

const PhotoSeriesExportSection = ({ photoSeries, disabled }) => {
  const [shownExportPhotoSeriesModal, setShownExportPhotoSeriesModal] = useState(false);

  const handleExport = () => {
    if (!photoSeries.id) return;
    window.location = composeApiUrl(`photoSeries/${photoSeries.id}/export`);
  };

  const handleSendToAnalysis = () => {
    if (!photoSeries.id || photoSeries.damages?.length > 0) return;
    makeGet(`photoSeries/${photoSeries.id}/damageDetectionAnalysis`).subscribe(() => {
      setShownExportPhotoSeriesModal(false);
    });
  };

  if (!photoSeries) return null;

  return (
    <>
      <button
        type="button"
        className="button btn ml-2"
        data-testid="exportPhotoSeriesBtn"
        data-variation="light-blue"
        disabled={disabled}
        style={{ maxWidth: '5rem' }}
        onClick={() => {
          if (photoSeries.analyzedAt || photoSeries.completedAt === null) handleExport();
          else setShownExportPhotoSeriesModal(true);
        }}
      >
        Export
      </button>
      <PhotoSeriesExportModal
        show={shownExportPhotoSeriesModal}
        onHide={() => setShownExportPhotoSeriesModal(false)}
        onExport={() => handleExport()}
        onSendToAnalysis={() => handleSendToAnalysis()}
        hasDamages={photoSeries.damages?.length > 0}
      />
    </>
  );
};

PhotoSeriesExportSection.propTypes = {
  photoSeries: PropTypes.object,
  disabled: PropTypes.bool.isRequired
};

export default PhotoSeriesExportSection;
