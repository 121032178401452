import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const PhotoSeriesExportModal = ({
  show,
  onHide,
  onExport,
  onSendToAnalysis,
  hasDamages
}) => (
  <>
    <Modal
      size="md"
      backdrop="static"
      show={show}
      onHide={() => {
        onHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <h5>Export photo series</h5>
      </Modal.Header>

      <Modal.Body>
        { !hasDamages && <h6>Photo series has not been analysed. What would you like to do?</h6> }
        <div className="photoseries-export__row1">
          <button
            type="button"
            className="btn btn-primary"
            data-testid="exportPhotoSeriesAnywayBtn"
            onClick={() => {
              onExport();
            }}
          >
            { hasDamages ? 'Export' : 'Export anyway'}
          </button>
          {
            !hasDamages && (
              <button
                type="button"
                className="btn btn-primary ml-2"
                data-testid="sendToAnalysisBtn"
                onClick={() => {
                  onSendToAnalysis();
                }}
              >
                Send to analysis
              </button>
            )
          }
        </div>
      </Modal.Body>
    </Modal>
  </>
);

PhotoSeriesExportModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onSendToAnalysis: PropTypes.func.isRequired,
  hasDamages: PropTypes.bool.isRequired
};

export default PhotoSeriesExportModal;
