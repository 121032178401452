import { createSelector } from 'reselect';
import constants from './constants';

const selectTagsState = (state) => state[constants.NAME];
const selectCustomersTags = createSelector([selectTagsState], (tags) => tags.customers);
const selectCustomerTag = createSelector([selectTagsState], (tags) => tags.customerTag);

export default {
  selectCustomersTags,
  selectCustomerTag
};
