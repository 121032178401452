import React from 'react';
import PropTypes from 'prop-types';
import { FileUploadDropzone } from '../../../../containers';
import IconPdf from '../../../../assets/img/icn-file.svg';

const GdprPrivacyPolicyFieldset = ({ name, gdprPrivacyPolicy, setGdprPrivacyPolicy }) => {
  const fileName = gdprPrivacyPolicy[name]?.name;
  const label = <>Drag and drop or upload your <b>.pdf</b>&nbsp;{name === 'sourceGdprPrivacyPolicy' ? 'source' : 'targeted'} language file.</>;
  const pdfDropHandler = (acceptedFiles) => {
    const uploadedFile = acceptedFiles && acceptedFiles[0];

    if (uploadedFile) setGdprPrivacyPolicy({ ...gdprPrivacyPolicy, [name]: uploadedFile });
  };

  return (
    <fieldset>
      <h5 className="category-title">GDPR privacy policy</h5>
      <FileUploadDropzone
        accept="application/pdf"
        name={name}
        label={<p>{label}</p>}
        buttonLabel="Upload file"
        handleDrop={pdfDropHandler}
        fileName={fileName}
        objectUrl={fileName && IconPdf}
        resetFile={() => setGdprPrivacyPolicy({ ...gdprPrivacyPolicy, [name]: {} })}
      />
    </fieldset>
  );
};

GdprPrivacyPolicyFieldset.propTypes = {
  name: PropTypes.string.isRequired,
  gdprPrivacyPolicy: PropTypes.object.isRequired,
  setGdprPrivacyPolicy: PropTypes.func.isRequired
};

export default GdprPrivacyPolicyFieldset;
