import React from 'react';
import PropTypes from 'prop-types';

import './sass/error.scss';

const Error = ({ children, ...props }) => <div className="form-error" {...props}>{children}</div>;

Error.propTypes = {
  children: PropTypes.node,
};

export default Error;
