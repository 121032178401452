import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as BlueLogo } from '../../assets/img/icn-blue-logo.svg';
import { ReactComponent as WhiteLogo } from '../../assets/img/icn-white-logo.svg';

import './sass/logo.scss';

const Logo = ({ white }) => <div className="logo-container">{white ? <WhiteLogo /> : <BlueLogo />}</div>;

Logo.propTypes = {
  white: PropTypes.bool,
};

export default Logo;
