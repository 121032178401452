import types from './types';

import { STATE_KEYS } from './constants';

const initialState = {
  [STATE_KEYS.ALL_TRANSLATIONS]: null,
};

const customerTranslationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_TRANSLATIONS_SUCCESS: {
      const { langId, customerTranslations } = action.payload;
      return {
        ...state,
        [STATE_KEYS.ALL_TRANSLATIONS]: {
          ...state[STATE_KEYS.ALL_TRANSLATIONS],
          [langId]: customerTranslations
        }
      };
    }

    case types.SAVE_CUSTOMER_TRANSLATIONS_SUCCESS: {
      const { customerTranslations } = action.payload;

      const allTranslations = state[STATE_KEYS.ALL_TRANSLATIONS];

      const updatedLanguages = Object.keys(allTranslations).map((languageId) => {
        const updatedTranslations = customerTranslations.filter((updatedTranslation) => updatedTranslation.languageId === languageId);
        const updatedTranslationsIds = updatedTranslations.map((updatedTranslation) => updatedTranslation.id);

        const oldTranslations = allTranslations[languageId].filter((oldTranslation) => updatedTranslationsIds.indexOf(oldTranslation.id) === -1);

        return {
          languageId,
          translations: [
            ...oldTranslations,
            ...updatedTranslations
          ]
        };
      });

      const updatedLanguagesTransformed = Object.fromEntries(updatedLanguages
        .map((translation) => [translation.languageId, translation.translations]));

      return {
        ...state,
        [STATE_KEYS.ALL_TRANSLATIONS]: {
          ...state[STATE_KEYS.ALL_TRANSLATIONS],
          ...updatedLanguagesTransformed
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default customerTranslationsReducer;
