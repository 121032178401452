import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './sass/slide-open.scss';

const SlideOpen = ({ open, children }) => {
  const classes = classNames('slide-open', { 'is-open': open, });

  return <div className={classes}>{children}</div>;
};

SlideOpen.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
};

export default SlideOpen;
