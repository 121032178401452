import React from 'react';
import PropTypes from 'prop-types';

const TranslationsInput = ({ objKey, objValue, onChange, onFocus, label, ...restProps }) => (
  <input
    type="text"
    id={objKey}
    name={objKey}
    placeholder={`Type your '${label}'`}
    value={objValue}
    aria-label={objKey}
    onChange={onChange}
    onFocus={onFocus}
    {...restProps}
  />
);

TranslationsInput.propTypes = {
  objKey: PropTypes.string.isRequired,
  objValue: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
};
export default TranslationsInput;
