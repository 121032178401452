import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Modal, Loader } from '../../../../components';
import { Field } from '../../../../components/Form';
import { authenticationService } from '../../../../services';
import { constants as sessionConstants } from '../../../../redux/session';
import { makePost } from '../../../../api/ajax';
import { actions, constants } from '../../../../redux/windshieldRepairabilityProfiles';

const NewWindshieldRepairabilityProfileModal = ({
  title, showModal, setShowAddNewModal, initialValues, setShowAddNewSuccessMessage
}) => {
  const { CREATE_WINDSHIELD_REPAIRABILITY_PROFILE } = sessionConstants.PERMISSION_TYPES;
  const { hasPermission } = authenticationService;
  const dispatch = useDispatch();

  const handleAddNew = (values, setSubmitting, resetForm) => {
    if (!hasPermission(CREATE_WINDSHIELD_REPAIRABILITY_PROFILE)) return;
    const newValues = {
      ...values,
      driverFieldOfViewMask: constants.DEFAULT_DRIVER_FOV_LEFT,
      driverFieldOfView: constants.DRIVER_FIELD_OF_VIEW.LEFT
    };
    makePost('windshieldRepairProfiles', newValues).subscribe(({ response }) => {
      dispatch(actions.addWindshieldRepairabilityProfileSuccess(response));
      setShowAddNewSuccessMessage(`New windshield repairability profile '${response.name}' has been added`);
      setSubmitting(false);
      resetForm();
      setShowAddNewModal(false);
    });
  };

  return (
    <Modal
      size="md"
      headerText={title}
      onHide={() => setShowAddNewModal(false)}
      backdrop="static"
      show={showModal}
    >
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={
            Yup.object().shape({
              name: Yup.string()
                .required('Name is required')
                .max(50, 'Windshield repairability profile name must not be longer than 50 characters')
                .min(5, 'Windshield repairability profile name must be at least 5 characters'),
            })
          }
          onSubmit={(formData, { setSubmitting, resetForm }) => handleAddNew(formData, setSubmitting, resetForm)}
          validateOnChange
        >
          {
            ({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="row">
                  <div className="col-10 col-sm-12">
                    <Field type="text" name="name" label="Name" touched={touched.name} errors={errors.name} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-10 col-sm-12 pt-3 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary mr-1"
                      onClick={() => setShowAddNewModal(false)}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? <Loader size="tiny" isRelative noDelay white />
                        : 'Save'}
                    </button>
                  </div>
                </div>
              </Form>
            )
          }
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

NewWindshieldRepairabilityProfileModal.propTypes = {
  title: PropTypes.string,
  showModal: PropTypes.bool,
  setShowAddNewModal: PropTypes.func,
  initialValues: PropTypes.object,
  setShowAddNewSuccessMessage: PropTypes.func
};

export default NewWindshieldRepairabilityProfileModal;
