import types from './types';

import { STATE_KEYS } from './constants';

const initialState = {
  [STATE_KEYS.LANGUAGES]: [],
  [STATE_KEYS.SOURCE_TRANSLATIONS]: [],
  [STATE_KEYS.TARGETED_TRANSLATIONS]: [],
  [STATE_KEYS.SOURCE_TERMS_AND_CONDITIONS]: {},
  [STATE_KEYS.TARGETED_TERMS_AND_CONDITIONS]: {},
  [STATE_KEYS.SOURCE_GDPR_PRIVACY_POLICY]: {},
  [STATE_KEYS.TARGETED_GDPR_PRIVACY_POLICY]: {}
};

const translationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LANGUAGES_SUCCESS: {
      return { ...state, [STATE_KEYS.LANGUAGES]: action.payload };
    }

    case types.GET_SOURCE_TRANSLATIONS_SUCCESS: {
      return { ...state, [STATE_KEYS.SOURCE_TRANSLATIONS]: action.payload };
    }

    case types.GET_TARGETED_TRANSLATIONS_SUCCESS: {
      return { ...state, [STATE_KEYS.TARGETED_TRANSLATIONS]: action.payload };
    }

    case types.GET_SOURCE_TERMS_AND_CONDITIONS_SUCCESS: {
      return { ...state, [STATE_KEYS.SOURCE_TERMS_AND_CONDITIONS]: action.payload };
    }

    case types.GET_TARGETED_TERMS_AND_CONDITIONS_SUCCESS: {
      return { ...state, [STATE_KEYS.TARGETED_TERMS_AND_CONDITIONS]: action.payload };
    }

    case types.GET_SOURCE_GDPR_PRIVACY_POLICY: {
      return { ...state, [STATE_KEYS.SOURCE_GDPR_PRIVACY_POLICY]: action.payload };
    }

    case types.GET_TARGETED_GDPR_PRIVACY_POLICY: {
      return { ...state, [STATE_KEYS.TARGETED_GDPR_PRIVACY_POLICY]: action.payload };
    }

    default: {
      return state;
    }
  }
};

export default translationsReducer;
