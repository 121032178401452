const prefix = 'drivex/vehicles';

const SEARCH_VEHICLES_SUCCESS = `${prefix}/SEARCH_VEHICLES_SUCCESS`;
const LOAD_VEHICLE_PHOTOSERIES_SUCCESS = `${prefix}/LOAD_VEHICLE_PHOTOSERIES_SUCCESS`;
const SET_PAGED_RESULT_LOADED = `${prefix}/SET_PAGED_RESULT_LOADED`;

export default {
  SEARCH_VEHICLES_SUCCESS,
  LOAD_VEHICLE_PHOTOSERIES_SUCCESS,
  SET_PAGED_RESULT_LOADED,
};
