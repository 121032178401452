import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import { Combobox, Error } from '../../../components';
import { comboTypes } from '../../../components/Combobox/Combobox';
import { Field } from '../../../components/Form';
import { InfiniteComboBox } from '../../../containers';
import { selectors as customerSelectors } from '../../../redux/customers';
import {
  actions as countriesActions,
  selectors as countriesSelectors,
  actions as countryActions, selectors as countrySelectors,
} from '../../../redux/countries';
import { makeGet, makePost } from '../../../api/ajax';
import { routes } from '../../../routes/Routes';
import { authenticationService } from '../../../services';
import { customerData } from '../../../data/customer.json';
import { trackEvent, EVENT_TYPES } from '../../../api/analytics';

const handleSubmit = ({ profession, country, monthlyVehicleCount, areaOfInterest, otherAreaOfInterest }) => {
  const additionalInformation = {
    profession,
    country,
    monthlyVehicleCount,
    usecase: areaOfInterest,
    usecaseSpecification: otherAreaOfInterest,
  };

  makePost('users/additionalInformation', additionalInformation).subscribe(({ response }) => {
    authenticationService.storeUserData(response);

    window.location = routes.home;

    // Track event in Analytics
    trackEvent(EVENT_TYPES.ADDITIONAL_INFO_ADDED);
  });
};

const AskAdditionalInformationForm = () => {
  const professionRef = useRef(null);
  const areaOfInterestRef = useRef(null);

  const dispatch = useDispatch();
  const customer = useSelector(customerSelectors.selectCurrentCustomer);
  const country = useSelector((state) => countriesSelectors.selectCountry(state, customer && customer.countryId));

  useEffect(() => {
    if (!customer || !customer.countryId) return;

    makeGet(`countries/${customer.countryId}`).subscribe(({ response }) => dispatch(countriesActions.loadCountrySuccess(response)));
  }, [customer, dispatch]);

  if (!customer) return null;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        otherAreaOfInterest: '',
        monthlyVehicleCount: '',
        profession: '',
        country: (country && country.code) || '',
        areaOfInterest: '',
      }}
      validationSchema={Yup.object().shape({
        otherAreaOfInterest: Yup.string().when('areaOfInterest', {
          is: customerData.OTHER_AREA_OF_INTEREST,
          then: Yup.string().required('Area of interest specification is required'),
        }),
        monthlyVehicleCount: Yup.number().required('Vehicles photographed per month is required'),
        profession: Yup.string().required('Profession is required'),
        country: Yup.string().required('Country is required'),
        areaOfInterest: Yup.string().required('Area of interest is required'),
      })}
      onSubmit={(values, { setStatus }) => {
        handleSubmit(
          { ...values },
          setStatus,
        );
      }}
      validateOnChange={false}
    >
      {({ values, errors, status, touched, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="col col-sm-4 additional-info-combo-wrapper">
              <Combobox
                type={comboTypes.infiniteCombo}
                label="Your profession*"
                values={customerData.PROFESSIONS}
                setSelectedItem={(selectedProfession) => {
                  const profession = _.isEmpty(selectedProfession)
                    ? ''
                    : selectedProfession.value;

                  setFieldValue('profession', profession);
                }}
                ref={professionRef}
                disableInfiniteScroll
                isInternalFiltration
                required
                error={errors.profession}
                searchingDisabled={false}
              />
            </div>
          </div>

          <div className="row mt-1">
            <div className="col col-sm-4 additional-info-combo-wrapper">
              <InfiniteComboBox
                label="Country*"
                inputId="countryInput"
                loadUrl="countries"
                searchParam="countryName"
                onLoadSuccess={(countriesPaged) => dispatch(countryActions.loadCountriesSuccess(countriesPaged))}
                resultSelector={countrySelectors.selectPagedResult}
                error={errors.country}
                onSelect={(selectedCountry) => {
                  const countryCode = _.isEmpty(selectedCountry)
                    ? ''
                    : selectedCountry.code;

                  setFieldValue('country', countryCode);
                }}
                initialValue={country && country.name}
                searchingDisabled={false}
              />
            </div>
          </div>

          <div className="row d-flex align-content-center align-items-center">
            <div className="col col-sm-4 additional-info-combo-wrapper">
              <Combobox
                type={comboTypes.infiniteCombo}
                label="Your area of interest*"
                values={customerData.AREAS_OF_INTEREST}
                setSelectedItem={(selectedItem) => {
                  const areaOfInterest = _.isEmpty(selectedItem)
                    ? ''
                    : selectedItem.value;

                  setFieldValue('areaOfInterest', areaOfInterest);
                }}
                ref={areaOfInterestRef}
                disableInfiniteScroll
                isInternalFiltration
                required
                error={errors.areaOfInterest}
                searchingDisabled={false}
              />
            </div>
            {values.areaOfInterest === customerData.OTHER_AREA_OF_INTEREST && (
              <div className="col col-sm-4 other-areas">
                <Field
                  type="text"
                  name="otherAreaOfInterest"
                  label="Please specify*"
                  errors={errors.otherAreaOfInterest}
                  touched={touched.otherAreaOfInterest}
                />
              </div>
            )}
          </div>

          <div className="row">
            <div className="col col-sm-4 monthy-vehicle-count">
              <Field
                type="number"
                min="0"
                name="monthlyVehicleCount"
                label="Vehicles photographed per month*"
                placeholder="Insert number"
                errors={errors.monthlyVehicleCount}
                touched={touched.monthlyVehicleCount}
              />
            </div>
          </div>

          <div className="row">
            <div className="col col-sm-6">{status && <Error>{status}</Error>}</div>
          </div>
          <div className="row">
            <div className="col col-sm-3 mt-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AskAdditionalInformationForm;
