/* eslint-disable */
import React, { useRef, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';

import { routes } from '../../../routes/Routes';

const sdk = new ChartsEmbedSDK({
  baseUrl: 'https://charts.mongodb.com/charts-drivex-api-live-project-ixpoido',
});

const ExternalCharts = () => {
  const refCompletedScansChart = useRef(null);
  const refWeeklyScansChart = useRef(null);
  const refCustomerMonthlyScansChart = useRef(null);

  const location = useLocation();
  const isVisbile = location.pathname === routes.statistics;

  useEffect(() => {
    return () => {
      refCompletedScansChart.current = null;
      refWeeklyScansChart.current = null;
      refCustomerMonthlyScansChart.current = null;
    }
  }, []);

  // Completed scans chart
  const renderCompletedScansChart = useCallback(async (ref) => {
    try {
      await (sdk.createChart({
        chartId: '662281da-27c4-4ad9-88d2-75c34340a640',
      })).render(ref);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const setRefCompletedScansChart = useCallback(
    (ref) => {
      if (ref) {
        renderCompletedScansChart(ref);
      }
      // Save a reference to the node
      refCompletedScansChart.current = ref;
    },
    [renderCompletedScansChart]
  );

  // Weekly scans chart
  const renderWeeklyScansChart = useCallback(async (ref) => {
    try {
      await (sdk.createChart({
        chartId: '662f66f6-c731-4f75-8cfa-520157c80f33',
      })).render(ref);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const setRefWeeklyScansChart = useCallback(
    (ref) => {
      if (ref) {
        renderWeeklyScansChart(ref);
      }
      // Save a reference to the node
      refWeeklyScansChart.current = ref;
    },
    [renderWeeklyScansChart]
  );

  // Customer monthly scans chart
  const renderCustomerMonthlyScansChart = useCallback(async (ref) => {
    try {
      await (sdk.createChart({
        chartId: '66ac8a8d-2c31-4aef-86b3-63e5d099dc33',
      })).render(ref);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const setRefCustomerMonthlyScansChart = useCallback(
    (ref) => {
      if (ref) {
        renderCustomerMonthlyScansChart(ref);
      }
      // Save a reference to the node
      refCustomerMonthlyScansChart.current = ref;
    },
    [renderCustomerMonthlyScansChart]
  );

  return (
    <div className="container" style={{ visibility: isVisbile ? 'visible' : 'hidden' }}>
      <div style={{ padding: '2rem' }}>
        <div className="row">
          <div className="col-sm-12 col-md-7">
            <div className="card">
              <div className="card-body">
                <div id="completedScansChart" ref={setRefCompletedScansChart} style={{ height: '60vh' }} />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-5 pb-4">
            <div className="card">
              <div className="card-body">
                <div id="weeklyScansChart" ref={setRefWeeklyScansChart} style={{ height: '60vh' }} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <div id="customerMonthlyScansChart" ref={setRefCustomerMonthlyScansChart} style={{ height: '75vh' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalCharts;
