import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { makeGetFile } from '../../../../../../api/ajax';

import {
  actions as imagesActions,
  constants as imagesConstants,
} from '../../../../../../redux/images';
import {
  selectors as damagesSelectors
} from '../../../../../../redux/damages';

import { Text, Thumbnail } from '../../../../../../components';

import ImageDetails from '../ImageDetails/ImageDetails';
import { useCanViewPhotoSeriesDamages } from '../../hooks/useCanViewPhotoSeriesDamages';

const PhotoThumbnail = ({ small, large, onClick, isDefaultThumbnail, image }) => {
  const canViewPhotoSeriesDamages = useCanViewPhotoSeriesDamages(image.photoSeriesId);
  const damages = useSelector((state) => damagesSelectors.selectImageDamages(state, image.photoSeriesId, image.id));

  const hasDamages = () => {
    if (!canViewPhotoSeriesDamages()) return false;
    if (!damages || damages.length === 0) return false;
    return damages.filter((damage) => damage.flaggedAt === null).length > 0;
  };

  return (
    <Thumbnail
      small={small}
      large={large}
      onClick={onClick}
      isDefaultThumbnail={isDefaultThumbnail}
      fileName={image.filePath}
      errors={image.errors && image.errors.length > 0}
      damages={hasDamages()}
      attemptNo={image.attemptNo}
    />
  );
};

PhotoThumbnail.propTypes = {
  onClick: PropTypes.func,
  isDefaultThumbnail: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  image: PropTypes.object
};

const PhotoThumbnailGroup = ({ images, name, onClick, imagesClassName }) => {
  const hasImages = images && Object.values(images).length > 0;
  if (!hasImages) return null;

  return (
    <>
      <div className="row no-gutters pb-2">
        <div className="col-sm-12 col-md-2">
          <Text size={1} color="black" weight="bold">
            { name }
          </Text>
        </div>
      </div>
      <div className="row no-gutters">
        {
          images
            && images.length > 0
            && _.map(images, (image, key) => (
              <div className={imagesClassName !== undefined ? imagesClassName : 'col-sm-12 col-md-2 pr-2 pb-2'} key={key}>
                <PhotoThumbnail
                  small
                  onClick={() => onClick(image)}
                  image={image}
                />
              </div>
            ))
        }
      </div>
    </>
  );
};

PhotoThumbnailGroup.propTypes = {
  images: PropTypes.array,
  name: PropTypes.string,
  onClick: PropTypes.func,
  imagesClassName: PropTypes.string
};

const PhotoThumbnails = ({ photoSeriesImages }) => {
  const [activeImage, setActiveImage] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeImage) {
      const updatedImage = photoSeriesImages.find((image) => image.id === activeImage.id);
      setActiveImage(updatedImage);
    }
  }, [photoSeriesImages, setActiveImage, activeImage]);

  if (!photoSeriesImages) return null;
  const largeThumbnail = _.orderBy(photoSeriesImages, 'createdAt')[0];
  const images = _.drop(photoSeriesImages);

  const exteriorImages = _.filter(images, (i) => i.imageType === imagesConstants.IMAGE_TYPES.EXTERIOR);

  const vinImages = _.filter(images, (i) => i.imageType === imagesConstants.IMAGE_TYPES.VIN);
  const dataStickerImages = _.filter(images, (i) => i.imageType === imagesConstants.IMAGE_TYPES.DATA_STICKER);
  const windshieldImages = _.filter(images, (i) => i.imageType === imagesConstants.IMAGE_TYPES.WINDSHIELD);
  const odometerImages = _.filter(images, (i) => i.imageType === imagesConstants.IMAGE_TYPES.ODOMETER);
  const interiorImages = _.filter(images, (i) => i.imageType === imagesConstants.IMAGE_TYPES.INTERIOR);
  const vehicleKeysImages = _.filter(images, (i) => i.imageType === imagesConstants.IMAGE_TYPES.KEYS);
  const additionalImages = _.filter(images, (i) => i.imageType === imagesConstants.IMAGE_TYPES.ADDITIONAL);
  const wheelsImages = _.filter(images, (i) => i.imageType === imagesConstants.IMAGE_TYPES.WHEELS);

  const customImages = _.filter(images, (i) => i.imageType === imagesConstants.IMAGE_TYPES.CUSTOM);
  const customImagesGrouped = _.groupBy(customImages, ({ customImageSubType: { id } }) => id);

  const updateActiveImage = (image) => {
    if (activeImage) dispatch(imagesActions.clearImage(activeImage.id));
    setActiveImage(image);
  };

  const displayImage = (image) => {
    updateActiveImage(image);
    makeGetFile(`images/${image.id}/download`).subscribe(({ response }) => {
      dispatch(imagesActions.loadImageSuccess(image.id, response));
    });
  };

  const switchPhoto = (direction) => {
    const currentPhotoIndex = _.findIndex(photoSeriesImages, (i) => i.id === activeImage.id);
    let nextPhotoIndex = currentPhotoIndex + direction;

    const isLast = currentPhotoIndex === photoSeriesImages.length - 1;
    if (isLast && direction > 0) {
      nextPhotoIndex = 0;
    } else if (currentPhotoIndex === 0 && direction < 0) {
      nextPhotoIndex = photoSeriesImages.length - 1;
    }

    const nextPhoto = photoSeriesImages[nextPhotoIndex];

    displayImage(nextPhoto);
  };

  return (
    <>
      <div className="row no-gutters pb-2">
        <div className="col-sm-12 col-md-8 pr-1">
          {largeThumbnail && (
            <PhotoThumbnail
              large
              onClick={() => displayImage(largeThumbnail)}
              image={largeThumbnail}
            />
          )}
        </div>
        <div className="col-sm-12 col-md-4">
          <PhotoThumbnailGroup
            images={exteriorImages}
            name="Exterior"
            onClick={displayImage}
            imagesClassName="col-sm-6 px-1 pb-1"
          />
        </div>
      </div>
      <PhotoThumbnailGroup images={vinImages} name="VIN" onClick={displayImage} />
      <PhotoThumbnailGroup images={dataStickerImages} name="Data label" onClick={displayImage} />
      <PhotoThumbnailGroup images={windshieldImages} name="Windshield" onClick={displayImage} />
      <PhotoThumbnailGroup images={odometerImages} name="Odometer" onClick={displayImage} />
      <PhotoThumbnailGroup images={interiorImages} name="Interior" onClick={displayImage} />
      <PhotoThumbnailGroup images={wheelsImages} name="Wheels" onClick={displayImage} />
      <PhotoThumbnailGroup images={vehicleKeysImages} name="Keys" onClick={displayImage} />
      {
        Object.keys(customImagesGrouped).map((customImageSubTypeId) => {
          const images = customImagesGrouped[customImageSubTypeId];
          return (
            <PhotoThumbnailGroup
              key={customImageSubTypeId}
              images={images}
              name={images[0].customImageSubType.name}
              onClick={displayImage}
            />
          );
        })
      }
      <PhotoThumbnailGroup images={additionalImages} name="Additional" onClick={displayImage} />
      {activeImage && (
        <ImageDetails
          image={activeImage}
          onSwitchPhoto={switchPhoto}
          onClose={() => updateActiveImage(null)}
        />
      )}
    </>
  );
};

PhotoThumbnails.propTypes = {
  photoSeriesImages: PropTypes.array,
};

export default PhotoThumbnails;
