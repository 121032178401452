import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './sass/container.scss';

const Container = ({ fullscreen, children, bottom, distance, verticalAlign, fit, horizontalAlign, className }) => {
  const classes = classNames(className, {
    bottom,
    fit,
    container: !fullscreen,
    'is-1': distance === 1,
    'is-2': distance === 2,
    'is-3': distance === 3,
    'valign-center': verticalAlign === 'center',
    'halign-center': horizontalAlign === 'center',
    'container-fullscreen': fullscreen,
  });

  return <div className={classes}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  fit: PropTypes.bool,
  bottom: PropTypes.bool,
  fullscreen: PropTypes.bool,
  distance: PropTypes.number,
  verticalAlign: PropTypes.string,
  horizontalAlign: PropTypes.string,
  className: PropTypes.string,
};
export default Container;
