import React, { useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import * as queryString from 'querystring';
import JwtDecode from 'jwt-decode';
import ReCAPTCHA from 'react-google-recaptcha';

import { constants as sessionConstants } from '../../redux/session';
import { makePost } from '../../api/ajax';
import { routes } from '../../routes/Routes';
import { PageTitle } from '../../components';
import { EmptyPage } from '../../layouts';
import { authenticationService } from '../../services';

const ConfirmEmail = () => {
  const history = useHistory();
  const location = useLocation();

  const query = queryString.parse(location.search.substring(1));
  const decodedJwt = JwtDecode(query.accessToken);
  const isTokenExpired = new Date(decodedJwt.exp * 1000) < Date.now();

  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [error, setError] = useState(isTokenExpired ? 'This link has expired. Please contact your provider for a new confirmation link.' : '');

  const handleRecaptchaChange = (captchaToken) => {
    if (captchaToken === null || isAuthenticating) {
      return;
    }

    setIsAuthenticating(true);

    makePost(`session/${query.userId}`, {
      token: query.accessToken,
      loginType: sessionConstants.ONE_TIME_LOGIN_TYPE.EMAIL_CONFIRMATION,
      captchaToken,
    }).subscribe(
      ({ response }) => {
        authenticationService.storeUserData(response);
        if (response.askAdditionalInformation) {
          history.replace(routes.askAdditionalInformation);
        } else {
          history.replace(routes.home);
        }
      },
      (error) => {
        if (error.status === 401) {
          setError('This link has already been used.');
          setIsAuthenticating(false);
        } else throw error;
      }
    );
  };

  return (
    <EmptyPage currentRoute={routes.account}>
      <PageTitle>
        <PageTitle.Left headingText="Email confirmation" />
      </PageTitle>
      <div className="jumbotron">
        {
          error.length > 0
            ? <p style={{ color: 'red' }}>{error}</p>
            : <p>Please check the box to continue</p>
        }
        {!isTokenExpired
          ? (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleRecaptchaChange}
            />
          )
          : (
            <Link exact to={routes.home} className="btn btn-primary">
              Log in
            </Link>
          )}
      </div>
    </EmptyPage>
  );
};

export default ConfirmEmail;
