import React from 'react';
import PropTypes from 'prop-types';

import { Toggle, Checkbox } from '../../../../../../components';

// TODO:  New component for smart scan comment settings
//        Currently draft implementation here and will be improved over time
const SmartScanCommentSettings = ({ values, setFieldValue }) => {
  const { smartScanCommentSettings } = values;
  return (
    <>
      <div className="row mt-2">
        <div className="col-sm-12">
          <div className="d-flex align-items-center">
            <span className="mr-2">SmartScan comments</span>
            <Toggle
              on={smartScanCommentSettings.enabled}
              toggleClick={() => {
                setFieldValue('smartScanCommentSettings', {
                  ...smartScanCommentSettings,
                  enabled: !smartScanCommentSettings.enabled,
                  automaticPopup: false,
                  visibleInReport: false
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-12 checkbox">
          <Checkbox
            id="smartScanCommentSettings.automaticPopup"
            label="Ask automatically"
            name="smartScanCommentSettings.automaticPopup"
            checked={smartScanCommentSettings.automaticPopup}
            onClickHandler={() => {
              setFieldValue('smartScanCommentSettings', {
                ...smartScanCommentSettings,
                automaticPopup: !smartScanCommentSettings.automaticPopup
              });
            }}
            className="pl-3"
            disabled={!smartScanCommentSettings.enabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 checkbox">
          <Checkbox
            id="smartScanCommentSettings.visibleInReport"
            label="Visible in report"
            name="smartScanCommentSettings.visibleInReport"
            checked={smartScanCommentSettings.visibleInReport}
            onClickHandler={() => {
              setFieldValue('smartScanCommentSettings', {
                ...smartScanCommentSettings,
                visibleInReport: !smartScanCommentSettings.visibleInReport
              });
            }}
            className="pl-3"
            disabled={!smartScanCommentSettings.enabled}
          />
        </div>
      </div>
    </>
  );
};

SmartScanCommentSettings.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default SmartScanCommentSettings;
