import React from 'react';
import PropTypes from 'prop-types';

import './sass/skeleton-ui.scss';

const SkeletonUI = ({ type, width, height, maxWidth, maxHeight, minWidth, minHeight, classNames }) => (
  <div style={{ width, height, maxWidth, maxHeight, minWidth, minHeight }} className={`skeleton-ui skeleton-ui--${type} ${classNames}`} />
);

SkeletonUI.propTypes = {
  type: PropTypes.oneOf(['avatar', 'radius-horizontal', 'block-horizontal', 'radius-vertical', 'block-vertical']).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  classNames: PropTypes.string
};
export default SkeletonUI;
