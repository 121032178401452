import types from './types';

const loadCurrentCustomerConfiguration = () => ({
  type: types.LOAD_CURRENT_CUSTOMER_CONFIGURATION,
});

const loadCurrentCustomerConfigurationSuccess = (customerConfiguration) => ({
  type: types.LOAD_CURRENT_CUSTOMER_CONFIGURATION_SUCCESS,
  payload: {
    customerConfiguration,
  },
});

const loadCurrentCustomerLogoSuccess = (logoFile) => ({
  type: types.LOAD_CURRENT_CUSTOMER_LOGO_SUCCESS,
  payload: {
    logoFile,
  },
});

const clearCustomerConfiguration = () => ({ type: types.CLEAR_CUSTOMER_CONFIGURATION });

export default {
  loadCurrentCustomerConfiguration,
  loadCurrentCustomerConfigurationSuccess,
  clearCustomerConfiguration,
  loadCurrentCustomerLogoSuccess,
};
