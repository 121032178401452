import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { Form, Formik, FieldArray } from 'formik';
import { Alert } from 'react-bootstrap';
import * as Yup from 'yup';

import { useBeforeUnload } from '../../../../../hooks';
import { Modal, Text, onHideHandler, renderShowConfirmationModal, } from '../../../../../components';
import { makeGet, makePatch, makePut } from '../../../../../api/ajax';
import { constants as imagesConstants, useImageTypeErrors } from '../../../../../redux/images';
import { actions as workflowsActions, selectors as workflowsSelectors, constants as workflowsConstants, } from '../../../../../redux/workflows';
import { trackEvent, EVENT_TYPES } from '../../../../../api/analytics';

import ValidationSetting from './ValidationSetting';
import ExteriorAngleSelection from './ExteriorAngleSelection';
import VinSubTypeSelection from './VinSubTypeSelection';
import WindshieldSubTypeSelection from './WindshieldSubTypeSelection';
import SmartScanCommentSettings from './SmartScanCommentSettings';
import InteriorAngleSelection from './InteriorAngleSelection';
import CustomImageTypeSelection from './CustomImageTypeSelection';
import ManualMarkingSettings from './ManualMarkingSettings';

import AdditionalImageTypeModal from './AdditionalImageTypeModal/AdditionalImageTypeModal';

const { INSPECTION_STICKER } = imagesConstants.IMAGE_SUB_TYPES;

const getVerificationErrorErrorType = (imageType, imageTypeError, verificationError) => {
  const imageVisibilityErrors = imagesConstants.IMAGE_TYPE_VISIBILITY_ERRORS[imageType];

  // All visibility errors are temporarily hidden
  const defaultType = imageVisibilityErrors?.indexOf(imageTypeError) > -1
    ? imagesConstants.IMAGE_VERIFICATION_ERROR_TYPE.HIDDEN
    : imagesConstants.IMAGE_VERIFICATION_ERROR_TYPE.WARNING;

  return (verificationError && verificationError.errorType)
    || defaultType;
};

const trackChangedSettings = (
  initialValues,
  requestData,
  imageType,
  { id: workflowId, name: workflowName, vehicleType: workflowVehicleType },
) => {
  const getDiff = (object, base) => _.transform(
    object,
    (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? getDiff(value, base[key]) : value;
      }
    }
  );

  const validationsTurnedOn = [];
  const validationsTurnedOff = [];
  const validationsModified = [];

  _.forEach(
    initialValues.enabledVerificationErrors,
    (initialVerificationError) => {
      const verificationError = _.find(requestData.enabledVerificationErrors, (err) => err.error === initialVerificationError.error);

      if (!verificationError) {
        if (initialVerificationError.enabled) validationsTurnedOff.push(initialVerificationError.error);
        return;
      }

      if (!initialVerificationError.enabled) {
        validationsTurnedOn.push(verificationError.error);
        return;
      }

      if (!_.isEqual(verificationError, initialVerificationError)) {
        validationsModified.push({ error: verificationError.error, ...getDiff(verificationError, initialVerificationError) });
      }
    }
  );

  const disabledImageSubTypes = _.differenceWith(
    initialValues.imageSubTypes.map((x) => x.imageSubType),
    requestData.imageSubTypes.map((x) => x.imageSubType),
    _.isEqual
  );
  const enabledImageSubTypes = _.differenceWith(
    requestData.imageSubTypes.map((x) => x.imageSubTypes),
    initialValues.imageSubTypes.map((x) => x.imageSubType),
    _.isEqual
  );

  const data = {
    workflowId,
    workflowName,
    workflowVehicleType,
    imageType: imagesConstants.CAPTURE_NAMES[imageType],
    changedValidationSettings: {
      ...(!_.isEmpty(validationsTurnedOn) && { validationsTurnedOn }),
      ...(!_.isEmpty(validationsTurnedOff) && { validationsTurnedOff }),
      ...(!_.isEmpty(validationsModified) && { validationsModified }),
      ...(!_.isEmpty(disabledImageSubTypes) && { disabledImageSubTypes }),
      ...(!_.isEmpty(disabledImageSubTypes) && { enabledImageSubTypes }),
    },
  };

  trackEvent(EVENT_TYPES.WORKFLOW_VALIDATION_SETTINGS_EDITED, data);
};

const handleSubmit = (
  formData,
  initialValues,
  workflow,
  imageType,
  loadImageTypeCaptureSettingsSuccess,
  loadUpdateWorkflowSuccess,
  setSuccessMessage,
  setHasUnsavedChanges,
  setErrorMessage
) => {
  const isInspectionSticker = formData.imageSubTypes
                              && formData.imageSubTypes.length > 0
                              && formData.imageSubTypes[0].imageSubType === INSPECTION_STICKER;

  const enabledVerificationErrors = isInspectionSticker
    ? null
    : _.filter(formData.enabledVerificationErrors, ({ enabled }) => enabled);

  const requestData = {
    imageSubTypes: formData.imageSubTypes,
    enabledVerificationErrors,
    smartScanCommentSettings: formData.smartScanCommentSettings,
    manualDamageLabelling: formData.manualDamageLabelling,
  };

  const handleError = (err) => {
    const { status, response: { error } } = err;
    switch (status) {
      case 400:
        setErrorMessage(error);
        break;
      default:
        setErrorMessage(null);
        break;
    }
  };

  const updateWindshieldRepairDamagePredictionConfidenceThreshold = (windshieldRepairDamagePredictionConfidenceThreshold) => {
    if (imageType !== imagesConstants.IMAGE_TYPES.ADDITIONAL || !workflow.manualDamageLabellingEnabled) {
      return;
    }

    if (workflow.windshieldRepairDamagePredictionConfidenceThreshold === windshieldRepairDamagePredictionConfidenceThreshold) {
      return;
    }

    makePatch(`workflows/${workflow.id}`, {
      windshieldRepairDamagePredictionConfidenceThreshold: formData.windshieldRepairDamagePredictionConfidenceThreshold !== '0'
        ? formData.windshieldRepairDamagePredictionConfidenceThreshold
        : null
    }).subscribe(
      ({ response }) => loadUpdateWorkflowSuccess(workflow.id, response),
      (err) => handleError(err)
    );
  };

  makePut(`workflows/${workflow.id}/imageType/${imageType}/details`, requestData).subscribe(
    ({ response }) => {
      loadImageTypeCaptureSettingsSuccess(workflow.id, imageType, response);
      setSuccessMessage('Capture settings changed!');
      setHasUnsavedChanges(false);
      setErrorMessage(null);

      // Track event in Analytics
      trackChangedSettings(initialValues, requestData, imageType, workflow);

      // Update damage type windshield repair damage prediction confidence threshold
      updateWindshieldRepairDamagePredictionConfidenceThreshold(formData.windshieldRepairDamagePredictionConfidenceThreshold);
    },
    (err) => {
      setSuccessMessage(null);
      handleError(err);
      setHasUnsavedChanges(false);
    },
  );
};

const ImageTypeModal = ({
  workflowId,
  imageType,
  onHide,
  loadImageTypeCaptureSettingsSuccess,
  loadUpdateWorkflowSuccess,
  readonly,
  vehicleType,
  ...props
}) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useBeforeUnload(hasUnsavedChanges);

  const isInterior = imageType === imagesConstants.IMAGE_TYPES.INTERIOR;
  const isExterior = imageType === imagesConstants.IMAGE_TYPES.EXTERIOR;
  const isVin = imageType === imagesConstants.IMAGE_TYPES.VIN;
  const isWindshield = imageType === imagesConstants.IMAGE_TYPES.WINDSHIELD;
  const isAdditional = imageType === imagesConstants.IMAGE_TYPES.ADDITIONAL;
  const isCustom = imageType === imagesConstants.IMAGE_TYPES.CUSTOM;

  const imageTypeErrors = useImageTypeErrors(vehicleType, imageType);
  const captureSettings = useSelector((state) => workflowsSelectors.getImageTypeCaptureSettings(state, workflowId, imageType));

  const workflow = useSelector((state) => workflowsSelectors.getWorkflow(state, workflowId));
  const isManualDamageLabelling = workflow.manualDamageLabellingEnabled;

  const hasVisibilityValidationSettings = imageType in imagesConstants.IMAGE_TYPE_VISIBILITY_ERRORS
    && (vehicleType === workflowsConstants.VEHICLE_TYPES.CAR);

  useEffect(() => {
    if (captureSettings) return;

    makeGet(`workflows/${workflowId}/imageType/${imageType}/details`).subscribe(({ response }) =>
      loadImageTypeCaptureSettingsSuccess(workflowId, imageType, response));
  }, [captureSettings, workflowId, imageType, loadImageTypeCaptureSettingsSuccess]);

  if (!captureSettings) return null;

  const imageTypeErrorsToParse = hasVisibilityValidationSettings
    ? [...imageTypeErrors, ...imagesConstants.IMAGE_TYPE_VISIBILITY_ERRORS[imageType]]
    : imageTypeErrors;

  const enabledVerificationErrors = _.reduce(imageTypeErrorsToParse, (accumulator, imageTypeError) => {
    const enabledVerificationError = _.find(
      captureSettings.enabledVerificationErrors,
      ({ error }) => error === imageTypeError,
    );

    accumulator[imageTypeError] = {
      enabled: _.isObject(enabledVerificationError),
      error: imageTypeError,
      errorType: getVerificationErrorErrorType(imageType, imageTypeError, enabledVerificationError),
      fieldPrefix: `enabledVerificationErrors[${imageTypeError}]`,
      dependentOn: imagesConstants.IMAGE_VERIFICATION_ERROR_DEPENDENCIES[imageTypeError],
    };

    return accumulator;
  }, {});

  const initialValues = {
    enabledVerificationErrors,
    imageSubTypes: captureSettings.imageSubTypes,
    smartScanCommentSettings: captureSettings.smartScanCommentSettings,
    manualDamageLabelling: captureSettings.manualDamageLabelling,
  };

  if (imageType === imagesConstants.IMAGE_TYPES.ADDITIONAL && workflow.manualDamageLabellingEnabled) {
    initialValues.windshieldRepairDamagePredictionConfidenceThreshold = workflow?.windshieldRepairDamagePredictionConfidenceThreshold || 0;
  }

  const toggleVerificationError = (setFieldValue, error, enabled) => {
    setFieldValue(`enabledVerificationErrors[${error}].enabled`, enabled);

    if (enabled) return;

    const dependentErrors = _.filter(
      initialValues.enabledVerificationErrors,
      (verificationErrorValue) => verificationErrorValue.dependentOn === error,
    );

    _.forEach(dependentErrors, ({ fieldPrefix }) => setFieldValue(`${fieldPrefix}.enabled`, false));
  };

  if (_.isEmpty(initialValues)) return null;

  const hideModal = () => {
    setSuccessMessage(null);
    onHide();
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, onHide)}
      <Modal
        headerText={`${imagesConstants.CAPTURE_NAMES[imageType]} capture settings`}
        size={isCustom ? 'xl' : isExterior || isInterior || isVin ? 'lg' : imageTypeErrors || isAdditional ? 'md' : 'sm'}
        onHide={() => onHideHandler(hasUnsavedChanges, hideModal, setShowConfirmModal)}
        backdrop="static"
        {...props}
      >
        <Modal.Body>
          <div className="row">
            <div className="col">
              {successMessage && (
                <Alert variant="success" dismissible onClick={() => setSuccessMessage(null)}>
                  {successMessage}
                </Alert>
              )}
              {
                errorMessage && (
                  <Alert variant="danger" dismissible onClick={() => setErrorMessage(null)}>
                    {errorMessage}
                  </Alert>
                )
              }
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(formData) =>
              handleSubmit(
                formData,
                initialValues,
                workflow,
                imageType,
                loadImageTypeCaptureSettingsSuccess,
                loadUpdateWorkflowSuccess,
                setSuccessMessage,
                setHasUnsavedChanges,
                setErrorMessage
              )}
            validationSchema={Yup.object().shape({
              windshieldRepairDamagePredictionConfidenceThreshold: Yup
                .number()
                .min(0, 'The damage confidence threshold must be at least 0.')
                .max(100, 'The damage confidence threshold cannot exceed 100.')
            })}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form
                onChange={({ target }) => {
                  if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                    setHasUnsavedChanges(true);
                  }
                }}
              >
                {
                  isAdditional ? (
                    <AdditionalImageTypeModal
                      errors={errors}
                      touched={touched}
                      workflow={workflow}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ) : (
                    <>
                      <div className="row">
                        {imageTypeErrors && imageTypeErrors.length > 0 && (
                          <div className="col-xs-12 col-md-5">
                            <h6>Quality validation settings</h6>
                            {values.enabledImageSubTypes && values.enabledImageSubTypes[0] === INSPECTION_STICKER ? (
                              <Text align="left" color="black" size={1}>
                                No validations available for this image subtype!
                              </Text>
                            ) : (
                              <FieldArray
                                name="enabledVerificationErrors"
                                render={() =>
                                  _.map(imageTypeErrors, (imageTypeError) => {
                                    const verificationErrorValue = values.enabledVerificationErrors[imageTypeError];

                                    const dependentErrorNotEnabled = verificationErrorValue?.dependentOn
                                      && _.find(
                                        values.enabledVerificationErrors,
                                        ({ error, enabled }) => enabled && error === verificationErrorValue?.dependentOn,
                                      ) === undefined;

                                    return (
                                      <ValidationSetting
                                        key={imageTypeError}
                                        imageTypeError={imageTypeError}
                                        checked={verificationErrorValue?.enabled}
                                        disabled={readonly || dependentErrorNotEnabled}
                                        fieldPrefix={verificationErrorValue?.fieldPrefix}
                                        setFieldValue={setFieldValue}
                                        toggleVerificationError={toggleVerificationError}
                                        hasErrorType
                                      />
                                    );
                                  })}
                              />
                            )}
                          </div>
                        )}
                        <div className={`col-xs-${imageTypeErrors && !isCustom ? '6' : '12 col-md-12'}`}>
                          {isVin && (
                            <VinSubTypeSelection
                              imageSubTypes={values.imageSubTypes}
                              setFieldValue={setFieldValue}
                              vehicleType={workflow.vehicleType}
                            />
                          )}
                          {isWindshield && (
                            <WindshieldSubTypeSelection
                              imageSubTypes={values.imageSubTypes}
                              setFieldValue={setFieldValue}
                            />
                          )}
                          {
                            isExterior && (
                              <ExteriorAngleSelection
                                imageSubTypes={values.imageSubTypes}
                                setFieldValue={setFieldValue}
                                vehicleType={workflow.vehicleType}
                              />
                            )
                          }
                          {
                            isInterior && (
                              <InteriorAngleSelection
                                imageSubTypes={values.imageSubTypes}
                                setFieldValue={setFieldValue}
                              />
                            )
                          }
                          {
                            isCustom && (
                              <CustomImageTypeSelection
                                imageSubTypes={values.imageSubTypes}
                                setFieldValue={setFieldValue}
                                showDamageMarking={isManualDamageLabelling}
                              />
                            )
                          }
                          {hasVisibilityValidationSettings && (
                            <div className="mt-2">
                              <h6>Visibility validation settings</h6>
                              <div className="ml-4">
                                <FieldArray
                                  name="enabledVerificationErrors"
                                  render={() =>
                                    _.map(imagesConstants.IMAGE_TYPE_VISIBILITY_ERRORS[imageType], (imageTypeError) => {
                                      const verificationErrorValue = values.enabledVerificationErrors[imageTypeError];

                                      return (
                                        <ValidationSetting
                                          key={imageTypeError}
                                          imageTypeError={imageTypeError}
                                          checked={verificationErrorValue?.enabled}
                                          disabled={readonly}
                                          fieldPrefix={verificationErrorValue?.fieldPrefix}
                                          setFieldValue={setFieldValue}
                                          toggleVerificationError={toggleVerificationError}
                                          hasErrorType
                                        />
                                      );
                                    })}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        {
                          !isCustom && (
                            <div className={isInterior ? 'col-xs-6 col-md-6' : 'col-xs-12 col-md-12'}>
                              <SmartScanCommentSettings
                                smartScanCommentSettings={values.smartScanCommentSettings}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          )
                        }
                      </div>
                      {
                        (isExterior || isWindshield) && isManualDamageLabelling && (
                          <div className="row">
                            <div className="col-xs-6 col-md-6 mt-2">
                              <ManualMarkingSettings
                                setFieldValue={setFieldValue}
                                manualDamageLabelling={values.manualDamageLabelling}
                              />
                            </div>
                          </div>
                        )
                      }
                    </>
                  )
                }
                <div className="row">
                  <div className="col-10 col-sm-12 pt-3 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary mr-1"
                      onClick={() => onHideHandler(hasUnsavedChanges, hideModal, setShowConfirmModal)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

ImageTypeModal.propTypes = {
  workflowId: PropTypes.string.isRequired,
  imageType: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
  loadImageTypeCaptureSettingsSuccess: PropTypes.func.isRequired,
  loadUpdateWorkflowSuccess: PropTypes.func.isRequired,
  vehicleType: PropTypes.oneOf(
    [
      workflowsConstants.VEHICLE_TYPES.CAR,
      workflowsConstants.VEHICLE_TYPES.MOTORCYCLE,
      workflowsConstants.VEHICLE_TYPES.TRUCK,
      workflowsConstants.VEHICLE_TYPES.BUS,
      workflowsConstants.VEHICLE_TYPES.TRAILER
    ]
  ).isRequired,
  readonly: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  loadImageTypeCaptureSettingsSuccess: (workflowId, imageType, captureSettings) =>
    dispatch(workflowsActions.loadImageTypeCaptureSettingsSuccess(workflowId, imageType, captureSettings)),
  loadUpdateWorkflowSuccess: (workflowId, response) => dispatch(workflowsActions.updateWorkflowSuccess(workflowId, response))
});

export default connect(null, mapDispatchToProps)(ImageTypeModal);
