import React from 'react';
import PropTypes from 'prop-types';

import './sass/badge.scss';

const Badge = ({ children, className }) => {
  if (!children) return null;
  return <div className={`circle-badge ${className}`}>{children}</div>;
};

Badge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Badge;
