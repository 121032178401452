const NAME = 'comments';

const DEFAULT_SORT = {
  page: 1,
  pageSize: 5,
};

const COMMENT_ORIGIN = {
  PUBLIC: 0,
  ADMIN: 1,
  SMART_SCAN: 2,
};

export default {
  NAME,
  DEFAULT_SORT,
  COMMENT_ORIGIN
};
