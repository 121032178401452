import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Alert } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useBeforeUnload } from '../../../../hooks/useBeforeUnload/useBeforeUnload';
import { Modal, onHideHandler, renderShowConfirmationModal, Toggle } from '../../../../components';
import { Field } from '../../../../components/Form';
import { makePatch } from '../../../../api/ajax';
import { actions as customerConfigurationsActions } from '../../../../redux/customerConfigurations';
import { actions as tagsActions } from '../../../../redux/tags';

const TagModal = ({ show, customerConfiguration, customerTag, onModalClose }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sharingToCompanyEnabled, setSharingToCompanyEnabled] = useState(customerConfiguration.sharingToCompanyEnabled);

  const dispatch = useDispatch();

  useBeforeUnload(hasUnsavedChanges);

  const initialValues = {
    tag: customerTag
  };

  const handleSubmit = (tag, setStatus, setShowSuccessMessage, resetForm, setHasUnsavedChanges) => {
    setShowSuccessMessage(null);
    const data = {
      sharingToCompanyEnabled, tag
    };

    makePatch('customerConfigurations/current', data).subscribe(
      ({ response }) => {
        dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(response));

        if (response.tag) {
          dispatch(tagsActions.loadCustomerTagSuccess(response.tag.value));
        }

        setShowSuccessMessage('Customer settings has been updated');
        setHasUnsavedChanges(false);
      },
      ({ response: { error } }) => {
        setStatus(error);
        setHasUnsavedChanges(false);
      }
    );
  };

  const hideDiscardConfirmation = () => {
    onModalClose();
    setShowConfirmModal(false);
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, hideDiscardConfirmation)}
      <Modal
        headerText="Change tag name"
        size="md"
        backdrop="static"
        onHide={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
        show={show}
      >
        <Modal.Body>
          <div className="row">
            <div className="col">
              {showSuccessMessage && (
                <Alert variant="success" dismissible onClick={() => setShowSuccessMessage(null)}>
                  {showSuccessMessage}
                </Alert>
              )}
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              tag: Yup.string().min(4, 'Must be at least 4 characters long')
            })}
            onSubmit={({ tag }, { setStatus, resetForm }) => handleSubmit(tag, setStatus, setShowSuccessMessage, resetForm, setHasUnsavedChanges)}
            validateOnChange={false}
          >
            {({ errors, status, touched }) => (
              <Form
                onChange={({ target }) => {
                  if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                    setHasUnsavedChanges(true);
                  }
                }}
              >
                <div className="row">
                  <div className="col-12 col-md-12">
                    <Toggle
                      label="Enable sharing to company"
                      on={sharingToCompanyEnabled}
                      toggleClick={() => setSharingToCompanyEnabled(!sharingToCompanyEnabled)}
                    />
                    <span className="ml-2">Enable sharing to company</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 mt-2">
                    <Field
                      label="Sharing tag"
                      type="tag"
                      name="tag"
                      touched={touched.tag}
                      errors={errors.tag || status}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 mt-3">
                    <button
                      type="reset"
                      className="btn btn-secondary mr-1"
                      onClick={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

TagModal.propTypes = {
  show: PropTypes.bool,
  onModalClose: PropTypes.func,
  customerConfiguration: PropTypes.object,
  customerTag: PropTypes.string
};

export default TagModal;
