const prefix = 'drivex/damages';

const SHOW_IMAGE_DAMAGE_BOUNDING_BOX = `${prefix}/SHOW_IMAGE_DAMAGE_BOUNDING_BOX`;
const LOAD_PHOTO_SERIES_DAMAGES_SUCCESS = `${prefix}/LOAD_PHOTO_SERIES_DAMAGES_SUCCESS`;
const LOAD_IMAGE_DAMAGES_SUCCESS = `${prefix}/LOAD_IMAGE_DAMAGES_SUCCESS`;
const LOAD_CUSTOM_DAMAGE_TYPES_SUCCESS = `${prefix}/LOAD_CUSTOM_DAMAGE_TYPES_SUCCESS`;
const ADD_CUSTOM_DAMAGE_TYPE_SUCCESS = `${prefix}/ADD_CUSTOM_DAMAGE_TYPE_SUCCESS`;
const EDIT_CUSTOM_DAMAGE_TYPE_SUCCESS = `${prefix}/EDIT_CUSTOM_DAMAGE_TYPE_SUCCESS`;
const DELETE_CUSTOM_DAMAGE_TYPE_SUCCESS = `${prefix}/DELETE_CUSTOM_DAMAGE_TYPE_SUCCESS`;

export default {
  SHOW_IMAGE_DAMAGE_BOUNDING_BOX,
  LOAD_PHOTO_SERIES_DAMAGES_SUCCESS,
  LOAD_IMAGE_DAMAGES_SUCCESS,
  LOAD_CUSTOM_DAMAGE_TYPES_SUCCESS,
  ADD_CUSTOM_DAMAGE_TYPE_SUCCESS,
  EDIT_CUSTOM_DAMAGE_TYPE_SUCCESS,
  DELETE_CUSTOM_DAMAGE_TYPE_SUCCESS
};
