/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import { Field } from 'formik';

import { constants as imageConstants } from '../../../../../redux/images';

const ValidationSetting = ({
  imageTypeError,
  checked,
  disabled,
  fieldPrefix,
  setFieldValue,
  toggleVerificationError,
  hasErrorType,
}) => {
  const validationName = imageConstants.IMAGE_VALIDATION_NAME[imageTypeError];
  const enabledFieldName = `${fieldPrefix}.enabled`;

  const renderValidationErrorTypes = () =>
    _.map(imageConstants.IMAGE_VERIFICATION_ERROR_TYPE, (errorType) => {
      const errorTypeFieldName = `${fieldPrefix}.errorType`;

      return (
        <div className="form-check" key={`${errorTypeFieldName}.${errorType}`}>
          <Field
            className="form-check-input"
            type="radio"
            name={errorTypeFieldName}
            id={`${errorTypeFieldName}.${errorType}`}
            disabled={!checked}
            value={errorType}
            onChange={() => {
              setFieldValue(errorTypeFieldName, errorType);
            }}
          />
          <label className="form-check-label" htmlFor={`${errorTypeFieldName}.${errorType}`}>
            {imageConstants.IMAGE_VERIFICATION_ERROR_TYPE_NAMES[errorType]}
          </label>
        </div>
      );
    });

  return (
    <div className="form-check">
      <Field
        name={enabledFieldName}
        className="form-check-input mr-2"
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={() => toggleVerificationError(setFieldValue, imageTypeError, !checked)}
      />
      <label className="form-check-label">{validationName}</label>
      {hasErrorType && (
        <div style={{ marginLeft: '2.5rem', paddingBottom: '0.5rem' }}>{renderValidationErrorTypes()}</div>
      )}
    </div>
  );
};

export default ValidationSetting;
