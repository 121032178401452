/* eslint no-unused-vars: "off" */
import queryString from 'query-string';
import _ from 'lodash';

import { parseTypes } from './constants';

// RegEx for matching dates between 1900-01-01 and 3999-12-31
const dateRegex = /^(19|[23]\d)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/g;

const parseDate = (value) => {
  if (!value || !value.match(dateRegex)) return null;

  return value;
};

const parseStringArray = (value) => {
  if (_.isArray(value)) return value;

  if (_.isString(value)) return [value];

  return null;
};

const parseNumber = (value) => {
  const intValue = parseInt(value, 10);

  return isNaN(intValue) ? null : intValue;
};

const parseNumberArray = (value) => {
  if (_.isArray(value)) {
    const numbers = _.reduce(
      value,
      (acc, valueItem) => {
        const int = parseInt(valueItem, 10);

        if (!isNaN(int)) acc.push(int);

        return acc;
      },
      []
    );

    return _.isEmpty(numbers) ? null : numbers;
  }

  const number = parseNumber(value);

  return _.isNumber(number) ? [number] : null;
};

const parseBoolean = (value) => value === 'true';

const parseValue = (value, parseType) => {
  if (parseType === parseTypes.number) return parseNumber(value);

  if (parseType === parseTypes.string) return value;

  if (parseType === parseTypes.date) return parseDate(value);

  if (parseType === parseTypes.stringArray) return parseStringArray(value);

  if (parseType === parseTypes.numberArray) return parseNumberArray(value);

  if (parseType === parseTypes.boolean) return parseBoolean(value);

  return null;
};

export const parseSearchQuery = (searchQuery, filterSettings, defaultSort) => {
  const searchObject = queryString.parse(searchQuery);

  if (_.isEmpty(searchObject)) return { ...defaultSort };

  const parsedSearchObject = {
    ...defaultSort,
  };

  for (const key in searchObject) {
    const permittedFilter = _.get(filterSettings, key);

    if (permittedFilter) {
      const permittedFilterValue = searchObject[key];

      if (!permittedFilterValue) continue;

      const parsedPermittedFilterValue = parseValue(permittedFilterValue, permittedFilter.parseType);

      if (parsedPermittedFilterValue !== null) parsedSearchObject[key] = parsedPermittedFilterValue;
    }
  }

  return parsedSearchObject;
};

/**
 * @getSearchParam is a function that takes in @search and @parameter arguments
 * @search this parameter is required to be a location.search property value
 * @paramter this parameter is required to be the property who's value you trying
 * to return e.g: a userId from auth route parameter(http://....?userId=xxx)
 * @note if the required parameter occured more than ones in the search property,
 * then you will received an array of the parameter values and you need to use the
 * one you need by referencing to them
 */
export const getSearchParam = (search = window.location.search, parameter) => {
  const composedSearch = new URLSearchParams(search);

  const paramCollection = search.split(parameter);

  return paramCollection.length > 2 ? composedSearch.getAll(parameter) : composedSearch.get(parameter);
};
