import { createSelector } from 'reselect';

import constants from './constants';

const selectPhotoSeriesState = (state) => state[constants.NAME];
const selectPhotoSeries = createSelector([selectPhotoSeriesState], (photoSeries) => photoSeries.photoSeries);

export default {
  selectPhotoSeries,
};
