import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from '../../routes/Routes';

import { MenuPage } from '../../layouts';
import { PageTitle, Button, Badge } from '../../components';

import { makeDelete, makeGet, makePost } from '../../api/ajax';
import { selectors, actions } from '../../redux/userAgentBlacklist';

import UserAgentBlacklistTable from './components/UserAgentBlacklistTable/UserAgentBlacklistTable';
import UserAgentBlacklistModal from './components/UserAgentBlacklistModal/UserAgentBlacklistModal';

const NEW_USER_AGENT_SUCCESS_MESSAGE = 'New blacklisted user agent added!';

export const OPERATING_SYSTEM = {
  AndroidAndIos: null,
  Android: 0,
  Ios: 1
};

export const operatingSystemName = (operatingSystem) => {
  switch (operatingSystem) {
    case OPERATING_SYSTEM.Android: return 'Android';
    case OPERATING_SYSTEM.Ios: return 'Ios';
    case OPERATING_SYSTEM.AndroidAndIos: return 'Android & Ios';
    default: return 'Unknown';
  }
};

const UserAgentBlacklist = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const dispatch = useDispatch();
  const userAgents = useSelector(selectors.selectBlacklistedUserAgents);

  useEffect(() => {
    makeGet('blacklistedUserAgents').subscribe(({ response }) => {
      dispatch(actions.loadBlacklistedUserAgents(response));
      setIsLoading(false);
    });
  }, [dispatch]);

  const handleDelete = (blacklistedUserAgentId) => {
    makeDelete(`blacklistedUserAgents/${blacklistedUserAgentId}`).subscribe(() => {
      dispatch(actions.deleteBlacklistedUserAgent(blacklistedUserAgentId));
    });
  };

  const handleSubmit = ({ phrase, comment, operatingSystem }) => {
    makePost('blacklistedUserAgents', { phrase, comment, operatingSystem }).subscribe(({ response }) => {
      dispatch(actions.newBlacklistedUserAgent(response.id, response.phrase, response.comment, response.operatingSystem));
      setSuccessMessage(NEW_USER_AGENT_SUCCESS_MESSAGE);
    });
  };

  return (
    <MenuPage currentRoute={routes.userAgentBlacklist}>
      <PageTitle>
        <PageTitle.Left headingText="User agent blacklist" isLoading={isLoading}>
          {
            !isLoading && (
              <Badge className="ml-2">
                { userAgents !== undefined ? userAgents.length : 0 }
              </Badge>
            )
          }
        </PageTitle.Left>
      </PageTitle>
      <div className="row">
        <div className="col-sm-12">
          <p>A blacklist of phrases that, if detected in the user agent, will trigger an unsupported browser error in SmartScan</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 pb-3">
          <Button testId="VehiclesNewVehicleReqBtn" className="btn btn-primary" onClick={() => setShowModal(true)}>
            + Add new
          </Button>
          <UserAgentBlacklistModal
            showModal={showModal}
            setShowModal={setShowModal}
            handleSubmit={handleSubmit}
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      </div>
      {
        userAgents.length > 0 && (
          <div className="row">
            <div className="col-sm-12">
              <UserAgentBlacklistTable userAgents={userAgents} handleDelete={handleDelete} />
            </div>
          </div>
        )
      }
    </MenuPage>
  );
};

export default UserAgentBlacklist;
