import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './sass/text.scss';

const Text = ({ align, color, border, weight, size, inline, children, ...props }) => {
  const classes = classNames('text', {
    'is-inline': inline,
    [`is-border-${border}`]: border,
    [`is-weight-${weight}`]: weight,
    [`is-align-${align}`]: align,
    [`is-color-${color}`]: color,
    [`is-size-${size}`]: size,
  });

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(['right', 'center', 'left']),
  color: PropTypes.oneOf(['black', 'gray', 'blue', 'red', 'yellow', 'green']),
  border: PropTypes.oneOf(['solid', 'dashed', 'dotted']),
  weight: PropTypes.oneOf(['fine', 'semi-bold', 'bold']),
  size: PropTypes.oneOf([1, 2, 3, '1', '2', '3']),
  inline: PropTypes.bool,
};

export default Text;
