import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';

import LoginForm from './containers/LoginForm';
import { FullPage } from '../../layouts';
import { Container, Logo, SlideOpen, Text, NotificationModal } from '../../components';
import { routes } from '../../routes/Routes';
import { getSearchParam } from '../../utils/url';
import { authenticationService } from '../../services';
import { identifyUser, trackEvent, EVENT_TYPES } from '../../api/analytics';

const message = {
  error: 'Your password reset link has expired. Please ask for a new password reset email.',
  success: 'Your new password has been sent to your e-mail.'
};

// type can be error or success
const resetPasswordStatusInitialValue = Object.freeze({
  type: 'error',
  message: ''
});

const Login = () => {
  const [showFields, setShowFields] = useState(false);
  const [resetPasswordStatus, setResetPasswordStatus] = useState(resetPasswordStatusInitialValue);
  const location = useLocation();
  const history = useHistory();

  // this hook controls the form slider
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFields(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // this hook manages the login route with passwordReset searchParam
  useEffect(() => {
    const isResetPassword = getSearchParam(location.search, 'passwordReset');

    if (isResetPassword === 'true') {
      setResetPasswordStatus({ type: 'success', message: message.success });
    } else if (isResetPassword === 'false') {
      setResetPasswordStatus({ type: 'error', message: message.error });
    }

    return () => {
      setResetPasswordStatus(resetPasswordStatusInitialValue);
    };
  }, [location]);

  const handleLoginSuccess = (response) => {
    authenticationService.storeUserData(response);

    // Track events in Analytics
    identifyUser(response);
    trackEvent(EVENT_TYPES.USER_LOGGED_IN);

    const to = (response.askAdditionalInformation && routes.askAdditionalInformation) || location.state?.from || routes.home;

    history.push(to);
  };

  const currentUser = authenticationService.currentUserValue;

  if (currentUser && currentUser.token) {
    return <Redirect to={routes.home} />;
  }

  return (
    <>
      <FullPage>
        <Container fullscreen verticalAlign="center" horizontalAlign="center">
          <Logo />

          <SlideOpen open={showFields}>
            <LoginForm onLoginSuccess={handleLoginSuccess} />
          </SlideOpen>
        </Container>

        <Container fullscreen bottom>
          <Text>
            All rights reserved &nbsp;
            <time>{new Date().getFullYear()}</time>
          </Text>
        </Container>
      </FullPage>

      <NotificationModal
        message={resetPasswordStatus.message}
        type={resetPasswordStatus.type}
        showModal={!!resetPasswordStatus.message && !!resetPasswordStatus.type}
        onCancel={() => setResetPasswordStatus(resetPasswordStatusInitialValue)}
        position="right"
      />
    </>
  );
};

export default Login;
