import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';

import { Modal, onHideHandler, renderShowConfirmationModal } from '../../../../components';
import { actions } from '../../../../redux/customerConfigurations';
import { makePatch } from '../../../../api/ajax';

const ReportLanguageModal = ({ customerConfiguration, show, onModalClose, languages }) => {
  const dispatch = useDispatch();

  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onChangeHandler = ({ target }) => {
    setShowSuccessMessage(null);
    makePatch('customerConfigurations/current', {
      reportLanguage: target.value
    }).subscribe(
      ({ response }) => {
        dispatch(actions.loadCurrentCustomerConfigurationSuccess(response));
        setShowSuccessMessage('Report language has been changed!');
        setHasUnsavedChanges(false);
      },
      () => {
        setHasUnsavedChanges(false);
      }
    );
  };

  const hideDiscardConfirmation = () => {
    hideModal();
    setShowConfirmModal(false);
  };

  const hideModal = () => {
    setShowSuccessMessage(null);
    onModalClose();
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, hideDiscardConfirmation)}
      <Modal
        headerText="Report language"
        size="md"
        backdrop="static"
        show={show}
        onHide={() => onHideHandler(hasUnsavedChanges, hideModal, setShowConfirmModal)}
      >
        <Modal.Body>
          <div className="row">
            <div className="col">
              {showSuccessMessage && (
                <Alert variant="success" dismissible onClick={() => setShowSuccessMessage(null)}>
                  {showSuccessMessage}
                </Alert>
              )}
            </div>
          </div>
          <form>
            <select
              name="subscriptionWarning"
              id="subWarnSelector"
              className="form-control form-select"
              value={customerConfiguration?.reportLanguage}
              onChange={onChangeHandler}
            >
              {languages.map(({ name, code }) => (
                <option key={code} value={code}>
                  { name }
                </option>
              ))}
            </select>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

ReportLanguageModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  customerConfiguration: PropTypes.object,
  languages: PropTypes.array
};

export default ReportLanguageModal;
