import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { TTranslations } from '../../../../@proptypes/proptypes';
import { Button, TranslationsInput } from '../../../../components';
import { constants as translationsConstants } from '../../../../redux/translations';

import DownloadTermsAndConditions from '../DownloadTermsAndConditions/DownloadTermsAndConditions';
import TermsAndConditionsFieldset from '../TermsAndConditionsFieldset/TermsAndConditionsFieldset';

import DownloadGdprPrivacyPolicy from '../DownloadGdprPrivacyPolicy/DownloadGdprPrivacyPolicy';
import GdprPrivacyPolicyFieldset from '../GdprPrivacyPolicyFieldset/GdprPrivacyPolicyFieldset';

import './sass/targeted-translations.scss';

function TargetedTranslations({
  allLanguages, translations, termsAndConditions, setTermsAndConditions, targetedLanguage,
  setLanguages, onInputChange, gdprPrivacyPolicy, setGdprPrivacyPolicy,
  showGdprPrivacyPolicy, showTermsAndConditions, disableTranslationsInput
}) {
  const { targetedTranslations, sourceTranslations, hasTargetedTranslationsChanged } = translations;
  const { LANGUAGES_CODE, TRANSLATION_TYPES: { SOURCE_TRANSLATIONS } } = translationsConstants;

  const classNames = ({ sourceTranslations, translationsIndex, translationIndex, value }) =>
    `${sourceTranslations && sourceTranslations[translationsIndex]?.translationValues[translationIndex]?.value === value
      ? 'field--same-as-source ' : ''}${!value ? ' field--empty' : ''}`;

  return (
    <div className="to-container">
      <div className="to-container__row1">
        <select
          name="targetedLanguage"
          id="targetedLanguage"
          value={targetedLanguage?.code}
          title={`The targeted translations languge is ${targetedLanguage?.name}`}
          onChange={({ target: { value } }) => {
            const targetedLanguage = allLanguages.find(({ code }) => code === value);
            setLanguages((languages) => ({ ...languages, targetedLanguage }));
          }}
        >
          {
            allLanguages
              ?.filter((language) => language.code !== LANGUAGES_CODE.ENGLISH)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(({ id, name, code }) => (
                <option value={code} key={id}>
                  {name}
                </option>
              ))
          }
        </select>
        <Button type="submit" className="btn btn-primary" testId="saveTranslationsBtn">
          Save translations
        </Button>
      </div>
      {
        showTermsAndConditions && (
          <>
            <TermsAndConditionsFieldset
              name="termsAndConditions"
              termsAndConditions={termsAndConditions}
              setTermsAndConditions={setTermsAndConditions}
              label={
                <>
                  Drag and drop or upload your <b>.pdf</b>&nbsp;targeted language file.
                </>
              }
            />
            <DownloadTermsAndConditions
              shouldEnableDownloading={targetedLanguage?.smartScanTermsAndConditionsFileName?.length > 0}
              btnTestId="targetedTermsAndConditionsDownloadBtn"
              languageId={targetedLanguage?.id}
            />
          </>
        )
      }
      {
        showGdprPrivacyPolicy && (
          <>
            <GdprPrivacyPolicyFieldset
              name="gdprPrivacyPolicy"
              gdprPrivacyPolicy={gdprPrivacyPolicy}
              setGdprPrivacyPolicy={setGdprPrivacyPolicy}
              label={
                <>
                  Drag and drop or upload your <b>.pdf</b>&nbsp;targeted language file.
                </>
              }
            />
            <DownloadGdprPrivacyPolicy
              shouldEnableDownloading={targetedLanguage?.smartScanGdprPrivacyPolicyFileName?.length > 0}
              btnTestId="targetedGdprPrivacyPolicyDownloadBtn"
              languageId={targetedLanguage?.id}
            />
          </>
        )
      }
      {targetedTranslations?.map(({ id, categoryName, translationValues }, translationsIndex) => (
        <fieldset key={id}>
          <h5 className="category-title">{categoryName}</h5>
          {translationValues.map(({ key, value }, translationIndex) => (
            <Fragment key={key}>
              <label htmlFor={key}>{key}</label>
              <TranslationsInput
                objValue={value}
                objKey={key}
                className={classNames({ sourceTranslations, translationsIndex, translationIndex, value })}
                onChange={({ target }) => {
                  onInputChange({
                    target,
                    translationsIndex,
                    translationIndex,
                    translations: targetedTranslations,
                    translationsType: SOURCE_TRANSLATIONS,
                    hasTargetedTranslationsChanged: !hasTargetedTranslationsChanged
                      ? !hasTargetedTranslationsChanged
                      : hasTargetedTranslationsChanged,
                  });
                }}
                disabled={disableTranslationsInput}
              />
            </Fragment>
          ))}
        </fieldset>
      ))}
    </div>
  );
}

TargetedTranslations.propTypes = {
  translations: TTranslations,
  targetedLanguage: PropTypes.object,
  allLanguages: PropTypes.array.isRequired,
  setLanguages: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  termsAndConditions: PropTypes.object.isRequired,
  setTermsAndConditions: PropTypes.func.isRequired,
  gdprPrivacyPolicy: PropTypes.object.isRequired,
  setGdprPrivacyPolicy: PropTypes.func.isRequired,
  showGdprPrivacyPolicy: PropTypes.bool.isRequired,
  showTermsAndConditions: PropTypes.bool.isRequired,
  disableTranslationsInput: PropTypes.bool.isRequired
};

export default TargetedTranslations;
