import types from './types';

import sessionTypes from '../session/types';

const initialState = { };

const customerMonthlyConsumptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_MONTHLY_CONSUMPTION_SUCCESS: {
      const {
        monthlyConsumption,
        month,
      } = action.payload;

      return {
        ...state,
        monthlyConsumptions: {
          ...state.monthlyConsumptions,
          [month]: monthlyConsumption,
        },
      };
    }
    case sessionTypes.LOG_OUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default customerMonthlyConsumptionsReducer;
