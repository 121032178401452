import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { PhotoSeriesDamages } from '../../../../../../components';
import { selectors as damagesSelectors } from '../../../../../../redux/damages';

const PhotoSeriesDamagesSection = ({ isLoadingPhotoSeries, photoSeries }) => {
  const damages = useSelector((state) => damagesSelectors.selectPhotoSeriesDamages(state, photoSeries?.id));

  if (!damages || damages.length === 0) return null;

  const PhotoSeriesQualityRatingProps = {
    isLoadingPhotoSeries,
    photoSeries,
    damages,
    vehicleType: photoSeries.workflow.vehicleType
  };

  return <PhotoSeriesDamages {...PhotoSeriesQualityRatingProps} />;
};

PhotoSeriesDamagesSection.propTypes = {
  isLoadingPhotoSeries: PropTypes.bool.isRequired,
  photoSeries: PropTypes.object
};

export default PhotoSeriesDamagesSection;
