import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { makePost } from '../../../api/ajax';
import { Field, Error } from '../../../components/Form';

const handleSubmit = (requestBody, setErrorMessage, setSubmitting, onLoginSuccess) => {
  setErrorMessage('');
  makePost('session', requestBody).subscribe(
    ({ response }) => onLoginSuccess(response),
    ({ response: { error } }) => {
      setSubmitting(false);
      setErrorMessage(error);
    },
  );
};

const LoginForm = ({ onLoginSuccess }) => {
  const [errorMsg, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');

  const history = useHistory();

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={({ email, password }, { setSubmitting }) => handleSubmit({ email, password }, setErrorMessage, setSubmitting, onLoginSuccess)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, errors, setValues, touched, isSubmitting }) => (
          <Form style={{ maxWidth: '22.4375rem', margin: '0 auto' }}>
            <Field
              name="email"
              label="E-mail"
              labelStyle={labelStyle}
              type="email"
              placeholder="Insert e-mail"
              value={values.email}
              errors={errors.email}
              touched={touched.email}
              onChange={(e) => {
                setEmail(e.target.value);
                setValues({ ...values, email: e.target.value });
              }}
              required
            />
            <Field
              name="password"
              label="Password"
              labelStyle={labelStyle}
              type="password"
              placeholder="Insert password"
              value={values.password}
              errors={errors.password}
              touched={touched.password}
              required
            />
            <button
              type="submit"
              className="btn btn-primary"
              style={{ width: '100%', margin: '0.625rem auto' }}
              disabled={isSubmitting}
            >
              Login
            </button>
          </Form>
        )}
      </Formik>
      <div>
        <button
          type="button"
          className="btn-link"
          onClick={(event) => {
            event.preventDefault();
            if (email) {
              history.push(`/resetPassword/${email}`);
            } else {
              setErrorMessage('Please insert your e-mail before pressing "forgot your password"');
            }
          }}
        >
          Forgot your password?
        </button>
        {!!errorMsg && <Error>{errorMsg}</Error>}
      </div>
    </>
  );
};

const labelStyle = {
  display: 'block',
  textAlign: 'left',
  fontSize: '0.7rem',
  padding: '0.3125rem 0',
};

LoginForm.propTypes = {
  onLoginSuccess: PropTypes.func,
};

export default LoginForm;
