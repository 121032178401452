import React from 'react';
import PropTypes from 'prop-types';

const ImageSubTypeCheckbox = ({
  imageSubTypes, imageSubType, label, setFieldValue, hidden
}) => {
  if (hidden) return null;
  return (
    <div className="form-check ml-4">
      <input
        className="form-check-input"
        type="checkbox"
        id={label}
        checked={imageSubTypes.some((c) => c.imageSubType === imageSubType)}
        onChange={(event) => {
          setFieldValue(
            'imageSubTypes',
            event.target.checked
              ? [...imageSubTypes, { imageSubType, instructions: [] }].sort((a, b) => a.imageSubType - b.imageSubType)
              : imageSubTypes.filter((c) => c.imageSubType !== imageSubType).sort((a, b) => a.imageSubType - b.imageSubType)
          );
        }}
      />
      <label className="form-check-label" htmlFor={label}>{label}</label>
    </div>
  );
};

ImageSubTypeCheckbox.propTypes = {
  imageSubTypes: PropTypes.array.isRequired,
  imageSubType: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  hidden: PropTypes.bool
};

ImageSubTypeCheckbox.defaultProps = {
  hidden: false
};

export default ImageSubTypeCheckbox;
