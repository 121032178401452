const prefix = 'drivex/windshieldRepairabilityProfiles';

const LOAD_WINDSHIELD_REPAIRABILITY_PROFILES_SUCCESS = `${prefix}/LOAD_WINDSHIELD_REPAIRABILITY_PROFILES_SUCCESS`;
const ADD_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS = `${prefix}/ADD_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS`;
const DELETE_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS = `${prefix}/DELETE_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS`;
const EDIT_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS = `${prefix}/EDIT_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS`;

export default {
  LOAD_WINDSHIELD_REPAIRABILITY_PROFILES_SUCCESS,
  ADD_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS,
  DELETE_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS,
  EDIT_WINDSHIELD_REPAIRABILITY_PROFILE_SUCCESS
};
