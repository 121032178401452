import types from './types';

const loadUsersSuccess = (users, page, pageCount) => ({
  type: types.LOAD_USERS_SUCCESS,
  payload: {
    users,
    page,
    pageCount,
  }
});

const updateUsersSuccess = (user, page) => ({
  type: types.UPDATE_USERS_SUCCESS,
  payload: {
    user,
    page,
  },
});

const clearUsers = () => ({
  type: types.CLEAR_USERS,
});

export default {
  loadUsersSuccess,
  updateUsersSuccess,
  clearUsers,
};
