import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Modal, Loader } from '../../../components';
import { Field } from '../../../components/Form';

import { constants as sessionConstants } from '../../../redux/session';
import { actions as customImageTypesActions } from '../../../redux/customImageTypes';

import { makePost } from '../../../api/ajax';

import { authenticationService } from '../../../services';

const CustomImageTypeModal = ({ title, showModal, setShowModal, initialValues, onSuccess, setShowAddNewSuccessMessage }) => {
  const { CREATE_CUSTOM_IMAGE_TYPE } = sessionConstants.PERMISSION_TYPES;
  const { hasPermission } = authenticationService;

  const dispatch = useDispatch();

  const onClose = () => {
    setShowModal(false);
  };

  const handleAddNew = (values, setSubmitting, resetForm) => {
    if (!hasPermission(CREATE_CUSTOM_IMAGE_TYPE)) return;

    makePost('customImageTypes', values).subscribe(({ response }) => {
      dispatch(customImageTypesActions.addCustomImageTypeSuccess(response));

      onSuccess();
      setShowAddNewSuccessMessage(`New custom image type '${response.name}' has been added`);

      setSubmitting(false);
      resetForm();

      onClose();
    });
  };

  return (
    <Modal
      size="md"
      headerText={title}
      onHide={onClose}
      backdrop="static"
      show={showModal}
    >
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required('Name is required')
              .max(50, 'Must not exceed 50 characters'),
          })}
          onSubmit={(formData, { setSubmitting, resetForm }) => handleAddNew(formData, setSubmitting, resetForm)}
          validateOnChange
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="row">
                <div className="col-10 col-sm-12">
                  <Field type="text" name="name" label="Name" touched={touched.name} errors={errors.name} />
                </div>
              </div>

              <div className="row">
                <div className="col-10 col-sm-12 pt-3 text-right">
                  <button
                    type="button"
                    className="btn btn-secondary mr-1"
                    onClick={onClose}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? <Loader size="tiny" isRelative noDelay white />
                      : 'Save'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

CustomImageTypeModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setShowAddNewSuccessMessage: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default CustomImageTypeModal;
