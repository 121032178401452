import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { makePost, makeDelete } from '../../../../../api/ajax';
import { selectors as customerSelectors } from '../../../../../redux/customers';
import { constants as sessionConstants } from '../../../../../redux/session';
import { actions as usersActions } from '../../../../../redux/users';
import { ActionsDropDown, ConfirmationModal, SkeletonUI } from '../../../../../components';
import { ReactComponent as Pen } from '../../../../../assets/img/icn-pen.svg';
import { ReactComponent as Mail } from '../../../../../assets/img/icn-mail-letter.svg';
import { ReactComponent as Cross } from '../../../../../assets/img/icn-cancel-cross.svg';
import { ReactComponent as Dots } from '../../../../../assets/img/icn-three-dots.svg';

import UserForm from './UserForm';

const tdStyle = {
  padding: '.75rem 0',
  verticalAlign: 'middle',
};

const resendConfirmation = (userId, showAlert) => {
  makePost(`users/${userId}/resendConfirmation`).subscribe(() => {
    showAlert('Confirmation link has been sent!');
  });
};

const UserDetails = ({
  isLoadingUsers,
  user,
  edit,
  editUser,
  cancel,
  page,
  showAlert,
  setPage,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  hasReachedSubscriptionLimit,
  allowedRoles
}) => {
  const [shownRemoveConfirmationModal, setShownRemoveConfirmationModal] = useState(false);

  const dispatch = useDispatch();
  const customerDetails = useSelector(customerSelectors.selectCustomerDetails);

  const notPublicRole = user && user.role !== sessionConstants.ROLE_TYPES.PUBLIC_USER;

  const removeUser = (userId, showAlert, setPage) => {
    makeDelete(`users/${userId}`).subscribe(() => {
      setShownRemoveConfirmationModal(false);

      showAlert('User has been deleted!');

      dispatch(usersActions.clearUsers());

      setPage(1);
    });
  };

  const renderReadOnly = (user) => (
    <>
      <tr style={{ borderBottom: '0.0625rem solid lightgray' }}>
        <td>{user?.email}</td>
        <td style={{ textAlign: 'right' }}>{sessionConstants.ROLE_NAMES[user?.role]}</td>
        <td style={{ paddingTop: '0.5rem' }}>
          {notPublicRole && (
          <ActionsDropDown toggle={<Dots className="actions-dropdown-toggle" />}>
            <ActionsDropDown.Item onClick={() => editUser(user?.id)}>
              <Pen />
              <span className="pl-3">Edit</span>
            </ActionsDropDown.Item>
            {
              !user.emailConfirmed && (
                <ActionsDropDown.Item onClick={() => resendConfirmation(user?.id, showAlert)}>
                  <Mail />
                  <span className="pl-3">Resend invitation</span>
                </ActionsDropDown.Item>
              )
            }
            <ActionsDropDown.Item onClick={() => setShownRemoveConfirmationModal(true)}>
              <Cross />
              <span className="pl-3">Remove</span>
            </ActionsDropDown.Item>
          </ActionsDropDown>
          )}
        </td>
      </tr>
      {shownRemoveConfirmationModal && (
        <ConfirmationModal
          showModal={shownRemoveConfirmationModal}
          heading="Delete user"
          message="Are you sure you want to delete this user?"
          onConfirm={() => removeUser(user.id, showAlert, setPage)}
          onCancel={() => setShownRemoveConfirmationModal(false)}
          style={{ top: '6.25rem' }}
        />
      )}
    </>
  );

  const submitUrl = user ? `users/${user.id}` : 'users';

  return (
    <>
      {(edit || !user) && (
      <UserForm
        user={user}
        customerDetails={customerDetails}
        edit={edit}
        submitUrl={submitUrl}
        cancel={cancel}
        page={page}
        showAlert={showAlert}
        setPage={setPage}
        tdStyle={tdStyle}
        hasUnsavedChanges={hasUnsavedChanges}
        setHasUnsavedChanges={(hasUnsavedChanges) => setHasUnsavedChanges(hasUnsavedChanges)}
        hasReachedSubscriptionLimit={hasReachedSubscriptionLimit}
        allowedRoles={allowedRoles}
      />
      )}
      { isLoadingUsers
        ? (
          <>
            {[...new Array(10)].map((v, i) => (
              <tr className="row pl-1 mb-2" key={i} width="100%">
                <td className="col-sm-7"><SkeletonUI type="radius-horizontal" maxWidth="100%" /></td>
                <td className="col-sm-4 pr-3"><SkeletonUI type="radius-horizontal" maxWidth="100%" /></td>
                <td className="col-sm-1 pr-3"><SkeletonUI type="radius-horizontal" maxWidth="100%" /></td>
              </tr>
            ))}
          </>
        ) : user ? renderReadOnly(user) : null}
    </>
  );
};

UserDetails.propTypes = {
  isLoadingUsers: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    role: PropTypes.number,
    email: PropTypes.string,
    id: PropTypes.string,
    emailConfirmed: PropTypes.bool
  }),
  edit: PropTypes.bool,
  editUser: PropTypes.func,
  cancel: PropTypes.func,
  page: PropTypes.number,
  showAlert: PropTypes.func,
  setPage: PropTypes.func,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  setHasUnsavedChanges: PropTypes.func,
  hasReachedSubscriptionLimit: PropTypes.bool,
  allowedRoles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.number
    })
  )
};
export default UserDetails;
