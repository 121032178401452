import types from './types';

const loadAccessUrlSuccess = (accessUrlId, data) => ({
  type: types.LOAD_ACCESS_URL_SUCCESS,
  payload: {
    accessUrlId,
    data,
  },
});

export default {
  loadAccessUrlSuccess,
};
