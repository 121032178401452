import { types } from './checkbox.types';

export const checkboxReducer = (state, action) => {
  if (action.type === types.CHECK_YES) {
    return state.yes ? { ...state, yes: null, no: null } : { ...state, yes: true, no: null };
  }

  if (action.type === types.CHECK_NO) {
    return state.no ? { ...state, no: null, yes: null } : { ...state, no: true, yes: null };
  }

  throw new Error(`🚨Unrecognized type "${action.type}"`);
};
