import React from 'react';
import PropTypes from 'prop-types';

import { formatDateTime } from '../../utils/date';

import { Text } from '..';

import './sass/comment.scss';

const Comment = ({ createdByUsername, createdAt, text }) => (
  <div className="comment">
    <div className="d-flex">
      <div className="flex-fill">
        <Text weight="bold" size={2}>{createdByUsername}</Text>
      </div>
      <div className="flex-fill text-right">
        <Text color="gray" size={2}>{formatDateTime(createdAt)}</Text>
      </div>
    </div>
    <div className="d-flex">
      <div className="flex-fill mt-2">
        {text}
      </div>
    </div>
  </div>
);

Comment.propTypes = {
  createdByUsername: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Comment;
