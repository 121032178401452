const prefix = 'drivex/userAgentBlacklist';

const LOAD_USER_AGENT_BLACKLIST_SUCCESS = `${prefix}/LOAD_USER_AGENT_BLACKLIST_SUCCESS`;
const DELETE_BLACKLISTED_USER_AGENT = `${prefix}/DELETE_BLACKLISTED_USER_AGENT`;
const NEW_BLACKLISTED_USER_AGENT = `${prefix}/NEW_BLACKLISTED_USER_AGENT`;

export default {
  LOAD_USER_AGENT_BLACKLIST_SUCCESS,
  DELETE_BLACKLISTED_USER_AGENT,
  NEW_BLACKLISTED_USER_AGENT
};
