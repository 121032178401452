import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Container } from '..';
import { routes } from '../../routes/Routes';

import './sass/fallback-page.scss';

const isUserLoggedIn = !!window.localStorage.getItem('currentUser');

const FallbackPage = ({ fallbackPath, message, buttonText, imgSrc }) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      <main className="fallback-main">
        <img src={imgSrc || `${process.env.PUBLIC_URL}/404.png`} alt="" />

        <p className="message">
          {message || (location.state && !!location.state.message && location.state.message) || 'Sorry the page you try to access is unavailable'}
        </p>

        <Button
          type="button"
          testId="fallbackPageBtn"
          className="btn btn-primary"
          onClick={() =>
            history.replace(
              (location.state && !!location.state.fallbackPath && location.state.fallbackPath) || (isUserLoggedIn && !fallbackPath)
                ? routes.home
                : isUserLoggedIn && fallbackPath
                  ? fallbackPath
                  : routes.login,
            )}
        >
          {buttonText || isUserLoggedIn ? 'Go home' : 'Go to Login'}
        </Button>
      </main>

      <Container fullscreen bottom>
        <footer className="fallback-footer">
          <p>
            DriveX Technologies &copy;&nbsp;
            <time>{new Date().getFullYear()}</time>
            &nbsp; All rights reserved
          </p>
        </footer>
      </Container>
    </>
  );
};

FallbackPage.propTypes = {
  fallbackPath: PropTypes.string,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  imgSrc: PropTypes.string,
};

export default FallbackPage;
