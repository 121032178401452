import React from 'react';
import PropTypes from 'prop-types';

import { constants as imageConstants } from '../../../../../redux/images';
import { constants as workflowConstants } from '../../../../../redux/workflows';

import ImageSubTypeCheckbox from './ImageSubTypeCheckbox';

const { TRAILER } = workflowConstants.VEHICLE_TYPES;

const ExteriorAngleSelection = ({ imageSubTypes, setFieldValue, vehicleType }) => (
  <>
    <h6>360 capture settings</h6>
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.FRONT}
      label="Front"
      setFieldValue={setFieldValue}
      hidden={vehicleType === TRAILER}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.FRONT_LEFT}
      label="Front-left diagonal"
      setFieldValue={setFieldValue}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.LEFT}
      label="Left side"
      setFieldValue={setFieldValue}
      hidden={vehicleType === TRAILER}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.BACK_LEFT}
      label="Back-left diagonal"
      setFieldValue={setFieldValue}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.BACK}
      label="Back"
      setFieldValue={setFieldValue}
      hidden={vehicleType === TRAILER}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.BACK_RIGHT}
      label="Back-right diagonal"
      setFieldValue={setFieldValue}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.RIGHT}
      label="Right side"
      setFieldValue={setFieldValue}
      hidden={vehicleType === TRAILER}
    />
    <ImageSubTypeCheckbox
      imageSubTypes={imageSubTypes}
      imageSubType={imageConstants.IMAGE_SUB_TYPES.FRONT_RIGHT}
      label="Front-right diagonal"
      setFieldValue={setFieldValue}
    />
  </>
);

ExteriorAngleSelection.propTypes = {
  imageSubTypes: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  vehicleType: PropTypes.number.isRequired
};

export default ExteriorAngleSelection;
