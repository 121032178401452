import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useBeforeUnload, useDidMount } from '../../../../hooks';
import { Error, Field } from '../../../../components/Form';
import { Modal, onHideHandler, renderShowConfirmationModal } from '../../../../components';
import { InfiniteComboBox } from '../../../../containers';
import { constants } from '../../../../redux/customers';
import { actions as countryActions, selectors as countrySelectors } from '../../../../redux/countries';
import { makePost, makeGet } from '../../../../api/ajax';
import { SUBSCRIPTION_TYPES } from '../../../../components/Cards/SubscriptionCard/SubscriptionCard';

const handleSubmit = (data, setStatus, setShowSuccess, resetForm, setHasUnsavedChanges) => {
  makePost('customers', data).subscribe(
    () => {
      setShowSuccess('Customer has been created!');
      resetForm();

      setHasUnsavedChanges(false);
    },
    ({ response: { error } }) => {
      setStatus(error);

      setHasUnsavedChanges(false);
    },
  );
};

const CustomerModal = ({ onModalClose, ...props }) => {
  const [showSuccess, setShowSuccess] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [defaultSubscription, setDefaultSubscription] = useState(null);
  const [dividedSubscriptions, setDividedSubscriptions] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dispatch = useDispatch();

  useBeforeUnload(hasUnsavedChanges);

  useDidMount(() => {
    makeGet('subscriptions').subscribe(({ response }) => {
      const sorted = _.orderBy(response, (s) => s.allowedPhotoSeries);
      setSubscriptions(sorted);
    });
  });

  useEffect(() => {
    if (!subscriptions) return;

    setDefaultSubscription(_.find(subscriptions, (s) => s.type === constants.SUBSCRIPTION_TYPES.LITE));

    const orderedSubscriptions = _.orderBy(subscriptions, 'enabledFeatures.scanVolume')
      .filter((s) => s?.type !== SUBSCRIPTION_TYPES.CORPORATE);

    setDividedSubscriptions(_.partition(
      orderedSubscriptions,
      (s) => _.indexOf(orderedSubscriptions, s) < orderedSubscriptions.length / 2,
    ));
  }, [subscriptions, setDefaultSubscription, setDividedSubscriptions]);

  if (!defaultSubscription || !dividedSubscriptions) return null;

  const initialValues = {
    companyName: '',
    companyRegistrationNo: '',
    reportEmail: '',
    moderatorFirstName: '',
    moderatorLastName: '',
    email: '',
    subscriptionId: defaultSubscription.id || '',
    phone: '',
    askAdditionalInformation: false,
    emailConfirmationDisabled: false,
    providedPassword: ''
  };

  const onHideFn = () => {
    onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal);
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, onModalClose)}

      <Modal size="lg" headerText="Add new customer" onHide={onHideFn} backdrop="static" {...props}>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col">
                {showSuccess && (
                  <Alert variant="success" dismissible onClick={() => setShowSuccess(null)}>
                    {showSuccess}
                  </Alert>
                )}
              </div>
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                companyName: Yup.string().required('Company name is required'),
                countryCode: Yup.string().required('Country is required'),
                companyRegistrationNo: Yup.string()
                  .required('Company registry code is required')
                  .min(2, 'Must be at least 2 characters long'),
                reportEmail: Yup.string().required('Report e-mail is required').email('Please enter a correct e-mail'),
                moderatorFirstName: Yup.string().required('First name is required'),
                moderatorLastName: Yup.string().required('Last name is required'),
                email: Yup.string().required('E-mail is required').email('Please enter a correct e-mail'),
                phone: Yup.number().min(5).required('Phone is required'),
                providedPassword: Yup.string().min(8, 'Must be at least 8 characters long')
                  .matches('^[\\w\\W][\x00-\x7F]+$', 'Latin characters only'),
              })}
              onSubmit={(data, { setStatus, resetForm }) =>
                handleSubmit(data, setStatus, setShowSuccess, resetForm, setHasUnsavedChanges)}
              validateOnChange={false}
            >
              {({ errors, status, touched, values, setFieldValue }) => (
                <Form
                  onChange={({ target }) => {
                    if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                      setHasUnsavedChanges(true);
                    }
                  }}
                >
                  <div className="row">
                    <div className="col-6 col-md-6">
                      <Field
                        type="text"
                        name="companyName"
                        label="Company name"
                        errors={errors.companyName}
                        touched={touched.companyName}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <Field
                        name="companyRegistrationNo"
                        id="companyRegistrationNo"
                        label="Company registry code"
                        errors={errors.companyRegistrationNo}
                        touched={touched.companyRegistrationNo}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <Field
                        type="text"
                        name="reportEmail"
                        label="Report e-mail"
                        errors={errors.reportEmail}
                        touched={touched.reportEmail}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <InfiniteComboBox
                        label="Country"
                        inputId="countryInput"
                        loadUrl="countries"
                        searchParam="countryName"
                        onLoadSuccess={(countriesPaged) => dispatch(countryActions.loadCountriesSuccess(countriesPaged))}
                        resultSelector={countrySelectors.selectPagedResult}
                        isError={errors.countryCode && true}
                        error={errors.countryCode}
                        searchingDisabled={false}
                        placeholder="Search"
                        onSelect={(selectedCountry) => {
                          const countryCode = _.isEmpty(selectedCountry)
                            ? ''
                            : selectedCountry.code;

                          setFieldValue('countryCode', countryCode);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-4 mb-3">
                    <div className="col-12 col-md-12">
                      <h6>Subscription</h6>
                      <div className="row">
                        {_.map(dividedSubscriptions, (subscriptionsGroup, groupIndex) => (
                          <div className="col-6 col-md-6" key={groupIndex}>
                            {_.map(
                              subscriptionsGroup,
                              (s, i) =>
                                s.name !== 'Smart Scan Basic' && (
                                  <div className="form-check" key={i}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="subscriptionId"
                                      value={s.id}
                                      id={s.id}
                                      checked={s.id === values.subscriptionId}
                                      onChange={() => setFieldValue('subscriptionId', s.id)}
                                    />
                                    <label className="form-check-label" htmlFor={s.id}>
                                      {`${s.name} (${s.enabledFeatures.scanVolume} scans)`}
                                    </label>
                                  </div>
                                ),
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-4">Contact person</h6>
                  <div className="row mt-3 mb-2">
                    <div className="col-6 col-md-6">
                      <Field
                        type="text"
                        name="moderatorFirstName"
                        label="First name"
                        touched={touched.moderatorFirstName}
                        errors={errors.moderatorFirstName}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <Field
                        type="text"
                        name="moderatorLastName"
                        label="Last name"
                        touched={touched.moderatorLastName}
                        errors={errors.moderatorLastName}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <Field type="text" name="phone" label="Phone" touched={touched.phone} errors={errors.phone} />
                    </div>
                  </div>
                  <h6 className="mt-4">DriveX administrator</h6>
                  <div className="row mt-3 mb-2">
                    <div className="col-6 col-md-6">
                      <Field type="email" name="email" label="E-mail" touched={touched.email} errors={errors.email} />
                    </div>
                    <div className="col-6 col-md-6">
                      <Field
                        type="text"
                        name="providedPassword"
                        label="Password"
                        touched={touched.providedPassword}
                        errors={errors.providedPassword}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">{status && <Error>{status}</Error>}</div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12 text-right">
                      <button
                        className="btn btn-secondary mr-1"
                        type="reset"
                        data-testid="modalCancelBtn"
                        onClick={onHideFn}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary" data-testid="saveCustomerBtn">
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

CustomerModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default CustomerModal;
