import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Toggle } from '../../../../components';
import { selectors as customerConfigurationsSelectors, actions as customerConfigurationsActions } from '../../../../redux/customerConfigurations';
import { makePatch, makeGetFile } from '../../../../api/ajax';
import { useSubscriptionLimit, FEATURES } from '../../../../hooks';
import { routes } from '../../../../routes/Routes';

import './sass/customer-watermark-modal.scss';

const CustomerWatermarkModal = ({ show, onModalClose }) => {
  const { isFeatureDisabled: isCustomThemeDisabled } = useSubscriptionLimit(FEATURES.customTheme);
  const customerConfiguration = useSelector(customerConfigurationsSelectors.selectCustomerConfiguration);
  const localLogoUrl = useSelector(customerConfigurationsSelectors.selectLocalLogoUrl);
  const dispatch = useDispatch();

  useEffect(() => {
    makeGetFile('customerConfigurations/current/custom-logo').subscribe(({ response }) => {
      if (response && response.size > 0) {
        dispatch(customerConfigurationsActions.loadCurrentCustomerLogoSuccess(response));
      }
    });
  }, [dispatch]);

  if (!customerConfiguration) return null;

  const isCustomThemingLogoFileSet = !!localLogoUrl;

  const onToggleClick = () => {
    const updateCustomerConfigDto = {
      customerWatermarkEnabled: !customerConfiguration.customerWatermarkEnabled
    };

    makePatch('customerConfigurations/current', updateCustomerConfigDto).subscribe(
      ({ response }) => dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(response))
    );
  };

  return (
    <>
      <Modal
        headerText="Customer watermark"
        size="md"
        backdrop="static"
        onHide={() => onModalClose(false)}
        show={show}
      >
        <Modal.Body>
          {
            isCustomThemeDisabled && (
              <Alert variant="warning" className="ml-0">
                <h6>Your subscription does not include the custom theming feature</h6>
                To continue using this feature, kindly upgrade your subscription!
              </Alert>
            )
          }
          {
            !isCustomThemingLogoFileSet && (
              <Alert variant="warning" className="ml-0">
                <h6>Custom logo not configured</h6>
                To use custom watermarks, kindly upload a custom logo under "Theme tool - your branding".&nbsp;
                <Link to={routes.customization}>
                  Click here!
                </Link>
              </Alert>
            )
          }
          <div className="customer-watermark-modal__row1">
            <div className="content">Enable custom watermark</div>
            <div className="toggle">
              <Toggle
                on={customerConfiguration.customerWatermarkEnabled}
                disabled={isCustomThemeDisabled || !isCustomThemingLogoFileSet}
                toggleClick={onToggleClick}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

CustomerWatermarkModal.propTypes = {
  show: PropTypes.bool,
  onModalClose: PropTypes.func,
};

export default CustomerWatermarkModal;
