import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { ReactComponent as IcnPhoto } from '../../../../assets/img/icn-photo.svg';
import { ReactComponent as IcnShare } from '../../../../assets/img/icn-share.svg';
import { Text, VehicleTable, LimitedCharsStringPresentation } from '../../../../components';
import { selectors as vehiclesSelectors } from '../../../../redux/vehicles';
import { constants as photoSeriesConstants } from '../../../../redux/photoSeries';
import { formatDateTime, formatTime } from '../../../../utils/date';

import '../../../../sass/vehicles-table.scss';

const qualityRatingColourMapping = {
  [photoSeriesConstants.QUALITY_RATING.HIGH]: VehicleTable.rowIndicatorColours.GREEN,
  [photoSeriesConstants.QUALITY_RATING.LOW]: VehicleTable.rowIndicatorColours.RED,
  [photoSeriesConstants.QUALITY_RATING.AVERAGE]: VehicleTable.rowIndicatorColours.YELLOW,
};

const VehiclesTable = ({ onSelectVehicle, selectedVehicle, isLoadingTableData }) => {
  const pagedResult = useSelector(vehiclesSelectors.selectPagedResult);

  if (!pagedResult || pagedResult?.order?.length === 0) return null;

  const getPhotoSeriesTimes = (vehicle) => vehicle.scanStart && `${formatDateTime(vehicle.scanStart)}-${formatTime(vehicle.scanEnd)}`;
  const { entities } = pagedResult;

  return (
    <>
      <VehicleTable>
        {_.map(pagedResult.order, (vehicleId) => {
          const vehicle = entities[vehicleId];

          return (
            <VehicleTable.VehicleTableRow
              rowIndicatorColour={vehicle.qualityRating != null && qualityRatingColourMapping[vehicle.qualityRating]}
              key={`${vehicle.id}_${vehicle.photoSeriesId}`}
              onSelectVehicle={() => onSelectVehicle(vehicle)}
              vehicle={vehicle}
              selected={selectedVehicle && vehicleId === selectedVehicle.id}
              isLoadingTableData={isLoadingTableData}
            >
              <div className="row">
                <div className="col-sm-6">
                  <Text size={2} weight="bold" inline className="vehicles-table__vehicle-information">
                    {vehicle.hasImages && <IcnPhoto className="svg-small mr-1" />}
                    <LimitedCharsStringPresentation value={vehicle.registrationNo} chars={10} />
                    {vehicle.sharedWithMe && <IcnShare className="svg-small ml-1 vehicles-table__icn-share" />}
                  </Text>
                </div>

                <div className="col-sm-6 text-right">
                  {vehicle.qualityRating != null && (
                    <Text size={1} weight="bold">
                      Report status
                    </Text>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <Text size={1} color="gray">
                    {getPhotoSeriesTimes(vehicle)}
                  </Text>
                </div>

                <div className="col-sm-4 d-flex align-items-end justify-content-center">
                  <Text size={1} color="gray">
                    <LimitedCharsStringPresentation value={vehicle.vin} chars={17} />
                  </Text>
                </div>

                <div className="col-sm-4 text-right">
                  {vehicle.qualityRating != null && <Text size={1}>{photoSeriesConstants.QUALITY_RATING_NAME[vehicle.qualityRating]}</Text>}
                </div>
              </div>
            </VehicleTable.VehicleTableRow>
          );
        })}
      </VehicleTable>
    </>
  );
};

VehiclesTable.propTypes = {
  pagedResult: PropTypes.shape({
    entities: PropTypes.object,
    order: PropTypes.array,
  }),
  onSelectVehicle: PropTypes.func,
  selectedVehicle: PropTypes.shape({
    id: PropTypes.string,
    photoSeriesId: PropTypes.string,
  }),
  isLoadingTableData: PropTypes.bool
};

export default VehiclesTable;
