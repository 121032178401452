import types from './types';

const defaultState = {
  byPhotoSeriesId: {},
  customDamageTypes: null
};

const damagesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.ADD_CUSTOM_DAMAGE_TYPE_SUCCESS: {
      const {
        customDamageTypes
      } = action.payload;

      return {
        ...state,
        customDamageTypes: [...state.customDamageTypes, ...customDamageTypes]
      };
    }
    case types.DELETE_CUSTOM_DAMAGE_TYPE_SUCCESS: {
      const {
        customDamageTypeId
      } = action.payload;

      return {
        ...state,
        customDamageTypes: state.customDamageTypes.filter((customDamageType) => customDamageType.id !== customDamageTypeId)
      };
    }
    case types.EDIT_CUSTOM_DAMAGE_TYPE_SUCCESS: {
      const {
        customDamageType
      } = action.payload;

      return {
        ...state,
        customDamageTypes: state.customDamageTypes.map((cdt) => {
          if (cdt.id === customDamageType.id) return customDamageType;
          return cdt;
        })
      };
    }
    case types.LOAD_CUSTOM_DAMAGE_TYPES_SUCCESS: {
      const {
        customDamageTypes
      } = action.payload;

      return {
        ...state,
        customDamageTypes
      };
    }
    case types.LOAD_PHOTO_SERIES_DAMAGES_SUCCESS: {
      const {
        photoSeriesId,
        damages
      } = action.payload;

      return {
        ...state,
        byPhotoSeriesId: {
          ...state.byPhotoSeriesId,
          [photoSeriesId]: {
            ...state.byPhotoSeriesId[photoSeriesId],
            damages
          }
        }
      };
    }
    case types.LOAD_IMAGE_DAMAGES_SUCCESS: {
      const byImageId = {};
      const {
        photoSeriesId,
        images
      } = action.payload;

      images.forEach((image) => {
        byImageId[image.id] = image.damages?.map((damage) => ({
          ...damage,
          show: damage.flaggedAt === null
        }));
      });

      return {
        ...state,
        byPhotoSeriesId: {
          ...state.byPhotoSeriesId,
          [photoSeriesId]: {
            ...state.byPhotoSeriesId[photoSeriesId],
            byImageId
          }
        }
      };
    }
    case types.SHOW_IMAGE_DAMAGE_BOUNDING_BOX: {
      const { photoSeriesId, imageId, damageId, show } = action.payload;
      return {
        ...state,
        byPhotoSeriesId: {
          ...state.byPhotoSeriesId,
          [photoSeriesId]: {
            ...state.byPhotoSeriesId[photoSeriesId],
            byImageId: {
              ...state.byPhotoSeriesId[photoSeriesId].byImageId,
              [imageId]: state.byPhotoSeriesId[photoSeriesId].byImageId[imageId].map((damage) => {
                if (damageId === undefined || damage.damageId === damageId) return { ...damage, show };
                return { ...damage };
              })
            }
          }
        }
      };
    }
    default:
      return state;
  }
};

export default damagesReducer;
