import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Formik, Field as FormikField } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { makePost } from '../../../../../api/ajax';
import { authenticationService } from '../../../../../services';
import { Error, Field } from '../../../../../components/Form';
import { constants as sessionConstants } from '../../../../../redux/session';
import { actions as usersActions } from '../../../../../redux/users';
import { trackEvent, EVENT_TYPES } from '../../../../../api/analytics';

const UserForm = ({
  user,
  customerDetails,
  cancel,
  submitUrl,
  page,
  showAlert,
  setPage,
  tdStyle,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  hasReachedSubscriptionLimit,
  allowedRoles
}) => {
  const [serverError, setServerError] = useState(null);
  const dispatch = useDispatch();

  const initialValues = {
    id: (user && user.id) || '',
    email: (user && user.email) || '',
    role: (user && user.role) || 0,
    customerId: customerDetails?.id || '',
    askAdditionalInformation: false
  };

  const handleSubmit = (data, resetForm, setStatus, setHasUnsavedChanges) => {
    const roleInt = parseInt(data.role, 10);

    const requestBody = data.id ? { role: roleInt } : { email: data.email, role: roleInt, askAdditionalInformation: data.askAdditionalInformation };

    makePost(submitUrl, requestBody).subscribe(
      ({ response }) => {
        if (!data.id) {
          showAlert('New user created!');

          dispatch(usersActions.clearUsers());

          setPage(1);

          resetForm({});

          const eventData = {
            newUserEmail: data.email,
            newUserRole: sessionConstants.ROLE_NAMES[data.role],
          };

          trackEvent(EVENT_TYPES.USER_INVITED, eventData);
        } else {
          dispatch(usersActions.updateUsersSuccess(response, page));

          showAlert('User has been updated!');

          cancel();
        }

        setHasUnsavedChanges(false);
      },
      ({ response }) => {
        setServerError(response.error);

        setStatus(response.error);

        setHasUnsavedChanges(false);
      },
    );
  };

  const canEditOthers = authenticationService.hasPermission(sessionConstants.PERMISSION_TYPES.EDIT_OTHER_CUSTOMERS);

  return (
    <tr>
      <td colSpan={3} style={tdStyle}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            email: Yup.string().required('Email is required').email('Please enter a correct email'),
            role: Yup.number().required('Required'),
          })}
          onSubmit={({ id, email, role, customerId, askAdditionalInformation }, { setStatus, resetForm }) => {
            const data = {
              id,
              email,
              role,
              customerId,
              askAdditionalInformation
            };
            handleSubmit(data, setStatus, resetForm, setHasUnsavedChanges);
          }}
          validateOnChange={false}
        >
          {({ values, errors, status, handleChange }) => {
            const emailError = status || errors.email;

            return (
              <Form
                onKeyPress={() => !!serverError && setServerError(null)}
                onChange={({ target }) => {
                  if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                    setHasUnsavedChanges(true);
                  }
                }}
              >
                <div className="row no-gutters">
                  <div className="col-12 col-md-7 pl-0">
                    <Field name="email" placeholder="Email" errors={emailError} disabled={values.id} />
                    {serverError && <Error>{serverError}</Error>}
                  </div>
                  <div className="col-xs-12 col-md-4 px-2">
                    <select className="form-control" value={values.role} onChange={handleChange} name="role">
                      {_.map(allowedRoles, (role, i) => (
                        <option key={i} value={role.type}>
                          {sessionConstants.ROLE_NAMES[role.type]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-xs-6 col-md-1 text-right">
                    <button type="submit" className="btn btn-outline-primary" disabled={hasReachedSubscriptionLimit}>
                      Save
                    </button>
                  </div>
                </div>
                {canEditOthers
                && (
                  <div className="row no-gutters">
                    <div className="col-6 col-md-6 mt-2">
                      <div className="form-check ml-1">
                        <FormikField
                          className="form-check-input"
                          type="checkbox"
                          name="askAdditionalInformation"
                          checked={values.askAdditionalInformation}
                        />
                        <label className="form-check-label">Ask additional user information</label>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </td>
    </tr>
  );
};

UserForm.propTypes = {
  user: PropTypes.object,
  cancel: PropTypes.func,
  page: PropTypes.number,
  tdStyle: PropTypes.object,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  setHasUnsavedChanges: PropTypes.func.isRequired,
  submitUrl: PropTypes.string.isRequired,
  customerDetails: PropTypes.object.isRequired,
  showAlert: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  hasReachedSubscriptionLimit: PropTypes.bool,
  allowedRoles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.number
    })
  )
};

export default UserForm;
