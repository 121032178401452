const NAME = 'photoSeries';

const QUALITY_RATING = {
  HIGH: 0,
  LOW: 1,
  AVERAGE: 2,
};

const QUALITY_RATING_NAME = {
  [QUALITY_RATING.HIGH]: 'High',
  [QUALITY_RATING.LOW]: 'Low',
  [QUALITY_RATING.AVERAGE]: 'Average',
};

const CONFIRMATION_TYPE = {
  DECLINED: 1,
  ACCEPTED: 2,
};

const STATUS = {
  APPROVED: 1,
  UNREVIEWED: 2,
  DECLINED: 3,
  COMPLETED: 4,
  IN_PROGRESS: 5,
};

const STATUS_NAME = {
  [STATUS.APPROVED]: 'Approved',
  [STATUS.UNREVIEWED]: 'Unreviewed',
  [STATUS.DECLINED]: 'Declined',
  [STATUS.COMPLETED]: 'Completed',
  [STATUS.IN_PROGRESS]: 'In progress',
};

const VISIBILIY_RATING = {
  SAFE: 1,
  WARNING: 2,
};

const VISIBILIY_RATING_NAME = {
  [VISIBILIY_RATING.SAFE]: 'Safe',
  [VISIBILIY_RATING.WARNING]: 'Warning',
};

const DAMAGES = {
  NOT_ANALYZED: 0,
  NO_DAMAGES: 1,
  DAMAGED: 2
};

const DAMAGES_NAME = {
  [DAMAGES.NOT_ANALYZED]: 'Not analyzed',
  [DAMAGES.NO_DAMAGES]: 'No damages',
  [DAMAGES.DAMAGED]: 'Damaged'
};

export default {
  NAME,
  QUALITY_RATING,
  QUALITY_RATING_NAME,
  CONFIRMATION_TYPE,
  STATUS,
  STATUS_NAME,
  VISIBILIY_RATING,
  VISIBILIY_RATING_NAME,
  DAMAGES,
  DAMAGES_NAME
};
