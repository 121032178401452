export const NAME = 'customerTranslations';

export const STATE_KEYS = {
  ALL_TRANSLATIONS: 'allTranslations',
};

export default {
  NAME,
  STATE_KEYS
};
