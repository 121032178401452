import types from './types';

const loadWorkflowsSuccess = (workflows) => ({
  type: types.LOAD_WORKFLOWS_SUCCESS,
  payload: {
    workflows,
  },
});

const updateImageTypesSuccess = (workflowId, imageTypeSettings) => ({
  type: types.UPDATE_IMAGE_TYPES_SUCCESS,
  payload: {
    workflowId,
    imageTypeSettings,
  },
});

const loadImageTypeCaptureSettingsSuccess = (workflowId, imageType, captureSettings) => ({
  type: types.LOAD_CAPTURE_SETTINGS_SUCCESS,
  payload: {
    workflowId,
    imageType,
    captureSettings,
  },
});

const createWorkflowSuccess = (workflow) => ({
  type: types.CREATE_WORKFLOW_SUCCESS,
  payload: {
    workflow,
  },
});

const updateWorkflowSuccess = (workflowId, workflow) => ({
  type: types.UPDATE_WORKFLOW_SUCCESS,
  payload: {
    workflowId,
    workflow,
  },
});

const deleteWorkflowSuccess = (workflowId) => ({
  type: types.DELETE_WORKFLOW_SUCCESS,
  payload: {
    workflowId,
  },
});

const loadSharedWorkflowsSuccess = (sharedWorkflows) => ({
  type: types.LOAD_SHARED_WORKFLOWS_SUCCESS,
  payload: {
    sharedWorkflows,
  },
});

export default {
  loadWorkflowsSuccess,
  updateImageTypesSuccess,
  loadImageTypeCaptureSettingsSuccess,
  createWorkflowSuccess,
  updateWorkflowSuccess,
  deleteWorkflowSuccess,
  loadSharedWorkflowsSuccess
};
