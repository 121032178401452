import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, ConfirmationModal } from '../../../../../../../../components';
import { ReactComponent as IconBin } from '../../../../../../../../assets/img/icn-bin.svg';
import { constants as sessionConstants } from '../../../../../../../../redux/session';
import { authenticationService } from '../../../../../../../../services';

import './sass/delete-image.scss';
import { makeDelete } from '../../../../../../../../api/ajax';

const {
  DELETE_IMAGE
} = sessionConstants.PERMISSION_TYPES;

const DeleteImage = ({ imageId }) => {
  const [shownConfirmationModal, setShownConfirmationModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { hasPermission } = authenticationService;

  if (!hasPermission(DELETE_IMAGE)) return null;

  const handleDelete = () => {
    setIsDeleting(true);
    makeDelete(`images/${imageId}`).subscribe(() => {
      window.location.reload();
    });
  };

  return (
    <>
      <div className="delete-image">
        <Button
          className="btn icon"
          variation="light-blue"
          type="button"
          testId="deleteImageBtn"
          onClick={() => setShownConfirmationModal(true)}
          disabled={isDeleting}
        >
          <IconBin />
        </Button>
      </div>
      {
        shownConfirmationModal && (
          <ConfirmationModal
            showModal={shownConfirmationModal}
            heading="Delete image"
            message="Are you sure you wish to delete the current image?"
            onConfirm={() => {
              handleDelete();
              setShownConfirmationModal(false);
            }}
            onCancel={() => setShownConfirmationModal(false)}
          />
        )
      }
    </>
  );
};

DeleteImage.propTypes = {
  imageId: PropTypes.string
};

export default DeleteImage;
