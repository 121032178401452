const prefix = 'drivex/customImageTypes';

const LOAD_CUSTOM_IMAGE_TYPES_SUCCESS = `${prefix}/LOAD_CUSTOM_IMAGES_TYPES_SUCCESS`;
const ADD_CUSTOM_IMAGE_TYPE_SUCCESS = `${prefix}/ADD_CUSTOM_IMAGE_TYPE_SUCCESS`;
const EDIT_CUSTOM_IMAGE_TYPE_SUCCESS = `${prefix}/EDIT_CUSTOM_IMAGE_TYPE_SUCCESS`;
const DELETE_CUSTOM_IMAGE_TYPE_SUCCESS = `${prefix}/DELETE_CUSTOM_IMAGE_TYPE_SUCCESS`;

export default {
  LOAD_CUSTOM_IMAGE_TYPES_SUCCESS,
  ADD_CUSTOM_IMAGE_TYPE_SUCCESS,
  EDIT_CUSTOM_IMAGE_TYPE_SUCCESS,
  DELETE_CUSTOM_IMAGE_TYPE_SUCCESS
};
