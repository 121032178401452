import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';

import { drivexStorage } from '../hooks/useLocalStorage/useLocalStorage';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const logout = () => {
  // remove user from local storage to log user out
  drivexStorage.removeItem('currentUser');
  drivexStorage.removeItem('publicUserView');
  currentUserSubject.next(null);
  window.location.reload();
};

const shouldRenewToken = (expiresAt) => {
  const nowSec = new Date().getTime() / 1000;
  const expiresAtSec = new Date(expiresAt) / 1000;
  const diff = expiresAtSec - nowSec;

  // refresh token when it's going to expire in less than 1 minute
  return diff < 60;
};

const storeUserData = (user) => {
  // store user details without jwt tokens in local storage
  delete user.token;
  delete user.refreshToken;

  drivexStorage.setItem('currentUser', user);
  currentUserSubject.next(user);

  return user;
};

const storePublicUserView = (value) => {
  drivexStorage.setItem('publicUserView', value);
};

const isPublicUserView = () => {
  const publicUserView = drivexStorage.getItem('publicUserView');
  return publicUserView !== undefined;
};

const hasPermission = (permission) => {
  const currentUser = currentUserSubject.value;

  if (!currentUser) return false;

  const { permissions } = currentUser;

  return _.indexOf(permissions, permission) >= 0;
};

const authenticationService = {
  logout,
  shouldRenewToken,
  storeUserData,
  storePublicUserView,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  hasPermission,
  isPublicUserView
};

export default authenticationService;
