import React, { useState } from 'react';

import { MenuPage } from '../../layouts';
import { Button, PageTitle } from '../../components';
import { routes } from '../../routes/Routes';

import PublicLinkGeneratorModal from '../Vehicles/containers/PublicLinkGeneratorModal/PublicLinkGeneratorModal';
import Dashboard from './containers/Dashboard/Dashboard';

const Home = () => {
  const [showLinkGeneratorModal, setShowLinkGeneratorModal] = useState(false);
  const [isLoadingStatistics, setIsLoadingStatistics] = useState(false);

  const toggleShowLinkGeneratorModal = () => {
    setShowLinkGeneratorModal(!showLinkGeneratorModal);
  };

  return (
    <MenuPage currentRoute={routes.home}>
      <PageTitle>
        <PageTitle.Left headingText="Dashboard" isLoading={isLoadingStatistics} />

        <PageTitle.Right>
          <Button testId="homeNewVehicleReqBtn" className="btn btn-primary" onClick={toggleShowLinkGeneratorModal}>
            New vehicle request
          </Button>
        </PageTitle.Right>
      </PageTitle>

      {showLinkGeneratorModal && (
        <PublicLinkGeneratorModal id="vehicles-link-generator-modal" show={showLinkGeneratorModal} onHide={() => setShowLinkGeneratorModal(false)} />
      )}
      <Dashboard setIsLoadingStatistics={setIsLoadingStatistics} />
    </MenuPage>
  );
};

export default Home;
