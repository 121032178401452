import { schema } from 'normalizr';

const vehicleSchema = new schema.Entity(
  'vehicles',
  {},
  { idAttribute: (vehicle) => `${vehicle.id}_${vehicle.photoSeriesId}` },
);

const photoSeriesSchema = new schema.Entity('vehiclePhotoSeries');

export const photoSeriesListSchema = [photoSeriesSchema];
export const vehicleListSchema = [vehicleSchema];
