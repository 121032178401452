import React from 'react';
import PropTypes from 'prop-types';

import { StarRating, SkeletonUI } from '..';

import './sass/photoseries-customer-feedback.scss';

const PhotoSeriesCustomerFeedback = ({ photoSeries, isLoadingPhotoSeriesDetails }) => (
  <div className="customer-feedback-container">
    {isLoadingPhotoSeriesDetails || !photoSeries
      ? <SkeletonUI type="radius-horizontal" maxWidth="12.5rem" />
      : (
        <div className="customer-feedback-container__row1">
          <span className="key heading">Customer feedback</span>
          <span className="value">
            <StarRating satisfactionRating={photoSeries.feedback && photoSeries.feedback.satisfactionRating} displayFigure />
          </span>
        </div>
      )}

    {isLoadingPhotoSeriesDetails || !photoSeries ? (
      <>
        <SkeletonUI type="radius-horizontal" />
        <SkeletonUI type="radius-horizontal" />
      </>
    ) : (
      <div className="customer-feedback-container__row2">
        <p>{(photoSeries.feedback && photoSeries.feedback.comment) || 'Customer left no feedback.'}</p>
      </div>
    )}
  </div>
);

PhotoSeriesCustomerFeedback.propTypes = {
  isLoadingPhotoSeriesDetails: PropTypes.bool.isRequired,
  photoSeries: PropTypes.object,
};

export default PhotoSeriesCustomerFeedback;
