import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';

import { ReactComponent as IconPen } from '../../../../assets/img/icn-pen.svg';
import { ReactComponent as IconCross } from '../../../../assets/img/icn-cancel-cross.svg';
import { ReactComponent as IconSettings } from '../../../../assets/img/icn-settings.svg';
import { ReactComponent as IconCar } from '../../../../assets/img/icn-car-dark.svg';
import { ReactComponent as IconMotorcycle } from '../../../../assets/img/icn-motorcycle.svg';
import { ReactComponent as IconTruck } from '../../../../assets/img/icn-truck.svg';
import { ReactComponent as IconBus } from '../../../../assets/img/icn-bus.svg';
import { ReactComponent as IconTrailer } from '../../../../assets/img/icn-trailer.svg';

import { makePatch, makeDelete } from '../../../../api/ajax';
import { ActionsDropDown, Card, SettingsList, Text, Toggle, ConfirmationModal } from '../../../../components';
import { constants as imagesConstants } from '../../../../redux/images';
import { actions as workflowsActions, constants as workflowsConstants } from '../../../../redux/workflows';
import { trackEvent, EVENT_TYPES } from '../../../../api/analytics';

import ImageTypeModal from './containers/ImageTypeModal';

const handleRemoveWorkflow = (workflow, dispatch, setShowConfirmRemoveModal) => {
  makeDelete(`workflows/${workflow.id}`).subscribe(() => {
    dispatch(workflowsActions.deleteWorkflowSuccess(workflow.id));

    // Track event in Analytics
    const eventData = {
      workflowId: workflow.id,
      workflowName: workflow.name,
      workflowReportingEmal: workflow.reportingEmail,
      workflowVehicleType: workflow.vehicleType,
    };

    trackEvent(EVENT_TYPES.WORKFLOW_DELETED, eventData);
  });
  setShowConfirmRemoveModal(false);
};

const VehicleIcon = ({ vehicleType }) => {
  if (vehicleType === workflowsConstants.VEHICLE_TYPES.MOTORCYCLE) return <IconMotorcycle className="svg-big" />;
  if (vehicleType === workflowsConstants.VEHICLE_TYPES.CAR) return <IconCar className="svg-big" />;
  if (vehicleType === workflowsConstants.VEHICLE_TYPES.TRUCK) return <IconTruck className="svg-big" />;
  if (vehicleType === workflowsConstants.VEHICLE_TYPES.BUS) return <IconBus className="svg-big" />;
  if (vehicleType === workflowsConstants.VEHICLE_TYPES.TRAILER) return <IconTrailer className="svg-big" />;
  return null;
};

VehicleIcon.propTypes = {
  vehicleType: PropTypes.number.isRequired
};

const WorkflowSettings = ({ workflow, editWorkflow, updateImageTypesSuccess, canRemove }) => {
  const { id, vehicleType, name, reportingEmail, imageTypeSettings } = workflow;
  const [selectedImageTypeSetting, setSelectedImageTypeSetting] = useState(null);
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);

  const lastImageTypeEnabled = _.filter(imageTypeSettings, (i) => i.enabled).length === 1;

  const dispatch = useDispatch();

  const handleImageTypeToggle = (imageType, enabled) => {
    makePatch(`workflows/${id}/imageTypes`, [{ imageType, enabled }]).subscribe(({ response }) => {
      updateImageTypesSuccess(id, response);
    });

    // Track event in Analytics
    const data = {
      workflowId: id,
      workflowName: name,
      workflowVehicleType: vehicleType,
      imageType: imagesConstants.CAPTURE_NAMES[imageType],
    };

    const eventType = enabled
      ? EVENT_TYPES.WORKFLOW_IMAGE_TYPE_ENABLED
      : EVENT_TYPES.WORKFLOW_IMAGE_TYPE_DISABLED;

    trackEvent(eventType, data);
  };

  return (
    <>
      <Card bg="light" text="dark">
        <Card.Header>
          <div className="row">
            <div className="col col-sm-2">
              <VehicleIcon vehicleType={vehicleType} />
            </div>

            <div className="col col-sm-8">
              <Text weight="bold" color="black" size={2}>
                {name}
              </Text>

              <Text weight="fine" color="gray">
                {reportingEmail}
              </Text>
            </div>

            <div className="col col-sm-2 text-right">
              <ActionsDropDown toggle={<IconSettings className="actions-dropdown-toggle" />} round>
                <ActionsDropDown.Item onClick={() => editWorkflow(workflow)}>
                  <IconPen />
                  <span className="pl-3">Edit</span>
                </ActionsDropDown.Item>

                {canRemove && (
                  <ActionsDropDown.Item onClick={() => setShowConfirmRemoveModal(true)}>
                    <IconCross />
                    <span className="pl-3">Remove</span>
                  </ActionsDropDown.Item>
                )}
              </ActionsDropDown>
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          <SettingsList>
            {_.map(imageTypeSettings, (imageTypeSetting, key) => (
              <SettingsList.Item key={key}>
                <div className="row no-gutters">
                  <div className="col-2">
                    <Toggle
                      on={imageTypeSetting.enabled}
                      toggleClick={() => handleImageTypeToggle(imageTypeSetting.imageType, !imageTypeSetting.enabled)}
                      disabled={lastImageTypeEnabled && imageTypeSetting.enabled}
                    />
                  </div>

                  <div className="col">{imagesConstants.CAPTURE_NAMES[imageTypeSetting.imageType]}</div>

                  <div className="col-5 text-right">
                    <button
                      className="btn btn-link"
                      type="button"
                      disabled={!imageTypeSetting.enabled}
                      onClick={() => setSelectedImageTypeSetting(imageTypeSetting)}
                    >
                      Settings
                    </button>
                  </div>
                </div>
              </SettingsList.Item>
            ))}
          </SettingsList>
        </Card.Body>
      </Card>
      {selectedImageTypeSetting && (
        <ImageTypeModal
          workflowId={id}
          vehicleType={vehicleType}
          imageType={selectedImageTypeSetting.imageType}
          onHide={() => setSelectedImageTypeSetting(null)}
          show={selectedImageTypeSetting !== null}
          readonly={selectedImageTypeSetting.readonly}
        />
      )}

      {showConfirmRemoveModal && (
        <ConfirmationModal
          showModal={showConfirmRemoveModal}
          heading="Delete workflow"
          message="Are you sure you want to delete this workflow?"
          onConfirm={() => handleRemoveWorkflow(workflow, dispatch, setShowConfirmRemoveModal)}
          onCancel={() => setShowConfirmRemoveModal(false)}
          style={{ top: '6.25rem' }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateImageTypesSuccess: (workflowId, imageTypeSettings) =>
    dispatch(workflowsActions.updateImageTypesSuccess(workflowId, imageTypeSettings)),
});

WorkflowSettings.propTypes = {
  workflow: PropTypes.object.isRequired,
  editWorkflow: PropTypes.func.isRequired,
  canRemove: PropTypes.bool.isRequired,
  updateImageTypesSuccess: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(WorkflowSettings);
