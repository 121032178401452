import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Error } from '../index';

const Errors = ({ status, errors }) => {
  if (!status && !errors) return null;

  let errorMessages = [];
  if (status) errorMessages = _.concat(errorMessages, status);
  if (errors) errorMessages = _.concat(errorMessages, _.values(errors));

  return (
    <Error>
      {_.map(errorMessages, (msg, key) => <p key={key}>{msg}</p>)}
    </Error>
  );
};

Errors.propTypes = {
  status: PropTypes.string,
  errors: PropTypes.string,
};

export default Errors;
