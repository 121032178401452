import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import './sass/custom-card.scss';

const CustomCardHeader = ({ children }) => (
  <div className="custom-card-header">
    {children}
  </div>
);

const CustomCardBody = ({ children }) => (
  <Card.Body className="custom-card-body">
    {children}
  </Card.Body>
);

const CustomCard = ({ children }) => (
  <Card
    bg="light"
    text="dark"
    className="custom-card"
  >
    {children}
  </Card>
);

CustomCard.Header = CustomCardHeader;
CustomCard.Body = CustomCardBody;

CustomCardHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

CustomCardBody.propTypes = {
  children: PropTypes.node.isRequired,

};

CustomCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomCard;
