import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions, constants } from '..';
import { makeGet } from '../../../api/ajax';

const useLoadExperimentalFeatures = () => {
  const experimentalFeatures = useSelector(selectors.selectExperimentalFeatures);
  const dispatch = useDispatch();

  useEffect(() => {
    if (experimentalFeatures !== null) return;

    makeGet('experimentalFeatures').subscribe(({ response }) => {
      dispatch(actions.loadExperimentalFeaturesSuccess(response));
    });
  }, [experimentalFeatures, dispatch]);

  const showExperimentalFeature = (experimentalFeature) => {
    // If experimental features are not loaded then we always handle it as experimental feature
    if (experimentalFeatures === null) {
      return false;
    }

    return !experimentalFeatures.includes(experimentalFeature);
  };

  return {
    showExperimentalFeature,
    EXPERIMENTAL_FEATURE_TYPE: constants.EXPERIMENTAL_FEATURE_TYPE,
    experimentalFeaturesLoaded: !!experimentalFeatures
  };
};

export default useLoadExperimentalFeatures;
