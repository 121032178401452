const utcToday = new Date();

utcToday.setUTCHours(0, 0, 0, 0);

export const getUtcToday = () => utcToday;
export const getNextMonth = () => new Date(Date.UTC(utcToday.getUTCFullYear(), utcToday.getMonth() + 1, 1, 0, 0, 0));

export const isCurrentMonth = (activeMonth) => activeMonth.getFullYear() === utcToday.getFullYear() && activeMonth.getMonth() === utcToday.getMonth();

const dateTimeFormat = new Intl.DateTimeFormat('et', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
});
export const formatDateTime = (dateTimeString) => dateTimeFormat.format(new Date(dateTimeString));

const timeFormat = new Intl.DateTimeFormat('et', { hour: 'numeric', minute: 'numeric' });
export const formatTime = (dateTimeString) => {
  if (!dateTimeString) return '...';

  return timeFormat.format(new Date(dateTimeString));
};

const dateFormat = new Intl.DateTimeFormat('et-EE', { day: '2-digit', month: '2-digit', year: 'numeric' });
export const formatDate = (value) => {
  if (!value) return '';

  return dateFormat.format(new Date(value));
};

export const getTimeFrame = (createdAt, completedAt) => {
  if (!createdAt || !completedAt) return '-';
  return `${formatDateTime(createdAt)} - ${formatTime(completedAt)}`;
};

export const getTimeInterval = (unit, startTime, endTime) => {
  const diff = Math.abs(new Date(endTime).getTime() - new Date(startTime).getTime());

  switch (unit) {
    case 'secs': {
      return Math.floor(diff / 1000 / 1000);
    }

    case 'mins': {
      return Math.floor(diff / 1000 / 60);
    }
    case 'hrs': {
      return Math.floor((diff % 86400000) / 3600000);
    }

    case 'days': {
      return Math.floor(diff / 86400000);
    }

    default: {
      return diff;
    }
  }
};

export const checkIsDateTodayOrLessThanToday = (dateToBeComparedToToday) => {
  if (dateToBeComparedToToday === null) {
    return false;
  }

  const dateObj = new Date(dateToBeComparedToToday);
  dateObj.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return dateObj < today;
};

export const getInputDateFormat = (date) => date.substr(0, 10);

// NOTE:  We convert local expiresAt date into customer specific TZ
export const createISODate = (dateString, timeZoneOffset) => new Date(`${dateString}T00:00:00.000${timeZoneOffset}`).toISOString();

// NOTE:  We convert local expiresAt date and time into customer specific TZ
export const createISODateTime = (dateTimeString, timeZoneOffset) => new Date(`${dateTimeString}:00${timeZoneOffset}`).toISOString();

export const dateFormatter = (date) => {
  const formats = Object.freeze({
    rawFormat() {
      return date;
    },
    inputDateFormat() {
      return date.toJSON().slice(0, 10);
    },
    ISOStringFormat() {
      return date.toISOString();
    },
  });

  return formats;
};

export const getPreviousDate = (numberOfDaysInThePast) => {
  const date = new Date();

  date.setDate(date.getDate() - numberOfDaysInThePast);

  return date;
};
