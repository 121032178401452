import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const rootModal = document.getElementById('rootCache');

const ReactCache = ({ children }) => {
  const cachedPagesContainer = document.createElement('div');
  cachedPagesContainer.setAttribute('class', 'cached-pages-container');
  cachedPagesContainer.setAttribute('style', `
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
  `);

  useEffect(() => {
    rootModal.appendChild(cachedPagesContainer);

    return () => {
      rootModal.removeChild(cachedPagesContainer);
    };
  }, [cachedPagesContainer]);

  return ReactDOM.createPortal(children, cachedPagesContainer);
};

ReactCache.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReactCache;
