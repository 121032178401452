import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '..';
import { ReactComponent as LinkIcon } from '../../assets/img/icn-link.svg';

import './sass/copy-to-clipboard.scss';

const CopyToClipboard = ({ targetText, buttonText, textAreaId }) => {
  const [text, setText] = useState({ text: buttonText, isCopied: false });

  if (!targetText || !buttonText || !document.queryCommandSupported('copy')) return null;

  const onCopyHandler = (e) => {
    e.preventDefault();

    const textTextarea = document.createElement('textarea');
    textTextarea.value = targetText;
    textTextarea.setAttribute('readonly', '');
    textTextarea.setAttribute('id', textAreaId);
    textTextarea.style.position = 'absolute';
    textTextarea.style.left = '-9999px';

    document.body.appendChild(textTextarea);
    textTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(textTextarea);

    if (!document.getElementById(textAreaId)) setText({ text: 'Successfully copied link', isCopied: true });
  };

  return (
    <div>
      <Button
        className={`copy-to-clipboard-button ${text.isCopied ? 'copy-to-clipboard-button--copied' : ''}`}
        testId="copyToClipBtn"
        variation="transparent"
        style={{ padding: '0' }}
        onClick={onCopyHandler}
      >
        <span>{text.isCopied ? <>&#10004;</> : <LinkIcon />}</span>
        &nbsp;
        <span aria-live="assertive" aria-atomic="true">{text.text}</span>
      </Button>
    </div>
  );
};

CopyToClipboard.propTypes = {
  buttonText: PropTypes.string.isRequired,
  targetText: PropTypes.string.isRequired,
  textAreaId: PropTypes.string.isRequired
};
export default CopyToClipboard;
