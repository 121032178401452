import types from './types';

import sessionTypes from '../session/types';

const defaultState = {};

const photoSeriesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_PHOTO_SERIES_SUCCESS: {
      const {
        photoSeriesId,
        data,
      } = action.payload;

      return {
        ...state,
        photoSeries: {
          ...state.photoSeries,
          [photoSeriesId]: data,
        },
      };
    }
    case types.LOAD_PHOTO_SERIES_REPAIR_ESTIMATIONS_SUCCESS: {
      const {
        photoSeriesId,
        data,
      } = action.payload;

      return {
        ...state,
        photoSeries: {
          ...state.photoSeries,
          [photoSeriesId]: {
            ...state.photoSeries[photoSeriesId],
            repairEstimations: data
          }
        }
      };
    }
    case types.LOAD_PHOTO_SERIES_DAMAGE_SUMMARY_SUCCESS: {
      const {
        photoSeriesId,
        data,
      } = action.payload;

      return {
        ...state,
        photoSeries: {
          ...state.photoSeries,
          [photoSeriesId]: {
            ...state.photoSeries[photoSeriesId],
            damages: data
          }
        }
      };
    }
    case types.LOAD_PHOTO_SERIES_REPORT_STATUS_SUCCESS: {
      const {
        photoSeriesId,
        data,
      } = action.payload;

      return {
        ...state,
        photoSeries: {
          ...state.photoSeries,
          [photoSeriesId]: {
            ...state.photoSeries[photoSeriesId],
            reportStatus: {
              ...state.photoSeries[photoSeriesId].reportStatus,
              ...data
            }
          }
        }
      };
    }
    case sessionTypes.LOG_OUT: {
      return defaultState;
    }
    default:
      return state;
  }
};

export default photoSeriesReducer;
