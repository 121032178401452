import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '..';

import './sass/page-title.scss';

const TitleLoader = () => <div className="title-loader-wrapper"><Loader size="small" /></div>;

const PageTitle = ({ children }) => {
  React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) return null;
  });

  return <div className="page-title"><div className="row">{children}</div></div>;
};

const PageTitleLeftNode = ({ children, headingText, isLoading }) => (
  <div className="col-sm-6">
    <h3>{headingText}</h3>
    {children}
    {isLoading && <PageTitle.Loader />}
  </div>
);

const PageTitleRightNode = ({ children }) => <>{children && <div className="col-sm-6 text-right">{children}</div>}</>;

PageTitle.Loader = TitleLoader;
PageTitle.Left = PageTitleLeftNode;
PageTitle.Right = PageTitleRightNode;

PageTitle.propTypes = {
  children: PropTypes.node.isRequired
};

PageTitleLeftNode.propTypes = {
  headingText: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool
};

PageTitleRightNode.propTypes = {
  children: PropTypes.node
};

export default PageTitle;
