import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { usePreviewSettingsContext } from '../CustomImageTypePreview';

import SmartScanUITexts from './SmartScanUITexts/SmartScanUITexts';

import {
  selectors as translationsSelectors,
  constants as translationsConstants,
} from '../../../../../redux/translations';

import { selectors as customerTranslationsSelectors } from '../../../../../redux/customerTranslations';
import { selectors as customerConfigurationSelectors } from '../../../../../redux/customerConfigurations';

import { getFormKeyParts, CAPTURE_MODE_KEY } from '../../../../../utils/customImages';

const SideBySideForm = ({ setActiveCarouselImage, CAROUSEL_CAPTURING_VIEW, CAROUSEL_PROCESS_MODAL, imageTypeId }) => {
  const { LANGUAGES_CODE } = translationsConstants;

  const [previewSettings, setPreviewSettings] = usePreviewSettingsContext();

  const languages = useSelector(translationsSelectors.selectLanguages);
  const customerConfiguration = useSelector(customerConfigurationSelectors.selectCustomerConfiguration);
  const allTranslations = useSelector((state) => customerTranslationsSelectors.selectAllTranslations(state));

  const onInputFocus = ({ target: { name } }, previewSettings, setPreviewSettings, getFormKeyParts) => {
    const smartScanUiPage = name.indexOf(CAPTURE_MODE_KEY) === -1
      // If contains, switch to capture mode
      ? CAROUSEL_PROCESS_MODAL
      // If not, switch to process modal mode
      : CAROUSEL_CAPTURING_VIEW;

    setActiveCarouselImage(smartScanUiPage);

    const { translationId, translationKey } = getFormKeyParts(name);

    const newPreviewSettings = {
      ...previewSettings,
      activeTranslationId: translationId,
      activeTranslationKey: translationKey,
      activeCheckbox: translationKey.includes('checkbox') ? translationKey : previewSettings.activeCheckbox
    };

    setPreviewSettings(newPreviewSettings);
  };

  if (!languages || !allTranslations) return null;

  const englishLanguage = languages.find((lang) => lang.code === LANGUAGES_CODE.ENGLISH);

  const renderOneForm = customerConfiguration.enabledLanguages.length === 1
    || customerConfiguration.enabledLanguages.find((id) => id === englishLanguage.id) === undefined;

  if (renderOneForm) {
    return (
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <SmartScanUITexts
            onInputFocus={(event) => onInputFocus(event, previewSettings, setPreviewSettings, getFormKeyParts)}
            disableTranslationsInput={false}
            languages={languages.filter((lang) => customerConfiguration.enabledLanguages.includes(lang.id))}
            imageTypeId={imageTypeId}
          />
        </div>
      </div>
    );
  }

  const sourceLanguage = LANGUAGES_CODE.ENGLISH;

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6">
        <SmartScanUITexts
          onInputFocus={(event) => onInputFocus(event, previewSettings, setPreviewSettings, getFormKeyParts)}
          disableTranslationsInput={false}
          languages={languages.filter((lang) => lang.code === sourceLanguage)}
          imageTypeId={imageTypeId}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <SmartScanUITexts
          onInputFocus={(event) => onInputFocus(event, previewSettings, setPreviewSettings, getFormKeyParts)}
          disableTranslationsInput={false}
          languages={languages.filter((lang) => lang.code !== sourceLanguage && customerConfiguration.enabledLanguages.includes(lang.id))}
          imageTypeId={imageTypeId}
          sourceLanguage={languages.find((lang) => lang.code === sourceLanguage)}
        />
      </div>
    </div>
  );
};

SideBySideForm.propTypes = {
  setActiveCarouselImage: PropTypes.func.isRequired,
  CAROUSEL_CAPTURING_VIEW: PropTypes.number.isRequired,
  CAROUSEL_PROCESS_MODAL: PropTypes.number.isRequired,
  imageTypeId: PropTypes.string.isRequired,
};

export default React.memo(SideBySideForm);
