import React from 'react';

import ResetPasswordForm from './containers/ResetPasswordForm';

import {
  Container, Logo, Text, SlideOpen,
} from '../../components';
import { FullPage } from '../../layouts';

const ResetPassword = () => (
  <FullPage>
    <Container fullscreen verticalAlign="center" horizontalAlign="center">
      <Logo />

      <SlideOpen open>
        <ResetPasswordForm />
      </SlideOpen>
    </Container>

    <Container fullscreen bottom>
      <Text>
        All rights reserved
        {' '}
        <time>{new Date().getFullYear()}</time>
      </Text>
    </Container>
  </FullPage>
);

export default ResetPassword;
