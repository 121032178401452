import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from '../../assets/img/icn-close-icon.svg';

import './sass/tags.scss';

const Tags = ({ tags, onRemoveTag }) => {
  if (!tags) return null;
  return (
    <ul className="tags-items-wrapper">
      {tags.map((tag) => (
        <li key={tag.id}>
          <section>
            <span>{tag.name}</span>
            {
              onRemoveTag && (
                <span className="tags-item-cancel-icon" role="button" tabIndex={0} onClick={() => onRemoveTag(tag)}>
                  <Close />
                </span>
              )
            }
          </section>
        </li>
      ))}
    </ul>
  );
};

Tags.propTypes = {
  tags: PropTypes.array,
  onRemoveTag: PropTypes.func
};

export default Tags;
