import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '..';

import './sass/table.scss';

const Table = ({ header, rows, handleDelete, handleEdit }) => (
  <div className="action-table">
    {
      header && (
        <div className="action-table-header">
          <div className="row">
            { header }
          </div>
        </div>
      )
    }
    {
      rows.filter((item) => !item.isDeleted).map((item) => (
        <div className="action-table-row" key={item.id}>
          <div className="row">
            {
              !item.row && (
                <>
                  <div className="col-sm-10 row-text">
                    <Text size={2} weight="bold" inline>
                      { item.name }
                    </Text>
                  </div>

                  <div className="col-sm-2 row-buttons">
                    {
                      handleEdit && (
                        <button
                          type="button"
                          className="btn btn-primary mr-2"
                          onClick={() => handleEdit(item)}
                        >
                          Edit
                        </button>
                      )
                    }
                    {
                      handleDelete && (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => handleDelete(item.id)}
                        >
                          Delete
                        </button>
                      )
                    }
                  </div>
                </>
              )
            }
            {
              item.row && (
                <>
                  { item.row }
                </>
              )
            }
          </div>
        </div>
      ))
    }
  </div>
);

Table.propTypes = {
  header: PropTypes.element,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      deleted: PropTypes.bool,
      row: PropTypes.element
    })
  ),
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func
};

export default Table;
