/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function IconWrench({ color }) {
  const hex = color === 'blue' ? '#0077FF' : '#000000';
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.125 12.6562C15.2708 12.7396 15.3333 12.8854 15.3125 13.0937C15.3125 13.2812 15.2292 13.4479 15.0625 13.5937L13.5313 15.125C13.2188 15.4375 12.9063 15.4375 12.5938 15.125L6.53125 9.06247C5.78125 9.37497 4.97917 9.44789 4.125 9.28122C3.29167 9.09372 2.5625 8.68747 1.9375 8.06247C1.27084 7.3958 0.854169 6.60414 0.687502 5.68747C0.520836 4.7708 0.645836 3.91664 1.0625 3.12497L4 5.99997L6 3.99997L3.125 1.12497C3.91667 0.749971 4.77084 0.635387 5.6875 0.781221C6.60417 0.927054 7.39584 1.3333 8.0625 1.99997C8.6875 2.62497 9.08334 3.36455 9.25 4.21872C9.4375 5.05205 9.375 5.84372 9.0625 6.59372L15.125 12.6562Z" fill={hex} />
    </svg>
  );
}

IconWrench.propTypes = {
  color: PropTypes.string.isRequired,
};

export default IconWrench;
