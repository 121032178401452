import { LOAD_SUBSCRIPTIONS_SUCCESS, LOAD_ACTIVE_SUBSCRIPTION_SUCCESS } from './types';

const loadSubscriptionsSuccess = (payload) => ({ type: LOAD_SUBSCRIPTIONS_SUCCESS, payload });

const loadEnabledFeaturesSuccess = (payload) => ({ type: LOAD_ACTIVE_SUBSCRIPTION_SUCCESS, payload });

export default {
  loadSubscriptionsSuccess,
  loadEnabledFeaturesSuccess,
};
