import React from 'react';

import { Thumbnail } from '..';
import IconCar from '../../assets/img/icn-car-grey.svg';

import './sass/photoseries-default-gallery.scss';

const PhotoSeriesDefaultGallery = () => {
  const thumbnailSmallProps = {
    small: true,
    fileName: IconCar,
    isDefaultThumbnail: true,
  };

  return (
    <div className="default-gallery-container">
      <div className="default-gallery-container__row1">
        <div className="default-gallery-container__row1-col1">
          <Thumbnail large fileName={IconCar} isDefaultThumbnail />
        </div>

        <div className="default-gallery-container__row1-col2">
          <div>
            <div className="small-img-wrapper">
              <Thumbnail {...thumbnailSmallProps} />
            </div>

            <div className="small-img-wrapper">
              <Thumbnail {...thumbnailSmallProps} />
            </div>

            <div className="small-img-wrapper">
              <Thumbnail {...thumbnailSmallProps} />
            </div>
          </div>

          <div>
            <div className="small-img-wrapper">
              <Thumbnail {...thumbnailSmallProps} />
            </div>

            <div className="small-img-wrapper">
              <Thumbnail {...thumbnailSmallProps} />
            </div>

            <div className="small-img-wrapper">
              <Thumbnail {...thumbnailSmallProps} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoSeriesDefaultGallery;
