import { ajax } from 'rxjs/ajax';
import { Subject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { authenticationService } from '../services';

import { composeApiUrl } from './composeApiUrl';

const defaultHeaders = {
  'Content-Type': 'application/json',
};

export const makeGet = (url) => makeRequest('GET', url, composeHeaders, null);

export const makeDelete = (url) => makeRequest('DELETE', url, composeHeaders, {});

export const makeGetFile = (url) => makeRequest('GET', url, composeHeaders, null, 'blob');

export const makePost = (url, body = {}) => makeRequest('POST', url, composeHeaders, body);

export const makePut = (url, body = {}) => makeRequest('PUT', url, composeHeaders, body);

export const makePatch = (url, body = {}) => makeRequest('PATCH', url, composeHeaders, body);

export const makeFileUpload = (url, dataUri, postData, fileKey = 'file', method = 'POST') => {
  const form = new FormData();

  if (postData instanceof File) {
    form.append(fileKey, postData);
  } else {
    const blob = dataUriToBlob(dataUri);
    if (blob) form.append(fileKey, blob);

    buildFormData(form, postData);
  }

  return makeRequest(method, url, composeFileUploadHeaders, form);
};

const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
};

const dataUriToBlob = (dataUri) => {
  if (!dataUri) return null;
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataUri.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataUri.split(',')[1]);
  } else {
    byteString = unescape(dataUri.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

const composeHeaders = (headers) => {
  delete defaultHeaders.Authorization;

  return {
    ...defaultHeaders,
    ...headers,
  };
};

const composeFileUploadHeaders = (headers, token) => {
  const composedHeaders = composeHeaders(headers, token);
  delete composedHeaders['Content-Type'];
  return composedHeaders;
};

const makeRequest = (requestFunc, url, composeHeadersFunc, body, responseType = 'json') =>
  refreshToken().pipe(
    switchMap(() => {
      const requestUrl = composeApiUrl(url);
      const requestHeaders = composeHeadersFunc({});

      return ajax({
        url: requestUrl,
        responseType,
        method: requestFunc,
        headers: requestHeaders,
        body,
        withCredentials: true,
      });
    }),
  );

let renewObservable = null;
let onTokenStored = null;

const refreshToken = () => {
  const currentUser = authenticationService.currentUserValue;

  if (currentUser && authenticationService.shouldRenewToken(currentUser.sessionExpiresAt)) {
    // renew request should be dispatched only the first time token expiration is detected
    // following requests will wait for the renewed token
    if (!renewObservable) {
      renewObservable = ajax({
        url: composeApiUrl('session/renew'),
        responseType: 'json',
        method: 'POST',
        withCredentials: true,
        headers: composeHeaders({}),
        body: {}
      });

      renewObservable.subscribe(storeNewToken);

      // create new subject each time token will be renewed
      onTokenStored = new Subject();
    }

    // returning this subject to the pipe will make all requests wait for the renewed token
    return onTokenStored.asObservable();
  }

  return of(null);
};

const storeNewToken = ({ response }) => {
  // set the new token and send it to all subscribers
  authenticationService.storeUserData(response);
  onTokenStored.next(null);

  // reset global renewObservable and complete the subject (drop subscribers)
  renewObservable = null;
  onTokenStored.complete();
};
