import React from 'react';
import PropTypes from 'prop-types';

import './sass/full-page.scss';

const FullPage = ({ children }) => <div className="full-page">{children}</div>;

FullPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FullPage;
